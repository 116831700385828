$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
.Lips {
	position: absolute;
	left: vw-calc(-290);
	right: vw-calc(-290);
	top: vw-calc(-35);
	bottom: vw-calc(-26);
	padding: vw-calc(40) vw-calc(50);
	z-index: 10;
	color: white;
	@include no-select();
	background-image: url('../../../assets/images/background.jpg');
	background-size: cover;
	background-position: top left;
	font-family: "Fivo Sans";
	&::before {
		content:"";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($bg-dark, .8);
	}

	.Button.Button--backBtn {
		width: vw-calc(150);
		height: vw-calc(65);
		margin-top: vw-calc(30);
		font-size: vw-calc(20);
		font-weight: 600;
		padding-top: vw-calc(17);
		background-image: url('../../../assets/images/prev-step-button.svg');
		background-size: contain;
		background-repeat: no-repeat;
	}

	.Lips-selectedGridCoordinates {
		width: vw-calc(170);
		height: vw-calc(67);
		background-color: $bg-gradient-dark;
		margin-top: vw-calc(50);
		margin-right: vw-calc(20);
		font-size: vw-calc(20);
		@include inline-flex('center', 'center', 'row');
	}
}

.Lips-close {
	position: absolute;
	top: vw-calc(10);
	right: vw-calc(45);
	width: vw-calc(30);
	height: vw-calc(30);
	background-image: url('../../../assets/images/icon-x.svg');
	background-size: contain;
	background-repeat: no-repeat;
	cursor: pointer;
	@include no-select();
	&:hover {@include scale(1.1);}
}

.Lips-content {
	position: relative;
	width: vw-calc(1187);
	height: vw-calc(630);
	background-image: linear-gradient($bg-gradient-light, $bg-gradient-dark);
	border-bottom: vw-calc(2) solid $cyan;
	padding: vw-calc(20) vw-calc(45);
	margin-top: vw-calc(20);
	&::after {
		content:"";
		width: vw-calc(80);
		height: vw-calc(80);
		position: absolute;
		top: 0;
		right: 0;
		background-image: url('../../../assets/images/corner-triangle.svg');
		background-size: 100%;
		background-position: center;
	}
}

.Lips-rockChoices {
	@include flex('space-between', 'center', 'row');
	width: 100%;
	height: 100%;
	padding: vw-calc(10) vw-calc(70);
	
	.Lips-rockChoice {
		@include flex('center', 'center', 'column');
		.Lips-rockChoiceImg {
			width: vw-calc(250);
			height: vw-calc(236);
			border: vw-calc(3) solid $cyan;
		}
	
		.Button{
			margin-top: vw-calc(35);
			width: vw-calc(130);
		}
	}
}

.Lips-squareChoices {
	.Lips-squareSelection {
		position: absolute;
		top: vw-calc(50);
		left: vw-calc(630);
		font-size: vw-calc(24);
		font-weight: 600;
		width: vw-calc(400);
		height: vw-calc(530);
	}

	.Button.deleteBtn {
		width: vw-calc(170);
		height: vw-calc(63);
		padding-top: vw-calc(20);
		position: absolute;
		top: vw-calc(88);
		right: vw-calc(35);
	}

	.Lips-gridCoordinates {
		position: absolute;
		top: vw-calc(180);
		.Button.coordBtn {
			width: vw-calc(73);
			height: vw-calc(50);
			display: inline-block;
			margin-top: vw-calc(20);
			margin-right: vw-calc(24);

			&.selected {
				background-image: linear-gradient(lighten($bg-gradient-light, 5%), lighten($bg-gradient-dark, 5%));
				color: white;
			}
		}
	}

	.Button.analyseBtn {
		width: vw-calc(170);
		height: vw-calc(65);
		padding-top: vw-calc(23);
		position: absolute;
		bottom: vw-calc(20);
		right: vw-calc(35);
	}
	
	.Lips-rockGrid {
		position: relative;
		.Lips-chosenRock {
			margin-top: vw-calc(30);
			margin-left: vw-calc(30);
			width: vw-calc(442);
			height: vw-calc(417);
		}
		table {
			position: absolute;
			border-collapse: collapse;
			top: 0;
			left: 0;
			td, th {
				border: vw-calc(2) solid $cyan;
				text-align: center;
				width: vw-calc(85);
				height: vw-calc(85);
				transition: all 0.3s ease-in-out;
			}
			thead {
				th {
					border-top: none;
					vertical-align: top;
					height: vw-calc(60);
					&:first-of-type {
						border-left: none;
					}
				}
			}
			tbody {
				tr td:first-of-type {
					width: vw-calc(87);
					border-left: none;
					text-align: left;
				}
				tr td:last-of-type {
					border-right: none;
					width: vw-calc(45);
				}
				tr:last-of-type td {
					border-bottom: none;
					height: vw-calc(47);
				}
			}
			td.selected {
				border: vw-calc(3) solid white;
				box-shadow: inset 0px 0px 0px vw-calc(2) white;
			}
			td.prevSelected {
				position: relative;
				&::after {
					content: "";
					width: vw-calc(85);
					height: vw-calc(85);
					background-color: rgba(white, 0.45);
					position: absolute;
					top: 0;
					left: 0;
				}
			}
		}
	}
}

.Lips-showGraph {
	.Lips-graphImg {
		position: absolute;
		top: vw-calc(-50);
		left: vw-calc(-60);
		width: vw-calc(1000);
		height: vw-calc(690);
	}
	.Lips-selections {
		position: absolute;
		top: vw-calc(50);
		right: vw-calc(100);
		font-size: vw-calc(24);
		font-weight: 600;
	}
	.Button.Button--backBtn {
		position: absolute;
		bottom: vw-calc(20);
		right: vw-calc(5);
	}
}