$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.ConnectionStatus {
	&-overlay {
		display: none;
		position: absolute;
		z-index: 1700;
		background-color: rgba(0,0,0,0.9);
		width: 100%;
		height: 100%;
		min-height: vw-calc(720);
		left: 0;
		top: 0;

		&.offline {
			display: flex;
			@include flex('center','flex-start');
			background-color: rgba(black, .7);
		}
	}
	&-body {
		border-radius: vw-calc(500);
		text-align: center;
		padding-top: vw-calc(200);
		font-weight: 700;
		.ConnectionStatus-title {
			font-size: vw-calc(45);
			color: $cyan;
			text-transform: uppercase;
			margin-bottom: vw-calc(30);
		}
		p {
			font-size: vw-calc(35);
			color: white;
			margin: vw-calc(0) 0;
		}
	}
}
