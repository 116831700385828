$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
.Puzzle {
	position: relative;
	@include no-select();
}

.Puzzle-title {
	font-family: "Fivo Sans";
	font-size: vw-calc(24);
	margin: vw-calc(15) 0;
	@include no-select();
}

.Puzzle-info {
	@include no-select();
}
.Puzzle-infoImage {
	display: inline-block;
	vertical-align: middle;
	width: vw-calc(100);
	height: vw-calc(100);
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
	&--capri-sonne {
		background-image: url('../../../assets/images/minigames/puzzles/food/capri-sonne.svg');
		background-size: auto 100%;
	}
	&--apple {
		background-image: url('../../../assets/images/minigames/puzzles/food/apple.svg');
		background-size: auto vw-calc(84);
	}
	&--marsbar {
		background-image: url('../../../assets/images/minigames/puzzles/food/marsbar.svg');
		background-size: auto vw-calc(40);
	}
	&--pizza {
		background-image: url('../../../assets/images/minigames/puzzles/food/pizza.svg');
		background-size: auto vw-calc(70);
	}
}
.Puzzle-infoText {
	display: inline-block;
	vertical-align: middle;
	font-family: "Fivo Sans";
	font-size: vw-calc(24);
}

.Puzzle-answerBtn {
	position: absolute;
	bottom: vw-calc(36);
	right: vw-calc(0);
	width: vw-calc(120);
	height: vw-calc(65);
	font-family: "Fivo Sans";
	font-size: vw-calc(20);
	line-height: vw-calc(65);
	text-align: center;
	text-transform: uppercase;
	background-image: linear-gradient(#012F2F, #002223, #002F2C);
	border-top: vw-calc(2) solid $cyan;
	border-bottom: vw-calc(2) solid $cyan;
	line-height: 1;
	padding-top: vw-calc(20);
	cursor: pointer;
	opacity: 0;
	@include fadeIn(0.5);
	@include no-select();

	&--teacher {
		right: vw-calc(140);
	}
}

.Puzzle-areas {
	position: relative;
	width: 100%;
	height: vw-calc(415);
	background-size: 100% auto;
	background-position: top left;
	background-repeat: no-repeat;
	@include no-select();
}

.Puzzle-area {
	position: absolute;
	width: vw-calc(195);
	height: vw-calc(55);
	background-image: linear-gradient(#012F2F, #002223, #002F2C);
	@include no-select();
	span {
		display: block;
		font-size: vw-calc(18);
		font-family: "Fivo Sans";
		font-weight: 600;
		line-height: vw-calc(56);
		text-align: center;
		
	}
}

.Puzzle-areaLabel {
	position: absolute;
	font-size: vw-calc(20);
	font-family: "Fivo Sans";
	font-weight: 600;
}

.Puzzle-pieces {
	position: relative;
	width: vw-calc(1000);
	margin-top: vw-calc(20);
	@include flex("flex-start", "center");
	@include flex-wrap(wrap);
	@include no-select();
}


.Puzzle-piece {
	@include flex("center", "center");
	position: relative;
	width: vw-calc(195);
	height: vw-calc(55);
	margin-bottom: vw-calc(20);
	margin-right: vw-calc(20);
	background-image: linear-gradient($bg-gradient-light, $bg-gradient-dark);
	border-top: vw-calc(2) solid $btn-gradient-light;
	border-bottom: vw-calc(2) solid $btn-gradient-light;
	padding-left: vw-calc(71);
	padding-right: vw-calc(30);
	font-family: "Fivo Sans";
	font-size: vw-calc(16);
	font-weight: vw-calc(600);
	line-height: vw-calc(16);
	text-align: center;
	cursor: not-allowed;
	@include no-select();
	&::before {
		content: "";
		position: absolute;
		top: vw-calc(-1);
		left: 0;
		width: vw-calc(55);
		height: vw-calc(52);
		background-image: linear-gradient($btn-gradient-light, #009898, #00ACAC, $btn-gradient-light);
		border-top: vw-calc(1) solid $btn-gradient-light;
		border-bottom: vw-calc(1) solid $btn-gradient-light;
	}
	&--draggable {
		cursor: move;
	}
	&--placed {
		background-image: linear-gradient($btn-gradient-light, $btn-gradient-dark);
		border: none;
		&::before {
			top: 0;
			height: vw-calc(55);
			background-image: linear-gradient($bg-gradient-light, $bg-gradient-dark);
			border:none;
		}
	}
	&--wrong {
		@include box-shadow(0, 0, vw-calc(10), vw-calc(2), $red);
	}
	&--correct,
	&--solved {
		@include box-shadow(0, 0, vw-calc(8), 0, $cyan);
		cursor: not-allowed;
	}
	&--notAvailable {
		opacity: 0.4;
	}
	&--iconOnly,
	&--d1,
	&--d2 {
		width: vw-calc(57);
		height: vw-calc(57);
		background-image: linear-gradient(0.5turn, #00C5C5, #009898, #00ACAC, #00CBCB);
	}
}

.Puzzle-arrow {
	position: absolute;
	top: vw-calc(0);
	display: inline-block;
	vertical-align: top;
	width: vw-calc(30);
	height: vw-calc(30);
	background-image: url('../../../assets/images/icon-back.svg');
	background-size: auto vw-calc(30);
	background-position: center top;
	background-repeat: no-repeat;
	margin-right: vw-calc(5);
	cursor: pointer;
	@include no-select();
	&--left {
		left: vw-calc(80);
	}
	&--right {
		left: vw-calc(720);
		@include rotate(180deg);
	}
}

.Puzzle-icon {
	position: absolute;	
	top: 0;
	left: 0;
	width: vw-calc(55);
	height: vw-calc(55);
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
	&--potential-energy {
		background-image: url('../../../assets/images/minigames/puzzles/energy/potential-energy.svg');
		background-size: vw-calc(40) auto;
	}
	&--nuclear-energy {
		background-image: url('../../../assets/images/minigames/puzzles/energy/nuclear-energy.svg');
		background-size: vw-calc(35) auto;
	}
	&--electric-energy {
		background-image: url('../../../assets/images/minigames/puzzles/energy/electric-energy.svg');
		background-size: auto vw-calc(40);
	}
	&--thermal-energy {
		background-image: url('../../../assets/images/minigames/puzzles/energy/thermal-energy.svg');
		background-size: auto vw-calc(40);
	}
	&--chemical-energy {
		background-image: url('../../../assets/images/minigames/puzzles/energy/chemical-energy.svg');
		background-size: vw-calc(30) auto;
	}
	&--radiation-energy {
		background-image: url('../../../assets/images/minigames/puzzles/energy/radiation-energy.svg');
		background-size: vw-calc(35) auto;
	}
	&--kinetic-energy {
		background-image: url('../../../assets/images/minigames/puzzles/energy/kinetic-energy.svg');
		background-size: vw-calc(40) auto;
	}
	&--arrows-to-right {
		background-image: url('../../../assets/images/minigames/puzzles/soundwave/arrows-to-right.svg');
		background-size: vw-calc(30) auto;
	}
	&--arrows-downwards {
		background-image: url('../../../assets/images/minigames/puzzles/soundwave/arrows-downwards.svg');
		background-size: vw-calc(55) auto;
	}
	&--arrows-without-fluctuations {
		background-image: url('../../../assets/images/minigames/puzzles/soundwave/arrows-without-fluctuations.svg');
		background-size: vw-calc(23) auto;
		left: vw-calc(3);
	}
	&--arrows-towards-center {
		background-image: url('../../../assets/images/minigames/puzzles/soundwave/arrows-towards-center.svg');
		background-size: vw-calc(35) auto;
	}
	&--arrows-to-left {
		background-image: url('../../../assets/images/minigames/puzzles/soundwave/arrows-to-left.svg');
		background-size: vw-calc(30) auto;
	}
	&--arrows-upwards {
		background-image: url('../../../assets/images/minigames/puzzles/soundwave/arrows-upwards.svg');
		background-size: vw-calc(55) auto;
	}
	&--arrows-with-fluctuations {
		background-image: url('../../../assets/images/minigames/puzzles/soundwave/arrows-with-fluctuations.svg');
		background-size: vw-calc(23) auto;
		left: vw-calc(2);
	}
	&--arrows-from-center {
		background-image: url('../../../assets/images/minigames/puzzles/soundwave/arrows-from-center.svg');
		background-size: vw-calc(35) auto;
	}
	&--graph-1,
	&--graph-2,
	&--graph-3,
	&--graph-4,
	&--graph-5 {
		width: 100%;
		height: 100%;
		background-size: vw-calc(589) vw-calc(153);
		background-position: top vw-calc(3) left vw-calc(6);
		background-repeat: no-repeat;
		background-color: $btn-gradient-light;
	}
	&--graph-1 {background-image: url('../../../assets/images/minigames/puzzles/soundwave/graph-1.svg');}
	&--graph-2 {background-image: url('../../../assets/images/minigames/puzzles/soundwave/graph-2.svg');}
	&--graph-3 {background-image: url('../../../assets/images/minigames/puzzles/soundwave/graph-3.svg');}
	&--graph-4 {background-image: url('../../../assets/images/minigames/puzzles/soundwave/graph-4.svg');}
	&--graph-5 {background-image: url('../../../assets/images/minigames/puzzles/soundwave/graph-5.svg');}

	&--wave-1 {background-image: url('../../../assets/images/minigames/puzzles/soundwaveMath/wave-1.svg');}
	&--wave-2 {background-image: url('../../../assets/images/minigames/puzzles/soundwaveMath/wave-2.svg');}
	&--wave-3 {background-image: url('../../../assets/images/minigames/puzzles/soundwaveMath/wave-3.svg');}
	&--wave-4 {background-image: url('../../../assets/images/minigames/puzzles/soundwaveMath/wave-4.svg');}
	&--wave-5 {background-image: url('../../../assets/images/minigames/puzzles/soundwaveMath/wave-5.svg');}
	&--wave-6 {background-image: url('../../../assets/images/minigames/puzzles/soundwaveMath/wave-6.svg');}
	&--wave-7 {background-image: url('../../../assets/images/minigames/puzzles/soundwaveMath/wave-7.svg');}
	&--wave-8 {background-image: url('../../../assets/images/minigames/puzzles/soundwaveMath/wave-8.svg');}
	&--wave-9 {background-image: url('../../../assets/images/minigames/puzzles/soundwaveMath/wave-9.svg');}
	&--wave-10 {background-image: url('../../../assets/images/minigames/puzzles/soundwaveMath/wave-10.svg');}
	&--wave-11 {background-image: url('../../../assets/images/minigames/puzzles/soundwaveMath/wave-11.svg');}

	&--molecule {background-image: url('../../../assets/images/minigames/puzzles/soundwave2/molecule.svg');}
	&--dna {
		background-size: auto;
		background-image: url('../../../assets/images/minigames/puzzles/soundwave2/dna.svg');
	}
	&--family {background-image: url('../../../assets/images/minigames/puzzles/soundwave2/family.svg');}
	&--virus {background-image: url('../../../assets/images/minigames/puzzles/soundwave2/virus.svg');}
	&--football-field {background-image: url('../../../assets/images/minigames/puzzles/soundwave2/football-field.svg');}
	&--bacteria {background-image: url('../../../assets/images/minigames/puzzles/soundwave2/bacteria.svg');}
	&--needle {background-image: url('../../../assets/images/minigames/puzzles/soundwave2/needle.svg');}
	&--x-rays {background-image: url('../../../assets/images/minigames/puzzles/soundwave2/x-rays.svg');}
	&--microwaves {background-image: url('../../../assets/images/minigames/puzzles/soundwave2/microwaves.svg');}
	&--gamma-rays {background-image: url('../../../assets/images/minigames/puzzles/soundwave2/gamma-rays.svg');}
	&--infrared-waves {background-image: url('../../../assets/images/minigames/puzzles/soundwave2/infrared-waves.svg');}
	&--radio-waves-router {background-image: url('../../../assets/images/minigames/puzzles/soundwave2/radio-waves-router.svg');}
	&--ultraviolet {background-image: url('../../../assets/images/minigames/puzzles/soundwave2/ultraviolet.svg');}
	&--radio-waves-radio {background-image: url('../../../assets/images/minigames/puzzles/soundwave2/radio-waves-radio.svg');}
}

.Puzzle-pieceLabels {
	width: 100%;
}

.Puzzle-pieceLabel {
	display: inline-block;
	font-size: vw-calc(20);
	font-family: "Fivo Sans";
	font-weight: 600;
	background-size: auto 90%;
	background-position: center;
	background-repeat: no-repeat;
	&--pizza {
		background-image: url('../../../assets/images/minigames/puzzles/food/pizza.svg');
		background-size: auto vw-calc(62);
	}
	&--apple {
		background-image: url('../../../assets/images/minigames/puzzles/food/apple.svg');
		background-size: auto vw-calc(64);
	}
	&--tuna {
		background-image: url('../../../assets/images/minigames/puzzles/food/tuna.svg');
		background-size: auto vw-calc(52);
	}
	&--coffee {
		background-image: url('../../../assets/images/minigames/puzzles/food/coffee.svg');
		background-size: auto vw-calc(68);
	}
	&--smarties {
		background-image: url('../../../assets/images/minigames/puzzles/food/smarties.svg');
		background-size: auto vw-calc(50);
	}
	&--capri-sonne {
		background-image: url('../../../assets/images/minigames/puzzles/food/capri-sonne.svg');
		background-size: auto vw-calc(76);
	}
	&--hazelnuts {
		background-image: url('../../../assets/images/minigames/puzzles/food/hazelnuts.svg');
		background-size: auto vw-calc(68);
	}
	&--tortilla {
		background-image: url('../../../assets/images/minigames/puzzles/food/tortilla.svg');
		background-size: auto vw-calc(56);
	}
	&--crackers {
		background-image: url('../../../assets/images/minigames/puzzles/food/crackers.svg');
		background-size: auto vw-calc(56);
	}
	&--potato {
		background-image: url('../../../assets/images/minigames/puzzles/food/potato.svg');
		background-size: auto vw-calc(45);
	}
	&--water {
		background-image: url('../../../assets/images/minigames/puzzles/food/water.svg');
		background-size: auto vw-calc(63);
	}
	&--spaghetti {
		background-image: url('../../../assets/images/minigames/puzzles/food/spaghetti.svg');
		background-size: auto vw-calc(45);
	}
	&--marsbar {
		background-image: url('../../../assets/images/minigames/puzzles/food/marsbar.svg');
		background-size: auto vw-calc(45);
	}
	&--cheese {
		background-image: url('../../../assets/images/minigames/puzzles/food/cheese.svg');
		background-size: auto vw-calc(52);
	}
	&--salat {
		background-image: url('../../../assets/images/minigames/puzzles/food/salat.svg');
		background-size: auto vw-calc(60);
	}
}

.Puzzle-pieceTextLabel {
	color: $btn-gradient-light;
	font-size: vw-calc(18);
	font-weight: 600;
	position: absolute;
}

.Puzzle.Puzzle--1 {
	.Puzzle-areas {background-image: url('../../../assets/images/minigames/puzzles/puzzle-1-bg.svg');}
	.Puzzle-area {
		&--1 {top: vw-calc(198); left: vw-calc(80);}
		&--2 {top: vw-calc(310); left: vw-calc(353);}
		&--3 {top: vw-calc(254); left: vw-calc(614);}
		&--4 {top: vw-calc(50); left: vw-calc(908);}
	}
}

.Puzzle.Puzzle--2 {
	.Puzzle-areas {background-image: url('../../../assets/images/minigames/puzzles/puzzle-2-bg.svg');}
	.Puzzle-area {
		&--1 {top: vw-calc(240); left: vw-calc(186);}
		&--2 {top: vw-calc(85); left: vw-calc(541);}
		&--3 {top: vw-calc(338); left: vw-calc(471);}
	}
}

.Puzzle.Puzzle--3 {
	.Puzzle-areas {background-image: url('../../../assets/images/minigames/puzzles/puzzle-3-bg.svg');}
	.Puzzle-area {
		&--1 {top: vw-calc(308); left: vw-calc(338);}
		&--2 {top: vw-calc(308); left: vw-calc(625);}
	}
}

.Puzzle.Puzzle--4 {
	.Puzzle-areas {background-image: url('../../../assets/images/minigames/puzzles/puzzle-4-bg.svg');}
	.Puzzle-area {
		&--1 {top: vw-calc(65); left: vw-calc(161);}
		&--2 {top: vw-calc(63); left: vw-calc(637);}
		&--3 {top: vw-calc(230); left: vw-calc(842);}
	}
}

.Puzzle.Puzzle--5 {
	.Puzzle-areas {background-image: url('../../../assets/images/minigames/puzzles/puzzle-5-bg.svg');}
	.Puzzle-area {
		&--1 {top: vw-calc(80); left: vw-calc(218);}
		&--2 {top: vw-calc(321); left: vw-calc(459);}
		&--3 {top: vw-calc(44); left: vw-calc(781);}
	}
}

.Puzzle.Puzzle--6 {
	.Puzzle-areas {background-image: url('../../../assets/images/minigames/puzzles/puzzle-6-bg.svg');}
	.Puzzle-area {
		&--1 {top: vw-calc(150); left: vw-calc(73);}
		&--2 {top: vw-calc(300); left: vw-calc(400);}
		&--3 {top: vw-calc(310); left: vw-calc(720);}
	}
}

.Puzzle.Puzzle--7 {
	.Puzzle-areas {background-image: url('../../../assets/images/minigames/puzzles/puzzle-7-bg.svg');}
	.Puzzle-area {
		&--1 {top: vw-calc(308); left: vw-calc(58);}
		&--2 {top: vw-calc(308); left: vw-calc(329);}
		&--3 {top: vw-calc(120); left: vw-calc(916);}
	}
}

.Puzzle.Puzzle--8 {
	.Puzzle-areas {background-image: url('../../../assets/images/minigames/puzzles/puzzle-8-bg.svg');}
	.Puzzle-area {
		width: vw-calc(170);
		height: vw-calc(55);
		&--1 {top: vw-calc(338); left: vw-calc(85);}
		&--2 {top: vw-calc(314); left: vw-calc(265);}
		&--3 {top: vw-calc(290); left: vw-calc(445);}
		&--4 {top: vw-calc(266); left: vw-calc(625);}
		&--5 {top: vw-calc(242); left: vw-calc(805);}
		&--6 {top: vw-calc(218); left: vw-calc(985);}
	}
	.Puzzle-piece.Puzzle-piece--placed {
		width: vw-calc(170);
		height: vw-calc(55);
		padding-left: vw-calc(65);
	}
	.Puzzle-area--2 .Puzzle-piece.Puzzle-piece--placed {
		padding-left: vw-calc(85);
	}
}

.Puzzle.Puzzle--9,
.Puzzle.Puzzle--10,
.Puzzle.Puzzle--11,
.Puzzle.Puzzle--15,
.Puzzle.Puzzle--27 {
	margin-top: vw-calc(20);
	background-image: linear-gradient($bg-gradient-light, $bg-gradient-dark);
	.Puzzle-title {position: absolute; z-index: 2; left: vw-calc(20);}
	.Puzzle-areas {
		height: vw-calc(395);
		background-image: url('../../../assets/images/minigames/puzzles/puzzle-9-bg.svg');
	}
	.Puzzle-area {
		width: vw-calc(152);
		height: vw-calc(58);
		span {display: none;	}
		&--1 {top: vw-calc(164); left: vw-calc(40);}
		&--2 {top: vw-calc(164); left: vw-calc(280);}
		&--3 {top: vw-calc(164); left: vw-calc(515);}
		&--4 {top: vw-calc(164); left: vw-calc(750);}
		&--5 {top: vw-calc(164); left: vw-calc(985);}
	}
	.Puzzle-areaLabel {
		width: vw-calc(152);
		height: vw-calc(58);
		text-align: center;
		&--1 {top: vw-calc(264); left: vw-calc(40);}
		&--2 {top: vw-calc(264); left: vw-calc(280);}
		&--3 {top: vw-calc(264); left: vw-calc(515);}
		&--4 {top: vw-calc(264); left: vw-calc(750);}
		&--5 {top: vw-calc(264); left: vw-calc(985);}
	}
	.Puzzle-pieces {
		width: 100%;
		padding: vw-calc(10) vw-calc(30) vw-calc(0) vw-calc(30);
	}
	.Puzzle-piece {
		width: vw-calc(152);
		height: vw-calc(58);
		margin-right: vw-calc(30);
		padding-left: vw-calc(10);
		padding-right: vw-calc(10);
		font-size: vw-calc(14);
		font-weight: 600;
		&::before {display: none;}
	}
	.Puzzle-pieceLabels {
		padding: 0 vw-calc(30) vw-calc(10) vw-calc(30);		
	}
	.Puzzle-pieceLabel {
		width: vw-calc(152);
		height: vw-calc(80);
		margin-right: vw-calc(30);
	}
}

.Puzzle.Puzzle--12,
.Puzzle.Puzzle--13,
.Puzzle.Puzzle--14 {
	margin-top: vw-calc(20);
	background-image: linear-gradient($bg-gradient-light, $bg-gradient-dark);
	.Puzzle-title {position: absolute; z-index: 2; left: vw-calc(20);}
	.Puzzle-info {position:absolute; z-index: 2; left: vw-calc(20); top: vw-calc(50);}
	.Puzzle-areas {
		height: vw-calc(477);
		background-image: url('../../../assets/images/minigames/puzzles/puzzle-12-bg.svg');
	}
	.Puzzle-area {
		width: vw-calc(152);
		height: vw-calc(58);
		span {display: none;	}
		&--1 {top: vw-calc(215); left: vw-calc(115);}
		&--2 {top: vw-calc(215); left: vw-calc(385);}
		&--3 {top: vw-calc(215); left: vw-calc(655);}
		&--4 {top: vw-calc(215); left: vw-calc(920);}
	}
	.Puzzle-pieces {
		width: 100%;
		padding: vw-calc(10) vw-calc(30) vw-calc(15) vw-calc(30);
		.Puzzle-piece:nth-last-child(1) {display: none;}
	}
	.Puzzle-piece {
		width: vw-calc(152);
		height: vw-calc(58);
		margin-right: vw-calc(5);
		padding-left: vw-calc(10);
		padding-right: vw-calc(10);
		font-size: vw-calc(14);
		font-weight: 600;
		&::before {display: none;}
	}
}

.Puzzle.Puzzle--16 {
	.Puzzle-areas {
		background-image: url('../../../assets/images/minigames/puzzles/puzzle-16-bg.svg');
	}
	.Puzzle-area {
		span {display: none;}
		&--1 {top: vw-calc(72); right: vw-calc(319); width: vw-calc(57);}
		&--2 {top: vw-calc(72); right: vw-calc(180); width: vw-calc(57);}
		&--3 {top: vw-calc(163); right: vw-calc(180);}
		&--4 {top: vw-calc(225); right: vw-calc(180);}
		&--5 {top: vw-calc(288); right: vw-calc(180);}
		&--6 {top: vw-calc(351); right: vw-calc(180);}
	}
	.Puzzle-areaLabel {
		width: vw-calc(150);
		font-size: vw-calc(18);
		line-height: vw-calc(18);
		&--1 {top: vw-calc(27); right: vw-calc(227);}
		&--2 {top: vw-calc(27); right: vw-calc(87);}
	}
	.Puzzle-pieces {
		width: 100%;
	}
	.Puzzle-piece {
		padding-left: 0;
		padding-right: 0;
		font-weight: 600;
		&::before {display: none;}
		&:nth-child(4) {margin-right: vw-calc(72);}
		&:nth-child(5),
		&:nth-child(6),
		&:nth-child(7),
		&:nth-child(8),
		&:nth-child(11),
		&:nth-child(12) {
			position: absolute;
			bottom: vw-calc(-75);
		}
		&:nth-child(6) {left: vw-calc(78);}
		&:nth-child(7) {left: vw-calc(155);}
		&:nth-child(8) {left: vw-calc(232);}
		&:nth-child(11) {left: vw-calc(360);}
		&:nth-child(12) {left: vw-calc(575);}
	}
}

.Puzzle.Puzzle--17 {
	.Puzzle-areas {
		background-image: url('../../../assets/images/minigames/puzzles/puzzle-17-bg.svg');
	}
	.Puzzle-area {
		span {display: none;}
		width: vw-calc(603);
		height: vw-calc(157);
		top: vw-calc(133);
		left: vw-calc(115);
		background-color: transparent;
		background-size: vw-calc(589) vw-calc(153);
		background-position: top vw-calc(3) left vw-calc(6);
		background-repeat: no-repeat;
		background-image: url('../../../assets/images/minigames/puzzles/soundwave/graph-grid.svg');
		&--placed {background-image: none;}
	}
	.Puzzle-pieces {
		margin-top: vw-calc(10);
		padding-left: vw-calc(113);
	}
	.Puzzle-piece {
		width: vw-calc(605);
		height: vw-calc(157);
		padding-left: 0;
		padding-right: 0;
		&::before {display: none;}
		&--placed {background-image: none;}
		&--placed .Puzzle-icon {background-color: transparent;}
	}
}

.Puzzle.Puzzle--18 {
	.Puzzle-areas {
		height: vw-calc(462);
		background-image: url('../../../assets/images/minigames/puzzles/puzzle-18-bg.svg');
	}
	.Puzzle-area {
		span {display: none;}
		width: vw-calc(118);
		height: vw-calc(44);
		&--1 {top: vw-calc(15); left: vw-calc(18);}
		&--2 {top: vw-calc(15); left: vw-calc(599);}
		&--3 {top: vw-calc(408); left: vw-calc(18);}
		&--4 {top: vw-calc(408); left: vw-calc(599);}
	}
	.Puzzle-piece {
		font-weight: 600;
		width: vw-calc(118);
		height: vw-calc(44);
		padding-left: 0;
		padding-right: 0;
		&::before {display: none;}
	}
}

.Puzzle.Puzzle--19 {
	.Puzzle-areas {
		background-image: url('../../../assets/images/minigames/puzzles/puzzle-19-bg.svg');
	}
	.Puzzle-area {
		span {display: none;}
		height: vw-calc(44);
		&--1 {top: vw-calc(300); left: vw-calc(905); width: vw-calc(84);}
		&--2 {top: vw-calc(300); left: vw-calc(1000); width: vw-calc(49);}
	}
	.Puzzle-areaLabel {
		font-size: vw-calc(18);
		&--1 {top: vw-calc(308); left: vw-calc(803);}
	}
	.Puzzle-pieces {
		margin-top: vw-calc(55);
	}
	.Puzzle-piece {
		font-weight: 600;
		width: vw-calc(84);
		height: vw-calc(44);
		padding-left: 0;
		padding-right: 0;
		margin-right: vw-calc(10);
		&::before {display: none;}
		&:nth-child(3) {margin-right: vw-calc(72);}

		&:nth-child(4),
		&:nth-child(5),
		&:nth-child(9),
		&:nth-child(10) {
			position: absolute;
			bottom: vw-calc(-60);
		}
		&:nth-child(5) {left: vw-calc(95);}
		&:nth-child(9) {left: vw-calc(345);}
		&:nth-child(10) {left: vw-calc(404); padding-left: vw-calc(5);}

		&--unit,
		&--formulaSymbol {
			width: vw-calc(49);
		}
	}
	.Puzzle-pieceTextLabel {
		bottom: vw-calc(70);
		&--6 {left: vw-calc(344);}
	}
}

.Puzzle.Puzzle--20 {
	.Puzzle-areas {
		background-image: url('../../../assets/images/minigames/puzzles/puzzle-20-bg.svg');
	}
	.Puzzle-area {
		span {display: none;}
		height: vw-calc(44);
		&--1 {bottom: vw-calc(16); left: vw-calc(116); width: vw-calc(84);}
		&--2 {bottom: vw-calc(16); left: vw-calc(222); width: vw-calc(49);}
		&--3 {bottom: vw-calc(16); left: vw-calc(419); width: vw-calc(120);}
		&--4 {bottom: vw-calc(16); left: vw-calc(565); width: vw-calc(120);}
		&--5 {bottom: vw-calc(16); left: vw-calc(707); width: vw-calc(120);}
		&--6 {bottom: vw-calc(16); left: vw-calc(946); width: vw-calc(49);}
		&--7 {bottom: vw-calc(16); left: vw-calc(1022); width: vw-calc(49);}
		&--8 {bottom: vw-calc(16); left: vw-calc(1096); width: vw-calc(49);}
	}
	.Puzzle-areaLabel {
		font-size: vw-calc(18);
		&--1 {bottom: vw-calc(18); left: vw-calc(16); width: vw-calc(92); line-height: vw-calc(18);}
		&--3 {bottom: vw-calc(24); left: vw-calc(318);}
		&--4 {bottom: vw-calc(24); left: vw-calc(547);}
		&--5 {bottom: vw-calc(24); left: vw-calc(692);}
		&--6 {bottom: vw-calc(24); left: vw-calc(874);}
		&--7 {bottom: vw-calc(24); left: vw-calc(1003);}
		&--8 {bottom: vw-calc(24); left: vw-calc(1079);}
	}
	.Puzzle-pieces {
		margin-top: vw-calc(55);
	}
	.Puzzle-piece {
		font-weight: 600;
		width: vw-calc(84);
		height: vw-calc(44);
		padding-left: 0;
		padding-right: 0;
		margin-right: vw-calc(10);
		&::before {display: none;}
		&--unit,
		&--formulaSymbol {
			width: vw-calc(49);
		}
		&--formulaWord {
			width: vw-calc(120);
		}
		&:nth-child(3),
		&:nth-child(8),
		&:nth-child(12) {margin-right: vw-calc(22);}
		
		&:nth-child(4),
		&:nth-child(5),
		&:nth-child(9),
		&:nth-child(10),
		&:nth-child(13),
		&:nth-child(14),
		&:nth-child(17),
		&:nth-child(18) {
			position: absolute;
			bottom: vw-calc(-60);
		}
		&:nth-child(5) {left: vw-calc(94);}
		&:nth-child(9) {left: vw-calc(295);}
		&:nth-child(10) {left: vw-calc(354);}
		&:nth-child(13) {left: vw-calc(483);}
		&:nth-child(14) {left: vw-calc(613);}
		&:nth-child(17) {left: vw-calc(754);}
		&:nth-child(18) {left: vw-calc(814);}
	}
	.Puzzle-pieceTextLabel {
		bottom: vw-calc(70);
		&--6 {left: vw-calc(294);}
		&--11 {left: vw-calc(482);}
		&--15 {left: vw-calc(754);}
	}
}

.Puzzle.Puzzle--21 {
	.Puzzle-areas {
		background-image: url('../../../assets/images/minigames/puzzles/puzzle-21-bg.svg');
	}
	.Puzzle-area {
		span {display: none;}
		width: vw-calc(57);
		height: vw-calc(57);
		top: vw-calc(159);
		&--1 {left: vw-calc(64);}
		&--2 {left: vw-calc(220);}
		&--3 {left: vw-calc(376);}
		&--4 {left: vw-calc(550);}
		&--5 {left: vw-calc(728);}
		&--6 {left: vw-calc(880);}
		&--7 {left: vw-calc(1075);}
	}
}

.Puzzle.Puzzle--22 {
	.Puzzle-areas {
		background-image: url('../../../assets/images/minigames/puzzles/puzzle-22-bg.svg');
	}
	.Puzzle-area {
		span {display: none;}
		width: vw-calc(57);
		height: vw-calc(57);
		top: vw-calc(78);
		&--1 {left: vw-calc(30);}
		&--2 {left: vw-calc(167);}
		&--3 {left: vw-calc(552);}
		&--4 {left: vw-calc(658);}
		&--5 {left: vw-calc(772);}
		&--6 {left: vw-calc(873);}
		&--7 {left: vw-calc(1002);}
	}
}

.Puzzle.Puzzle--21, .Puzzle.Puzzle--22 {
	.Puzzle-piece {
		border: none;
		&::before {
			top: 0;
		}
		&--placed {
			&::before {
				width: vw-calc(57);
				height: vw-calc(57);
			}
		}
		&--iconOnly {
			padding: 0;
			margin-bottom: vw-calc(20);
			margin-right: vw-calc(150);
		}
		.Puzzle-icon {
			width: vw-calc(57);
			height: vw-calc(57);
		}

	}
	.Puzzle-pieceTextLabel {
		position: absolute;
		width: vw-calc(137);
		height: vw-calc(57);
		background-image: linear-gradient($bg-gradient-light, $bg-gradient-dark);
		padding-left: vw-calc(20);
		padding-right: vw-calc(20);
		padding-top: vw-calc(12);
		color: white;
		font-family: "Fivo Sans";
		font-size: vw-calc(16);
		font-weight: vw-calc(600);
		line-height: vw-calc(16);
		text-align: center;
		@include no-select();
		bottom: vw-calc(97);
		&--1 {left: vw-calc(56);}
		&--2 {left: vw-calc(264); padding-top: vw-calc(20);}
		&--3 {left: vw-calc(471);}
		&--4 {left: vw-calc(678);}
		&--5 {left: vw-calc(56); bottom: vw-calc(20);}
		&--6 {left: vw-calc(264); bottom: vw-calc(20); padding: 0; padding-top: vw-calc(20);}
		&--7 {left: vw-calc(471); bottom: vw-calc(20);}
	}
	.Puzzle-answerBtn {
		bottom: vw-calc(41);
	}
}

.Puzzle.Puzzle--23,
.Puzzle.Puzzle--24,
.Puzzle.Puzzle--25,
.Puzzle.Puzzle--26 {
	.Puzzle-title {
		position: absolute;
		top: vw-calc(15);
		left: vw-calc(30);
		z-index: 1;
		font-family: 'Open Sans';
		font-size: vw-calc(16);
		font-weight: 600;
		text-transform: uppercase;
		color: $cyan;
	}
	.Puzzle-areas {
		margin-top: vw-calc(20);
		height: vw-calc(295);
		background-size: 95% auto, 100% auto;
		background-position: bottom vw-calc(30) center, top left;
		background-repeat: no-repeat;
	}
	.Puzzle-area,
	.Puzzle-piece {
		&::before {
			content: '';
			font-family: 'Open Sans';
			font-size: vw-calc(30);
			font-weight: 600;
			height: vw-calc(57);
		}
	}
	.Puzzle-area {
		span {display: none;}
		width: vw-calc(57);
		height: vw-calc(57);
		top: vw-calc(90);
		padding-top: vw-calc(5);
		padding-left: vw-calc(9);
		&--placed {
			::before {content: '';}
		}
	}
	.Puzzle-pieces {
		width: 100%;
	}
	.Puzzle-piece {
		width: vw-calc(30);
		height: vw-calc(30);
		background-image: none;
		padding: 0;
		&::before {
			font-size: vw-calc(16);
			width: vw-calc(30);
			height: vw-calc(30);
			top: unset;
			border: none;
			@include flex('center', 'center');
		}
		&:nth-of-type(7),
		&:nth-of-type(8) {
			margin-top: vw-calc(20);
		}
		&:nth-of-type(8),
		&:nth-of-type(10) {
			margin-right: vw-calc(700);
		}
		&--placed {
			&::before {
				position: absolute;
				background-image: linear-gradient($btn-gradient-light, $btn-gradient-dark);
				width: vw-calc(57);
				height: vw-calc(58);
				font-size: vw-calc(30);
				top: vw-calc(-50);
				left: vw-calc(-9);
			}
		}
		&--placed.Puzzle-piece--solved,
		&--placed.Puzzle-piece--wrong {
			width: vw-calc(57);
			height: vw-calc(58);
			top: vw-calc(-50);
			left: vw-calc(-9);
			&::before {
				top: vw-calc(0);
				left: vw-calc(0);
			}
		}
	}
	.Puzzle-icon {
		width: vw-calc(30);
		height: vw-calc(30);
	}
	.Puzzle-pieceTextLabel {
		position: absolute;
		padding-top: vw-calc(12);
		color: white;
		font-family: "Open Sans";
		font-size: vw-calc(16);
		font-weight: vw-calc(600);
		line-height: vw-calc(18);
		text-align: left;
		@include no-select();
		&--1 {left: vw-calc(40); bottom: vw-calc(295);}
		&--2 {left: vw-calc(570); bottom: vw-calc(295);}
		&--3 {left: vw-calc(40); bottom: vw-calc(245);}
		&--4 {left: vw-calc(570); bottom: vw-calc(245);}
		&--5 {left: vw-calc(40); bottom: vw-calc(195);}
		&--6 {left: vw-calc(570); bottom: vw-calc(195);}
		&--7 {left: vw-calc(40); bottom: vw-calc(125);}
		&--8 {left: vw-calc(340); bottom: vw-calc(125);}
		&--9 {left: vw-calc(40); bottom: vw-calc(76);}
		&--10 {left: vw-calc(340); bottom: vw-calc(76);}
		&--11 {left: vw-calc(40); bottom: vw-calc(25);}
		&--12 {left: vw-calc(340); bottom: vw-calc(25);}
	}
}

.Puzzle.Puzzle--23 {
	.Puzzle-areas {
		background-image: url('../../../assets/images/minigames/puzzles/puzzle-23-bg.svg'), url('../../../assets/images/minigames/puzzles/puzzle-time-bg.png');
	}
	.Puzzle-area {
		&--1 {
			left: vw-calc(350);
			&::before {content: 'D1';}
		}
		&--2 {
			left: vw-calc(588);
			&::before {content: 'D2';}
		}
		&--3 {
			left: vw-calc(1050);
			&::before {content: 'D1';}
		}
		&--4 {
			left: vw-calc(110);
			top: vw-calc(152);
			&::before {content: 'D2';}
		}
	}
	.Puzzle-pieces {
		width: 100%;
	}
	.Puzzle-piece {
		&--d2 {
			margin-right: vw-calc(500);
			&::before {content: 'D2';}
		}
		&--d1 {
			position: relative;
			margin-right: vw-calc(270);
			&::before {content: 'D1';}
		}
	}
}

.Puzzle.Puzzle--24 {
	.Puzzle-areas {
		background-image: url('../../../assets/images/minigames/puzzles/puzzle-24-bg.svg'), url('../../../assets/images/minigames/puzzles/puzzle-time-bg.png');
		background-position: bottom vw-calc(10) left vw-calc(30), top left;
		background-size: 85% auto, 100% auto;
	}
	.Puzzle-area {
		padding-left: vw-calc(6);
		&--1 {
			left: vw-calc(380);
			&::before {content: 'M1';}
		}
		&--2 {
			left: vw-calc(605);
			&::before {content: 'M2';}
		}
		&--3 {
			left: vw-calc(117);
			top: vw-calc(152);
			&::before {content: 'M1';}
		}
		&--4 {
			left: vw-calc(285);
			top: vw-calc(152);
			&::before {content: 'M2';}
		}
	}
	.Puzzle-piece {
		&--m2 {
			margin-right: vw-calc(500);
			&::before {content: 'M2';}
		}
		&--m1 {
			position: relative;
			margin-right: vw-calc(270);
			&::before {content: 'M1';}
		}
		&--placed::before,
		&--placed.Puzzle-piece--solved,
		&--placed.Puzzle-piece--wrong {
			left: vw-calc(-6);
		}
	}
	.Puzzle-pieceTextLabel {
		&--6 {bottom: vw-calc(184);}
	}
}

.Puzzle.Puzzle--25 {
	.Puzzle-areas {
		background-image: url('../../../assets/images/minigames/puzzles/puzzle-25-bg.svg'), url('../../../assets/images/minigames/puzzles/puzzle-time-bg.png');
		background-position: bottom vw-calc(8) left vw-calc(30), top left;
	}
	.Puzzle-area {
		&--1 {
			left: vw-calc(317);
			&::before {content: 'å1';}
		}
		&--2 {
			left: vw-calc(565);
			&::before {content: 'å2';}
		}
		&--3 {
			left: vw-calc(995);
			&::before {content: 'å1';}
		}
		&--4 {
			left: vw-calc(30);
			top: vw-calc(152);
			&::before {content: 'å2';}
		}
	}
	.Puzzle-piece {
		margin-top: vw-calc(0);
		&--å2 {
			margin-right: vw-calc(500);
			&::before {content: 'å2';}
		}
		&--å1 {
			position: relative;
			margin-right: vw-calc(270);
			&::before {content: 'å1';}
		}
		&:nth-of-type(5) {
			margin-right: vw-calc(1140);
		}

		&:nth-of-type(6),
		&:nth-of-type(7) {
			margin-top: vw-calc(20);
		}

		&:nth-of-type(8),
		&:nth-of-type(10) {
			margin-right: vw-calc(270);
		}

		&:nth-of-type(7),
		&:nth-of-type(9) {
			margin-right: vw-calc(830);
		}

		&:nth-of-type(8) {
			margin-top: vw-calc(0);
		}
	}
	.Puzzle-pieceTextLabel {
		&--1 {width: vw-calc(480); bottom: vw-calc(284);}
		&--2 {width: vw-calc(480); bottom: vw-calc(284);}
		&--3 {width: vw-calc(480); bottom: vw-calc(234);}
		&--4 {width: vw-calc(480); bottom: vw-calc(234);}
		&--5 {width: vw-calc(480); bottom: vw-calc(184);}
		&--6 {left: vw-calc(340); bottom: vw-calc(123);}
		&--6 {left: vw-calc(40); bottom: vw-calc(123);}
		&--7 {left: vw-calc(340); bottom: vw-calc(123);}
		&--8 {left: vw-calc(40); bottom: vw-calc(74);}
		&--9 {left: vw-calc(340); bottom: vw-calc(74);}
		&--10 {left: vw-calc(40); bottom: vw-calc(23);}
		&--11 {left: vw-calc(340); bottom: vw-calc(23);}
	}
}

.Puzzle.Puzzle--26 {
	.Puzzle-areas {
		background-image: url('../../../assets/images/minigames/puzzles/puzzle-26-bg.svg'), url('../../../assets/images/minigames/puzzles/puzzle-time-bg.png');
		background-position: bottom vw-calc(57) left vw-calc(30), top left;
	}
	.Puzzle-area {
		&--1 {
			left: vw-calc(380);
			&::before {content: 'Å1';}
		}
		&--2 {
			left: vw-calc(855);
			&::before {content: 'Å2';}
		}
		&--3 {
			left: vw-calc(265);
			top: vw-calc(152);
			&::before {content: 'Å1';}
		}
		&--4 {
			left: vw-calc(585);
			top: vw-calc(152);
			&::before {content: 'Å2';}
		}
	}
	.Puzzle-piece {
		&--å2 {
			margin-right: vw-calc(500);
			&::before {content: 'Å2';}
		}
		&--å1 {
			position: relative;
			margin-right: vw-calc(270);
			&::before {content: 'Å1';}
		}
	}
	.Puzzle-pieceTextLabel {
		&--1 {width: vw-calc(480); bottom: vw-calc(284);}
		&--2 {width: vw-calc(480); bottom: vw-calc(284);}
		&--3 {width: vw-calc(480); bottom: vw-calc(234);}
		&--4 {width: vw-calc(480); bottom: vw-calc(234);}
		&--5 {width: vw-calc(480); bottom: vw-calc(184);}
		&--6 {width: vw-calc(480); bottom: vw-calc(197);}
	}
}

.Puzzle.Puzzle--28,
.Puzzle.Puzzle--29,
.Puzzle.Puzzle--30,
.Puzzle.Puzzle--31,
.Puzzle.Puzzle--32,
.Puzzle.Puzzle--33 {
	.Puzzle-areas {
		height: vw-calc(240);
		background-image: url('../../../assets/images/minigames/puzzles/puzzle-28-bg.svg');
	}
	.Puzzle-area {
		width: vw-calc(291);
		height: vw-calc(98);
		&::before {content: '';}
		span {display: none;}
		&--1 {
			right: vw-calc(78);
			top: vw-calc(59);
		}
	}
	.Puzzle-piece {
		margin-right: vw-calc(28);
		margin-bottom: vw-calc(16);
		width: vw-calc(291);
		height: vw-calc(98);
		background: linear-gradient(#015051, #002F2C);
		&--placed {
			background-image: linear-gradient(#012F2F, #002223, #002F2C);
		}
		&--placed::before,
		&::before {
			content: "";
			width: 100%;
			height: vw-calc(2);
			background: #0C7171;
			position: absolute;
			top: vw-calc(47);
			left: 0;
			border: none;
		}
		.Puzzle-icon {
			width: 100%;
			height: 100%;
			&--wave-6 {
				background-size: 100% 100%;
			}
		}
	}
}

.Puzzle.Puzzle--29 {
	.Puzzle-areas {
		background-image: url('../../../assets/images/minigames/puzzles/puzzle-29-bg.svg');
	}
}

.Puzzle.Puzzle--30 {
	.Puzzle-areas {
		background-image: url('../../../assets/images/minigames/puzzles/puzzle-30-bg.svg');
	}
}

.Puzzle.Puzzle--31 {
	.Puzzle-areas {
		background-image: url('../../../assets/images/minigames/puzzles/puzzle-31-bg.svg');
	}
}

.Puzzle.Puzzle--32 {
	.Puzzle-areas {
		height: vw-calc(460);
		border: 1px solid #18A4A4;
		background-image: url('../../../assets/images/minigames/puzzles/puzzle-32-bg.svg');
	}
	.Puzzle-area {
		left: vw-calc(445);
		&--1 {
			border: vw-calc(3) solid #FD0D1B;
			top: vw-calc(120);
		}
		&--2 {
			border: vw-calc(3) solid #0C609C;
			top: vw-calc(265);
		}
	}
	.Puzzle-areaLabel {
		left: vw-calc(445);
		&--1 {
			top: vw-calc(90);
		}
		&--2 {
			top: vw-calc(235);
		}
	}
	.Puzzle-pieces {
		width: vw-calc(300);
		position: absolute;
		top: vw-calc(151);
		right: vw-calc(48);
		flex-direction: column;
		.Puzzle-piece:first-of-type {
			margin-bottom: vw-calc(48);
		}
	}
	.Puzzle-piece.Puzzle-piece--placed {
		width: vw-calc(285);
		height: vw-calc(92);
	}
}

.Puzzle.Puzzle--33 {
	.Puzzle-areas {
		height: vw-calc(483);
		border: 1px solid #18A4A4;
		background-image: url('../../../assets/images/minigames/puzzles/puzzle-33-bg.svg');
	}
	.Puzzle-area {
		width: vw-calc(77);
		height: vw-calc(382);
		top: vw-calc(60);
		left: vw-calc(445);
	}
	.Puzzle-pieces {
		width: vw-calc(520);
		position: absolute;
		top: vw-calc(90);
		right: vw-calc(48);
		display: inline-block;
		.Puzzle-piece {
			width: vw-calc(77);
			height: vw-calc(382);
			display: inline-block;
			margin-right: vw-calc(50);
			padding: 0;
			border-top: none;
			border-bottom: none;
			border-left: 1px solid $cyan;
			border-right: 1px solid $cyan;
			background-image: linear-gradient(-90deg, $bg-gradient-light, $bg-gradient-dark);
			&::before { display: none; }
			.Puzzle-icon {
				background-size: 100% 100%;
				&--wave-8 { background-size: 101% 100%; }
				&--wave-10 { background-size: 101% 100%; }
				&--wave-11 { background-size: 103% 100%; }
			}
		}
	}
	.Puzzle-piece--placed {
		width: vw-calc(77);
		height: vw-calc(382);
		padding: 0;
		&::before { display: none; }
	}
	.Puzzle-piece--correct {
		width: vw-calc(77);
		padding: 0;
		margin: 0;
	}
}

.Puzzle.Puzzle--34,
.Puzzle.Puzzle--35,
.Puzzle.Puzzle--36,
.Puzzle.Puzzle--37,
.Puzzle.Puzzle--38,
.Puzzle.Puzzle--39 {
	background: linear-gradient(#015051, #002F2C);
	.Puzzle-areas {
		height: vw-calc(605);
		border-bottom: vw-calc(2) solid $cyan;
		.Puzzle-area {
			width: vw-calc(90);
			height: vw-calc(40);
			span { display: none; }
			&--1 { left: vw-calc(186); }
			&--2 { left: vw-calc(371); }
			&--3 { left: vw-calc(565); }
			&--4 { left: vw-calc(752); }
			&--5 { left: vw-calc(942); }
		}
	}
	.Puzzle-pieces {
		position: absolute;
	}
}

.Puzzle.Puzzle--34 {
	.Puzzle-areas {
		background-image: url('../../../assets/images/minigames/puzzles/puzzle-34-bg.svg');
		.Puzzle-area {
			width: vw-calc(112);
			height: vw-calc(90);
			top: vw-calc(72);
			span { display: none; }
			&--1 { left: vw-calc(163); }
			&--2 { left: vw-calc(296); }
			&--3 { left: vw-calc(450); }
			&--4 { left: vw-calc(576); }
			&--5 { left: vw-calc(703); }
			&--6 { left: vw-calc(831); }
			&--7 { left: vw-calc(957); }
		}
	}
	.Puzzle-pieces {
		top: vw-calc(222);
		left: vw-calc(106);
		.Puzzle-piece {
			width: vw-calc(110);
			height: vw-calc(86);
			padding: 0;
			background: none;
			border: none;
			&:nth-of-type(3) {
				margin-right: vw-calc(20);
			}
			&:nth-of-type(5),
			&:nth-of-type(6) {
				margin-right: vw-calc(45);
			}
			&::before { display: none; }
			.Puzzle-icon {
				width: 100%;
				height: 100%;
				width: vw-calc(110);
				height: vw-calc(86);
				&--family {
					margin-top: vw-calc(3);
					margin-left: vw-calc(3);
					width: vw-calc(105);
					height: vw-calc(81);
				}
				&--needle {
					margin-top: vw-calc(5);
					margin-left: vw-calc(5);
					width: vw-calc(103);
					height: vw-calc(78);
				}
				&--bacteria {
					margin-top: vw-calc(13);
					margin-left: vw-calc(7);
					width: vw-calc(98);
					height: vw-calc(69);
				}
				&--virus {
					margin-top: vw-calc(8);
					margin-left: vw-calc(4);
					width: vw-calc(105);
					height: vw-calc(80);
				}
				&--dna {
					margin-top: vw-calc(-8);
					margin-left: vw-calc(-8);
					width: vw-calc(130);
					height: vw-calc(106);
					background-size: contain;
				}
				&--molecule {
					margin-top: vw-calc(13);
					margin-left: vw-calc(10);
					width: vw-calc(90);
					height: vw-calc(63);
				}
			}
			.Puzzle-pieceName {
				margin-top: vw-calc(100);
			}
		}
	}
	.Puzzle-piece--placed {
		width: vw-calc(112);
		height: vw-calc(90);
		background: none;
		padding: vw-calc(5);
		&::before { display: none; }
		.Puzzle-icon {
			width: vw-calc(110);
			height: vw-calc(86);
			&--family {
				margin-top: vw-calc(3);
				margin-left: vw-calc(3);
				width: vw-calc(105);
				height: vw-calc(81);
			}
			&--needle {
				margin-top: vw-calc(5);
				margin-left: vw-calc(5);
				width: vw-calc(103);
				height: vw-calc(78);
			}
			&--bacteria {
				margin-top: vw-calc(13);
				margin-left: vw-calc(7);
				width: vw-calc(98);
				height: vw-calc(69);
			}
			&--virus {
				margin-top: vw-calc(8);
				margin-left: vw-calc(4);
				width: vw-calc(105);
				height: vw-calc(80);
			}
			&--dna {
				margin-top: vw-calc(-8);
				margin-left: vw-calc(-8);
				width: vw-calc(130);
				height: vw-calc(106);
				background-size: contain;
			}
			&--molecule {
				margin-top: vw-calc(11);
				margin-left: vw-calc(10);
				width: vw-calc(90);
				height: vw-calc(63);
			}
		}
		.Puzzle-pieceName {
			display: none;
		}
	}
}

.Puzzle.Puzzle--35 {
	.Puzzle-areas {
		background-image: url('../../../assets/images/minigames/puzzles/puzzle-35-bg.svg');
		.Puzzle-area {
			top: vw-calc(183);
		}
	}
	.Puzzle-pieces {
		top: vw-calc(290);
		left: vw-calc(30);
		width: vw-calc(650);
		.Puzzle-piece {
			width: vw-calc(90);
			height: vw-calc(40);
			margin-right: vw-calc(66);
			padding: 0;
			&::before { display: none; }
		}
	}
	.Puzzle-piece--placed {
		width: vw-calc(90);
		height: vw-calc(40);
		padding: 0;
		&::before { display: none; }
	}
}

.Puzzle.Puzzle--36 {
	.Puzzle-areas {
		background-image: url('../../../assets/images/minigames/puzzles/puzzle-36-bg.svg');
		.Puzzle-area {
			top: vw-calc(255);
		}
	}
	.Puzzle-pieces {
		top: vw-calc(360);
		left: vw-calc(30);
		width: vw-calc(780);
		.Puzzle-piece {
			width: vw-calc(90);
			height: vw-calc(40);
			margin-right: vw-calc(66);
			padding: 0;
			&::before { display: none; }
		}
	}
	.Puzzle-piece--placed {
		width: vw-calc(90);
		height: vw-calc(40);
		padding: 0;
		&::before { display: none; }
	}
}

.Puzzle.Puzzle--37 {
	.Puzzle-areas {
		background-image: url('../../../assets/images/minigames/puzzles/puzzle-37-bg.svg');
		.Puzzle-area {
			top: vw-calc(328);
		}
	}
	.Puzzle-pieces {
		top: vw-calc(430);
		left: vw-calc(30);
		width: vw-calc(780);
		.Puzzle-piece {
			width: vw-calc(90);
			height: vw-calc(40);
			margin-right: vw-calc(66);
			padding: 0;
			&::before { display: none; }
		}
	}
	.Puzzle-piece--placed {
		width: vw-calc(90);
		height: vw-calc(40);
		padding: 0;
		&::before { display: none; }
	}
}

.Puzzle.Puzzle--38 {
	.Puzzle-areas {
		background-image: url('../../../assets/images/minigames/puzzles/puzzle-38-bg.svg');
		.Puzzle-area {
			height: vw-calc(39);
			top: vw-calc(393);
			&--1 {
				width: vw-calc(230);
				left: vw-calc(153);
				background-image: url('../../../assets/images/minigames/puzzles/soundwave2/arrow-left.svg');
				background-size: contain;
				background-repeat: no-repeat;
				.Puzzle-piece--placed {
					height: vw-calc(39);
					background-image: url('../../../assets/images/minigames/puzzles/soundwave2/arrow-left-cyan.svg');
					background-size: cover;
					background-repeat: no-repeat;
					&.Puzzle-piece--solved {
						box-shadow: none;
						@include box-shadow-picture(0, 0, vw-calc(7), rgba($cyan, .63));
					}
				}
			}
			&--2 {
				width: vw-calc(185);
				left: vw-calc(385);
			}
			&--3 {
				width: vw-calc(134);
				left: vw-calc(572);
			}
			&--4 {
				width: vw-calc(35);
				left: vw-calc(709);
				&.Puzzle-area--placed {
					height: vw-calc(82);
				}
				.Puzzle-piece--placed {
					position: absolute;
					top: vw-calc(24);
					left: vw-calc(-23);
					margin: 0;
					width: vw-calc(82);
					height: vw-calc(35);
					font-size: vw-calc(11);
					@include rotate(90deg);
				}
			}
			&--5 {
				width: vw-calc(91);
				left: vw-calc(747);
			}
			&--6 {
				width: vw-calc(186);
				left: vw-calc(842);
			}
			&--7 {
				width: vw-calc(113);
				left: vw-calc(1031);
				background-image: url('../../../assets/images/minigames/puzzles/soundwave2/arrow-right.svg');
				background-size: contain;
				background-repeat: no-repeat;
				.Puzzle-piece--placed {
					height: vw-calc(39);
					background-image: url('../../../assets/images/minigames/puzzles/soundwave2/arrow-right-cyan.svg');
					background-size: cover;
					background-repeat: no-repeat;
					&.Puzzle-piece--solved {
						box-shadow: none;
						@include box-shadow-picture(0, 0, vw-calc(7), rgba($cyan, .63));
					}
				}
			}
		}
	}
	.Puzzle-pieces {
		top: vw-calc(473);
		left: vw-calc(30);
		width: vw-calc(780);
		.Puzzle-piece {
			width: vw-calc(120);
			height: vw-calc(40);
			margin-right: vw-calc(66);
			padding: 0;
			font-size: vw-calc(14);
			&::before { display: none; }
		}
	}
	.Puzzle-piece--placed {
		width: 100%;
		height: vw-calc(40);
		padding: 0;
		font-size: vw-calc(13);
		&::before { display: none; }
	}
}

.Puzzle.Puzzle--39 {
	.Puzzle-areas {
		background-image: url('../../../assets/images/minigames/puzzles/puzzle-39-bg.svg');
		.Puzzle-area {
			height: vw-calc(50);
			top: vw-calc(437);
			span { display: none; }
			&--1 {
				width: vw-calc(110);
				left: vw-calc(165);
			}
			&--2 {
				width: vw-calc(109);
				left: vw-calc(277);
			}
			&--3 {
				width: vw-calc(187);
				left: vw-calc(388);
			}
			&--4 {
				width: vw-calc(130);
				left: vw-calc(577);
			}
			&--5 {
				width: vw-calc(97);
				left: vw-calc(746);
			}
			&--6 {
				width: vw-calc(187);
				left: vw-calc(845);
			}
			&--7 {
				width: vw-calc(102);
				left: vw-calc(1034);
			}
		}
	}
	.Puzzle-pieces {
		top: unset;
		bottom: 0;
		left: vw-calc(45);
		.Puzzle-piece {
			border: none;
			background: none;
			margin-right: vw-calc(10);
			&::before { display: none; }
			.Puzzle-icon {
				width: vw-calc(80);
				height: vw-calc(40);
				&--infrared-waves {
					margin-top: vw-calc(8);
					height: vw-calc(25);
				}
			}
		}
	}
	.Puzzle-piece--placed {
		width: 100%;
		border: none;
		background: none;
		padding: 0;
		height: vw-calc(50);
		&::before { display: none; }
		.Puzzle-icon {
			background-position: center;
			width: 100%;
			height: vw-calc(38);
			margin-top: vw-calc(5);
			&--radio-waves-radio {
				margin-top: vw-calc(3);
				height: vw-calc(42);
			}
			&--infrared-waves {
				margin-top: vw-calc(15);
				height: vw-calc(20);
			}
		}
	}
}