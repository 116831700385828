$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.CameraPopup {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: rgba(#011B1C, 0.8);
	padding: vw-calc(50);
	z-index: 10;
	&--solarpanels,
	&--hidden-base,
	&--hidden-base2 {
		padding: vw-calc(50) vw-calc(150);
	}
	&--ice,
	&--satellite,
	&--communication,
	&--crater,
	&--vehicles,
	&--lab,
	&--stars {
		padding: vw-calc(50) vw-calc(250);
	}

	&--satellite.CameraPopup--cam2 {
		padding: vw-calc(50) vw-calc(80);
		.CameraPopup-image {
			margin-top: vw-calc(20);
			background-size: contain;
		}
		.CameraPopup-overlay {
			display: none;
		}
	}
}

.CameraPopup-frame {
	width: 100%;
	height: 100%;
	position: relative;
	padding: vw-calc(10);
	margin-bottom: vw-calc(25);
	background-color: #011B1C;
	@include corner-box(green);
}

.CameraPopup-overlay {
	position: absolute;
	top: unset;
	right: vw-calc(10);
	bottom: vw-calc(10);
	left: vw-calc(10);
	height: vw-calc(150);
	background: linear-gradient(rgba(#A52B17, 0), rgba(#A52B17, 0.62));
}
.CameraPopup-image {
	width: 100%;
	height: vw-calc(550);
	background-repeat: no-repeat;
	background-size: 100%;
}


.CameraPopup-info1 {
	position: absolute;
	left: vw-calc(16);;
	bottom: vw-calc(16);
	text-align: left;
	font-size: vw-calc(20);
	text-transform: uppercase;
	line-height: vw-calc(25);	
	span {
		display: block;
		font-size: vw-calc(16);
	}
}


.CameraPopup-info2 {
	position: absolute;
	right: vw-calc(16);
	bottom: vw-calc(16);
	text-align: left;
	text-transform: uppercase;
	font-size: vw-calc(30);
	span {
		display: block;
		text-transform: none;
		font-size: vw-calc(16);
	}
}

.CameraPopup-printIcon {
	position: absolute;
	right: vw-calc(20);
	top: vw-calc(5);
	width: vw-calc(40);
	height: vw-calc(40);
	background-image: url('../../assets/images/icon-print.svg');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
	&:hover {
		@include scale(1.05);
	}
}

/* This should match styles/print.css */
.CameraPopup-printImage {
	width: 90%;
	min-width: 90%;
	height: auto;
	opacity: 0;
	visibility: hidden;
	position: absolute;
	top: 0;
	left: 0
}

@media print {
	* {
		-webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
		color-adjust: exact !important;                 /*Firefox*/
	}
	.CameraPopup,
	.CameraPopup-frame {
		background-image: none;
		background-color: white;
		padding: 0;
		border: none;
	}
	.CameraPopup-printIcon,
	.CameraPopup-info2,
	.CameraPopup-image {
		display: none;
	}

	.CameraPopup-printImage {
		opacity: 1;
		visibility: visible;
		content: url('../../assets/images/morse-code-print.svg');
	}
}