$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.Admin {
	position: relative;
	width: 100%;
	height: 100%;
	background-color: white;
	color: black;
	padding: vw-calc(20);
	overflow-y: auto;

	.Admin-link {
		width: vw-calc(350);
		background-color: lightgrey;
		padding: vw-calc(5) vw-calc(12);
		border-radius: vw-calc(5);
		font-weight: bold;
		cursor: pointer;
		&:hover {background-color: darken(lightgrey, 5%);}
	}
}
