$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.Presentation {
	position: relative;
	height: 100%;
	padding: vw-calc(30);
}

.Presentation-title {
	font-size: vw-calc(20);
	font-weight: 600;
	font-family: "Fivo Sans";
	margin-bottom: vw-calc(10);
}

.Presentation-text { 
	margin-bottom: vw-calc(30);
}

.Presentation-table {
	width: 100%;
	margin-bottom: vw-calc(50);
	th, td {
		font-size: vw-calc(12);
		text-align: left;
		padding: vw-calc(5);
		vertical-align: top;
		&:nth-child(1) {border-right: vw-calc(2) solid $cyan;}
	}
	thead th {
		font-size: vw-calc(14);
		border-bottom: vw-calc(2) solid $cyan;
	}
	tbody td {
		border-bottom: vw-calc(1) solid white;
	}
	tbody tr {
		&:nth-last-child(1) td {
			border-bottom: none;
		}
	}
}

