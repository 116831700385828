$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.Investigation {
	position: absolute;
	left: vw-calc(-320);
	right: vw-calc(-320);
	top: vw-calc(-30);
	bottom: vw-calc(-24);
	padding: vw-calc(20) vw-calc(100) vw-calc(20) vw-calc(20);
	z-index: 10;
	background-image: url('../../assets/images/background.jpg');
	background-size: cover;
	background-position: top left;
	&::before {
		content:"";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($bg-dark, .8);
	}
	.InstructionsIcon {
		z-index: 11;
		width: vw-calc(24);
		height: vw-calc(24);
		top: vw-calc(33);
		right: vw-calc(190);
		background-image: url('../../assets/images/icon-instructions-light.svg');
	}
	&--teacher {
		padding: vw-calc(40) vw-calc(60);
		.InstructionsIcon {
			width: vw-calc(31);
			height: vw-calc(31);
			top: vw-calc(15);
			right: vw-calc(90);
			background-image: url('../../assets/images/icon-instructions.svg');
		}
	}
}

.Investigation-close {
	position: absolute;
	top: vw-calc(15);
	right: vw-calc(45);
	width: vw-calc(32);
	height: vw-calc(32);
	background-image: url('../../assets/images/icon-x.svg');
	background-size: 100%;
	background-repeat: no-repeat;
	cursor: pointer;
	&:hover {@include scale(1.1);}
}

.Investigation-title {
	font-family: "Fivo Sans";
	font-size: vw-calc(24);
	margin: vw-calc(15) 0;
}

.Investigation-info {
	position: absolute;
	top: vw-calc(50);
	right: vw-calc(15);
	width: vw-calc(24);
	height: vw-calc(24);
	background-image: url('../../assets/images/icon-instructions-light.svg');
	background-size: contain;
	background-repeat: no-repeat;
	cursor: pointer;
	&:hover {@include scale(1.1);}
	z-index: 2;
}