$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.Tab {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	overflow: hidden;
	border-bottom: vw-calc(2) solid $cyan;
}

.Tab-titleContainer {
	z-index: 2;
	position: absolute;
	top: 0;
	left: 0;
	width: 70%;
	height: vw-calc(52);
	background-color: $bg-tab-title;
	border-right: vw-calc(4) solid $cyan;
	border-top: vw-calc(4) solid $cyan;
	border-left: none;
	border-bottom: none;
	padding: vw-calc(10) 0;
	@include origin(100%, 100%);
	@include skewX(20);
	.Tab-title {
		@include skewX(-20);
	}
	&::after {
		content:"";
		display: block;
		position: absolute;
		bottom: 0;
		left: 100%;
		width: 100%;
		height: vw-calc(2);
		background-color: $cyan;
	}
}

.Tab-content {
	z-index: 1;
	position: absolute;
	top: vw-calc(50);
	width: 100%;
	height: 100%;
	background-image: linear-gradient($bg-gradient-light, $bg-gradient-dark);
}