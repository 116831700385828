$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
.AtomReactor {
	position: absolute;
	left: vw-calc(-290);
	right: vw-calc(-290);
	top: vw-calc(-35);
	bottom: vw-calc(-26);
	padding: vw-calc(40) vw-calc(50);
	z-index: 10;
	color: white;
	@include no-select();
	background-image: url('../../../assets/images/background.jpg');
	background-size: cover;
	background-position: top left;
	overflow: hidden;
	&::before {
		content:"";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($bg-dark, .8);
	}

	&::after {
		content:"";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($bg-dark, .8);
		visibility: hidden;
		@include opacity(0);
		@include doubleTransition('opacity', 0.3s, 'ease-in-out', 'visibility', 0.3s, 'ease-in-out');
	}

	&--showOverlay::after {
		visibility: visible;
		@include opacity(1);
	}

	.AtomReactor-content {
		position: relative;
	}

	.AtomReactor-diagram {
		position: relative;
		@include flex('center', 'center', 'column');
	}

	.AtomReactor-btn {
		margin: vw-calc(5) 0;
		color: $cyan;
		cursor: pointer;
		font-weight: 600;
		&:hover {
			text-decoration: underline;
		}
		&:first-of-type {
			margin-top: vw-calc(15);
		}
		&.inline {
			display: inline-block;
			margin-top: vw-calc(10);
		}
		&.margin-left {
			margin-left: vw-calc(5);
		}
	}

	.AtomReactor-gif {
		width: vw-calc(400);
		height: vw-calc(300);
		background: url('../../../assets/resources/reactor-diagram.gif');
		background-repeat: no-repeat;
		background-size: contain;
	}

	table {
		th, td {
			width: vw-calc(70);
			text-align: center;
		}
		th {
			font-weight: normal;
		}
		td:first-of-type {
			text-align: left;
		}
	}

	.CoolantLevels {
		margin-top: vw-calc(30);
		table {
			td:first-of-type {
				width: vw-calc(170);
			}
		}
	}

	.Column {
		position: relative;
		width: 30%;
		display: inline-block;
		vertical-align: top;
		margin-right: 3%;
		&:nth-of-type(2),
		&:nth-of-type(3) {
			padding-top: vw-calc(30);
		}
		&:nth-of-type(3) {
			margin-right: 0;
			width: 33%;
		}
	}

	p {
		margin-bottom: 0;
		vertical-align: top;
	}

	input[type=range] {
		-webkit-appearance: none;
		margin: vw-calc(10);
		margin-left: 0;
		margin-bottom: vw-calc(-5);
		width: 40%;
	}
	input[type=range]:focus {
		outline: none;
	}
	// Google Chrome
	input[type=range]::-webkit-slider-runnable-track {
		width: 100%;
		height: vw-calc(10);
		cursor: pointer;
		border: vw-calc(1) solid #444;
		background: #333;
	}
	input[type=range]::-webkit-slider-thumb {
		box-shadow: vw-calc(1) vw-calc(1) vw-calc(1) #000000, vw-calc(0) vw-calc(0) vw-calc(1) #0d0d0d;
		border: vw-calc(1) solid lighten($cyan, 20%);
		height: vw-calc(18);
		width: vw-calc(30);
		background: $cyan;
		cursor: pointer;
		-webkit-appearance: none;
		margin-top: vw-calc(-5);
	}
	input[type=range]:focus::-webkit-slider-runnable-track {
		background: #333;
	}
	// Mozilla Firefox
	input[type=range]::-moz-range-track {
		width: 100%;
		height: vw-calc(18);
		cursor: pointer;
		box-shadow: vw-calc(1) vw-calc(1) vw-calc(1) #000000, 0px 0px vw-calc(1) #0d0d0d;
		background: #333;
		border: vw-calc(1) solid #444;
	}
	input[type=range]::-moz-range-thumb {
		box-shadow: vw-calc(1) vw-calc(1) vw-calc(1) #000000, 0px 0px vw-calc(1) #0d0d0d;
		border: vw-calc(1) solid lighten($cyan, 20%);
		height: vw-calc(18);
		width: vw-calc(30);
		background: $cyan;
		cursor: pointer;
		border-radius: 0;
		margin-top: vw-calc(-10);
	}
	// Microsoft Edge
	input[type=range]::-ms-track {
		width: 100%;
		height: vw-calc(10);
		cursor: pointer;
		background: transparent;
		border-color: transparent;
		border-width: vw-calc(16) 0;
		color: transparent;
	}
	input[type=range]::-ms-fill-lower {
		background: #333;
		border: vw-calc(1) solid #444;
		box-shadow: vw-calc(1) vw-calc(1) vw-calc(1) #000000, 0px 0px vw-calc(1) #0d0d0d;
	}
	input[type=range]::-ms-fill-upper {
		background: #333;
		border: vw-calc(1) solid #444;
		border-radius: 2.6px;
		box-shadow: vw-calc(1) vw-calc(1) vw-calc(1) #000000, 0px 0px vw-calc(1) #0d0d0d;
	}
	input[type=range]::-ms-thumb {
		box-shadow: vw-calc(1) vw-calc(1) vw-calc(1) #000000, 0px 0px vw-calc(1) #0d0d0d;
		border: vw-calc(1) solid lighten($cyan, 20%);
		height: vw-calc(18);
		width: vw-calc(36);
		background: $cyan;
		cursor: pointer;
	}
	input[type=range]:focus::-ms-fill-lower {
		background: #333;
	}
	input[type=range]:focus::-ms-fill-upper {
		background: #333;
	}
}

.AtomReactor-iframeWrapper {
	position: relative;
	width: vw-calc(1200);
	height: vw-calc(650);
	overflow-x: visible;
}

.AtomReactor-close {
	position: absolute;
	top: vw-calc(10);
	right: vw-calc(45);
	width: vw-calc(30);
	height: vw-calc(30);
	background-image: url('../../../assets/images/icon-x.svg');
	background-size: contain;
	background-repeat: no-repeat;
	cursor: pointer;
	@include no-select();
	&:hover {@include scale(1.1);}
}