$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.Settings {
	width: 100%;
	height: 100%;
}

.Settings-title {
	text-transform: uppercase;
	font-size: vw-calc(20);
	font-weight: 600;
	color: white;
	margin-bottom: vw-calc(10);
}

.Settings-actions {
	position: absolute;
	top: vw-calc(258);
	left: vw-calc(107);
	.Button {
		color: $cyan;
		margin: 0 0.7em;
		width: vw-calc(204);
		text-align: left;
		padding-left: vw-calc(13);
		&.wide {
			width: vw-calc(250);
		}
	}
}

.Settings-adminBtn {
	position: absolute;
	top: vw-calc(100);
	right: vw-calc(20);
	.Button {
		color: $cyan;
		font-size: vw-calc(15);
	}
}


.Settings-games {
	display: inline-block;
	position: absolute;
	left: vw-calc(120);
	top: vw-calc(388);
	width: vw-calc(1100);
	overflow: auto;
	white-space: nowrap;
	padding-bottom: vw-calc(15);
	@include custom-scrollbar(vw-calc(10), rgba($cyan, 0.2), $cyan);
}

.Settings-game {
	position: relative;
	width: vw-calc(320);
	height: vw-calc(220);
	vertical-align: top;
	display: inline-block;
	margin-right: vw-calc(30);
	background-image: linear-gradient($bg-gradient-light, $bg-gradient-dark);
	padding: vw-calc(40) 0 vw-calc(0) vw-calc(20);
	border-top: vw-calc(2) solid $cyan;
	border-bottom: vw-calc(2) solid $cyan;
	font-size: vw-calc(18);
	color: $cyan;
	div {
		overflow: hidden;
		white-space: nowrap;
	}
	span {
		color: white;
		font-weight: 600;
		min-width: vw-calc(105);
		float: left;
	}

	.Settings-gameButtons {
		margin-top: vw-calc(25);
		.Button {
			width: vw-calc(204);
			height: vw-calc(42);
			padding-top: vw-calc(10);
		}
	}
}



.Settings-manageTeachersBtn {
	position: absolute;
	top: vw-calc(12);
	left: vw-calc(12);
	width: vw-calc(25);
	height: vw-calc(20);
	background-size: 100% auto;
	background-repeat: no-repeat;
	cursor: pointer;
	&:hover {
		@include scale(1.1);
	}
	cursor: pointer;
}

.Settings-gameDeleteBtn {
	position: absolute;
	top: vw-calc(14);
	right: vw-calc(13);
	width: vw-calc(30);
	height: vw-calc(30);
	background-image: url('../../assets/images/icon-x.svg');
	background-size: 100% auto;
	background-repeat: no-repeat;
	cursor: pointer;
	&:hover {
		@include scale(1.1);
	}
	cursor: pointer;
}
