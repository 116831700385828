$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.MissionControlMessage {
	position: relative;
	padding: vw-calc(20) vw-calc(50);
	&::before {
		content: "";
		position:absolute;
		width: 100%;
		height: vw-calc(16);
		border-top: vw-calc(2) solid $cyan;
		border-left: vw-calc(2) solid $cyan;
		border-right: vw-calc(2) solid $cyan;
		top: vw-calc(-2);
		left: vw-calc(-2);
	}
	&::after {
		content: "";
		position:absolute;
		width: 100%;
		height: vw-calc(16);
		border-bottom: vw-calc(2) solid $cyan;
		border-left: vw-calc(2) solid $cyan;
		border-right: vw-calc(2) solid $cyan;
		bottom: vw-calc(-2);
		left: vw-calc(-2);
	}
}

.MissionControlMessage-content {
	font-family: 'Fivo Sans';
	font-size: vw-calc(15);
	padding: 0 vw-calc(15);
	color: $cyan;
	ul {
		list-style: none;
		padding-inline-start: 0;
		li {
			position: relative;
			text-indent: vw-calc(10);
			margin: vw-calc(15) 0;
			p {display: inline;}
			&::before {
				content: "/";
				position: absolute;
				left: vw-calc(-10);
				color: white;
				font-weight: 700;
			}
		}
	}
}

.MissionControlMessage-start,
.MissionControlMessage-end {
	text-transform: uppercase;
	font-weight: 600;
	span {
		display: inline-block;
		color: $cyan;
		margin-right: vw-calc(10);
	}
}