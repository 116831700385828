$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.GameLoading {
	width: 100%;
	height: 100%;
	background-image: url('../../assets/images/background.jpg');
	background-size: cover;
	background-position: top left;
	&::before {
		content:"";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($bg-dark, .8);
	}
}

.GameLoading-message {
	position: absolute;
	left: vw-calc(517);
	bottom: vw-calc(120);
	font-size: vw-calc(45);
	font-weight: 600;
	text-transform: uppercase;
	color: $cyan;

	span {
		animation-name: loading-dots;
		animation-duration: 1.4s;
		animation-iteration-count: infinite;
		animation-fill-mode: both;
	}
	span:nth-child(2) {
		animation-delay: .2s;
	}
	span:nth-child(3) {
		animation-delay: .4s;
	}
}

.GameLoading-rocket-wrapper {
	position: relative;
	top: vw-calc(180);
	left: vw-calc(505);
}

.GameLoading-rocket {
	position: absolute;
	width: vw-calc(290);
}

.GameLoading-rocket-tail {
	position: absolute;
	width: vw-calc(290);
	top: vw-calc(70);
	left: vw-calc(2);
	animation: shake 0.2s;
	animation-iteration-count: infinite;
}

@keyframes shake {
	0% {transform: translate(0px, 0px)}
	50% {transform: translate(-1px, -2px) }
	80% {transform: translate(1px, 2px)}
	100% {transform: translate(1px, -1px) }
}

@keyframes loading-dots {
	0% {opacity: .2;}
	20% {opacity: 1;}
	100% {opacity: .2;}
}