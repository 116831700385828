$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.Splash-screen {
	width: 100%;
	height: 100%;
	background-image: url('../../assets/images/background.jpg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	@include flex("center", "center");
	.Logo-textured-glow {
		width: vw-calc(1000);
		height: auto;
		@include box-shadow-picture(vw-calc(5), vw-calc(5), vw-calc(1), rgba(#000000, .5), 0, 0, vw-calc(10), rgba($cyan, .7))
	}
}
.Login {
	position: relative;
	min-height: 100%;
	padding: vw-calc(110) vw-calc(290);
	color: $text-dark;
	font-size: vw-calc(13);
	font-weight: normal;
	@include flex("space-between", "flex-start");
	background-image: url('../../assets/images/background.jpg');
	background-size: cover;
	background-position: top left;
	flex-direction: column;
	.Login-logo-cgl {
		width: 10em;
		height: 3em;
		// background-color: red;
		background-image: url('../../assets/images/logo-cgl.svg');
		background-size: 100% auto;
		background-position: center right;
		background-repeat: no-repeat;
		z-index: 1;
		margin-left: 62.5em; 	
	}
	.Login-wrap{
		@include flex("space-between", "flex-start");
		width: 100%;
		min-height: 100%;
	}

	&::before {
		content:"";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($bg-dark, .8);
	}
	.Tab-titleContainer {
		padding-left: vw-calc(25);
		width: 65%;
	}
	.Tab-content {
		padding-left: vw-calc(21);
		padding-right: vw-calc(21);
		padding-top: vw-calc(30);
	}
}

.TeacherLogin--resetPassword {
	.Tab-titleContainer { width: 78%; }
	.Login-errorMessage { height: vw-calc(160); }
}

.TeacherLogin--createUser {
	.Login-errorMessage { height: vw-calc(120); }
	.Login-form .Button { width: vw-calc(170); }
}


.Login-logo {
	width: vw-calc(243);
	height: vw-calc(70);
	position: absolute;
	top: vw-calc(15);
	left: vw-calc(15);
	background-image: url('../../assets/images/logo-medium.svg');
	background-size: 100%;
	background-position: center;
	background-repeat: no-repeat;
}

.GroupLogin,
.TeacherLogin {
	position: relative;
	width: vw-calc(320);
	height: vw-calc(551);
}

.Login-title {
	font-size: vw-calc(20);
	font-weight: 600;
	color: white;
	text-transform: uppercase;
	text-align: left;
	span { color: $cyan; }
}

.Login-form {
	margin: vw-calc(16) 0;
	text-align: left;
	input {
		text-align: left;
		width: vw-calc(267);
		height: vw-calc(43);
		padding: vw-calc(16);
		margin-bottom: vw-calc(18);
		font-size: vw-calc(15);
		color: $cyan;
		background-color: lighten($bg-input, 5%);
		@include corner-box(cyan);
		@include input-placeholder($cyan);
		&:-webkit-autofill {
			color: $cyan !important;
			background-color: lighten($bg-input, 5%) !important;
		}
		&.invalid {
			@include corner-box(red);
		}
	}
	.Button {
		margin-top: vw-calc(10);
		width: vw-calc(141);
		height: vw-calc(42);
		padding: vw-calc(10) 0;
	}
}

.Login-errorMessage {
	height: vw-calc(150);
	margin-top: vw-calc(16);
	color: $text-dark;
	font-size: vw-calc(13);
	font-weight: normal;
}

.Button--loginToggle {
	text-align: left;
	font-weight: normal;
	display: block;
	margin-bottom: vw-calc(10);
	padding: 0;
	background: none;
	text-transform: none;
	color: $text-dark;
	font-size: vw-calc(13);
	font-weight: normal;
	&:hover {
		background: none;
		color: white;
	}
}


.GroupLogin {
	.GroupBadge {
		color: white;
		display: inline-block;
		width: vw-calc(74);
		margin-right: vw-calc(25);
		margin-bottom: vw-calc(40);
		.GroupBadge-icon {
			padding-bottom: vw-calc(7);
		}
		&:hover {
			cursor: pointer;
			-webkit-filter: drop-shadow(0 0 vw-calc(7) rgba($cyan, .63));
			filter: drop-shadow(0 0 vw-calc(7) rgba($cyan, .63));
		}
		&:nth-of-type(1),
		&:nth-of-type(4),
		&:nth-of-type(7),
		&:nth-of-type(10) {
			margin-left: vw-calc(21);
		}
		&:nth-of-type(1) {
			margin-top: vw-calc(33);
		}
	}
	.Login-form {
		padding-left: vw-calc(21);
		padding-top: vw-calc(33);
	}
	.Tab-content {
		padding-left: vw-calc(0);
		padding-right: vw-calc(0);
		padding-top: vw-calc(0);
	}
}
