$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.BasicPage {
	padding: vw-calc(30);
	&--page-9-1-1-feature {
		b {
			font-weight: 600;
			color: $cyan;
		}
	}
}

.BasicPage-title {
	font-size: vw-calc(20);
	font-family: "Fivo Sans";
	margin-bottom: vw-calc(10);
}

.BasicPage-text {
	margin-bottom: vw-calc(30);
	img {
		width: 95%;
	}
	a {
		color: white;
	}
}

.BasicPage-table {
	width: 100%;
	margin-bottom: vw-calc(40);
	th, td {
		font-size: vw-calc(15);
		text-align: left;
		padding: vw-calc(5);
		padding-left: vw-calc(10);
		vertical-align: top;
		&:nth-child(1),
		&:nth-child(2) {
			border-right: vw-calc(2) solid $cyan;
		}
	}
	thead th {
		font-size: vw-calc(15);
		border-bottom: vw-calc(2) solid $cyan;
	}
	tbody td {
		border-bottom: vw-calc(1) solid white;
	}
	tbody tr {
		&:nth-last-child(1) td {
			border-bottom: none;
		}
	}
}
.BasicPage-tables.BasicPage-tables--page-2-2-1-energyConsumption div:nth-child(2) {
	td:nth-child(1) {
		width: vw-calc(180);
	}
	td:nth-child(2) {
		width: vw-calc(200);
	}
	td.equation {
		position: relative;
		margin-left: vw-calc(-10);
		.paranthesis {
			position: absolute;
			top: 0;
			font-size: vw-calc(35);
		}
		.fraction {
			display: inline-block;
			position: relative;
			vertical-align: middle;
			letter-spacing: vw-calc(1);
			text-align: center;
			padding-left: vw-calc(15);
		}
		.fraction > span {
			display: block;
			padding: vw-calc(1);
		}
		.fraction span.bottom {
			border-top: thin solid white;
		}
		.fraction span.symbol {
			display: none;
		}
	}
}

.BasicPage-tables.BasicPage-tables--page-2-2-2-task {
	th, td {
		&:nth-child(2) {
			border-right: none;
		}
	}
	td:nth-child(1) {
		width: vw-calc(200);
	}
	td:nth-child(2) {
		width: vw-calc(200);
	}
}

.BasicPage-tables.BasicPage-tables--page-2-3-1-rocketFuel {
	th:nth-child(2) {
		white-space: pre-wrap;
		position: relative;
		&:after {
			content: "L";
			position: absolute;
			top: vw-calc(3);
			left: vw-calc(108);
			font-size: vw-calc(16);
			font-style: italic;
		}
	}
	th, td {
		&:nth-child(2) {
			border-right: none;
		}
	}
	td {
		width: vw-calc(200);
	}
}

.BasicPage-tables.BasicPage-tables--page-2-3-1-supplyNeeds {
	td:nth-child(1) {
		width: vw-calc(180);
	}
	td:nth-child(2) {
		width: vw-calc(255);
	}
}

.BasicPage-tables.BasicPage-tables--page-2-3-1-calculateLoad {
	th, td {
		&:nth-child(2) {
			padding-right: vw-calc(10);
			text-align: right;
			border-right: none;
		}
	}
	td:nth-child(1) {
		width: vw-calc(400);
	}
}

