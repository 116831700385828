$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.ControlPanel {
	position: absolute;
	background-image: linear-gradient($bg-gradient-light, $bg-gradient-dark);
	border-bottom: vw-calc(2) solid $cyan;
	&--page,
	&--test {
		left: vw-calc(32);
		bottom: vw-calc(24);
		width: vw-calc(225);
		padding: vw-calc(10) 0;
		padding-top: vw-calc(70);
		text-align: left;
		
		&::before {
			content: "";
			position: absolute;
			width: vw-calc(225);
			height: vw-calc(10);
			top: vw-calc(-1);
			left: 0;
			background-image: url('../../assets/images/menu-border.png');
			background-size: vw-calc(225) auto;
			background-position: left top;
			background-repeat: no-repeat;
		}
		&::after {
			content: "Kontrolpanel";
			position: absolute;
			top: vw-calc(-35);
			left: 0;
			font-family: "Fivo Sans";
			text-transform: uppercase;
			font-size: vw-calc(20);
			font-weight: 600;
			line-height: vw-calc(24);
			margin-bottom: vw-calc(12);
			@include no-select();
		}
	}

	&--settings,
	&--overview {
		top: vw-calc(20);
		right: vw-calc(20);
		width: vw-calc(300);
		height: vw-calc(70);
		text-align: right;
		border-top: vw-calc(2) solid $cyan;
		.ControlPanel-settingsBtn,
		.ControlPanel-logoutBtn {
			margin: vw-calc(7) vw-calc(10) 0 0;
			width: vw-calc(25);
			height: vw-calc(50);
			background-size: auto vw-calc(25);
		}
	}

	&--group {
		padding: vw-calc(25) 0 0 vw-calc(112);
		height: vw-calc(120);
		background-image: linear-gradient($bg-gradient-light, $bg-gradient-dark);
		.ControlPanel-buttons {text-align: left;}
		.ControlPanel-fullscreenBtn,
		.ControlPanel-logoutBtn {
			margin: 0 vw-calc(20) 0 0;
			width: vw-calc(25);
			height: vw-calc(25);
			background-size: auto vw-calc(23);
		}
	}
}

.ControlPanel--group.ControlPanel--overview {
	width: vw-calc(250);
	height: vw-calc(70);
	padding: vw-calc(25);
	&::after {display: none;}
	.ControlPanel-groupImg {
		left: auto;
		right: vw-calc(10);
		top: vw-calc(10);
		img {width: vw-calc(50);}
	}
	.ControlPanel-groupName {
		position: absolute;
		right: vw-calc(70);
		top: vw-calc(15);
		font-size: vw-calc(15);
	}
	.ControlPanel-buttons {
		position: absolute;
		left: vw-calc(10);
		top: vw-calc(20);
		.ControlPanel-fullscreenBtn {
			margin: 0 vw-calc(10) 0 0;
		}
	}
}


.ControlPanel-buttons {
	position: relative;
	text-align: center;
}

.ControlPanel-email {
	position: absolute;
	left: 0;
	top: 0;
	height: vw-calc(70);
	padding: vw-calc(10);
	color: white;
	font-size: vw-calc(14);
	text-align: left;
	pointer-events: none;
	span {
		display: block;
		color: $cyan;
		font-size: vw-calc(14);
		margin-top: vw-calc(5);
	}
}

.ControlPanel-gameCode {
	text-align: left;
	padding-left: vw-calc(30);
	margin-bottom: vw-calc(10);
	font-family: "Fivo Sans";
	span {
		display: inline-block;
		color: $cyan;
		text-transform: uppercase;
	}
}


.ControlPanel-settingsBtn,
.ControlPanel-fullscreenBtn,
.ControlPanel-logoutBtn {
	display: inline-block;
	width: vw-calc(32);
	height: vw-calc(32);
	background-size: auto vw-calc(28);
	background-position: center center;
	background-repeat: no-repeat;
	margin: 0 vw-calc(16);
	cursor: pointer;
	&:hover {@include scale(1.1);}
}
.ControlPanel-settingsBtn {background-image: url('../../assets/images/icon-settings.svg');}
.ControlPanel-logoutBtn {background-image: url('../../assets/images/icon-logout.svg');}
.ControlPanel-fullscreenBtn {
	background-image: url('../../assets/images/icon-fullscreen-expand.svg');
	&.fullscreen {background-image: url('../../assets/images/icon-fullscreen-contract.svg');}
}


.ControlPanel-groupImg {
	position: absolute;
	left: vw-calc(10);
	top: vw-calc(25);
	img {
		width: vw-calc(80);
	}
}

.ControlPanel-groupName {
	font-family: "Fivo Sans";
	text-transform: uppercase;
	font-size: vw-calc(15);
	margin-bottom: vw-calc(5);
	span {
		color: $text-dark;
		font-size: vw-calc(13);
		display: block;
	}
}