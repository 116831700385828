$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.Base-container {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	visibility: hidden;
	display: block;
	@include opacity(0);
	@include doubleTransition('opacity', 0.8s, 'ease-in-out', 'visibility', 0.8s, 'ease-in-out');
	&--closed {
		@include baseZoomOut(0.5, vw-calc(1147), vw-calc(485));
		&.Base-container--closeToCenter {
			@include baseZoomOut2(0.5, vw-calc(640), vw-calc(360));
		}
	}
	&--open {
		visibility: visible;
		@include scale3d(0, 0, 1);
		@include baseZoomIn(0.3);
	}
	&--fadeIn {
		@include transition-delay(0.2s, 0.2s);
		visibility: visible;
		@include opacity(1);
	}
}
.Base {
	position: absolute;
	display: block;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: #F1986B;
	background-image: url('../../assets/images/base/elements/background.svg');
	background-size: 100% 100%;
	background-position: top left;
	background-repeat: no-repeat;
	z-index: 1000;
	&--repaired {
		background-image: url('../../assets/images/base/elements-repaired/background.svg');
	}
}

.Base-close {
	position: absolute;
	top: vw-calc(20);
	right: vw-calc(20);
	width: vw-calc(30);
	height: vw-calc(30);
	background-image: url('../../assets/images/icon-x.svg');
	background-size: contain;
	background-repeat: no-repeat;
	z-index: 1001;
	cursor: pointer;
	&:hover {@include scale(1.1);}
}

.BasePopup {
	position: relative;
	top: vw-calc(40);
	left: vw-calc(414);
	width: vw-calc(424);
	border-top: vw-calc(2) solid $cyan;
	padding: vw-calc(24) vw-calc(27) vw-calc(70) vw-calc(27);
	background-image: linear-gradient(#004F50, rgba(#002F2C, 0));
	font-size: vw-calc(18);
	transform-origin: vw-calc(820) vw-calc(670);
	@include scale(0);
	@include transition('all', 0.5s, 'ease-in-out');
	@include transition-delay(1.5s);
	&--open {
		@include transition-delay(0s);
		@include scale(1);
	}
	&--noAnimation {
		@include scale(1);
		@include transition('none', 0s, 'ease-in-out');
	}
	&--lower {
		top: vw-calc(100);
	}
	span {
		color: $cyan;
		font-weight: 600;
	}
	.BasePopup-missionTitle {
		font-size: vw-calc(20);
		margin-bottom: vw-calc(13);
	}
	.BasePopup-playBtn {
		position: absolute;
		width: vw-calc(46);
		height: vw-calc(46);
		right: vw-calc(20);
		bottom: vw-calc(20);
		background-image: url('../../assets/images/icon-play.svg');
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		cursor: pointer;
	}
}

.Base-showPopupBtn {
	position: absolute;
	bottom: 0;
	right: 0;
	padding: vw-calc(24) vw-calc(115) vw-calc(24) vw-calc(27);
	border-top: vw-calc(2) solid $cyan;
	background-image: linear-gradient(#004F50, rgba($bg-gradient-dark, 0));
	cursor: pointer;
	opacity: 1;
	@include transition('opacity', 0.3s, 'ease-in-out');
	@include transition-delay(1s);
	&--hide {
		@include transition-delay(0s);
		opacity: 0;
	}
	&--noAnimation {
		@include transition('none', 0s, 'ease-in-out');
		opacity: 0;
	}
	span {
		color: $cyan;
		font-weight: 600;
	}
}