$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.OverviewStories {
	position: relative;
}

.OverviewStories-tier {
	position: relative;
	display: inline-block;
	vertical-align: top;
	width: vw-calc(319);
	text-align: center;
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: vw-calc(-6);
		height: vw-calc(112);
		width: vw-calc(12);
		background-image: url('../../assets/images/icon-next.svg'), repeating-linear-gradient(#fff 0%, #fff vw-calc(30), transparent vw-calc(30), transparent vw-calc(82), #fff vw-calc(82), #fff 100%);
		background-position: center center, top center;
		background-repeat: no-repeat, repeat-y;
		background-size: 80% auto, vw-calc(2) 100%;
	}
	&--1::before {display: none;}
}

.OverviewStories-story {
	position: relative;
	display: inline-block;
	vertical-align: top;
	width: vw-calc(260);
	height: vw-calc(50);
	margin-bottom: vw-calc(12);
	font-family: 'Fivo Sans';
	font-weight: 600;
	font-size: vw-calc(20);
	line-height: 1;
	padding-top: vw-calc(15);
	padding-left: vw-calc(40);
	color: $cyan;
	background-image: linear-gradient($bg-gradient-light, #063B3B);
	// border-top: vw-calc(2) solid $bg-dark;
	// border-bottom: vw-calc(2) solid $bg-dark;
	text-align: left;
	cursor: pointer;
	z-index: 5;
	&:hover {color: white;}
	&--selected {
		color: white;
		border-top: vw-calc(2) solid $cyan;
		border-bottom: vw-calc(2) solid $cyan;
		border-left: vw-calc(4) solid $cyan;
	}
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: vw-calc(16);
		width: vw-calc(12);
		height: 100%;
		background-image: url('../../assets/images/icon-circle-dark.svg');
		background-size: vw-calc(11) vw-calc(11);
		background-position: center center;
		background-repeat: no-repeat;
	}
	&--active {
		&::before {
			background-image: url('../../assets/images/icon-circle-light.svg');
		}
	}
	&--closed {
		&::before {
			background-image: url('../../assets/images/icon-circle-light-full.svg');
		}
	}
}

.OverviewStories-story--group.OverviewStories-story--ready {
	opacity: 0.5;
	cursor: not-allowed;
	&:hover {color: $cyan;}
}