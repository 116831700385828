$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.WriteSubmission {
	position: relative;
	width: vw-calc(1073);
	height: vw-calc(300);
	background-image: linear-gradient($bg-gradient-light, $bg-gradient-dark);
	border-top: vw-calc(2) solid $cyan;
	border-bottom: vw-calc(2) solid $cyan;
	padding: vw-calc(20) vw-calc(45);
	margin: vw-calc(20) vw-calc(40) 0 vw-calc(66);
}

.WriteSubmission-header {
	position: relative;
	width: vw-calc(770);
	height: vw-calc(42);
	margin-bottom: vw-calc(14);
}

.WriteSubmission-title {
	display: inline-block;
	vertical-align: top;
	width: vw-calc(565);
	height: vw-calc(42);
	line-height: vw-calc(42);
	font-size: vw-calc(18);
	font-weight: 500;
	color: $cyan;
}

.WriteSubmission-input {
	width: 100%;
	height: 100%;
	font-size: vw-calc(18);
	font-weight: 500;
	color: white;
	line-height: vw-calc(35);
	background: rgba($cyan, 0.24);
	padding: 0 vw-calc(16);
	cursor: text;
	@include input-placeholder($cyan);
}

// .WriteSubmission-tools {

// }

.WriteSubmission-text {
	height: vw-calc(205);
	width: vw-calc(770);
}

.WriteSubmission-textarea {
	width: 100%;
	height: 100%;
	font-size: vw-calc(16);
	color: white;
	padding: vw-calc(16);
	background: rgba($cyan, 0.24);
	resize: none; 
	cursor: text;
	overflow-y: auto;
	@include textarea-placeholder($cyan);
	@include fancy-scrollbar(vw-calc(10), 100%, rgba($cyan, 0.2), vw-calc(10), 100%, vw-calc(20), $cyan);
}

.WriteSubmission-actions {
	position: absolute;
	top: vw-calc(77);
	right: vw-calc(45);
}

.WriteSubmission-saveBtn,
.WriteSubmission-submitBtn {
	width: vw-calc(160);
	height: vw-calc(60);
	margin-bottom: vw-calc(16);
	.Button {
		width: 100%;
		height: 100%;
		padding-top: vw-calc(20);
	}
}