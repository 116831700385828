$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.Logo {
	position: absolute;
	width: vw-calc(245);
	height: vw-calc(70);
	top: vw-calc(20);
	left: vw-calc(15);
	background-image: url('../../assets/images/logo-small.svg');
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
	&--link {cursor: pointer;}
}

.Logo--settings {
	top: vw-calc(20);
	left: vw-calc(110);
}