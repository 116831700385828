$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.InstructionsIcon {
	position: absolute;
	top: vw-calc(100);
	right: vw-calc(325);
	width: vw-calc(32);
	height: vw-calc(32);
	background-image: url('../../assets/images/icon-instructions.svg');
	background-size: contain;
	background-repeat: no-repeat;
	cursor: pointer;
	&:hover {@include scale(1.1);}
	&--story {
		background-image: url('../../assets/images/icon-instructions-group.svg');
	}

	&--puzzle {
		position: absolute;
		top: vw-calc(142);
		right: vw-calc(63);
		width: vw-calc(24);
		height: vw-calc(24);
		background-image: url('../../assets/images/icon-instructions-light.svg');
		z-index: 2;
	}
	&--minigame-3 {
		top: vw-calc(100);
	}

	&--minigame-5 {
		top: vw-calc(95);
	}

	&--minigame-7 {
		top: vw-calc(93);
	}
	
	
	&--cyoa {
		width: vw-calc(29);
		height: vw-calc(29);
		top: vw-calc(10);
		right: vw-calc(85);
	}

	&--infoMinigame {
		top: vw-calc(70);
		right: vw-calc(55);
		z-index: 2;
		width: vw-calc(24);
		height: vw-calc(24);
		background-image: url('../../assets/images/icon-instructions-light.svg');
	}
}

// If Instructions has any of these classes, add transition
// If not, no transition: when you navigate to a new page instructions should be closed
.Instructions.show,
.Instructions.hide,
.Instructions.hidden {
	.ReactCollapse--collapse {
		@include transition('height', 0.4s, 'ease-in-out');
	}
}

.InstructionsIconDropdown {
	width: vw-calc(660);
	margin-top: vw-calc(15);
	margin-left: vw-calc(20);
	padding-left: vw-calc(10);
	padding-right: vw-calc(10);
	padding-top: vw-calc(5);
	padding-bottom: vw-calc(4);
	font-family: "Fivo Sans";
	font-weight: 600;
	font-size: vw-calc(20);
	color: $cyan;
	background-color: rgba($cyan, 0.1);
	background-image: url('../../assets/images/icon-down.svg');
	background-repeat: no-repeat;
	background-position: right vw-calc(20) center;
	background-size: vw-calc(15);
	cursor: pointer;
	&--show {
		background-image: url('../../assets/images/icon-up.svg');
	}
}

.InstructionsDropdown {
	width: vw-calc(660);
	overflow: hidden;
	margin-left: vw-calc(20);
	padding-left: vw-calc(25);
	padding-right: vw-calc(25);
	padding-top: vw-calc(0);
	background-color: rgba($cyan, 0.1);
	.Instructions-body {
		padding-bottom: vw-calc(30);
		a {
			color: white;
		}
	}
}

.InstructionsPopup {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(#011B1C, 0.5);
	@include flex('center','center');
	visibility: hidden;
	@include opacity(0);
	@include doubleTransition('opacity', 0.7s, 'ease-in-out', 'visibility', 0.7s, 'ease-in-out');
	z-index: -1;
	&--show,
	&--hide {
		z-index: 52;
		visibility: visible;
		@include opacity(1);
	}
	
	/* instructionsZoomOut contains the left and top position to which the instruction popup should close */
	
	/* Minigames, teacher + groups */
	&--hidden {
		z-index: 52;
		visibility: hidden;
		@include opacity(0);
		.Instructions-body {
			@include instructionsZoomOut(0.5, vw-calc(965), vw-calc(-10));
		}
	}
	&.InstructionsPopup.InstructionsPopup--minigame-3.InstructionsPopup--hidden,
	&.InstructionsPopup.InstructionsPopup--minigame-5.InstructionsPopup--hidden,
	&.InstructionsPopup.InstructionsPopup--minigame-7.InstructionsPopup--hidden {
		.Instructions-body {
			@include instructionsZoomOut(0.5, vw-calc(965), vw-calc(-50));
		}
	}

	/* CYOA game, teacher + groups */
	&.InstructionsPopup--cyoa.InstructionsPopup--hidden {
		.Instructions-body {
			@include instructionsZoomOut(0.5, vw-calc(930), vw-calc(-125));
		}
	}

	/* Wave simulator, teacher + groups */
	&.InstructionsPopup--waveSimulator.InstructionsPopup--hidden {
		.Instructions-body {
			@include instructionsZoomOut(0.5, vw-calc(970), vw-calc(-40));
		}
	}

	/* Info Minigame (LIPS, base rooms discovery), teacher + groups */
	&.InstructionsPopup--infoMinigame.InstructionsPopup--hidden {
		.Instructions-body {
			@include instructionsZoomOut(0.5, vw-calc(970), vw-calc(-80));
		}
	}
	&.InstructionsPopup--infoMinigame.InstructionsPopup--infoMinigame-1.InstructionsPopup--hidden {
		.Instructions-body {
			@include instructionsZoomOut(0.5, vw-calc(970), vw-calc(0));
		}
	}

	/* Investigations, groups */
	&.InstructionsPopup--investigation.InstructionsPopup--hidden,
	&.InstructionsPopup--minigameAndInvestigation.InstructionsPopup--hidden {
		.Instructions-body {
			@include instructionsZoomOut(0.5, vw-calc(860), vw-calc(-115));
		}
		&.InstructionsPopup--investigation-11 {
			.Instructions-body {
				@include instructionsZoomOut(0.5, vw-calc(860), vw-calc(-50));
			}
		}
	}

	/* Investigations, teacher */
	&.InstructionsPopup--investigation.InstructionsPopup--teacher.InstructionsPopup--hidden,
	&.InstructionsPopup--minigameAndInvestigation.InstructionsPopup--teacher.InstructionsPopup--hidden {
		.Instructions-body {
			@include instructionsZoomOut(0.5, vw-calc(960), vw-calc(-125));
		}
	}
	
	.Instructions-body {
		position: relative;
		height: vw-calc(400);
		width: vw-calc(800);
		background-image: linear-gradient($bg-gradient-light, $bg-gradient-dark);
		color: white;
		overflow: hidden;
		@include corner-box-big(cyan);
		@include no-select();
	}
	
	.Instructions-close {
		position: absolute;
		top: vw-calc(20);
		right: vw-calc(20);
		width: vw-calc(30);
		height: vw-calc(30);
		background-image: url('../../assets/images/icon-x.svg');
		background-size: contain;
		background-repeat: no-repeat;
		cursor: pointer;
		&:hover {@include scale(1.1);}
	}
	
	.Instructions-text {
		position: relative;
		height: 100%;
		width: 100%;
		padding: vw-calc(65);
		overflow-y: auto;
		font-size: vw-calc(16);
		line-height: 1.375;
		@include fancy-scrollbar(vw-calc(10), 100%, rgba($cyan, 0.2), vw-calc(10), 100%, vw-calc(20), $cyan);
	}

	table {
		color: white;
		border: 1px solid white;
		border-collapse: collapse;
		margin-top: vw-calc(10);
		th {
			font-weight: 400;
		}
		th, td {
			width: vw-calc(350);
			text-align: center;
			padding: vw-calc(5);
			border: 1px solid white;
			font-weight: 100;
		}
	}

	&--infoMinigame-1 {
		.Instructions-body {
			height: vw-calc(550);
			color: $cyan;

			table {
				color: $cyan;
				border: 1px solid $cyan;
				th, td {
					border: 1px solid $cyan;
				}
			}
		}
	}

	&--investigation-11 {
		.Instructions-body {
			height: vw-calc(550);
		}
	}

}
@media print {
	.Instructions {display: none;}
}
