$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.Popup-overlay {
	height: 100%;
	position: absolute;
	z-index: 1500;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,.50);
	@include flex('center','center');
	visibility: hidden;
	@include opacity(0);
	@include doubleTransition('opacity', 0.4s, 'ease-in-out', 'visibility', 0.4s, 'ease-in-out');
	
	&--show {
		visibility: visible;
		@include opacity(1);
	}
	
	&--intro {
		background-color: rgba(255,255,255,.30);
		&.Popup-overlay--hide {
			.Popup--intro {
				@include baseZoomOut(0.5, vw-calc(-240), vw-calc(-40));
			}
		}
	}
}

.Popup {
	position: relative;
	width: vw-calc(500);
	text-align: left;
	background-image: linear-gradient($bg-gradient-light, $bg-gradient-dark);
	color: white;
	padding: vw-calc(65);
	overflow-y: auto;
	@include corner-box-big(cyan);

	&--intro {
		width: vw-calc(680);
		padding: vw-calc(45) vw-calc(65) vw-calc(150) vw-calc(65);
		margin-top: vw-calc(40);
		border: none;
		border-top: vw-calc(2) solid $cyan;
		background-image: linear-gradient(#004F50 72%, rgba(#002F2C, 0) 100%);
		
		.Popup-body {
			font-size: vw-calc(18);
			line-height: 1.3;
		}
		.Button {
			display: none;
		}
	}

	&--cyoa {
		width: vw-calc(680);
		padding: vw-calc(40) vw-calc(65);
		p {
			margin-top: 0;
			margin-bottom: vw-calc(2);
		}
		li {
			&:nth-of-type(1) p,
			&:nth-of-type(3) p,
			&:nth-of-type(5) p,
			&:nth-of-type(7) p,
			&:nth-of-type(9) p {
				margin-bottom: vw-calc(15);
			}
		}
		ul {
			margin-bottom: vw-calc(15);
		}
	}
}

.Popup-title {
	font-family: 'Fivo Sans';
	font-size: vw-calc(20);
	text-transform: uppercase;
	color: $cyan;
	line-height: 1;
	font-weight: 600;	
}

.Popup-close {
	position: absolute;
	top: vw-calc(20);
	right: vw-calc(20);
	width: vw-calc(30);
	height: vw-calc(30);
	background-image: url('../../assets/images/icon-x.svg');
	background-size: contain;
	background-repeat: no-repeat;
	cursor: pointer;
	&:hover {@include scale(1.1);}
}

.Popup-body {
	font-size: vw-calc(16);
	line-height: 1.375;
}

.Popup-form {
	.PopupForm-text {
		input {
			width: 100%;
			font-size: vw-calc(16);
			line-height: 1;
			padding: vw-calc(12);
			margin-bottom: vw-calc(30);
			background-color: rgba($cyan, 0.24);
			color: white;
			border: none;
			border-radius: $border-radius;
			display: block;
			@include corner-box(cyan);
			@include input-placeholder($cyan);
			&:focus {outline: none;}
		}
	}
	.Button {
		width: 100%;
		height: vw-calc(50);
	}
	
	.PopupForm-button {
		display: inline-block;
	}
}

.Popup-buttons {
	.Button {
		padding-left: vw-calc(25);
		padding-right: vw-calc(25);
		&--loading.Button--dark {
			background-image: url('../../assets/images/icon-loading-white.svg');
			background-position-x: right;
		}
	}
	&--2 {
		.Button--popup {
			display: inline-block;
			&:nth-child(1) {
				margin-right: vw-calc(20);
			}
		}
	}
}

.Popup--createGame {
	.Popup-close {
		width: vw-calc(32);
		height: vw-calc(32);
	}
}

.Popup--newSession {
	.Popup-text span {
		color: $cyan;
		font-weight: 600;
	}
}

.Popup-createGame,
.Popup--tagBase {
	width: vw-calc(800);
	.Popup-body .Popup-form {
		.PopupForm-text {
			display: inline-block;
			vertical-align: middle;
			input {
				width: vw-calc(155);
				margin: 0;
			}	
		}
		.Button {
			float: right;
			vertical-align: middle;
			width: vw-calc(160);
			padding: vw-calc(14) 0;
			height: auto;
		}
	}
}
.Popup--tagBase {
	.Popup-form {margin-top: vw-calc(80);}
}

.Popup--resources,
.Popup--videos {
	padding-right: vw-calc(20);
	.Popup-title {
		margin-bottom: vw-calc(15);
	}
	a {
		color: white;
	}
	p {
		margin: 0;
	}
}