$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.EvaluationDndDragLayer {
	position: fixed;
	pointer-events: none;
	z-index: 10;
	left: 0em;
	top: 0em;
}