$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.Clues {
	position: relative;
	width: vw-calc(1153);
	height: vw-calc(345);
	padding: 0 vw-calc(40) 0 vw-calc(40);
	margin-left: vw-calc(26);
}

.Clues-prevBtn,
.Clues-nextBtn {
	position: absolute;
	top: vw-calc(150);
	width: vw-calc(40);
	height: vw-calc(60);
	background-size: vw-calc(15) vw-calc(24);
	background-position: center;
	background-repeat: no-repeat;
	background-color: darken($bg-gradient-light, 2%);
	border-top: vw-calc(2) solid $cyan;
	border-bottom: vw-calc(2) solid $cyan;
	cursor: pointer;
}
.Clues-prevBtn {left: 0; background-image: url('../../assets/images/investigations/icon-prev.svg');}
.Clues-nextBtn {right: 0; background-image: url('../../assets/images/investigations/icon-next.svg');}

.Clues-clue {
	height: 100%;
	background-image: url('../../assets/images/corner-triangle.svg'), linear-gradient($bg-gradient-light, $bg-gradient-dark);
	background-size: vw-calc(80), 100%;
	background-repeat: no-repeat;
	background-position: top right, center center;
	border-top: vw-calc(2) solid $cyan;
	border-bottom: vw-calc(2) solid $cyan;
	padding: vw-calc(30) vw-calc(40);
}

.Clues-clueNumber {
	position: absolute;
	bottom: vw-calc(10);
	right: vw-calc(53);
	font-family: "Fivo Sans";
	font-size: vw-calc(16);
	font-weight: 600;
	color: $cyan;
}

.Clues-clueImage {
	text-align: left;
	height: 100%;
	img {
		height: 100%;
	}
}

.CluePopup {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: rgba(#011B1C, 0.8);
	padding: vw-calc(50);
	z-index: 11;
	@include flex('center','center');
}

.CluePopup-clueImage {
	img {
		max-height: 100%;
		max-width: 100%;
	}
}

.Clues-clueTitle {
	font-size: vw-calc(20);
	font-family: 'Open Sans';
	font-weight: 600;
	margin-top: vw-calc(-25);
	text-transform: uppercase;
}

.Clues-clueTextWrapper {
	position: relative;
	width: vw-calc(956);
	height: vw-calc(307);
	background-image: url('../../assets/images/investigations/striped-background.png');
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: center;
	margin-left: vw-calc(-10);
	margin-top: vw-calc(-12);
	padding: vw-calc(2);
	table {
		color: $cyan;
		border: 1px solid $cyan;
		border-collapse: collapse;
		margin-top: vw-calc(10);
		th, td {
			width: vw-calc(220);
			text-align: left;
			padding: vw-calc(5);
			padding-left: vw-calc(15);
			border: 1px solid $cyan;
		}
		td {font-weight: 400;}
	}
}

.Clues-clueText {
	position: relative;
	width: 99.9%;
	height: 99%;
	font-size: vw-calc(16);
	font-family: 'Open Sans';
	font-weight: 600;
	padding: vw-calc(16) vw-calc(22);
	overflow: auto;
	@include fancy-scrollbar(vw-calc(16), 100%, #0D5151, 0, vw-calc(16), vw-calc(139), #00CBCB, 1px solid $cyan);
	p {margin: 0;}
	p:first-of-type {
		font-family: 'Fivo Sans';
		color: $cyan;
		text-transform: uppercase;
		margin-bottom: vw-calc(7);
	}
}

.Clues-clueSound {
	&:first-child {
		margin-top: vw-calc(30);
	}
	.Clues-clueSoundTitle {
		width: vw-calc(300);
		position: relative;
		text-align: right;
		font-size: vw-calc(25);
		font-family: 'Fivo Sans';
		font-weight: 600;
		color: $cyan;
	}
	.Clues-clueSoundTitle, .Audio {display: inline-block;}
}

.InvestigationTutorial--investigation-2,
.Investigation--investigation-2 {
	.Clues-clue--2,
	.Clues-clue--3 {
		.Clues-clueImage {
			height: vw-calc(296);
			width: vw-calc(956);
			img {width: 100%;}
		}
	}
	.Clues-clue--5 .Clues-clueImage {
		height: vw-calc(322);
		margin-top: vw-calc(-20);
	}
	.Clues-clue--7 .Clues-clueImage {
		width: vw-calc(956);
		img {width: 100%;}
	}
	.Clues-clueTitle::after {
		content: " [RECOVERED]";
		font-size: vw-calc(20);
		font-family: 'Open Sans';
		font-weight: 600;
		color: $cyan;
	}
}

.InvestigationTutorial--investigation-3,
.Investigation--investigation-3 {
	.Clues-clue--1 {
		border: none;
		background: linear-gradient($bg-gradient-light, $bg-gradient-dark);
		background-size: 100%;
		padding: 0;
		position: relative;
		overflow: hidden;
		.Clues-clueImage {
			margin-left: vw-calc(-11);
			img {
				width: vw-calc(1088);
				height: vw-calc(350);
			}
		}
		&:after {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			width: vw-calc(80);
			height: vw-calc(80);
			background-image: url('../../assets/images/corner-triangle.svg');
			background-size: 100%;
			background-repeat: no-repeat;
			background-position: top right;
		}
	}
}

.InvestigationTutorial--investigation-4,
.Investigation--investigation-4 {
	.Clues-clue--1 {
		.Clues-clueTitle {
			text-transform: none;
			font-weight: 600;
			margin-top: vw-calc(5);
		}
		.Clues-clueText {padding-left: 0;}
		.Clues-clueNumber {display: none;}
	}
}

.InvestigationTutorial--investigation-5,
.Investigation--investigation-5 {
	.Clues-clue--1 {
		position: relative;
		padding-top: vw-calc(50);
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(#00CBCB, 0.2);
		}
		.Clues-clueTitle::after {
			content: " [RECOVERED]";
			font-size: vw-calc(20);
			font-family: 'Open Sans';
			font-weight: 600;
			color: $cyan;
		}
		.Clues-clueNumber {
			right: vw-calc(13);
			bottom: vw-calc(8);
		}
	}
	.Clues-clue--2 {
		background: linear-gradient($bg-gradient-light, $bg-gradient-dark);
		background-size: 100%;
		padding: 0;
		position: relative;
		overflow: hidden;
		.Clues-clueImage {
			margin-left: vw-calc(-11);
			margin-top: vw-calc(-2);
			img {
				width: vw-calc(1088);
				height: vw-calc(350);
			}
		}
		&:after {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			width: vw-calc(80);
			height: vw-calc(80);
			background-image: url('../../assets/images/corner-triangle.svg');
			background-size: 100%;
			background-repeat: no-repeat;
			background-position: top right;
		}
		.Clues-clueNumber {
			right: vw-calc(13);
			bottom: vw-calc(8);
		}
	}
	.Clues-clue--3 {
		color: $cyan;
	}
}

.InvestigationTutorial--investigation-6,
.Investigation--investigation-6 {
	.Clues-clue--1 {
		&:before {
			content: "";
			position: absolute;
			width: vw-calc(952);
			height: vw-calc(303);
			background-image: url('../../assets/images/investigations/striped-background.png');
			background-repeat: no-repeat;
			background-size: 100% 100%;
			background-position: center;
			margin-left: vw-calc(-10);
			margin-top: vw-calc(-12);
			padding: vw-calc(2);
		}
		.Clues-clueImage {
			width: vw-calc(943);
			height: vw-calc(300);
			position: relative;
			margin-top: vw-calc(-10);
			padding-top: vw-calc(20);
			padding-bottom: vw-calc(25);
			padding-left: vw-calc(15);
			overflow: auto;
			@include fancy-scrollbar(vw-calc(16), 100%, #0D5151, 0, vw-calc(16), vw-calc(139), #00CBCB, 1px solid $cyan);
			img {
				position: relative;
				width: vw-calc(658);
				height: auto;
			}
		}
	}
	.Clues-clue--2 {
		table {
			margin-left: vw-calc(15);
			margin-top: vw-calc(15);
			th, td {
				&:nth-of-type(2) {
					margin-left: 0;
					width: vw-calc(420);
					text-align: center;
				}
			}
		}
	}
	.Clues-clue--3 {
		.Clues-clueText div {
			display: inline-block;
			width: vw-calc(340);
		}
		table {
			margin-left: vw-calc(15);
			margin-top: vw-calc(15);
			th, td {
				width: vw-calc(290);
			}
			td {
				border: none;
				padding-top: 0;
				padding-bottom: 0;
				margin: 0;
			}
			tr {
				&:nth-of-type(2),
				&:nth-of-type(7) {
					height: vw-calc(53);
				}
			}
		}
	}
	.CluePopup {
		.CluePopup-clueImage {
			background-color: #0D5151;
			padding: vw-calc(13);
			img {
				width: vw-calc(630);
			}
		}
	}
}

.InvestigationTutorial--investigation-7,
.Investigation--investigation-7 {
	.Clues-clue--1 {
		&:before {
			content: "";
			position: absolute;
			width: vw-calc(952);
			height: vw-calc(303);
			background-image: url('../../assets/images/investigations/striped-background.png');
			background-repeat: no-repeat;
			background-size: 100% 100%;
			background-position: center;
			margin-left: vw-calc(-10);
			margin-top: vw-calc(-12);
			padding: vw-calc(2);
		}
		.Clues-clueImage {
			width: vw-calc(943);
			height: vw-calc(300);
			position: relative;
			margin-top: vw-calc(-10);
			padding-top: vw-calc(10);
			padding-bottom: vw-calc(15);
			padding-left: vw-calc(5);
			overflow: auto;
			@include fancy-scrollbar(vw-calc(16), 100%, #0D5151, 0, vw-calc(16), vw-calc(139), #00CBCB, 1px solid $cyan);
			img {
				margin-top: vw-calc(10);
				position: relative;
				width: vw-calc(658);
				height: auto;
			}
		}
	}
	.CluePopup {
		.CluePopup-clueImage {
			background-color: #0D5151;
			padding: vw-calc(13);
			img {
				width: vw-calc(890);
			}
		}
	}
}

.InvestigationTutorial--investigation-8,
.Investigation--investigation-8 {
	.Clues-clue--1,
	.Clues-clue--2,
	.Clues-clue--3,
	.Clues-clue--4 {
		&:before {
			content: "";
			position: absolute;
			width: vw-calc(952);
			height: vw-calc(303);
			background-image: url('../../assets/images/investigations/striped-background.png');
			background-repeat: no-repeat;
			background-size: 100% 100%;
			background-position: center;
			margin-left: vw-calc(-10);
			margin-top: vw-calc(-12);
			padding: vw-calc(2);
		}
		.Clues-clueImage {
			width: vw-calc(943);
			height: vw-calc(300);
			position: relative;
			margin-top: vw-calc(-10);
			padding-top: vw-calc(10);
			padding-bottom: vw-calc(15);
			padding-left: vw-calc(5);
			padding-right: vw-calc(5);
			overflow: auto;
			@include fancy-scrollbar(vw-calc(16), 100%, #0D5151, 0, vw-calc(16), vw-calc(139), #00CBCB, 1px solid $cyan);
			img {
				position: relative;
				width: vw-calc(658);
				height: auto;
			}
		}
	}
	.Clues-clue--1 .Clues-clueImage img {
		width: vw-calc(750);
	}

	.Clues-clue--2 .Clues-clueImage img {
		margin-top: vw-calc(-40);
		margin-left: vw-calc(-20);
	}
	.Clues-clue--3 .Clues-clueImage img,
	.Clues-clue--4 .Clues-clueImage img {
		margin-top: vw-calc(10);
		width: vw-calc(900);
	}
	.CluePopup {
		.CluePopup-clueImage {
			background-color: #0D5151;
			padding: vw-calc(13);
			img {
				width: vw-calc(800);
			}
		}
		&--1 .CluePopup-clueImage img {
			width: vw-calc(890);
		}
		&--2 .CluePopup-clueImage img,
		&--3 .CluePopup-clueImage img {
			width: vw-calc(680);
		}
		&--4 .CluePopup-clueImage img {
			width: vw-calc(1000);
		}
	}
}

.InvestigationTutorial--investigation-9,
.Investigation--investigation-9 {
	.Clues-clue--1 {
		padding-top: vw-calc(50);
		.Clues-clueTitle {
			margin-left: vw-calc(-25);
		}
		.Clues-clueImage {
			img { width: vw-calc(624); }
			div {
				position: absolute;
				top: vw-calc(27);
				left: vw-calc(245);
				font-size: vw-calc(20);
				font-weight: 600;
				color: $cyan;
			}
		}
	}
	.Clues-clue--2,
	.Clues-clue--3 {
		&:before {
			content: "";
			position: absolute;
			width: vw-calc(952);
			height: vw-calc(303);
			background-image: url('../../assets/images/investigations/striped-background.png');
			background-repeat: no-repeat;
			background-size: 100% 100%;
			background-position: center;
			margin-left: vw-calc(-10);
			margin-top: vw-calc(-12);
			padding: vw-calc(2);
		}
		.Clues-clueImage {
			width: vw-calc(943);
			height: vw-calc(300);
			position: relative;
			margin-top: vw-calc(-10);
			padding-top: vw-calc(10);
			padding-bottom: vw-calc(15);
			padding-left: vw-calc(15);
			padding-right: vw-calc(5);
			overflow: auto;
			@include fancy-scrollbar(vw-calc(16), 100%, #0D5151, 0, vw-calc(16), vw-calc(139), #00CBCB, 1px solid $cyan);
			img {
				position: relative;
				width: vw-calc(849);
				height: auto;
			}
			div {
				padding-left: vw-calc(20);
				padding-right: vw-calc(40);
				margin-top: vw-calc(-15);
				margin-bottom: vw-calc(20);
				white-space: pre-line;
			}
		}
	}
	.Clues-clue--3 {
		.Clues-clueImage {
			padding-top: vw-calc(15);
			padding-left: vw-calc(10);
			img {
				width: vw-calc(570);
			}
		}
	}
	.CluePopup {
		&--0 .CluePopup-clueImage {
			img {
				width: vw-calc(900);
			}
		}
		&--1 .CluePopup-clueImage {
			background-color: #0D5151;
			padding: vw-calc(13);
			img {
				width: vw-calc(490);
			}
		}
		&--2 .CluePopup-clueImage {
			background-color: #0D5151;
			padding: vw-calc(13);
			img {
				width: vw-calc(650);
			}
		}
	}
}

.InvestigationTutorial--investigation-10,
.Investigation--investigation-10 {
	.Clues-clue::before {
		content: '';
		position: absolute;
		width: vw-calc(1074);
		height: 100%;
		top: 0;
		left: vw-calc(39);
		background-color: rgba(#00CBCB, 0.27);
	}
	.Clues-clue--1 {
		.Clues-clueTitle {
			margin-left: vw-calc(-20);
			margin-top: vw-calc(0);
			margin-bottom: vw-calc(65);
			position: relative;
			&::after {
				content: " [RECOVERED]";
				font-size: vw-calc(20);
				font-family: 'Open Sans';
				font-weight: 600;
				color: $cyan;
			}
		}
	}
	.Clues-clue--2,
	.Clues-clue--4 {
		.Clues-clueImage {
			padding-top: vw-calc(0);
			padding-left: vw-calc(10);
			width: vw-calc(1000);
			height: vw-calc(330);
			margin-top: vw-calc(-25);
			position: relative;
			img {
				width: 100%;
			}
		}
	}
	.Clues-clue--4 {
		.Clues-clueImage {
			padding-left: vw-calc(0);
			margin-left: vw-calc(-18);
			margin-top: vw-calc(-30);
		}
	}
	.Clues-clue--3 {
		&::before {
			content: none;
		}
		.Clues-clueText {
			margin: vw-calc(90) vw-calc(30);
			p {
				margin-bottom: vw-calc(-5);
				font-size: vw-calc(20);
				font-weight: 400;
				text-transform: none;
			}
		}
	}
	
	.CluePopup {
		&--1 .CluePopup-clueImage {
			background-color: #0D5151;
			padding: vw-calc(20);
			img {
				width: vw-calc(1100);
			}
		}
	}
}

.InvestigationTutorial--investigation-11,
.Investigation--investigation-11 {
	.Clues .Clues-clue {
		white-space: pre-line;
		.Clues-clueText {
			padding-left: vw-calc(40);
			padding-right: vw-calc(40);
			font-weight: 400;
		}
	}
}

.InvestigationTutorial--investigation-12,
.Investigation--investigation-12 {
	.Clues-clue {
		position: relative;
		&:before {
			content: "";
			position: absolute;
			width: vw-calc(952);
			height: vw-calc(303);
			background-image: url('../../assets/images/investigations/striped-background.png');
			background-repeat: no-repeat;
			background-size: 100% 100%;
			background-position: center;
			margin-left: vw-calc(-10);
			margin-top: vw-calc(-12);
			padding: vw-calc(2);
		}
		.Clues-clueImage {
			width: vw-calc(943);
			height: vw-calc(300);
			position: relative;
			margin-top: vw-calc(-10);
			padding-top: vw-calc(10);
			padding-bottom: vw-calc(15);
			padding-left: vw-calc(30);
			overflow: auto;
			@include fancy-scrollbar(vw-calc(16), 100%, #0D5151, 0, vw-calc(16), vw-calc(139), #00CBCB, 1px solid $cyan);
			img {
				margin-top: vw-calc(10);
				position: relative;
				width: vw-calc(530);
				height: auto;
				cursor: pointer;
			}
			div {
				width: vw-calc(330);
				display: inline-block;
				vertical-align: top;
				padding-top: vw-calc(3);
				padding-left: vw-calc(10);
				margin-right: vw-calc(15);
				h2 {
					font-size: vw-calc(20);
					margin-bottom: vw-calc(5);
				}
			}
		}
	}
	.CluePopup {
		.CluePopup-clueImage {
			background-color: #0D5151;
			padding: vw-calc(20);
			img {
				width: vw-calc(740);
				cursor: pointer;
			}
		}
	}
}

.InvestigationTutorial--investigation-13,
.Investigation--investigation-13 {
	.Clues-clue {
		position: relative;
		&:before {
			content: "";
			position: absolute;
			width: vw-calc(952);
			height: vw-calc(303);
			background-image: url('../../assets/images/investigations/striped-background.png');
			background-repeat: no-repeat;
			background-size: 100% 100%;
			background-position: center;
			margin-left: vw-calc(-10);
			margin-top: vw-calc(-12);
			padding: vw-calc(2);
		}

		.Clues-clueImage {
			width: vw-calc(943);
			height: vw-calc(300);
			position: relative;
			margin-top: vw-calc(-10);
			padding-top: vw-calc(10);
			padding-bottom: vw-calc(15);
			padding-left: vw-calc(5);
			overflow: auto;
			border-top: vw-calc(1) solid $cyan;
			@include fancy-scrollbar(vw-calc(16), 100%, #0D5151, 0, vw-calc(16), vw-calc(139), #00CBCB, 1px solid $cyan);
			img {
				margin-top: vw-calc(10);
				position: relative;
				width: vw-calc(530);
				height: auto;
				cursor: pointer;
				border: vw-calc(1) solid $cyan;
			}
			div {
				width: vw-calc(350);
				display: inline-block;
				vertical-align: top;
				padding-top: vw-calc(3);
				padding-left: vw-calc(10);
				margin-right: vw-calc(30);
			}
		}
	}
	.CluePopup {
		.CluePopup-clueImage {
			background-color: #0D5151;
			padding: vw-calc(20);
			img {
				width: vw-calc(740);
				cursor: pointer;
			}
		}
	}
}

.InvestigationTutorial--investigation-14,
.Investigation--investigation-14 {
	.Clues-clue {
		padding-left: vw-calc(100);
	}

	.CluePopup {
		.CluePopup-clueImage {
			background-color: #0D5151;
			padding: vw-calc(20);
			img {
				width: vw-calc(800);
				cursor: pointer;
			}
		}
		&--1 {
			.CluePopup-clueImage img {
				width: vw-calc(540);
			}
		}
	}
}