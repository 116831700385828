$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.Result {
	position: relative;
	padding: vw-calc(30);

	.Button {
		position: absolute;
		&--activateTagging {
			top: vw-calc(19);
			right: vw-calc(20);
			width: vw-calc(184);
			height: vw-calc(42);
			padding-top: vw-calc(10);
		}
		&--goToNextSession {
			bottom: vw-calc(10);
			right: vw-calc(20);
		}
	}
}

.Result-title {
	font-size: vw-calc(20);
	font-family: "Fivo Sans";
	margin-bottom: vw-calc(20);
}


.Result-text { 
	margin-bottom: vw-calc(30);
}

.Result-ranking {
	position: relative;
	overflow: hidden;
}
.Result-headerRow {
	width: 95%;
	padding-bottom: vw-calc(5);
	border-bottom: vw-calc(1) solid white;
	@include flex($justify:"flex-start", $align:"center", $direction:"");
	div {
		width: 35%;
		display: inline-block;
		vertical-align: top;
		text-align: center;
		font-size: vw-calc(18);
		&:nth-of-type(1) {
			padding-left: vw-calc(20);
		}
		&:nth-of-type(2) {
			padding-left: vw-calc(70);
		}
		&:nth-of-type(3) {
			padding-left: vw-calc(50);
		}
	}
	&--3 {
		div:nth-of-type(1) {padding-left: vw-calc(60);}
		div:nth-of-type(2) {padding-left: vw-calc(70);}
		div:nth-of-type(3) {padding-left: vw-calc(70);}
		div:nth-of-type(4) {padding-left: vw-calc(40);}
	}
}
.Result-groupRow {
	width: 95%;
	@include flex($justify:"center", $align:"center", $direction:"");
	border-bottom: vw-calc(1) solid $text-dark;
}

.Result-groupPoints {
	width: 100%;
	text-align: center;
	display: inline-block;
	vertical-align: top;
	padding-top: vw-calc(10);
	padding-bottom: vw-calc(10);
	border-left: vw-calc(1) solid $text-dark;
	&--total {
		position: relative;
		border-left: vw-calc(3) solid $cyan;
		width: 50%;
		&::before {
			content: "";
			background-color: $cyan;
			width: vw-calc(3);
			height: 100%;
			position: absolute;
			left: vw-calc(-3);
			top: vw-calc(3);
		}
	}
}
