$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.GroupLoginStatuses {
	position: relative;
	padding: vw-calc(30);
}

.GroupLoginStatuses-title {
	font-family: "Fivo Sans";
	font-size: vw-calc(24);
	margin: vw-calc(15) 0;
}
.GroupLoginStatuses-info {
	font-size: vw-calc(20);
	padding: vw-calc(10);
	p {margin: 0;}
	span {
		float: left;
		width: vw-calc(65);
		font-weight: bold;
	}
}


.GroupLoginStatuses-container {
	margin-top: vw-calc(20);
	padding: vw-calc(10);
}

