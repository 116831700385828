$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.MissionPanel {
	position: absolute;
	left: vw-calc(290);
	right: vw-calc(290);
	top: vw-calc(32);
	bottom: vw-calc(24);
}


.MissionPanel-content {
	overflow: auto;
	height: vw-calc(613);
	margin-top: vw-calc(50);
	border-bottom: vw-calc(2) solid $cyan;
	background-image: linear-gradient($bg-gradient-light, $bg-gradient-dark);
	@include fancy-scrollbar(vw-calc(10), 100%, rgba($cyan, 0.2), vw-calc(10), 100%, vw-calc(20), $cyan);
}