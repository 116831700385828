$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.Menu {
	position: absolute;
	top: vw-calc(120);
	left: vw-calc(32);
	cursor: pointer;
}

.Menu-title {
	position: relative;
	display: inline-block;
	text-transform: uppercase;
	font-size: vw-calc(20);
	line-height: vw-calc(24);
	margin-bottom: vw-calc(12);
	cursor: pointer;
	@include no-select();
}

.Menu-items {
	position: relative;
	width: vw-calc(225);
	&::before {
		content: "";
		position: absolute;
		width: vw-calc(225);
		height: vw-calc(10);
		top: vw-calc(-1);
		left: 0;
		background-image: url('../../assets/images/menu-border.png');
		background-size: vw-calc(225) auto;
		background-position: left top;
		background-repeat: no-repeat;
	}
}
.Menu-item {
	height: vw-calc(40);
	font-size: vw-calc(18);
	line-height: vw-calc(40);
	color: $cyan;
	padding: 0 vw-calc(10);
	border-top: vw-calc(2) solid #011B1C;
	border-left: vw-calc(4) solid transparent;
	&:last-child {border-bottom: vw-calc(2) solid transparent;}
	cursor: pointer;
	// &--status {
		background-image: url('../../assets/images/icon-circle-cyan.svg'), linear-gradient($bg-gradient-light, #063B3B);
		background-size: vw-calc(11) vw-calc(11), 100%;
		background-position: right vw-calc(14) center, center;
		background-repeat: no-repeat, no-repeat;
	// }


	&--selected {
		color: white;
		border-top: vw-calc(2) solid $cyan;
		border-left: vw-calc(4) solid $cyan;
		background-image: url('../../assets/images/icon-circle-light.svg'), linear-gradient($bg-gradient-light, #063B3B);
		& + .Menu-item {
			border-top: vw-calc(2) solid $cyan;
		}
		&:nth-child(1) {border-top: vw-calc(2) solid #011B1C;;}
		&:last-child {border-bottom: vw-calc(2) solid $cyan;}
	}

	&--open {
		background-image: url('../../assets/images/icon-circle-cyan-full.svg'), linear-gradient($bg-gradient-light, #063B3B);
	}	
}

.Menu-item.Menu-item--open.Menu-item--selected {
	background-image: url('../../assets/images/icon-circle-light-full.svg'), linear-gradient($bg-gradient-light, #063B3B);
}


@media print {
	.Menu {display: none;}
}