$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.LandingPage {
	padding: vw-calc(0) vw-calc(100);
	width: 100%;
	height: 100%;
	background-image: url('../../assets/images/background.jpg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	color: white;
	@include flex('center', 'center', 'column');
	.Logo-textured-glow {
		width: vw-calc(520);
		height: auto;
		margin-top: vw-calc(-100);
		@include box-shadow-picture(vw-calc(5), vw-calc(5), vw-calc(1), rgba(#000000, .5), 0, 0, vw-calc(10), rgba($cyan, .7))
	}
	.LandingPage-message {
		position: relative;
		margin-bottom: vw-calc(20);
		font-size: vw-calc(13);
		width: vw-calc(800);
		padding: vw-calc(20) vw-calc(36);
		margin-top: vw-calc(30);
		background-color: rgba($color: #015051, $alpha: 0.75);
		&.two-column {
			column-count: 2;
			column-gap: vw-calc(30);
		}
		div {
			b { color: $cyan;}
			margin-top: vw-calc(-5);
		}
		pre {margin: 0;}
		code {
			font-family: 'Fivo Sans';
			white-space: pre-line;
		}
	}
	.Button {
		position: relative;
		display: block;
		width: vw-calc(150);
		margin: 0 auto;
		z-index: 1;
	}
	.LandingPage-logos {
		position: absolute;
		left: vw-calc(50);
		bottom: vw-calc(10);
		font-size: vw-calc(14);
		width: 100%;
		padding-right: vw-calc(100);
		@include flex('space-between', 'flex-start', 'row');
		.LandingPage-logoWrapper {
			&:first-of-type {
				width: vw-calc(500);
				img {
					margin-top: vw-calc(10);
					&:first-of-type {
						width: vw-calc(294);
					}
					&:last-of-type {
						width: vw-calc(140);
						margin-left: vw-calc(41);
						margin-bottom: vw-calc(15);
					}
				}
			}
			&:last-of-type {
				padding-top: vw-calc(10);
				width: vw-calc(160);
				text-align: center;
				img {
					width: vw-calc(75);
					margin-top: vw-calc(20);
				}
			}
		}
	}
}