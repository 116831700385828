$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.GroupSubmissions {
	position: relative;
	height: vw-calc(345);
	padding: 0 vw-calc(40) 0 vw-calc(40);
}

.GroupSubmissions-prevBtn,
.GroupSubmissions-nextBtn {
	position: absolute;
	top: vw-calc(155);
	width: vw-calc(40);
	height: vw-calc(40);
	background-size: auto vw-calc(24);
	background-position: center top;
	background-repeat: no-repeat;
	cursor: pointer;
}
.GroupSubmissions-prevBtn {left: vw-calc(25); background-image: url('../../assets/images/investigations/icon-prev.svg');}
.GroupSubmissions-nextBtn {right: vw-calc(41); background-image: url('../../assets/images/investigations/icon-next.svg');}

.GroupSubmissions-group {
	height: 100%;
	width: vw-calc(1073);
	background-image: url('../../assets/images/corner-triangle.svg'), linear-gradient($bg-gradient-light, $bg-gradient-dark);
	background-size: vw-calc(80), 100%;
	background-repeat: no-repeat;
	background-position: top right, center center;
	border-top: vw-calc(2) solid $cyan;
	border-bottom: vw-calc(2) solid $cyan;
	padding: vw-calc(30) vw-calc(40);
	margin-left: vw-calc(26);
}


.GroupSubmissions-groupNumber {
	position: absolute;
	bottom: vw-calc(10);
	right: vw-calc(94);
	font-family: "Fivo Sans";
	font-size: vw-calc(16);
	font-weight: 600;
	color: $cyan;
}

.GroupSubmissions-groupImage {
	text-align: left;
	height: 100%;
	img {
		height: 100%;
	}
}