$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.WaveSimulator {
	position: absolute;
	left: vw-calc(0);
	right: vw-calc(0);
	top: vw-calc(0);
	bottom: vw-calc(0);
	padding: vw-calc(20) vw-calc(45);
	z-index: 10;
	color: white;
	@include no-select();
	background-image: url('../../assets/images/background.jpg');
	background-size: cover;
	background-position: top left;
	&--interference {
		@include flex($justify:"center", $align:"center", $direction:"");
		iframe {
			background-color: black;
		}
	}
	&::before {
		content:"";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		background-color: rgba($bg-dark, .8);
	}

	.WaveSimulator-heading {
		position: relative;
		font-size: vw-calc(42);
	}

	.WaveSimulator-variables {
		font-weight: 600;
		font-size: vw-calc(20);
	}
	
	.WaveSimulator-variables-container {
		position: relative;
		left: vw-calc(30);
		top: vw-calc(50);
	}
	.WaveSimulator-variables-name,
	.WaveSimulator-variables-number,
	.WaveSimulator-variables-unit {
		display: inline-block;
		padding-top: vw-calc(10);
		margin-bottom: vw-calc(20);
	}

	.WaveSimulator-variables-name {
		width: vw-calc(130);
		text-align: right;
		margin-right: vw-calc(10);
	}

	.WaveSimulator-variables-number {
		position: relative;
		width: vw-calc(126);
		height: vw-calc(58);
		background-image: linear-gradient($bg-gradient-light, $bg-gradient-dark);
		border-top: vw-calc(2) solid $cyan;
		border-bottom: vw-calc(2) solid $cyan;
		text-align: center;
		margin-right: vw-calc(5);

		div {
			padding-right: vw-calc(5);
		}

		sup {
			position: absolute;
			top: vw-calc(0);
			right: vw-calc(35);
			font-size: 80%;
		}
	}

	.WaveSimulator-controlpanel {
		position: absolute;
		bottom: vw-calc(18);
		right: vw-calc(45);
		height: vw-calc(193);
		width: vw-calc(1190);
		background-image: linear-gradient($bg-gradient-light, $bg-gradient-dark);
		border-top: vw-calc(2) solid $cyan;
		border-bottom: vw-calc(2) solid $cyan;

		.Button {
			position: absolute;
			left: vw-calc(54);
			top: vw-calc(75);
			font-size: vw-calc(24);
			width: vw-calc(135);
			border: none;
		}
	}

	.WaveSimulator-controlpanel-type {
		position: absolute;
		top: vw-calc(40);
		left: vw-calc(257);
		text-align: center;
		.light {
			left: vw-calc(5);
		}
		.sound {
			left: vw-calc(80);
		}
		.WaveSimulator-controlpanel-rotary {
			cursor: pointer;
			top: vw-calc(20);
			left: vw-calc(30);
			&.sound {
				@include rotate(70deg);
			}
		}
		.WaveSimulator-controlpanel-label {
			position: absolute;
			top: vw-calc(90);
			left: vw-calc(15);
			font-size: vw-calc(20);
			font-weight: 600;
			color: $text-dark;
			@include no-select();
		}
	}


	.WaveSimulator-controlpanel-environment {
		position: absolute;
		top: vw-calc(54);
		left: vw-calc(450);
		.earth {
			top: vw-calc(-14);
			left: vw-calc(0);
		}
		.mars {
			position: absolute;
			top: vw-calc(-35);
			left: vw-calc(67);
		}
		.space {
			position: absolute;
			top: vw-calc(-14);
			left: vw-calc(125);
		}
		.WaveSimulator-controlpanel-rotary {
			top: vw-calc(8);
			left: vw-calc(60);
			cursor: pointer;
			&.earth {
				@include rotate(-7deg);
			}
			&.mars {
				@include rotate(29deg);
			}
			&.space {
				@include rotate(73deg);
			}
		}
		.WaveSimulator-controlpanel-label {
			position: absolute;
			top: vw-calc(78);
			left: vw-calc(42);
			font-size: vw-calc(20);
			font-weight: 600;
			color: $text-dark;
			@include no-select();
		}
	}

	.WaveSimulator-controlpanel-param {
		position: absolute;
		padding: 0 vw-calc(5);
		border-radius: vw-calc(7);
		color: white;
		font-size: vw-calc(16);
		font-weight: 600;
		cursor: pointer;
		@include no-select();
	}

	.WaveSimulator-controlpanel-rotary {
		position: absolute;
		width: vw-calc(66);
		height: vw-calc(80);
		background-image: url('../../assets/images/rotary-button.svg');
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
		transform-origin: center;
		@include transition(transform, 0.2s, linear);
	}

	.WaveSimulator-controlpanel-sliders {
		position: relative;
		top: vw-calc(10);
		left: vw-calc(730);
	}

	.WaveSimulator-controlpanel-lock {
		position: absolute;
		top: vw-calc(24);
		left: vw-calc(-40);
		width: vw-calc(15);
		height: vw-calc(23);
		background-image: url('../../assets/images/icon-lock.svg');
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
		cursor: pointer;
		&:hover {
			@include scale(1.1);
		}
		&.unlocked {
			width: vw-calc(18);
			height: vw-calc(26);
			left: vw-calc(-41);
			background-image: url('../../assets/images/icon-lock-unlocked.svg');
		}
	}

	.WaveSimulator-controlpanel-wavelength,
	.WaveSimulator-controlpanel-frequency,
	.WaveSimulator-controlpanel-temperature {
		font-size: vw-calc(17);
		font-weight: 600;
		margin-left: vw-calc(15);
		position: relative;
	}

	.WaveSimulator-controlpanel-range {
		-webkit-appearance: none;
		appearance: none;
		width: vw-calc(406);
		height: vw-calc(20);
		border-radius: vw-calc(50);
		background-color: $bg-gradient-dark;
		margin-bottom: vw-calc(10);
		outline: none;
		@include box-shadow(0, 0, vw-calc(6), 0, $cyan);
	}

	// for webkit browsers
	.WaveSimulator-controlpanel-range::-webkit-slider-thumb {
		-webkit-appearance: none;
		appearance: none;
		background-color: $cyan;
		width: vw-calc(59);
		height: vw-calc(18);
		border-radius: vw-calc(50);
	}

	// for Firefox
	.WaveSimulator-controlpanel-range::-moz-range-thumb {
		-webkit-appearance: none;
		appearance: none;
		background-color: $cyan;
		width: vw-calc(59);
		height: vw-calc(18);
		border-radius: vw-calc(50);
	}

	.WaveSimulator-view {
		position: absolute;
		right: vw-calc(45);
		top: vw-calc(90);
		width: vw-calc(700);
		height: vw-calc(400);
		background-image: linear-gradient($bg-gradient-light, $bg-gradient-dark);
		border-bottom: vw-calc(2) solid #0D5151;
		overflow: hidden;
		&:before {
			content: "";
			width: 100%;
			height: vw-calc(2);
			position: absolute;
			top: 0;
			left: 0;
			background-color: $cyan;
		}
		&:after {
			content: "";
			position: absolute;
			width: vw-calc(80);
			height: vw-calc(80);
			top: vw-calc(-2);
			right: vw-calc(-2);
			background-image: url('../../assets/images/corner-triangle.svg');
			background-size: contain;
			background-repeat: no-repeat;
		}
	}

	#WaveSimulator-canvas {
		width: vw-calc(700);
		height: vw-calc(400);
	}

	.WaveSimulator-close {
		position: absolute;
		top: vw-calc(30);
		right: vw-calc(45);
		width: vw-calc(30);
		height: vw-calc(30);
		background-image: url('../../assets/images/icon-x.svg');
		background-size: 99%;
		background-repeat: no-repeat;
		cursor: pointer;
		@include no-select();
		&:hover {@include scale(1.1);}
	}

	.InstructionsIcon {
		top: vw-calc(100);
		right: vw-calc(55);
		width: vw-calc(24);
		height: vw-calc(24);
		background-image: url('../../assets/images/icon-instructions-light.svg');
		z-index: 2;
	}
}
