$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.Graph {
	width: vw-calc(450);
	height: vw-calc(213);
	background-color: white;
	position: absolute;
	right: vw-calc(25);
	overflow: hidden;
	canvas {
		width: 100% !important;
		height: 100% !important;
	}
	&.oxygen {
		top: vw-calc(45);
	}
	&.energy {
		top: vw-calc(265);
	}
	&.distance {
		top: vw-calc(485);
		height: vw-calc(230);
	}

	&.atomReactorEnergy,
	&.atomReactor {
		position: relative;
		width: vw-calc(300);
		height: vw-calc(150);
		display: inline-block;
		margin-right: vw-calc(10);
		margin-top: vw-calc(10);
		margin-bottom: vw-calc(10);
	}
	&.atomReactorEnergy {
		position: absolute;
		right: vw-calc(15);
	}
}