$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.Game {
	position: relative;
	height: 100%;
	width: 100%;
	background-color: $bg-dark;
	color: white;
	&::after {
		content:"";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($bg-dark, .8);
		z-index: 4;
		visibility: hidden;
		@include opacity(0);
		@include doubleTransition('opacity', 0.6s, 'ease-in-out', 'visibility', 0.6s, 'ease-in-out');
	}
	&--overview {
		background-image: url('../../assets/images/background-overview.jpg');
		background-size: cover;
		background-position: top left;
		&::before {
			content:"";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba($brown, .23);
		}

		&.Game--group {
			.Camera {
				right: vw-calc(230);
			}
		}
	}
	&--settings,
	&--page {
		background-image: url('../../assets/images/background.jpg');
		background-size: cover;
		background-position: top left;
		&::before {
			content:"";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba($bg-dark, .8);
		}
	}
	&--showBase {
		&::after {
			background-color: rgba($bg-dark, 0.98);
			visibility: visible;
			@include opacity(1);
			@include doubleTransition('opacity', 0s, 'ease-in-out', 'visibility', 0s, 'ease-in-out');
		}
	}
}

@media print {
	.Game {color: black; background: white;}
}