$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.SessionStatus {
	position: absolute;
	bottom: vw-calc(120);
	left: vw-calc(60);
	color: #21CBCB;
	font-size: vw-calc(18);
	font-weight: 600;
	text-transform: uppercase;
	z-index: 1;
	text-align: center;
	padding: vw-calc(8) 0;
	background-color: #0B6564;
	width: vw-calc(170);
	height: vw-calc(42);
	.Button {
		width: 100%;
		padding: vw-calc(8) 0;
		height: vw-calc(42);
		margin-top: vw-calc(-8);
		font-size: vw-calc(18);
		background: linear-gradient(#FEC186, #D36C5C);
		&:hover,
		&:focus,
		&:active {
			background-image: linear-gradient(darken(#FEC186, 5%), darken(#D36C5C, 5%));
		}
	}
}