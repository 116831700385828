$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.Evaluation {
	position: relative;
	height: vw-calc(530);
	padding: vw-calc(30);
}

.Evaluation-container {
	position: relative;
	width: 100%;
	height: 100%;
	margin-left: vw-calc(10);
	margin-top: vw-calc(20);
}

.Evaluation-title {
	font-size: vw-calc(20);
	font-family: "Fivo Sans";
	margin-bottom: vw-calc(10);
}

.Evaluation-axis {
	position: absolute;
	&--x {
		height: vw-calc(60);
		width: vw-calc(400);
		left: vw-calc(75);
		top: vw-calc(400);
		.Evaluation-axisTitle {
			padding-left: vw-calc(25);
		}
		.Evaluation-axisTic {
			display: inline-block;
			width: vw-calc(80);
			padding-top: vw-calc(5);
			text-align: center;
		}
	}
	&--y {
		height: vw-calc(400);
		width: vw-calc(70);
		top: vw-calc(0);
		left: vw-calc(5);
		.Evaluation-axisTitle {
			@include rotate(-90deg);
			@include origin(0%, 0%);
			width: vw-calc(390);
			bottom: vw-calc(-35);
			padding-left: vw-calc(25);
			input {cursor: vertical-text;}
		}
		.Evaluation-axisTic {
			height: vw-calc(80);
			text-align: right;
			padding-right: vw-calc(15);
			padding-top: vw-calc(30);
		}
	}
}

.Evaluation-axisTitle {
	position: absolute;
	text-align: left;
	bottom: 0;
	left: 0;
	width: 100%;
	background-image: url('../../assets/images/icon-pencil.svg');
	background-repeat: no-repeat;
	background-size: vw-calc(20);
	background-position: 0 vw-calc(7);
	input {
		min-width: vw-calc(250);
		font-size: vw-calc(15);
		margin-right: vw-calc(10);
		padding: vw-calc(5) vw-calc(5) vw-calc(5) vw-calc(10);
		background: transparent;
		color: white;
		height: vw-calc(30);
		cursor: text;
		&:focus {
			background: rgba($text-dark, 0.8);
		}
		&:-webkit-autofill {
			background: transparent !important;
			color: white !important;
		}
	}
	.Button {
		position: absolute;
		top: 0;
		right: 0;
		width: vw-calc(77);
		height: vw-calc(29);
		padding-top: vw-calc(3);
	}
}

.Evaluation-axisTic {
	font-weight: 600;
	font-size: vw-calc(13);
	@include no-select();
}

.EvaluationGrid {
	position: absolute;
	left: vw-calc(75);
	width: vw-calc(400);
	height: vw-calc(400);
	@include no-select();
	.GroupBadge {
		width: 100%;
		height: 100%;
	}
}

.EvaluationGrid-square {
	display: inline-block;
	vertical-align: top;
	width: vw-calc(80);
	height: vw-calc(80);
	border: 1px solid $text-dark;
	@include no-select();
	&--gridSize1 .EvaluationGrid-group {
		width: vw-calc(60);
		height: vw-calc(60);
		margin: vw-calc(8);
		margin-top: vw-calc(11);
	}
	&--gridSize4 .EvaluationGrid-group {
		width: vw-calc(36);
		height: vw-calc(36);
		margin-top: vw-calc(3);
		margin-left: vw-calc(2);
	}
	&--gridSize9 .EvaluationGrid-group {
		width: vw-calc(21);
		height: vw-calc(21);
		margin-left: vw-calc(4);
		margin-top: vw-calc(2);
	}
	&--gridSize12 .EvaluationGrid-group {
		width: vw-calc(18);
		height: vw-calc(20);
		margin-top: vw-calc(2);
	}

}
.EvaluationGrid-group {
	display: inline-block;
	width: vw-calc(40);
	height: vw-calc(40);
	@include no-select();
	&--draggable,
	&--dragging {
		cursor: move;
	}
	&--hover {
		.GroupBadge-icon { @include box-shadow-picture(0, 0, vw-calc(7), rgba($cyan, .63)) }
	}
}

.EvaluationGroups {
	position: absolute;
	top: vw-calc(0);
	left: vw-calc(490);
	width: vw-calc(150);
}

.EvaluationGroups-group {
	display: inline-block;
	margin: vw-calc(6) vw-calc(8);
	@include no-select();
	&--draggable { cursor: move; }
	&--hover {
		.GroupBadge-icon { @include box-shadow-picture(0, 0, vw-calc(7), rgba($cyan, .63)) }
	}
	&--isPlaced {
		.GroupBadge-icon {
			-webkit-filter: none;
			filter: none;
		}
	}
	.GroupBadge { 
		width: vw-calc(59);
		background-size: contain;
		.GroupBadge-name span {
			font-size: vw-calc(10);
		}
	}
}

.Evaluation-saveBtn {
	position: absolute;
	left: vw-calc(430);
	top: vw-calc(-60);
	.Button {
		width: vw-calc(210);
		height: vw-calc(42);
		padding: vw-calc(10) vw-calc(1);
		&--loading {
			background-size: auto 60%, cover;
		}
	}
}