$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.Button {
	display: inline-block;
	font-weight: bold;
	text-transform: uppercase;
	text-decoration: none;
	text-align: center;
	padding: vw-calc(5) vw-calc(15);
	font-weight: 600;
	font-size: vw-calc(15);
	background-image: linear-gradient($btn-gradient-light, $btn-gradient-dark);
	padding: vw-calc(7) vw-calc(20);
	color: white;
	outline: none;
	touch-action: manipulation;
	pointer-events: auto;
	cursor: pointer;
	@include no-select();
	@include opacity(1);
	@include doubleTransition('opacity', 0.3s, 'ease-in-out', 'visibility', 0.3s, 'ease-in-out');
	&:hover,
	&:focus,
	&:active {
		background-image: linear-gradient(darken($btn-gradient-light, 5%), darken($btn-gradient-dark, 5%));
	}

	&--disabled {
		cursor: not-allowed;
		background-image: linear-gradient(lighten(grey, 30%), darken(grey, 10%));
		&:hover,
		&:focus,
		&:active {
			background-image: linear-gradient(lighten(grey, 30%), darken(grey, 10%));
		}
	}

	&--loading {
		background-image: url('../../assets/images/icon-loading-white.svg'), linear-gradient($btn-gradient-light, $btn-gradient-dark);
		background-color: transparent;
		background-size: auto 80%, cover;
		background-position: center right, center center;
		background-repeat: no-repeat, no-repeat;
		cursor: not-allowed;
		&:hover {
			background-image: url('../../assets/images/icon-loading-white.svg'), linear-gradient($btn-gradient-light, $btn-gradient-dark);
		}
	}

	&--selected {
		background-color: $green;
		&:hover,
		&:focus,
		&:active {
			background-image: linear-gradient(darken($btn-gradient-light, 5%), darken($btn-gradient-dark, 5%));
		}
	}

	&--hidden {
		visibility: visible;
		cursor: default;
		@include opacity(0);
	}
}


.Button.Button--wide {
	font-size: vw-calc(18);
	padding: vw-calc(7) vw-calc(50);
}

.Button.Button--dark {
	font-family: "Fivo Sans";
	font-size: vw-calc(18);
	line-height: 1;
	text-align: center;
	text-transform: uppercase;
	padding: vw-calc(13) vw-calc(20);
	padding-top: vw-calc(15);
	background-image: linear-gradient($bg-gradient-light, $bg-gradient-dark);
	border-top: vw-calc(2) solid $cyan;
	border-bottom: vw-calc(2) solid $cyan;
}
.Button.Button--dark.Button--loading {
		background-color: transparent;
		background-image: url('../../assets/images/icon-loading-white.svg'), linear-gradient($bg-gradient-light, $bg-gradient-dark);
		background-size: auto 80%, auto auto;
		background-position: center right, center center;
		background-repeat: no-repeat, no-repeat;
	}
.Button.Button--dark.Button--selected {
	background-color: transparent;
	&:hover,
	&:focus,
	&:active {
		background-color: transparent;
	}
}
.Button.Button--dark.Button--disabled {
	color: white;
	background-image: linear-gradient(lighten(grey, 30%), darken(grey, 10%));
	border-top: vw-calc(2) solid darken(grey, 30%);
	border-bottom: vw-calc(2) solid darken(grey, 30%);
	&:hover,
	&:focus,
	&:active {
		background-image: linear-gradient(lighten(grey, 30%), darken(grey, 10%));
	}
}