/* Appearance & touch action & no select */
@mixin appearance($value) {
	-webkit-appearance: #{$value};
	-moz-appearance:    #{$value};
	appearance:         #{$value};
}

@mixin touchaction($value) {
	touch-action: #{$value};
	-ms-touch-action: #{$value};
}

@mixin no-select() {
	-webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently*/
}


/* Flex */
@mixin flex($justify:"", $align:"", $direction:"") {
	display: -webkit-box;      // OLD - iOS 6-, Safari 3.1-6
	display: -moz-box;         // OLD - Firefox 19- (buggy but mostly works)
	display: -ms-flexbox;      // TWEENER - IE 10
	display: -webkit-flex;     // NEW - Chrome
	display: flex;             // NEW, Spec - Opera 12.1, Firefox 20+
	@include flex-properties($justify, $align, $direction);
}

@mixin inline-flex($justify:"", $align:"", $direction:"") {
	display: -webkit-inline-box;
	display: -moz-inline-box;
	display: -ms-inline-flexbox;
	display: -webkit-inline-flex;
	display: inline-flex;
  @include flex-properties($justify, $align, $direction);
}

@mixin flex-properties($justify, $align, $direction) {
	@if $justify !="" {
		-webkit-justify-content: #{$justify};
		justify-content: #{$justify};
		-webkit-box-pack: #{$justify};
		-ms-flex-pack: #{$justify};
	}
	@if $align !="" {
		-webkit-box-align: #{$align};
		-webkit-flex-align: #{$align};
		-ms-flex-align: #{$align};
		-webkit-align-items: #{$align};
		align-items: #{$align};	
	}
	@if $direction !="" {
		-webkit-box-direction: #{$direction};
		-webkit-flex-direction: #{$direction};
		flex-direction: #{$direction};
	}
}

@mixin flex-wrap($wrap) {
	-webkit-flex-wrap: $wrap;
	-moz-flex-wrap: $wrap;
	-ms-flex-wrap: $wrap;
	-o-flex-wrap: $wrap;
	flex-wrap: $wrap;
}

@mixin align-content($align) {
	-webkit-align-content: $align;
    align-content: $align;
}

@mixin align-self($value) {
	-webkit-align-self: #{$value};
	align-self: #{$value};
}

/* Transforms */
@mixin scale($scale) {
	-ms-transform: scale($scale); /* IE 9 */
	-webkit-transform: scale($scale); /* Safari */
	transform: scale($scale);
}

@mixin scaleY($scale) {
	-ms-transform: scaleY($scale); /* IE 9 */
	-webkit-transform: scaleY($scale); /* Safari */
	transform: scaleY($scale);
}

@mixin scaleX($scale) {
	-ms-transform: scaleX($scale); /* IE 9 */
	-webkit-transform: scaleX($scale); /* Safari */
	transform: scaleX($scale);
}

@mixin scale3d($scaleX, $scaleY, $scaleZ) {
	-ms-transform: scale3d($scaleX, $scaleY, $scaleZ); /* IE 9 */
	-webkit-transform: scale3d($scaleX, $scaleY, $scaleZ); /* Safari */
	transform: scale3d($scaleX, $scaleY, $scaleZ);
}

@mixin rotate($angle) {
	-ms-transform: rotate($angle); /* IE 9 */
	-webkit-transform: rotate($angle); /* Safari */
	transform: rotate($angle);
}

@mixin origin($x, $y) {
	-ms-transform-origin: $x $y;
	-webkit-transform-origin: $x $y;
	transform-origin: $x $y;
}

@mixin skewX($deg) {
	-webkit-transform: skewX(#{$deg}deg);
	-ms-transform: skewX(#{$deg}deg);
	-o-transform: skewX(#{$deg}deg);
	transform: skewX(#{$deg}deg);
}

@mixin translateY($y) {
	-webkit-transform: translateY($y);
	-ms-transform: translateY($y);
	-o-transform: translateY($y);
	transform: translateY($y);
}

@mixin translateX($x) {
	-webkit-transform: translateY($x);
	-ms-transform: translateY($x);
	-o-transform: translateY($x);
	transform: translateY($x);
}



/* Opacity */
@mixin opacity($opacity) {
	$procentOpacity: $opacity * 100;	
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity="$procentOpacity")"; /* IE 8 */
	filter: alpha(opacity=$procentOpacity); /* IE 5-7 */
	-moz-opacity: $opacity; /* Netscape */
	-khtml-opacity: $opacity; /* Safari 1.x */
	opacity: $opacity;
}


/* Input & Textarea */
@mixin input-placeholder($color) {
	&::-webkit-input-placeholder {color: $color !important;}	/* Chrome/Opera/Safari */
	&:-moz-placeholder {color: $color !important;}  					/* Firefox 18- */
	&::-moz-placeholder {color: $color !important;} 					/* Firefox 19+ */
	&:-ms-input-placeholder {color: $color !important;}			/* IE 10+ */
}

@mixin textarea-placeholder($color) {
	&::-webkit-input-placeholder {color: $color !important;}	/* Chrome/Opera/Safari */
	&:-moz-placeholder {color: $color !important;}  					/* Firefox 18- */
	&::-moz-placeholder {color: $color !important;} 					/* Firefox 19+ */
	&:-ms-input-placeholder {color: $color !important;}			/* IE 10+ */
}



/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
@mixin fancy-scrollbar($width,$height,$backgroundColor,$borderRadius,$thumbWidth, $thumbHeight, $thumbColor, $thumbBorder: none) { 
	&::-webkit-scrollbar {
		width: $width;
		height: $height;
	}
	&::-webkit-scrollbar-track {
		background-color: $backgroundColor;
		border-radius: $borderRadius;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: $borderRadius;
		background: $thumbColor;
		width: $thumbWidth;
		height: $thumbHeight;
		border: $thumbBorder;
	}
	&::-webkit-scrollbar-thumb:window-inactive {
		background-color: transparent;
		border: 1px solid $thumbColor;
	}
}

@mixin custom-scrollbar($width, $backgroundColor, $thumbColor) {
	/*Chrome, Safari and Edge*/
	&::-webkit-scrollbar {
		width: $width; /* width of the veritcal scrollbar */
		height: $width; /* width of the horizontal scrollbar */
	}
	&::-webkit-scrollbar-track {
		background-color: $backgroundColor;
	}
	&::-webkit-scrollbar-thumb {
		background-color: $thumbColor;
		border-radius: $width;
		border: 1px solid $backgroundColor;
	}
	&::-webkit-scrollbar-corner {
		background-color: rgba(0, 0, 0, 0);
	}
	/*Firefox*/
	scrollbar-width: thin;
	scrollbar-color: $thumbColor $backgroundColor;
}

/* Box shadow */
@mixin box-shadow($hoffset, $voffset, $blur, $spread, $color) {
	-webkit-box-shadow: $hoffset $voffset $blur $spread $color;
	-moz-box-shadow: $hoffset $voffset $blur $spread $color;
	box-shadow: $hoffset $voffset $blur $spread $color;
}

/* Box shadow for svgs, pngs, etc. */
@mixin box-shadow-picture($hoffset, $voffset, $blur, $color, $second_hoffset:0, $second_voffset:0, $second_blur:0, $second_color:transparent) {
	-webkit-filter: drop-shadow($hoffset $voffset $blur $color) drop-shadow($second_hoffset $second_voffset $second_blur $second_color);
	filter: drop-shadow($hoffset $voffset $blur $color) drop-shadow($second_hoffset $second_voffset $second_blur $second_color);
}

/* Corner box */
@mixin corner-box($color) {
	border-width: vw-calc(3);
	border-style: solid;
	border-image: url('../assets/images/frame-' + $color + '.svg') round;
	border-image-slice: 10 10 10 10;
	border-image-width: vw-calc(10) vw-calc(10);
}

/* Corner box big */
@mixin corner-box-big($color) {
	border-width: vw-calc(3);
	border-style: solid;
	border-image: url('../assets/images/frame-big-' + $color + '.svg') round;
	border-image-slice: 20 20 20 20;
	border-image-width: vw-calc(20) vw-calc(20);
}

/* Transitions */
@mixin transition($target,$duration,$type,) {	
	-moz-transition: #{$target} $duration #{$type};		/* Firefox */
	-webkit-transition: #{$target} $duration #{$type};	/* WebKit */
	-o-transition: #{$target} $duration #{$type};			/* Opera */
	transition: #{$target} $duration #{$type};					/* Standard */
}

@mixin transition-delay($delay1, $delay2: 0s) {
	-webkit-transition-delay: $delay1, $delay2 ;
	-moz-transition-delay: $delay1, $delay2 ;
	-o-transition-delay: $delay1, $delay2 ;
	transition-delay: $delay1, $delay2 ;
}

@mixin doubleTransition($target,$seconds,$type,$target2,$seconds2,$type2) {	
	-moz-transition: #{$target} $seconds #{$type}, #{$target2} $seconds2 #{$type2};		/* Firefox */
	-webkit-transition: #{$target} $seconds #{$type}, #{$target2} $seconds2 #{$type2};	/* WebKit */
	-o-transition: #{$target} $seconds #{$type}, #{$target2} $seconds2 #{$type2};			/* Opera */
	transition: #{$target} $seconds #{$type}, #{$target2} $seconds2 #{$type2};					/* Standard */
}

/* Animations */

/* Fade in */
@mixin fadeIn($duration, $delay: 0) {
	-moz-animation: fadeIn #{$duration}s linear #{$delay}s 1 forwards; /* Fx 5+ */
	-o-animation: fadeIn #{$duration}s linear #{$delay}s 1 forwards; /* Opera 12+ */
	-webkit-animation: fadeIn #{$duration}s linear #{$delay}s 1 forwards;
	animation: fadeIn #{$duration}s linear #{$delay}s 1 forwards;
}

@-webkit-keyframes fadeIn {
	0%   {opacity: 0;}
	100% {opacity: 1;}
}

@-moz-keyframes fadeIn {
	0%   {opacity: 0;}
	100% {opacity: 1;}
}

@-o-keyframes fadeIn {
	0%   {opacity: 0;}
	100% {opacity: 1;}
}

@keyframes fadeIn {
	0%   {opacity: 0;}
	100% {opacity: 1;}
}

/* Base zoom in */
@mixin baseZoomIn($duration, $delay: 0) {
	transform-origin: vw-calc(640) vw-calc(360);
	-moz-animation: baseZoomIn #{$duration}s linear #{$delay}s 1 forwards; /* Fx 5+ */
	-o-animation: baseZoomIn #{$duration}s linear #{$delay}s 1 forwards; /* Opera 12+ */
	-webkit-animation: baseZoomIn #{$duration}s linear #{$delay}s 1 forwards;
	animation: baseZoomIn #{$duration}s linear #{$delay}s 1 forwards;
}

@-webkit-keyframes baseZoomIn {
	0%   {opacity: 0; @include scale3d(0.9, 0.9, 1);}
	100% {opacity: 1; @include scale3d(1, 1, 1);}
}

@-moz-keyframes baseZoomIn {
	0%   {opacity: 0; @include scale3d(0.9, 0.9, 1);}
	100% {opacity: 1; @include scale3d(1, 1, 1);}
}

@-o-keyframes baseZoomIn {
	0%   {opacity: 0; @include scale3d(0.9, 0.9, 1);}
	100% {opacity: 1; @include scale3d(1, 1, 1);}
}

@keyframes baseZoomIn {
	0%   {opacity: 0; @include scale3d(0.9, 0.9, 1);}
	100% {opacity: 1; @include scale3d(1, 1, 1);}
}

/* Base zoom out */
@mixin baseZoomOut($duration, $x, $y, $delay: 0) {
	transform-origin: $x $y;
	-moz-animation: baseZoomOut #{$duration}s linear #{$delay}s 1 forwards; /* Fx 5+ */
	-o-animation: baseZoomOut #{$duration}s linear #{$delay}s 1 forwards; /* Opera 12+ */
	-webkit-animation: baseZoomOut #{$duration}s linear #{$delay}s 1 forwards;
	animation: baseZoomOut #{$duration}s linear #{$delay}s 1 forwards;
}

@-webkit-keyframes baseZoomOut {
	0%   {opacity: 1; @include scale3d(1, 1, 1);}
	100% {opacity: 1; @include scale3d(0, 0, 1);}
}

@-moz-keyframes baseZoomOut {
	0%   {opacity: 1; @include scale3d(1, 1, 1);}
	100% {opacity: 1; @include scale3d(0, 0, 1);}
}

@-o-keyframes baseZoomOut {
	0%   {opacity: 1; @include scale3d(1, 1, 1);}
	100% {opacity: 1; @include scale3d(0, 0, 1);}
}

@keyframes baseZoomOut {
	0%   {opacity: 1; @include scale3d(1, 1, 1);}
	100% {opacity: 1; @include scale3d(0, 0, 1);}
}

@mixin baseZoomOut2($duration, $x, $y, $delay: 0) {
	transform-origin: $x $y;
	-moz-animation: baseZoomOut2 #{$duration}s linear #{$delay}s 1 forwards; /* Fx 5+ */
	-o-animation: baseZoomOut2 #{$duration}s linear #{$delay}s 1 forwards; /* Opera 12+ */
	-webkit-animation: baseZoomOut2 #{$duration}s linear #{$delay}s 1 forwards;
	animation: baseZoomOut2 #{$duration}s linear #{$delay}s 1 forwards;
}

@-webkit-keyframes baseZoomOut2 {
	0%   {opacity: 1; @include scale3d(1, 1, 1);}
	100% {opacity: 0; @include scale3d(0, 0, 1);}
}

@-moz-keyframes baseZoomOut2 {
	0%   {opacity: 1; @include scale3d(1, 1, 1);}
	100% {opacity: 0; @include scale3d(0, 0, 1);}
}

@-o-keyframes baseZoomOut2 {
	0%   {opacity: 1; @include scale3d(1, 1, 1);}
	100% {opacity: 0; @include scale3d(0, 0, 1);}
}

@keyframes baseZoomOut2 {
	0%   {opacity: 1; @include scale3d(1, 1, 1);}
	100% {opacity: 0; @include scale3d(0, 0, 1);}
}

/* Base zoom out */
@mixin instructionsZoomOut($duration, $x, $y, $delay: 0) {
	transform-origin: $x $y;
	-moz-animation: instructionsZoomOut #{$duration}s linear #{$delay}s 1 forwards; /* Fx 5+ */
	-o-animation: instructionsZoomOut #{$duration}s linear #{$delay}s 1 forwards; /* Opera 12+ */
	-webkit-animation: instructionsZoomOut #{$duration}s linear #{$delay}s 1 forwards;
	animation: instructionsZoomOut #{$duration}s linear #{$delay}s 1 forwards;
}

/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
	0%   { @include scale3d(1, 1, 1);}
	100% { @include scale3d(0, 0, 1);}
}

@-moz-keyframes instructionsZoomOut {
	0%   { @include scale3d(1, 1, 1);}
	100% { @include scale3d(0, 0, 1);}
}

@-o-keyframes instructionsZoomOut {
	0%   { @include scale3d(1, 1, 1);}
	100% { @include scale3d(0, 0, 1);}
}

@keyframes instructionsZoomOut {
	0%   { @include scale3d(1, 1, 1);}
	100% { @include scale3d(0, 0, 1);}
}

// Current pin animation (bounce + glow)
@mixin bounceGlow($duration) {
	-moz-animation: bounceGlow #{$duration}s infinite ease-in-out; /* Fx 5+ */
	-o-animation: bounceGlow #{$duration}s infinite ease-in-out; /* Opera 12+ */
	-webkit-animation: bounceGlow #{$duration}s infinite ease-in-out;
	animation: bounceGlow #{$duration}s infinite ease-in-out;
}
@-webkit-keyframes bounceGlow {
	0%, 50%, 100% {@include translateY(0);}
	25%, 75% {@include translateY(vw-calc(7));}
	
	50% {@include box-shadow-picture(0, 0, vw-calc(10), rgba(#04FDFD, .7));}
}
@keyframes bounceGlow {
	0%, 50%, 100% {@include translateY(0);}
	25%, 75% {@include translateY(vw-calc(7));}

	50% {@include box-shadow-picture(0, 0, vw-calc(10), rgba(#04FDFD, .7));}
}
