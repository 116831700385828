$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.GroupBadge {
	position: relative;
	width: vw-calc(60);
	height: vw-calc(50);
	@include no-select();
	&--showName {
		width: vw-calc(70);
		height: vw-calc(70);
	}
	&--showName.GroupBadge--loading {
		.GroupBadge-name {
			background-image: url('../../assets/images/icon-loading-white.svg');
			background-size: auto 90%;
			background-position: bottom vw-calc(1.25) right vw-calc(0);
			background-repeat: no-repeat;
		}
	}
	&--showName.GroupBadge--loading.GroupBadge--arandas,
	&--showName.GroupBadge--loading.GroupBadge--bacolor,
	&--showName.GroupBadge--loading.GroupBadge--korolev,
	&--showName.GroupBadge--loading.GroupBadge--magong,
	&--showName.GroupBadge--loading.GroupBadge--victoria {
		.GroupBadge-name {
			background-position: bottom vw-calc(1) right vw-calc(-5);
		}
	}
	&--showName.GroupBadge--loading.GroupBadge--eberswalde {
		.GroupBadge-name {
			width: vw-calc(92);
			text-align: left;
		}
	}
}

.GroupBadge.GroupBadge--groupLogin {
	display: inline-block;
	margin: vw-calc(20) vw-calc(20);
	.GroupBadge-name {color: red;}
	&.GroupBadge--isPlaying .GroupBadge-name {color: green;}
}

.GroupBadge.GroupBadge--investigation {
	display: inline-block;
	vertical-align: top;
	width: vw-calc(40);
	height: vw-calc(35);
	background-size: contain;
	background-position: top center;
}

.GroupBadge.GroupBadge--result {
	display: inline-block;
	width: vw-calc(33);
	height: vw-calc(28);
	margin-left: vw-calc(10);
	margin-right: vw-calc(10);
	img {
		width: vw-calc(33);
		height: vw-calc(28);
	}
}

.GroupBadge.GroupBadge--minigame-result {
	display: inline-block;
	width: vw-calc(37);
	height: vw-calc(32);
	margin-left: vw-calc(10);
	margin-right: vw-calc(10);
	img {
		width: vw-calc(37);
		height: vw-calc(32);
	}
}

.GroupBadge-icon {
	width: 100%;
	pointer-events: none;
	@include no-select();
}

.GroupBadge-name {
	text-align: center;
	margin-top: vw-calc(-5);
	@include no-select();
	span {
		font-size: vw-calc(14);
	}
}