$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.CameraBaseContainer {
	position: relative;
	width: vw-calc(167);
	height: vw-calc(128);
	overflow: hidden;
	&--CameraPopup {
		width: 100%;
		height: 100%;
	}
	&--BaseOverview {
		width: vw-calc(168);
		height: vw-calc(87);
		.CameraBase {
			left: vw-calc(-550);
			top: vw-calc(-320);
			@include scale(0.14);
		}
	}
}

.CameraBase {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	min-height: vw-calc(720);
	min-width: vw-calc(1280);
	background-color: #F1986B;
	background-image: url('../../assets/images/base/elements/background.svg');
	background-size: 100% 100%;
	background-position: top left;
	background-repeat: no-repeat;
	@include scale(0.75);
	&--repaired {
		background-image: url('../../assets/images/base/elements-repaired/background.svg');
	}
	&--null { // First landing, Radiomars, Den store opdatering
		left: vw-calc(-500);
		top: vw-calc(-300);
	}
	&--dome {
		left: vw-calc(-535);
		top: vw-calc(-355);
	}
	&--solarpanels {
		@include scale(0.57);
		left: vw-calc(-278);
		top: vw-calc(-435);
	}
	&--ice {
		left: vw-calc(-950);
		top: vw-calc(-215);
	}
	&--greenhouse {
		@include scale(0.7);
		left: vw-calc(-475);
		top: vw-calc(-460);
	}
	&--rocket {
		left: vw-calc(-730);
		top: vw-calc(-160);
	}
	&--satellite {
		@include scale(0.70);
		left: vw-calc(-795);
		top: vw-calc(-345);
	}
	&--communication {
		@include scale(1.1);
		left: vw-calc(-795);
		top: vw-calc(-435);
	}
	&--dorms {
		@include scale(0.65);
		left: vw-calc(-475);
		top: vw-calc(-290);
	}
	&--crater {
		@include scale(1.2);
		left: vw-calc(-1135);
		top: vw-calc(-10);
	}
	&--vehicles {
		left: vw-calc(-920);
		top: vw-calc(-330);
	}
	&--lab {
		left: vw-calc(-370);
		top: vw-calc(-410);
	}
	&--garage {
		left: vw-calc(-680);
		top: vw-calc(-310);
	}
	&--curiosity {
		@include scale(1.3);
		left: vw-calc(-675);
		top: vw-calc(-700);
	}
	&--stars {
		left: vw-calc(-490);
		top: vw-calc(-90);
	}
	&--spacesuit {
		left: vw-calc(-570);
		top: vw-calc(-500);
	}
	&--hidden-base{
		@include scale(1.15);
		left: vw-calc(-925);
		top: vw-calc(10);
	}
	&--CameraPopup {
		@include scale(3.5);
		&.CameraBase--null { // First landing, Radiomars, Den store opdatering
			left: vw-calc(0);
			top: vw-calc(0);
		}
		&.CameraBase--dome {
			left: vw-calc(20);
			top: vw-calc(-330);
		}
		&.CameraBase--solarpanels {
			@include scale(3);
			left: vw-calc(1250);
			top: vw-calc(-735);
		}
		&.CameraBase--ice {
			left: vw-calc(-2120);
			top: vw-calc(350);
		}
		&.CameraBase--greenhouse {
			@include scale(3.75);
			left: vw-calc(380);
			top: vw-calc(-880);
		}
		&.CameraBase--rocket {
			left: vw-calc(-890);
			top: vw-calc(580);
		}
		&.CameraBase--satellite {
			left: vw-calc(-1450);
			top: vw-calc(-290);
		}
		&.CameraBase--communication {
			left: vw-calc(-1000);
			top: vw-calc(-500);
		}
		&.CameraBase--dorms {
			@include scale(3.7);
			left: vw-calc(450);
			top: vw-calc(-10);
		}
		&.CameraBase--crater {
			left: vw-calc(-1955);
			top: vw-calc(800);
		}
		&.CameraBase--vehicles {
			left: vw-calc(-1950);
			top: vw-calc(-190);
		}
		&.CameraBase--lab {
			@include scale(3);
			left: vw-calc(500);
			top: vw-calc(-500);
		}
		&.CameraBase--garage {
			left: vw-calc(-620);
			top: vw-calc(-120);
		}
		&.CameraBase--curiosity {
			@include scale(4.5);
			left: vw-calc(-460);
			top: vw-calc(-1385);
		}
		&.CameraBase--stars {
			@include scale(3.7);
			left: vw-calc(90);
			top: vw-calc(950);
		}
		&.CameraBase--spacesuit {
			left: vw-calc(-130);
			top: vw-calc(-1020);
		}
		&.CameraBase--hidden-base {
			left: vw-calc(-1300);
			top: vw-calc(900);
		}
	}
}
