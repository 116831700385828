$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
.WaveSimulatorsAdmin {
	position: relative;
	width: 100%;
	height: 100%;
	background-color: white;
	color: black;
	padding: vw-calc(50) vw-calc(20);
	overflow-y: scroll;
}

.WaveSimulatorsAdmin-backBtn {
	position: absolute;
	top: vw-calc(10);
	left: vw-calc(20);
	display: inline-block;
	background-color: lightgrey;
	padding: vw-calc(8) vw-calc(12);
	border-radius: vw-calc(5);
	font-weight: bold;
	margin: 0;
	cursor: pointer;
	&:hover {background-color: darken(lightgrey, 5%);}
}

.WaveSimulatorsAdmin-saveBtn {
	width: vw-calc(100);
	display: block;
	margin-top: vw-calc(30);
	background-color: lightgrey;
	padding: vw-calc(8) vw-calc(12);
	border-radius: vw-calc(5);
	font-weight: bold;
	text-align: center;
	cursor: pointer;
	&:hover {background-color: darken(lightgrey, 5%);}
	&.disabled {
		opacity: 0.3;
		cursor: not-allowed;
		&:hover {background-color: lightgrey;}
	}
	&.saving {
		background-image: url('../../../assets/images/icon-loading.svg');
		background-size: vw-calc(30) auto;
		background-position: right vw-calc(5) center;
		background-repeat: no-repeat;
	}
}

.WaveSimulatorsAdmin-title {
	font-weight: bold;
	font-size: vw-calc(18);
	margin: vw-calc(20) 0 vw-calc(30) 0;
}

.WaveSimulatorsAdmin-navForm {
	display: inline-block;
	vertical-align: top;
	margin-right: vw-calc(20);
	width: vw-calc(300);
}

.WaveSimulatorsAdmin-form {
	display: block;	
	margin-bottom: vw-calc(50);
}

.WaveSimulatorsAdmin-inputRow {
	margin: vw-calc(10) 0;
}

span.WaveSimulatorsAdmin-label {
	display: inline-block;
	vertical-align: middle;
	width: vw-calc(100);
	font-weight: bold;
}

p.WaveSimulatorsAdmin-label {
	font-weight: bold;
	margin-bottom: vw-calc(5);
}


.WaveSimulatorsAdmin-input {
	display: inline-block;
	vertical-align: middle;
	font-size: vw-calc(16);
	width: vw-calc(350);
	border: 1px solid rgba(black, 0.25);
}

.WaveSimulatorsAdmin-textarea {
	width: vw-calc(500);
	height: vw-calc(300);
	font-size: vw-calc(16);
	vertical-align: top;
	border: 1px solid rgba(black, 0.25);
	&.small {
		height: vw-calc(100);
	}
}

.WaveSimulatorsAdmin-radioButtonWrap {
	display: inline-block;
	margin-right: vw-calc(20);
}

.WaveSimulatorsAdmin-radio {
	@include appearance(radio);
}


.WaveSimulatorsAdmin-preview {
	display: inline-block;
	vertical-align: bottom;
	border: 1px solid black;
	width: vw-calc(500);
	height: vw-calc(300);
	padding: 0 vw-calc(10);
	margin-left: vw-calc(50);
	// margin-bottom: vw-calc(50);
	overflow: auto;
	&.small {
		height: vw-calc(100);
	}
}