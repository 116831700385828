$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.NextStep {
	position: absolute;
	bottom: vw-calc(24);
	right: vw-calc(16);
	width: vw-calc(240);
	height: vw-calc(70);
	background-image: url('../../assets/images/next-step-button.svg');
	background-size: contain;
	background-position: top left;
	background-repeat: no-repeat;
	font-family: "Fivo Sans";
	font-size: vw-calc(20);
	font-weight: bold;
	line-height: 1;
	padding-top: vw-calc(25);
	text-align: center;
	text-transform: uppercase;
	cursor: pointer;
	@include no-select();

	&--investigation {
		width: vw-calc(155);
		height: vw-calc(60);
		bottom: vw-calc(25);
		color: $cyan;
		padding-top: vw-calc(20);
		background-image: url('../../assets/images/investigations/next-step-button.svg');
	}

	&--next-session {
		padding-top: vw-calc(27);
		padding-left: vw-calc(8);
		text-align: left;
		font-size: vw-calc(17);
	}
}
