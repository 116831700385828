@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.Button {
  display: inline-block;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  padding: 0.00391rem 0.01172rem;
  font-weight: 600;
  font-size: 0.01172rem;
  background-image: linear-gradient(#00CBCB, #006666);
  padding: 0.00547rem 0.01562rem;
  color: white;
  outline: none;
  touch-action: manipulation;
  pointer-events: auto;
  cursor: pointer;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" 100 ")";
  /* IE 8 */
  filter: alpha(opacity=100);
  /* IE 5-7 */
  -moz-opacity: 1;
  /* Netscape */
  -khtml-opacity: 1;
  /* Safari 1.x */
  opacity: 1;
  /* Firefox */
  /* WebKit */
  /* Opera */
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  /* Standard */ }
  .Button:hover, .Button:focus, .Button:active {
    background-image: linear-gradient(#00b2b2, #004d4d); }
  .Button--disabled {
    cursor: not-allowed;
    background-image: linear-gradient(#cdcdcd, #676767); }
    .Button--disabled:hover, .Button--disabled:focus, .Button--disabled:active {
      background-image: linear-gradient(#cdcdcd, #676767); }
  .Button--loading {
    background-image: url(/static/media/icon-loading-white.bf6fc4a1.svg), linear-gradient(#00CBCB, #006666);
    background-color: transparent;
    background-size: auto 80%, cover;
    background-position: center right, center center;
    background-repeat: no-repeat, no-repeat;
    cursor: not-allowed; }
    .Button--loading:hover {
      background-image: url(/static/media/icon-loading-white.bf6fc4a1.svg), linear-gradient(#00CBCB, #006666); }
  .Button--selected {
    background-color: #166563; }
    .Button--selected:hover, .Button--selected:focus, .Button--selected:active {
      background-image: linear-gradient(#00b2b2, #004d4d); }
  .Button--hidden {
    visibility: visible;
    cursor: default;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" 0 ")";
    /* IE 8 */
    filter: alpha(opacity=0);
    /* IE 5-7 */
    -moz-opacity: 0;
    /* Netscape */
    -khtml-opacity: 0;
    /* Safari 1.x */
    opacity: 0; }

.Button.Button--wide {
  font-size: 0.01406rem;
  padding: 0.00547rem 0.03906rem; }

.Button.Button--dark {
  font-family: "Fivo Sans";
  font-size: 0.01406rem;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  padding: 0.01016rem 0.01562rem;
  padding-top: 0.01172rem;
  background-image: linear-gradient(#015051, #002F2C);
  border-top: 0.00156rem solid #04FDFD;
  border-bottom: 0.00156rem solid #04FDFD; }

.Button.Button--dark.Button--loading {
  background-color: transparent;
  background-image: url(/static/media/icon-loading-white.bf6fc4a1.svg), linear-gradient(#015051, #002F2C);
  background-size: auto 80%, auto auto;
  background-position: center right, center center;
  background-repeat: no-repeat, no-repeat; }

.Button.Button--dark.Button--selected {
  background-color: transparent; }
  .Button.Button--dark.Button--selected:hover, .Button.Button--dark.Button--selected:focus, .Button.Button--dark.Button--selected:active {
    background-color: transparent; }

.Button.Button--dark.Button--disabled {
  color: white;
  background-image: linear-gradient(#cdcdcd, #676767);
  border-top: 0.00156rem solid #343434;
  border-bottom: 0.00156rem solid #343434; }
  .Button.Button--dark.Button--disabled:hover, .Button.Button--dark.Button--disabled:focus, .Button.Button--dark.Button--disabled:active {
    background-image: linear-gradient(#cdcdcd, #676767); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.Tab {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  border-bottom: 0.00156rem solid #04FDFD; }

.Tab-titleContainer {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 70%;
  height: 0.04063rem;
  background-color: #015051;
  border-right: 0.00313rem solid #04FDFD;
  border-top: 0.00313rem solid #04FDFD;
  border-left: none;
  border-bottom: none;
  padding: 0.00781rem 0;
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  -webkit-transform: skewX(20deg);
  transform: skewX(20deg); }
  .Tab-titleContainer .Tab-title {
    -webkit-transform: skewX(-20deg);
    transform: skewX(-20deg); }
  .Tab-titleContainer::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 100%;
    width: 100%;
    height: 0.00156rem;
    background-color: #04FDFD; }

.Tab-content {
  z-index: 1;
  position: absolute;
  top: 0.03906rem;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(#015051, #002F2C); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.GroupBadge {
  position: relative;
  width: 0.04688rem;
  height: 0.03906rem;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/ }
  .GroupBadge--showName {
    width: 0.05469rem;
    height: 0.05469rem; }
  .GroupBadge--showName.GroupBadge--loading .GroupBadge-name {
    background-image: url(/static/media/icon-loading-white.bf6fc4a1.svg);
    background-size: auto 90%;
    background-position: bottom 0.00098rem right 0rem;
    background-repeat: no-repeat; }
  .GroupBadge--showName.GroupBadge--loading.GroupBadge--arandas .GroupBadge-name, .GroupBadge--showName.GroupBadge--loading.GroupBadge--bacolor .GroupBadge-name, .GroupBadge--showName.GroupBadge--loading.GroupBadge--korolev .GroupBadge-name, .GroupBadge--showName.GroupBadge--loading.GroupBadge--magong .GroupBadge-name, .GroupBadge--showName.GroupBadge--loading.GroupBadge--victoria .GroupBadge-name {
    background-position: bottom 0.00078rem right -0.00391rem; }
  .GroupBadge--showName.GroupBadge--loading.GroupBadge--eberswalde .GroupBadge-name {
    width: 0.07187rem;
    text-align: left; }

.GroupBadge.GroupBadge--groupLogin {
  display: inline-block;
  margin: 0.01562rem 0.01562rem; }
  .GroupBadge.GroupBadge--groupLogin .GroupBadge-name {
    color: red; }
  .GroupBadge.GroupBadge--groupLogin.GroupBadge--isPlaying .GroupBadge-name {
    color: green; }

.GroupBadge.GroupBadge--investigation {
  display: inline-block;
  vertical-align: top;
  width: 0.03125rem;
  height: 0.02734rem;
  background-size: contain;
  background-position: top center; }

.GroupBadge.GroupBadge--result {
  display: inline-block;
  width: 0.02578rem;
  height: 0.02187rem;
  margin-left: 0.00781rem;
  margin-right: 0.00781rem; }
  .GroupBadge.GroupBadge--result img {
    width: 0.02578rem;
    height: 0.02187rem; }

.GroupBadge.GroupBadge--minigame-result {
  display: inline-block;
  width: 0.02891rem;
  height: 0.025rem;
  margin-left: 0.00781rem;
  margin-right: 0.00781rem; }
  .GroupBadge.GroupBadge--minigame-result img {
    width: 0.02891rem;
    height: 0.025rem; }

.GroupBadge-icon {
  width: 100%;
  pointer-events: none;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/ }

.GroupBadge-name {
  text-align: center;
  margin-top: -0.00391rem;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/ }
  .GroupBadge-name span {
    font-size: 0.01094rem; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.CookieConsent {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -0.15625rem;
  color: #21CBCB;
  background-color: rgba(11, 101, 100, 0.85);
  height: 0.14062rem;
  -webkit-animation: slideIn 0.5s linear 1s 1 forwards;
  animation: slideIn 0.5s linear 1s 1 forwards;
  z-index: 100;
  margin-bottom: 3em; }

.CookieConsent-text {
  width: 100%;
  padding-left: 0.24219rem;
  padding-right: 0.23438rem;
  padding-top: 0.01172rem; }
  .CookieConsent-text span {
    font-size: 0.01094rem; }
  .CookieConsent-text a {
    font-size: 0.01094rem;
    color: #21CBCB; }

.CookieConsent-button {
  padding-left: 0.24219rem;
  padding-top: 0.01172rem; }
  .CookieConsent-button .Button {
    padding: 0.00391rem 0.01953rem;
    height: 0.02813rem;
    font-size: 0.0125rem;
    background: linear-gradient(#FEC186, #D36C5C); }
    .CookieConsent-button .Button:hover, .CookieConsent-button .Button:focus, .CookieConsent-button .Button:active {
      background-image: linear-gradient(#feb46d, #ce5a48); }

@-webkit-keyframes slideIn {
  100% {
    bottom: 0; } }

@keyframes slideIn {
  100% {
    bottom: 0; } }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.ImageLoader {
  position: absolute;
  display: none; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.LandingPage {
  padding: 0rem 0.07812rem;
  width: 100%;
  height: 100%;
  background-image: url(/static/media/background.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  display: flex;
  justify-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-flex-align: center;
  align-items: center;
  flex-direction: column; }
  .LandingPage .Logo-textured-glow {
    width: 0.40625rem;
    height: auto;
    margin-top: -0.07812rem;
    -webkit-filter: drop-shadow(0.00391rem 0.00391rem 0.00078rem rgba(0, 0, 0, 0.5)) drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7));
    filter: drop-shadow(0.00391rem 0.00391rem 0.00078rem rgba(0, 0, 0, 0.5)) drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)); }
  .LandingPage .LandingPage-message {
    position: relative;
    margin-bottom: 0.01562rem;
    font-size: 0.01016rem;
    width: 0.625rem;
    padding: 0.01562rem 0.02813rem;
    margin-top: 0.02344rem;
    background-color: rgba(1, 80, 81, 0.75); }
    .LandingPage .LandingPage-message.two-column {
      -webkit-column-count: 2;
              column-count: 2;
      grid-column-gap: 0.02344rem;
      -webkit-column-gap: 0.02344rem;
              column-gap: 0.02344rem; }
    .LandingPage .LandingPage-message div {
      margin-top: -0.00391rem; }
      .LandingPage .LandingPage-message div b {
        color: #04FDFD; }
    .LandingPage .LandingPage-message pre {
      margin: 0; }
    .LandingPage .LandingPage-message code {
      font-family: 'Fivo Sans';
      white-space: pre-line; }
  .LandingPage .Button {
    position: relative;
    display: block;
    width: 0.11719rem;
    margin: 0 auto;
    z-index: 1; }
  .LandingPage .LandingPage-logos {
    position: absolute;
    left: 0.03906rem;
    bottom: 0.00781rem;
    font-size: 0.01094rem;
    width: 100%;
    padding-right: 0.07812rem;
    display: flex;
    justify-content: space-between;
    -webkit-box-pack: space-between;
    -ms-flex-pack: space-between;
    -webkit-flex-align: flex-start;
    align-items: flex-start;
    flex-direction: row; }
    .LandingPage .LandingPage-logos .LandingPage-logoWrapper:first-of-type {
      width: 0.39062rem; }
      .LandingPage .LandingPage-logos .LandingPage-logoWrapper:first-of-type img {
        margin-top: 0.00781rem; }
        .LandingPage .LandingPage-logos .LandingPage-logoWrapper:first-of-type img:first-of-type {
          width: 0.22969rem; }
        .LandingPage .LandingPage-logos .LandingPage-logoWrapper:first-of-type img:last-of-type {
          width: 0.10938rem;
          margin-left: 0.03203rem;
          margin-bottom: 0.01172rem; }
    .LandingPage .LandingPage-logos .LandingPage-logoWrapper:last-of-type {
      padding-top: 0.00781rem;
      width: 0.125rem;
      text-align: center; }
      .LandingPage .LandingPage-logos .LandingPage-logoWrapper:last-of-type img {
        width: 0.05859rem;
        margin-top: 0.01562rem; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.Splash-screen {
  width: 100%;
  height: 100%;
  background-image: url(/static/media/background.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-flex-align: center;
  align-items: center; }
  .Splash-screen .Logo-textured-glow {
    width: 0.78125rem;
    height: auto;
    -webkit-filter: drop-shadow(0.00391rem 0.00391rem 0.00078rem rgba(0, 0, 0, 0.5)) drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7));
    filter: drop-shadow(0.00391rem 0.00391rem 0.00078rem rgba(0, 0, 0, 0.5)) drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)); }

.Login {
  position: relative;
  min-height: 100%;
  padding: 0.08594rem 0.22656rem;
  color: #10A0A0;
  font-size: 0.01016rem;
  font-weight: normal;
  display: flex;
  justify-content: space-between;
  -webkit-box-pack: space-between;
  -ms-flex-pack: space-between;
  -webkit-flex-align: flex-start;
  align-items: flex-start;
  background-image: url(/static/media/background.jpg);
  background-size: cover;
  background-position: top left;
  flex-direction: column; }
  .Login .Login-logo-cgl {
    width: 10em;
    height: 3em;
    background-image: url(/static/media/logo-cgl.df91f7a9.svg);
    background-size: 100% auto;
    background-position: center right;
    background-repeat: no-repeat;
    z-index: 1;
    margin-left: 62.5em; }
  .Login .Login-wrap {
    display: flex;
    justify-content: space-between;
    -webkit-box-pack: space-between;
    -ms-flex-pack: space-between;
    -webkit-flex-align: flex-start;
    align-items: flex-start;
    width: 100%;
    min-height: 100%; }
  .Login::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(1, 27, 28, 0.8); }
  .Login .Tab-titleContainer {
    padding-left: 0.01953rem;
    width: 65%; }
  .Login .Tab-content {
    padding-left: 0.01641rem;
    padding-right: 0.01641rem;
    padding-top: 0.02344rem; }

.TeacherLogin--resetPassword .Tab-titleContainer {
  width: 78%; }

.TeacherLogin--resetPassword .Login-errorMessage {
  height: 0.125rem; }

.TeacherLogin--createUser .Login-errorMessage {
  height: 0.09375rem; }

.TeacherLogin--createUser .Login-form .Button {
  width: 0.13281rem; }

.Login-logo {
  width: 0.18984rem;
  height: 0.05469rem;
  position: absolute;
  top: 0.01172rem;
  left: 0.01172rem;
  background-image: url(/static/media/logo-medium.7ab1d129.svg);
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat; }

.GroupLogin,
.TeacherLogin {
  position: relative;
  width: 0.25rem;
  height: 0.43047rem; }

.Login-title {
  font-size: 0.01562rem;
  font-weight: 600;
  color: white;
  text-transform: uppercase;
  text-align: left; }
  .Login-title span {
    color: #04FDFD; }

.Login-form {
  margin: 0.0125rem 0;
  text-align: left; }
  .Login-form input {
    text-align: left;
    width: 0.20859rem;
    height: 0.03359rem;
    padding: 0.0125rem;
    margin-bottom: 0.01406rem;
    font-size: 0.01172rem;
    color: #04FDFD;
    background-color: #01696a;
    border-width: 0.00234rem;
    border-style: solid;
    border-image: url(/static/media/frame-cyan.6d9c79f0.svg) round;
    border-image-slice: 10 10 10 10;
    border-image-width: 0.00781rem 0.00781rem;
    /* Chrome/Opera/Safari */
    /* Firefox 18- */
    /* Firefox 19+ */
    /* IE 10+ */ }
    .Login-form input::-webkit-input-placeholder {
      color: #04FDFD !important; }
    .Login-form input:-moz-placeholder {
      color: #04FDFD !important; }
    .Login-form input::-moz-placeholder {
      color: #04FDFD !important; }
    .Login-form input:-ms-input-placeholder {
      color: #04FDFD !important; }
    .Login-form input:-webkit-autofill {
      color: #04FDFD !important;
      background-color: #01696a !important; }
    .Login-form input.invalid {
      border-width: 0.00234rem;
      border-style: solid;
      border-image: url(/static/media/frame-red.e5036a3a.svg) round;
      border-image-slice: 10 10 10 10;
      border-image-width: 0.00781rem 0.00781rem; }
  .Login-form .Button {
    margin-top: 0.00781rem;
    width: 0.11016rem;
    height: 0.03281rem;
    padding: 0.00781rem 0; }

.Login-errorMessage {
  height: 0.11719rem;
  margin-top: 0.0125rem;
  color: #10A0A0;
  font-size: 0.01016rem;
  font-weight: normal; }

.Button--loginToggle {
  text-align: left;
  font-weight: normal;
  display: block;
  margin-bottom: 0.00781rem;
  padding: 0;
  background: none;
  text-transform: none;
  color: #10A0A0;
  font-size: 0.01016rem;
  font-weight: normal; }
  .Button--loginToggle:hover {
    background: none;
    color: white; }

.GroupLogin .GroupBadge {
  color: white;
  display: inline-block;
  width: 0.05781rem;
  margin-right: 0.01953rem;
  margin-bottom: 0.03125rem; }
  .GroupLogin .GroupBadge .GroupBadge-icon {
    padding-bottom: 0.00547rem; }
  .GroupLogin .GroupBadge:hover {
    cursor: pointer;
    -webkit-filter: drop-shadow(0 0 0.00547rem rgba(4, 253, 253, 0.63));
    filter: drop-shadow(0 0 0.00547rem rgba(4, 253, 253, 0.63)); }
  .GroupLogin .GroupBadge:nth-of-type(1), .GroupLogin .GroupBadge:nth-of-type(4), .GroupLogin .GroupBadge:nth-of-type(7), .GroupLogin .GroupBadge:nth-of-type(10) {
    margin-left: 0.01641rem; }
  .GroupLogin .GroupBadge:nth-of-type(1) {
    margin-top: 0.02578rem; }

.GroupLogin .Login-form {
  padding-left: 0.01641rem;
  padding-top: 0.02578rem; }

.GroupLogin .Tab-content {
  padding-left: 0rem;
  padding-right: 0rem;
  padding-top: 0rem; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.ConnectionStatus-overlay {
  display: none;
  position: absolute;
  z-index: 1700;
  background-color: rgba(0, 0, 0, 0.9);
  width: 100%;
  height: 100%;
  min-height: 0.5625rem;
  left: 0;
  top: 0; }
  .ConnectionStatus-overlay.offline {
    display: flex;
    display: flex;
    justify-content: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-flex-align: flex-start;
    align-items: flex-start;
    background-color: rgba(0, 0, 0, 0.7); }

.ConnectionStatus-body {
  border-radius: 0.39062rem;
  text-align: center;
  padding-top: 0.15625rem;
  font-weight: 700; }
  .ConnectionStatus-body .ConnectionStatus-title {
    font-size: 0.03516rem;
    color: #04FDFD;
    text-transform: uppercase;
    margin-bottom: 0.02344rem; }
  .ConnectionStatus-body p {
    font-size: 0.02734rem;
    color: white;
    margin: 0rem 0; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.Popup-overlay {
  height: 100%;
  position: absolute;
  z-index: 1500;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-flex-align: center;
  align-items: center;
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" 0 ")";
  /* IE 8 */
  filter: alpha(opacity=0);
  /* IE 5-7 */
  -moz-opacity: 0;
  /* Netscape */
  -khtml-opacity: 0;
  /* Safari 1.x */
  opacity: 0;
  /* Firefox */
  /* WebKit */
  /* Opera */
  transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
  /* Standard */ }
  .Popup-overlay--show {
    visibility: visible;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" 100 ")";
    /* IE 8 */
    filter: alpha(opacity=100);
    /* IE 5-7 */
    -moz-opacity: 1;
    /* Netscape */
    -khtml-opacity: 1;
    /* Safari 1.x */
    opacity: 1; }
  .Popup-overlay--intro {
    background-color: rgba(255, 255, 255, 0.3); }
    .Popup-overlay--intro.Popup-overlay--hide .Popup--intro {
      -webkit-transform-origin: -0.1875rem -0.03125rem;
              transform-origin: -0.1875rem -0.03125rem;
      /* Fx 5+ */
      /* Opera 12+ */
      -webkit-animation: baseZoomOut 0.5s linear 0s 1 forwards;
      animation: baseZoomOut 0.5s linear 0s 1 forwards; }

.Popup {
  position: relative;
  width: 0.39062rem;
  text-align: left;
  background-image: linear-gradient(#015051, #002F2C);
  color: white;
  padding: 0.05078rem;
  overflow-y: auto;
  border-width: 0.00234rem;
  border-style: solid;
  border-image: url(/static/media/frame-big-cyan.2452e273.svg) round;
  border-image-slice: 20 20 20 20;
  border-image-width: 0.01562rem 0.01562rem; }
  .Popup--intro {
    width: 0.53125rem;
    padding: 0.03516rem 0.05078rem 0.11719rem 0.05078rem;
    margin-top: 0.03125rem;
    border: none;
    border-top: 0.00156rem solid #04FDFD;
    background-image: linear-gradient(#004F50 72%, rgba(0, 47, 44, 0) 100%); }
    .Popup--intro .Popup-body {
      font-size: 0.01406rem;
      line-height: 1.3; }
    .Popup--intro .Button {
      display: none; }
  .Popup--cyoa {
    width: 0.53125rem;
    padding: 0.03125rem 0.05078rem; }
    .Popup--cyoa p {
      margin-top: 0;
      margin-bottom: 0.00156rem; }
    .Popup--cyoa li:nth-of-type(1) p,
    .Popup--cyoa li:nth-of-type(3) p,
    .Popup--cyoa li:nth-of-type(5) p,
    .Popup--cyoa li:nth-of-type(7) p,
    .Popup--cyoa li:nth-of-type(9) p {
      margin-bottom: 0.01172rem; }
    .Popup--cyoa ul {
      margin-bottom: 0.01172rem; }

.Popup-title {
  font-family: 'Fivo Sans';
  font-size: 0.01562rem;
  text-transform: uppercase;
  color: #04FDFD;
  line-height: 1;
  font-weight: 600; }

.Popup-close {
  position: absolute;
  top: 0.01562rem;
  right: 0.01562rem;
  width: 0.02344rem;
  height: 0.02344rem;
  background-image: url(/static/media/icon-x.be145b6d.svg);
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer; }
  .Popup-close:hover {
    /* IE 9 */
    -webkit-transform: scale(1.1);
    /* Safari */
    transform: scale(1.1); }

.Popup-body {
  font-size: 0.0125rem;
  line-height: 1.375; }

.Popup-form .PopupForm-text input {
  width: 100%;
  font-size: 0.0125rem;
  line-height: 1;
  padding: 0.00937rem;
  margin-bottom: 0.02344rem;
  background-color: rgba(4, 253, 253, 0.24);
  color: white;
  border: none;
  border-radius: 0.25em;
  display: block;
  border-width: 0.00234rem;
  border-style: solid;
  border-image: url(/static/media/frame-cyan.6d9c79f0.svg) round;
  border-image-slice: 10 10 10 10;
  border-image-width: 0.00781rem 0.00781rem;
  /* Chrome/Opera/Safari */
  /* Firefox 18- */
  /* Firefox 19+ */
  /* IE 10+ */ }
  .Popup-form .PopupForm-text input::-webkit-input-placeholder {
    color: #04FDFD !important; }
  .Popup-form .PopupForm-text input:-moz-placeholder {
    color: #04FDFD !important; }
  .Popup-form .PopupForm-text input::-moz-placeholder {
    color: #04FDFD !important; }
  .Popup-form .PopupForm-text input:-ms-input-placeholder {
    color: #04FDFD !important; }
  .Popup-form .PopupForm-text input:focus {
    outline: none; }

.Popup-form .Button {
  width: 100%;
  height: 0.03906rem; }

.Popup-form .PopupForm-button {
  display: inline-block; }

.Popup-buttons .Button {
  padding-left: 0.01953rem;
  padding-right: 0.01953rem; }
  .Popup-buttons .Button--loading.Button--dark {
    background-image: url(/static/media/icon-loading-white.bf6fc4a1.svg);
    background-position-x: right; }

.Popup-buttons--2 .Button--popup {
  display: inline-block; }
  .Popup-buttons--2 .Button--popup:nth-child(1) {
    margin-right: 0.01562rem; }

.Popup--createGame .Popup-close {
  width: 0.025rem;
  height: 0.025rem; }

.Popup--newSession .Popup-text span {
  color: #04FDFD;
  font-weight: 600; }

.Popup-createGame,
.Popup--tagBase {
  width: 0.625rem; }
  .Popup-createGame .Popup-body .Popup-form .PopupForm-text,
  .Popup--tagBase .Popup-body .Popup-form .PopupForm-text {
    display: inline-block;
    vertical-align: middle; }
    .Popup-createGame .Popup-body .Popup-form .PopupForm-text input,
    .Popup--tagBase .Popup-body .Popup-form .PopupForm-text input {
      width: 0.12109rem;
      margin: 0; }
  .Popup-createGame .Popup-body .Popup-form .Button,
  .Popup--tagBase .Popup-body .Popup-form .Button {
    float: right;
    vertical-align: middle;
    width: 0.125rem;
    padding: 0.01094rem 0;
    height: auto; }

.Popup--tagBase .Popup-form {
  margin-top: 0.0625rem; }

.Popup--resources,
.Popup--videos {
  padding-right: 0.01562rem; }
  .Popup--resources .Popup-title,
  .Popup--videos .Popup-title {
    margin-bottom: 0.01172rem; }
  .Popup--resources a,
  .Popup--videos a {
    color: white; }
  .Popup--resources p,
  .Popup--videos p {
    margin: 0; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.Logo {
  position: absolute;
  width: 0.19141rem;
  height: 0.05469rem;
  top: 0.01562rem;
  left: 0.01172rem;
  background-image: url(/static/media/logo-small.35b0a031.svg);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat; }
  .Logo--link {
    cursor: pointer; }

.Logo--settings {
  top: 0.01562rem;
  left: 0.08594rem; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.ControlPanel {
  position: absolute;
  background-image: linear-gradient(#015051, #002F2C);
  border-bottom: 0.00156rem solid #04FDFD; }
  .ControlPanel--page, .ControlPanel--test {
    left: 0.025rem;
    bottom: 0.01875rem;
    width: 0.17578rem;
    padding: 0.00781rem 0;
    padding-top: 0.05469rem;
    text-align: left; }
    .ControlPanel--page::before, .ControlPanel--test::before {
      content: "";
      position: absolute;
      width: 0.17578rem;
      height: 0.00781rem;
      top: -0.00078rem;
      left: 0;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAfQAAAAJCAYAAADErd75AAAACXBIWXMAABjrAAAY6wFtaPejAAAAZElEQVR4nO3b0QmAMAwFwFg6uG7iaHUOKTpEC6HlboTk45GEHLX3LwDIcr2lnKrPqKKCALA+EzpAricimh4wSqADwAas3AFgAwIdADZQNREglRs6Uwh0gFy3tzVmsHIHgNVFxA9MBxCsHe0zmgAAAABJRU5ErkJggg==);
      background-size: 0.17578rem auto;
      background-position: left top;
      background-repeat: no-repeat; }
    .ControlPanel--page::after, .ControlPanel--test::after {
      content: "Kontrolpanel";
      position: absolute;
      top: -0.02734rem;
      left: 0;
      font-family: "Fivo Sans";
      text-transform: uppercase;
      font-size: 0.01562rem;
      font-weight: 600;
      line-height: 0.01875rem;
      margin-bottom: 0.00937rem;
      -webkit-touch-callout: none;
      /* iOS Safari */
      -webkit-user-select: none;
      /* Safari */
      /* Konqueror HTML */
      /* Firefox */
      -ms-user-select: none;
      /* Internet Explorer/Edge */
      user-select: none;
      /* Non-prefixed version, currently*/ }
  .ControlPanel--settings, .ControlPanel--overview {
    top: 0.01562rem;
    right: 0.01562rem;
    width: 0.23438rem;
    height: 0.05469rem;
    text-align: right;
    border-top: 0.00156rem solid #04FDFD; }
    .ControlPanel--settings .ControlPanel-settingsBtn,
    .ControlPanel--settings .ControlPanel-logoutBtn, .ControlPanel--overview .ControlPanel-settingsBtn,
    .ControlPanel--overview .ControlPanel-logoutBtn {
      margin: 0.00547rem 0.00781rem 0 0;
      width: 0.01953rem;
      height: 0.03906rem;
      background-size: auto 0.01953rem; }
  .ControlPanel--group {
    padding: 0.01953rem 0 0 0.0875rem;
    height: 0.09375rem;
    background-image: linear-gradient(#015051, #002F2C); }
    .ControlPanel--group .ControlPanel-buttons {
      text-align: left; }
    .ControlPanel--group .ControlPanel-fullscreenBtn,
    .ControlPanel--group .ControlPanel-logoutBtn {
      margin: 0 0.01562rem 0 0;
      width: 0.01953rem;
      height: 0.01953rem;
      background-size: auto 0.01797rem; }

.ControlPanel--group.ControlPanel--overview {
  width: 0.19531rem;
  height: 0.05469rem;
  padding: 0.01953rem; }
  .ControlPanel--group.ControlPanel--overview::after {
    display: none; }
  .ControlPanel--group.ControlPanel--overview .ControlPanel-groupImg {
    left: auto;
    right: 0.00781rem;
    top: 0.00781rem; }
    .ControlPanel--group.ControlPanel--overview .ControlPanel-groupImg img {
      width: 0.03906rem; }
  .ControlPanel--group.ControlPanel--overview .ControlPanel-groupName {
    position: absolute;
    right: 0.05469rem;
    top: 0.01172rem;
    font-size: 0.01172rem; }
  .ControlPanel--group.ControlPanel--overview .ControlPanel-buttons {
    position: absolute;
    left: 0.00781rem;
    top: 0.01562rem; }
    .ControlPanel--group.ControlPanel--overview .ControlPanel-buttons .ControlPanel-fullscreenBtn {
      margin: 0 0.00781rem 0 0; }

.ControlPanel-buttons {
  position: relative;
  text-align: center; }

.ControlPanel-email {
  position: absolute;
  left: 0;
  top: 0;
  height: 0.05469rem;
  padding: 0.00781rem;
  color: white;
  font-size: 0.01094rem;
  text-align: left;
  pointer-events: none; }
  .ControlPanel-email span {
    display: block;
    color: #04FDFD;
    font-size: 0.01094rem;
    margin-top: 0.00391rem; }

.ControlPanel-gameCode {
  text-align: left;
  padding-left: 0.02344rem;
  margin-bottom: 0.00781rem;
  font-family: "Fivo Sans"; }
  .ControlPanel-gameCode span {
    display: inline-block;
    color: #04FDFD;
    text-transform: uppercase; }

.ControlPanel-settingsBtn,
.ControlPanel-fullscreenBtn,
.ControlPanel-logoutBtn {
  display: inline-block;
  width: 0.025rem;
  height: 0.025rem;
  background-size: auto 0.02187rem;
  background-position: center center;
  background-repeat: no-repeat;
  margin: 0 0.0125rem;
  cursor: pointer; }
  .ControlPanel-settingsBtn:hover,
  .ControlPanel-fullscreenBtn:hover,
  .ControlPanel-logoutBtn:hover {
    /* IE 9 */
    -webkit-transform: scale(1.1);
    /* Safari */
    transform: scale(1.1); }

.ControlPanel-settingsBtn {
  background-image: url(/static/media/icon-settings.892d8f58.svg); }

.ControlPanel-logoutBtn {
  background-image: url(/static/media/icon-logout.8d9972b8.svg); }

.ControlPanel-fullscreenBtn {
  background-image: url(/static/media/icon-fullscreen-expand.76517758.svg); }
  .ControlPanel-fullscreenBtn.fullscreen {
    background-image: url(/static/media/icon-fullscreen-contract.b5e6547e.svg); }

.ControlPanel-groupImg {
  position: absolute;
  left: 0.00781rem;
  top: 0.01953rem; }
  .ControlPanel-groupImg img {
    width: 0.0625rem; }

.ControlPanel-groupName {
  font-family: "Fivo Sans";
  text-transform: uppercase;
  font-size: 0.01172rem;
  margin-bottom: 0.00391rem; }
  .ControlPanel-groupName span {
    color: #10A0A0;
    font-size: 0.01016rem;
    display: block; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.Game {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #011B1C;
  color: white; }
  .Game::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(1, 27, 28, 0.8);
    z-index: 4;
    visibility: hidden;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" 0 ")";
    /* IE 8 */
    filter: alpha(opacity=0);
    /* IE 5-7 */
    -moz-opacity: 0;
    /* Netscape */
    -khtml-opacity: 0;
    /* Safari 1.x */
    opacity: 0;
    /* Firefox */
    /* WebKit */
    /* Opera */
    transition: opacity 0.6s ease-in-out, visibility 0.6s ease-in-out;
    /* Standard */ }
  .Game--overview {
    background-image: url(/static/media/background-overview.jpg);
    background-size: cover;
    background-position: top left; }
    .Game--overview::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(160, 57, 11, 0.23); }
    .Game--overview.Game--group .Camera {
      right: 0.17969rem; }
  .Game--settings, .Game--page {
    background-image: url(/static/media/background.jpg);
    background-size: cover;
    background-position: top left; }
    .Game--settings::before, .Game--page::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(1, 27, 28, 0.8); }
  .Game--showBase::after {
    background-color: rgba(1, 27, 28, 0.98);
    visibility: visible;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" 100 ")";
    /* IE 8 */
    filter: alpha(opacity=100);
    /* IE 5-7 */
    -moz-opacity: 1;
    /* Netscape */
    -khtml-opacity: 1;
    /* Safari 1.x */
    opacity: 1;
    /* Firefox */
    /* WebKit */
    /* Opera */
    transition: opacity 0s ease-in-out, visibility 0s ease-in-out;
    /* Standard */ }

@media print {
  .Game {
    color: black;
    background: white; } }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.GameLoading {
  width: 100%;
  height: 100%;
  background-image: url(/static/media/background.jpg);
  background-size: cover;
  background-position: top left; }
  .GameLoading::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(1, 27, 28, 0.8); }

.GameLoading-message {
  position: absolute;
  left: 0.40391rem;
  bottom: 0.09375rem;
  font-size: 0.03516rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #04FDFD; }
  .GameLoading-message span {
    -webkit-animation-name: loading-dots;
            animation-name: loading-dots;
    -webkit-animation-duration: 1.4s;
            animation-duration: 1.4s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both; }
  .GameLoading-message span:nth-child(2) {
    -webkit-animation-delay: .2s;
            animation-delay: .2s; }
  .GameLoading-message span:nth-child(3) {
    -webkit-animation-delay: .4s;
            animation-delay: .4s; }

.GameLoading-rocket-wrapper {
  position: relative;
  top: 0.14062rem;
  left: 0.39453rem; }

.GameLoading-rocket {
  position: absolute;
  width: 0.22656rem; }

.GameLoading-rocket-tail {
  position: absolute;
  width: 0.22656rem;
  top: 0.05469rem;
  left: 0.00156rem;
  -webkit-animation: shake 0.2s;
          animation: shake 0.2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

@-webkit-keyframes shake {
  0% {
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px); }
  50% {
    -webkit-transform: translate(-1px, -2px);
            transform: translate(-1px, -2px); }
  80% {
    -webkit-transform: translate(1px, 2px);
            transform: translate(1px, 2px); }
  100% {
    -webkit-transform: translate(1px, -1px);
            transform: translate(1px, -1px); } }

@keyframes shake {
  0% {
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px); }
  50% {
    -webkit-transform: translate(-1px, -2px);
            transform: translate(-1px, -2px); }
  80% {
    -webkit-transform: translate(1px, 2px);
            transform: translate(1px, 2px); }
  100% {
    -webkit-transform: translate(1px, -1px);
            transform: translate(1px, -1px); } }

@-webkit-keyframes loading-dots {
  0% {
    opacity: .2; }
  20% {
    opacity: 1; }
  100% {
    opacity: .2; } }

@keyframes loading-dots {
  0% {
    opacity: .2; }
  20% {
    opacity: 1; }
  100% {
    opacity: .2; } }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.Settings {
  width: 100%;
  height: 100%; }

.Settings-title {
  text-transform: uppercase;
  font-size: 0.01562rem;
  font-weight: 600;
  color: white;
  margin-bottom: 0.00781rem; }

.Settings-actions {
  position: absolute;
  top: 0.20156rem;
  left: 0.08359rem; }
  .Settings-actions .Button {
    color: #04FDFD;
    margin: 0 0.7em;
    width: 0.15937rem;
    text-align: left;
    padding-left: 0.01016rem; }
    .Settings-actions .Button.wide {
      width: 0.19531rem; }

.Settings-adminBtn {
  position: absolute;
  top: 0.07812rem;
  right: 0.01562rem; }
  .Settings-adminBtn .Button {
    color: #04FDFD;
    font-size: 0.01172rem; }

.Settings-games {
  display: inline-block;
  position: absolute;
  left: 0.09375rem;
  top: 0.30312rem;
  width: 0.85938rem;
  overflow: auto;
  white-space: nowrap;
  padding-bottom: 0.01172rem;
  /*Chrome, Safari and Edge*/
  /*Firefox*/
  scrollbar-width: thin;
  scrollbar-color: #04FDFD rgba(4, 253, 253, 0.2); }
  .Settings-games::-webkit-scrollbar {
    width: 0.00781rem;
    /* width of the veritcal scrollbar */
    height: 0.00781rem;
    /* width of the horizontal scrollbar */ }
  .Settings-games::-webkit-scrollbar-track {
    background-color: rgba(4, 253, 253, 0.2); }
  .Settings-games::-webkit-scrollbar-thumb {
    background-color: #04FDFD;
    border-radius: 0.00781rem;
    border: 1px solid rgba(4, 253, 253, 0.2); }
  .Settings-games::-webkit-scrollbar-corner {
    background-color: rgba(0, 0, 0, 0); }

.Settings-game {
  position: relative;
  width: 0.25rem;
  height: 0.17188rem;
  vertical-align: top;
  display: inline-block;
  margin-right: 0.02344rem;
  background-image: linear-gradient(#015051, #002F2C);
  padding: 0.03125rem 0 0rem 0.01562rem;
  border-top: 0.00156rem solid #04FDFD;
  border-bottom: 0.00156rem solid #04FDFD;
  font-size: 0.01406rem;
  color: #04FDFD; }
  .Settings-game div {
    overflow: hidden;
    white-space: nowrap; }
  .Settings-game span {
    color: white;
    font-weight: 600;
    min-width: 0.08203rem;
    float: left; }
  .Settings-game .Settings-gameButtons {
    margin-top: 0.01953rem; }
    .Settings-game .Settings-gameButtons .Button {
      width: 0.15937rem;
      height: 0.03281rem;
      padding-top: 0.00781rem; }

.Settings-manageTeachersBtn {
  position: absolute;
  top: 0.00937rem;
  left: 0.00937rem;
  width: 0.01953rem;
  height: 0.01562rem;
  background-size: 100% auto;
  background-repeat: no-repeat;
  cursor: pointer;
  cursor: pointer; }
  .Settings-manageTeachersBtn:hover {
    /* IE 9 */
    -webkit-transform: scale(1.1);
    /* Safari */
    transform: scale(1.1); }

.Settings-gameDeleteBtn {
  position: absolute;
  top: 0.01094rem;
  right: 0.01016rem;
  width: 0.02344rem;
  height: 0.02344rem;
  background-image: url(/static/media/icon-x.be145b6d.svg);
  background-size: 100% auto;
  background-repeat: no-repeat;
  cursor: pointer;
  cursor: pointer; }
  .Settings-gameDeleteBtn:hover {
    /* IE 9 */
    -webkit-transform: scale(1.1);
    /* Safari */
    transform: scale(1.1); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.OverviewStories {
  position: relative; }

.OverviewStories-tier {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 0.24922rem;
  text-align: center; }
  .OverviewStories-tier::before {
    content: "";
    position: absolute;
    top: 0;
    left: -0.00469rem;
    height: 0.0875rem;
    width: 0.00937rem;
    background-image: url(/static/media/icon-next.a7b9e9c3.svg), repeating-linear-gradient(#fff 0%, #fff 0.02344rem, transparent 0.02344rem, transparent 0.06406rem, #fff 0.06406rem, #fff 100%);
    background-position: center center, top center;
    background-repeat: no-repeat, repeat-y;
    background-size: 80% auto, 0.00156rem 100%; }
  .OverviewStories-tier--1::before {
    display: none; }

.OverviewStories-story {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 0.20312rem;
  height: 0.03906rem;
  margin-bottom: 0.00937rem;
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-size: 0.01562rem;
  line-height: 1;
  padding-top: 0.01172rem;
  padding-left: 0.03125rem;
  color: #04FDFD;
  background-image: linear-gradient(#015051, #063B3B);
  text-align: left;
  cursor: pointer;
  z-index: 5; }
  .OverviewStories-story:hover {
    color: white; }
  .OverviewStories-story--selected {
    color: white;
    border-top: 0.00156rem solid #04FDFD;
    border-bottom: 0.00156rem solid #04FDFD;
    border-left: 0.00313rem solid #04FDFD; }
  .OverviewStories-story::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0.0125rem;
    width: 0.00937rem;
    height: 100%;
    background-image: url(/static/media/icon-circle-dark.4e4dfb96.svg);
    background-size: 0.00859rem 0.00859rem;
    background-position: center center;
    background-repeat: no-repeat; }
  .OverviewStories-story--active::before {
    background-image: url(/static/media/icon-circle-light.f26a876a.svg); }
  .OverviewStories-story--closed::before {
    background-image: url(/static/media/icon-circle-light-full.b6526b3b.svg); }

.OverviewStories-story--group.OverviewStories-story--ready {
  opacity: 0.5;
  cursor: not-allowed; }
  .OverviewStories-story--group.OverviewStories-story--ready:hover {
    color: #04FDFD; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.OverviewStory {
  position: relative;
  width: 0.95312rem;
  height: 0.27344rem;
  margin: 0.01172rem 0.02344rem 0 0.02344rem; }
  .OverviewStory--group .OverviewMission.OverviewMission--ready .OverviewMission-info {
    background: linear-gradient(#009D9D, #015555); }
  .OverviewStory--group .OverviewSession.OverviewSession--ready {
    opacity: 0.5;
    cursor: not-allowed; }
    .OverviewStory--group .OverviewSession.OverviewSession--ready .OverviewSession-playBtn {
      opacity: 0.25;
      cursor: not-allowed; }

.OverviewStory-info {
  position: absolute;
  width: 0.23438rem; }

.OverviewStory-title {
  width: 100%;
  height: 0.03906rem;
  position: relative;
  z-index: 1;
  overflow: hidden; }

.OverviewStory-titleBackground {
  background-color: #015051;
  height: 100%;
  width: 100%;
  border-top: 0.00313rem solid #04FDFD;
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  -webkit-transform: skewX(20deg);
  transform: skewX(20deg);
  z-index: 2; }

.OverviewStory-titleBorder {
  position: absolute;
  width: 100%;
  height: 100%;
  border-right: 0.00313rem solid #04FDFD;
  z-index: 2; }

.OverviewStory-titleText {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-size: 0.01406rem;
  line-height: 1;
  padding-left: 0.01172rem;
  padding-top: 0.01562rem;
  text-transform: uppercase;
  z-index: 3; }

.OverviewStory-text {
  position: absolute;
  top: 0.03516rem;
  left: 0;
  height: 0.24844rem;
  width: 0.23438rem;
  padding: 0.01953rem;
  font-size: 0.01094rem;
  background-image: linear-gradient(#015051, #002F2C);
  border-bottom: 0.00156rem solid #04FDFD;
  z-index: 2; }

.OverviewStory-introText {
  margin-bottom: 0.0125rem; }

.OverviewStory-objectivesText p {
  margin: 0; }
  .OverviewStory-objectivesText p span {
    color: #04FDFD; }

.OverviewMissions {
  position: absolute;
  top: 0.03516rem;
  left: 0.22969rem;
  right: 0;
  border-top: 0.00156rem solid #04FDFD;
  border-left: 0.00313rem solid #04FDFD;
  border-bottom: 0.00156rem solid #04FDFD;
  background-image: linear-gradient(#015051, #002F2C);
  height: 0.24844rem;
  z-index: 3; }
  .OverviewMissions--3 .OverviewMission {
    width: 33.3333%; }
  .OverviewMissions--3 .OverviewSession {
    width: 100%; }
  .OverviewMissions--4 .OverviewMission {
    width: 25%; }
  .OverviewMissions--4 .OverviewSession {
    width: 100%; }
  .OverviewMissions--2 .OverviewMission:nth-last-child(1) .OverviewMission-info, .OverviewMissions--3 .OverviewMission:nth-last-child(1) .OverviewMission-info, .OverviewMissions--4 .OverviewMission:nth-last-child(1) .OverviewMission-info {
    border-right: none; }
  .OverviewMissions--2 .OverviewMission:nth-last-child(1) .OverviewSessions, .OverviewMissions--3 .OverviewMission:nth-last-child(1) .OverviewSessions, .OverviewMissions--4 .OverviewMission:nth-last-child(1) .OverviewSessions {
    border-right: none; }

.OverviewMission {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  height: 100%; }

.OverviewMission-info {
  height: 0.06641rem;
  padding: 0.00781rem 0.01562rem;
  background: linear-gradient(#00CBCB, #006666);
  border-right: 0.00156rem solid #015051; }

.OverviewMission-title {
  font-family: 'Fivo Sans';
  font-size: 0.0125rem;
  font-weight: 600;
  text-transform: uppercase;
  color: white;
  margin-bottom: 0.00391rem; }

.OverviewMission-text {
  font-size: 0.00937rem; }

.OverviewSessions {
  border-right: 0.00156rem solid #04FDFD;
  height: 0.17969rem; }
  .OverviewSessions--3 {
    display: flex;
    flex-wrap: wrap; }
    .OverviewSessions--3 .OverviewSession {
      width: 50%; }
      .OverviewSessions--3 .OverviewSession:nth-child(3) {
        position: absolute;
        bottom: 0;
        left: 25%;
        width: 25%; }
        .OverviewSessions--3 .OverviewSession:nth-child(3):before {
          content: "";
          position: absolute;
          top: -98.7%;
          left: 0;
          height: 199%;
          background-color: #04FDFD;
          width: 1px; }
        .OverviewSessions--3 .OverviewSession:nth-child(3):after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          height: 1px;
          width: 100%;
          background-image: linear-gradient(to right, #04FDFD 0%, #04FDFD 25%, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 75%, #04FDFD 75%, #04FDFD 100%);
          background-position: center left;
          background-size: 100% 100%;
          background-repeat: repeat-x; }
  .OverviewSessions--story-7, .OverviewSessions--story-8 {
    display: block; }
    .OverviewSessions--story-7 .OverviewSession:nth-child(3), .OverviewSessions--story-8 .OverviewSession:nth-child(3) {
      position: relative;
      left: 50%;
      bottom: 100%;
      width: 50%; }
      .OverviewSessions--story-7 .OverviewSession:nth-child(3):before, .OverviewSessions--story-8 .OverviewSession:nth-child(3):before {
        top: 0; }
      .OverviewSessions--story-7 .OverviewSession:nth-child(3):after, .OverviewSessions--story-8 .OverviewSession:nth-child(3):after {
        top: unset;
        bottom: 0; }

.OverviewSession {
  position: relative;
  height: 0.08984rem;
  padding: 0.00781rem;
  z-index: 1;
  cursor: pointer; }
  .OverviewSession:nth-child(1)::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background-image: linear-gradient(to right, #04FDFD 0%, #04FDFD 25%, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 75%, #04FDFD 75%, #04FDFD 100%);
    background-position: center left;
    background-size: 100% 100%;
    background-repeat: repeat-x; }
  .OverviewSession--experiment {
    background-image: url(/static/media/icon-experiment.6f8093c7.svg);
    background-size: auto 0.01562rem;
    background-position: top 0.01016rem right 0.01172rem;
    background-repeat: no-repeat; }
  .OverviewSession--active .OverviewSession-title::before {
    background-image: url(/static/media/icon-circle-light.f26a876a.svg); }
  .OverviewSession--closed .OverviewSession-title::before {
    background-image: url(/static/media/icon-circle-light-full.b6526b3b.svg); }

.OverviewSession-title {
  position: relative;
  font-size: 0.01016rem;
  padding-left: 0.00937rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0.00391rem; }
  .OverviewSession-title::before {
    content: "";
    position: absolute;
    top: 0;
    left: -0.00156rem;
    width: 0.00781rem;
    height: 100%;
    background-image: url(/static/media/icon-circle-dark.4e4dfb96.svg);
    background-size: 0.00469rem 0.00469rem;
    background-position: center center;
    background-repeat: no-repeat; }

.OverviewSession-text {
  font-size: 0.00937rem;
  padding-left: 0.00937rem; }

.OverviewSession-subjectsText {
  color: #04FDFD;
  margin-top: -0.00391rem;
  margin-bottom: 0.00781rem; }
  .OverviewSession-subjectsText p {
    color: white;
    margin-bottom: -0.00391rem;
    font-weight: 600; }

.OverviewSession-durationText {
  background-image: url(/static/media/icon-clock.10d70dac.svg);
  background-size: auto 90%;
  background-position: left center;
  background-repeat: no-repeat;
  padding-left: 0.01562rem; }

.OverviewSession-playBtn {
  position: absolute;
  width: 0.04688rem;
  height: 50%;
  right: 0;
  bottom: 0;
  background-image: url(/static/media/icon-play.f7bcca49.svg);
  background-size: 0.01953rem auto;
  background-position: right 0.01094rem bottom 0.01562rem;
  background-repeat: no-repeat;
  cursor: pointer; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.Overview {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0.07812rem 0; }

.Overview-text {
  width: -webkit-fit-content;
  width: fit-content;
  text-transform: uppercase;
  font-size: 0.01406rem;
  font-weight: 600;
  padding: 0 0.01172rem 0 0.02344rem;
  margin-bottom: 1em;
  background-image: url(/static/media/icon-next.a7b9e9c3.svg);
  background-size: 0.00703rem;
  background-position: right 0.00547rem;
  background-repeat: no-repeat;
  cursor: pointer; }
  .Overview-text:hover {
    letter-spacing: 0.005em; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.Menu {
  position: absolute;
  top: 0.09375rem;
  left: 0.025rem;
  cursor: pointer; }

.Menu-title {
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  font-size: 0.01562rem;
  line-height: 0.01875rem;
  margin-bottom: 0.00937rem;
  cursor: pointer;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/ }

.Menu-items {
  position: relative;
  width: 0.17578rem; }
  .Menu-items::before {
    content: "";
    position: absolute;
    width: 0.17578rem;
    height: 0.00781rem;
    top: -0.00078rem;
    left: 0;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAfQAAAAJCAYAAADErd75AAAACXBIWXMAABjrAAAY6wFtaPejAAAAZElEQVR4nO3b0QmAMAwFwFg6uG7iaHUOKTpEC6HlboTk45GEHLX3LwDIcr2lnKrPqKKCALA+EzpAricimh4wSqADwAas3AFgAwIdADZQNREglRs6Uwh0gFy3tzVmsHIHgNVFxA9MBxCsHe0zmgAAAABJRU5ErkJggg==);
    background-size: 0.17578rem auto;
    background-position: left top;
    background-repeat: no-repeat; }

.Menu-item {
  height: 0.03125rem;
  font-size: 0.01406rem;
  line-height: 0.03125rem;
  color: #04FDFD;
  padding: 0 0.00781rem;
  border-top: 0.00156rem solid #011B1C;
  border-left: 0.00313rem solid transparent;
  cursor: pointer;
  background-image: url(/static/media/icon-circle-cyan.3b1fbe27.svg), linear-gradient(#015051, #063B3B);
  background-size: 0.00859rem 0.00859rem, 100%;
  background-position: right 0.01094rem center, center;
  background-repeat: no-repeat, no-repeat; }
  .Menu-item:last-child {
    border-bottom: 0.00156rem solid transparent; }
  .Menu-item--selected {
    color: white;
    border-top: 0.00156rem solid #04FDFD;
    border-left: 0.00313rem solid #04FDFD;
    background-image: url(/static/media/icon-circle-light.f26a876a.svg), linear-gradient(#015051, #063B3B); }
    .Menu-item--selected + .Menu-item {
      border-top: 0.00156rem solid #04FDFD; }
    .Menu-item--selected:nth-child(1) {
      border-top: 0.00156rem solid #011B1C; }
    .Menu-item--selected:last-child {
      border-bottom: 0.00156rem solid #04FDFD; }
  .Menu-item--open {
    background-image: url(/static/media/icon-circle-cyan-full.b870cdf4.svg), linear-gradient(#015051, #063B3B); }

.Menu-item.Menu-item--open.Menu-item--selected {
  background-image: url(/static/media/icon-circle-light-full.b6526b3b.svg), linear-gradient(#015051, #063B3B); }

@media print {
  .Menu {
    display: none; } }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.BaseElement {
  position: absolute;
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }
  .BaseElement--ice {
    top: 0.17188rem;
    right: 0.04297rem;
    width: 0.07812rem;
    height: 0.08594rem;
    background-image: url(/static/media/ice.ebed4c7f.svg);
    background-position: top right; }
    .BaseElement--ice--repaired {
      background-image: url(/static/media/ice.d9bd6a3d.svg); }
  .BaseElement--rocket {
    left: 0.65234rem;
    top: 0.08047rem;
    width: 0.06641rem;
    height: 0.11484rem;
    background-image: url(/static/media/rocket.a3411f2d.svg); }
  .BaseElement--dome {
    left: 0.40313rem;
    top: 0.28594rem;
    width: 0.15234rem;
    height: 0.14453rem;
    background-image: url(/static/media/dome.38423242.svg); }
    .BaseElement--dome--repaired {
      background-image: url(/static/media/dome.46ca99ac.svg); }
  .BaseElement--dorms {
    left: 0.30547rem;
    top: 0.21641rem;
    width: 0.1875rem;
    height: 0.15625rem;
    background-image: url(/static/media/dorms.210df105.svg); }
    .BaseElement--dorms--repaired {
      background-image: url(/static/media/dorms.c3fbdb72.svg); }
  .BaseElement--entrance {
    left: 0.50078rem;
    top: 0.36406rem;
    width: 0.15937rem;
    height: 0.15625rem;
    background-image: url(/static/media/entrance.ad633e10.svg); }
    .BaseElement--entrance--repaired {
      left: 0.50156rem;
      top: 0.36484rem;
      width: 0.11953rem;
      height: 0.15625rem;
      background-image: url(/static/media/entrance.98ce35fe.svg); }
  .BaseElement--satellite {
    left: 0.71719rem;
    top: 0.31406rem;
    width: 0.12109rem;
    height: 0.15625rem;
    background-image: url(/static/media/satellite.e4aaf0ca.svg); }
    .BaseElement--satellite--repaired {
      width: 0.07812rem;
      left: 0.72266rem;
      top: 0.26797rem;
      background-image: url(/static/media/satellite.bb06a53e.svg); }
  .BaseElement--garage {
    left: 0.53516rem;
    top: 0.24219rem;
    width: 0.19922rem;
    height: 0.15625rem;
    background-image: url(/static/media/garage.a4468cc6.svg); }
    .BaseElement--garage--repaired {
      left: 0.53594rem;
      top: 0.24219rem;
      width: 0.17578rem;
      background-image: url(/static/media/garage.fca618a6.svg); }
  .BaseElement--lab {
    left: 0.22969rem;
    top: 0.35078rem;
    width: 0.20625rem;
    height: 0.15625rem;
    background-image: url(/static/media/lab.7bc4ff1e.svg); }
    .BaseElement--lab--repaired {
      background-image: url(/static/media/lab.1f2de883.svg); }
  .BaseElement--greenhouse {
    left: 0.32812rem;
    top: 0.37812rem;
    width: 0.16016rem;
    height: 0.15625rem;
    background-image: url(/static/media/greenhouse.0581b50e.svg); }
    .BaseElement--greenhouse--repaired {
      z-index: 1;
      background-image: url(/static/media/greenhouse.2e2bace5.svg); }
  .BaseElement--communication {
    left: 0.60156rem;
    top: 0.34219rem;
    width: 0.11094rem;
    height: 0.15625rem;
    background-image: url(/static/media/communication.3a267a2d.svg); }
    .BaseElement--communication--repaired {
      left: 0.60234rem;
      background-image: url(/static/media/communication.c8db2c44.svg); }
  .BaseElement--solarpanels {
    left: 0.02344rem;
    bottom: 0.04141rem;
    width: 0.19687rem;
    height: 0.11328rem;
    background-image: url(/static/media/solar-cells.e9c03f3e.svg);
    background-position: bottom left;
    background-size: 98%; }
    .BaseElement--solarpanels--repaired {
      left: 0.02734rem;
      bottom: 0.04297rem;
      width: 0.19141rem;
      background-size: contain;
      background-image: url(/static/media/solar-cells.76f3962e.svg); }
  .BaseElement--3dprinter {
    left: 0.03125rem;
    top: 0.26172rem;
    width: 0.10078rem;
    height: 0.05781rem;
    background-image: url(/static/media/3d-printer.172b6408.svg); }
    .BaseElement--3dprinter--repaired {
      background-image: url(/static/media/3d-printer.172b6408.svg); }
  .BaseElement--3dbase {
    left: 0.05781rem;
    top: 0.16797rem;
    width: 0.15625rem;
    height: 0.08594rem;
    background-image: url(/static/media/3d-base.c10526cc.svg); }
    .BaseElement--3dbase--repaired {
      background-image: url(/static/media/3d-base.c10526cc.svg); }
  .BaseElement--curiosity {
    left: 0.53516rem;
    bottom: 0.00781rem;
    width: 0.08906rem;
    height: 0.05781rem;
    background-image: url(/static/media/rover-curiosity.d55704c8.svg);
    background-position: bottom left; }
    .BaseElement--curiosity--repaired {
      background-image: url(/static/media/rover-curiosity.fbf08c5e.svg); }
  .BaseElement--bigvehicle1 {
    left: 0.83594rem;
    top: 0.30469rem;
    width: 0.07969rem;
    height: 0.05859rem;
    background-image: url(/static/media/vehicle-big.34c2b93f.svg); }
    .BaseElement--bigvehicle1--repaired {
      background-image: url(/static/media/vehicle-big.077a83aa.svg); }
  .BaseElement--bigvehicle2 {
    left: 0.60156rem;
    top: 0.16016rem;
    width: 0.03125rem;
    height: 0.02344rem;
    /* IE 9 */
    -webkit-transform: scaleX(-1);
    /* Safari */
    transform: scaleX(-1);
    background-image: url(/static/media/vehicle-big.34c2b93f.svg); }
    .BaseElement--bigvehicle2--repaired {
      background-image: url(/static/media/vehicle-big.077a83aa.svg); }
  .BaseElement--smallvehicle1 {
    left: 0.87891rem;
    top: 0.27891rem;
    width: 0.04922rem;
    height: 0.03594rem;
    z-index: 2;
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
    background-image: url(/static/media/vehicle-small.97f0d326.svg); }
    .BaseElement--smallvehicle1--repaired {
      left: 0.875rem;
      top: 0.27344rem;
      -webkit-transform: none;
      transform: none;
      background-image: url(/static/media/vehicle-small.515854f4.svg); }
  .BaseElement--smallvehicle2 {
    left: 0.84609rem;
    top: 0.25938rem;
    width: 0.04922rem;
    height: 0.03594rem;
    z-index: 1;
    background-image: url(/static/media/vehicle-small.97f0d326.svg); }
    .BaseElement--smallvehicle2--repaired {
      background-image: url(/static/media/vehicle-small.515854f4.svg); }
  .BaseElement--smallvehicle3 {
    left: 0.85156rem;
    top: 0.16797rem;
    width: 0.01719rem;
    height: 0.0125rem;
    /* IE 9 */
    -webkit-transform: scaleX(-1);
    /* Safari */
    transform: scaleX(-1);
    background-image: url(/static/media/vehicle-small.97f0d326.svg); }
    .BaseElement--smallvehicle3--repaired {
      background-image: url(/static/media/vehicle-small.515854f4.svg); }
  .BaseElement--smallvehicle4 {
    left: 0.34375rem;
    top: 0.20156rem;
    width: 0.04688rem;
    height: 0.03281rem;
    background-image: url(/static/media/vehicle-small.97f0d326.svg); }
    .BaseElement--smallvehicle4--repaired {
      background-image: url(/static/media/vehicle-small.515854f4.svg); }
  .BaseElement--drone1 {
    left: 0.17969rem;
    top: 0.30469rem;
    width: 0.03516rem;
    height: 0.01719rem;
    background-image: url(/static/media/drone.207b34b7.svg); }
    .BaseElement--drone1--repaired {
      left: 0.19531rem;
      top: 0.3125rem;
      background-image: url(/static/media/drone.a268865e.svg); }
  .BaseElement--drone2 {
    left: 0.77344rem;
    top: 0.22266rem;
    width: 0.03516rem;
    height: 0.01719rem;
    background-image: url(/static/media/drone.207b34b7.svg);
    /* IE 9 */
    -webkit-transform: scaleX(-1);
    /* Safari */
    transform: scaleX(-1); }
    .BaseElement--drone2--repaired {
      background-image: url(/static/media/drone.a268865e.svg); }
  .BaseElement--stars {
    top: 0.01562rem;
    left: 0.35156rem;
    width: 0.30469rem;
    height: 0.05859rem;
    background-image: url(/static/media/stars.321b1556.svg); }
  .BaseElement--crater {
    top: 0.08672rem;
    left: 0.85469rem;
    width: 0.11719rem;
    height: 0.02187rem;
    background-image: none; }
    .BaseElement--crater--repaired {
      background-image: url(/static/media/crater.2ace9100.svg); }
  .BaseElement--spacesuit {
    left: 0.50156rem;
    top: 0.5rem;
    width: 0.23438rem;
    height: 0.03516rem;
    background-image: url(/static/media/spacesuit.974a857d.svg); }
  .BaseElement--hidden-base {
    top: 0.05078rem;
    left: 0.70859rem;
    width: 0.11719rem;
    height: 0.04453rem;
    background-image: none; }
    .BaseElement--hidden-base--repaired {
      background-image: url(/static/media/hidden-base.6efebf9c.svg); }
  .BaseElement--foreground {
    bottom: 0;
    left: 0;
    width: 0.30469rem;
    height: 0.05859rem;
    background-image: url(/static/media/foreground.7626e7ca.svg);
    background-position: bottom left; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.CameraBaseContainer {
  position: relative;
  width: 0.13047rem;
  height: 0.1rem;
  overflow: hidden; }
  .CameraBaseContainer--CameraPopup {
    width: 100%;
    height: 100%; }
  .CameraBaseContainer--BaseOverview {
    width: 0.13125rem;
    height: 0.06797rem; }
    .CameraBaseContainer--BaseOverview .CameraBase {
      left: -0.42969rem;
      top: -0.25rem;
      /* IE 9 */
      -webkit-transform: scale(0.14);
      /* Safari */
      transform: scale(0.14); }

.CameraBase {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  min-height: 0.5625rem;
  min-width: 1rem;
  background-color: #F1986B;
  background-image: url(/static/media/background.5ea30e69.svg);
  background-size: 100% 100%;
  background-position: top left;
  background-repeat: no-repeat;
  /* IE 9 */
  -webkit-transform: scale(0.75);
  /* Safari */
  transform: scale(0.75); }
  .CameraBase--repaired {
    background-image: url(/static/media/background.21a3f5e7.svg); }
  .CameraBase--null {
    left: -0.39062rem;
    top: -0.23438rem; }
  .CameraBase--dome {
    left: -0.41797rem;
    top: -0.27734rem; }
  .CameraBase--solarpanels {
    /* IE 9 */
    -webkit-transform: scale(0.57);
    /* Safari */
    transform: scale(0.57);
    left: -0.21719rem;
    top: -0.33984rem; }
  .CameraBase--ice {
    left: -0.74219rem;
    top: -0.16797rem; }
  .CameraBase--greenhouse {
    /* IE 9 */
    -webkit-transform: scale(0.7);
    /* Safari */
    transform: scale(0.7);
    left: -0.37109rem;
    top: -0.35938rem; }
  .CameraBase--rocket {
    left: -0.57031rem;
    top: -0.125rem; }
  .CameraBase--satellite {
    /* IE 9 */
    -webkit-transform: scale(0.7);
    /* Safari */
    transform: scale(0.7);
    left: -0.62109rem;
    top: -0.26953rem; }
  .CameraBase--communication {
    /* IE 9 */
    -webkit-transform: scale(1.1);
    /* Safari */
    transform: scale(1.1);
    left: -0.62109rem;
    top: -0.33984rem; }
  .CameraBase--dorms {
    /* IE 9 */
    -webkit-transform: scale(0.65);
    /* Safari */
    transform: scale(0.65);
    left: -0.37109rem;
    top: -0.22656rem; }
  .CameraBase--crater {
    /* IE 9 */
    -webkit-transform: scale(1.2);
    /* Safari */
    transform: scale(1.2);
    left: -0.88672rem;
    top: -0.00781rem; }
  .CameraBase--vehicles {
    left: -0.71875rem;
    top: -0.25781rem; }
  .CameraBase--lab {
    left: -0.28906rem;
    top: -0.32031rem; }
  .CameraBase--garage {
    left: -0.53125rem;
    top: -0.24219rem; }
  .CameraBase--curiosity {
    /* IE 9 */
    -webkit-transform: scale(1.3);
    /* Safari */
    transform: scale(1.3);
    left: -0.52734rem;
    top: -0.54688rem; }
  .CameraBase--stars {
    left: -0.38281rem;
    top: -0.07031rem; }
  .CameraBase--spacesuit {
    left: -0.44531rem;
    top: -0.39062rem; }
  .CameraBase--hidden-base {
    /* IE 9 */
    -webkit-transform: scale(1.15);
    /* Safari */
    transform: scale(1.15);
    left: -0.72266rem;
    top: 0.00781rem; }
  .CameraBase--CameraPopup {
    /* IE 9 */
    -webkit-transform: scale(3.5);
    /* Safari */
    transform: scale(3.5); }
    .CameraBase--CameraPopup.CameraBase--null {
      left: 0rem;
      top: 0rem; }
    .CameraBase--CameraPopup.CameraBase--dome {
      left: 0.01562rem;
      top: -0.25781rem; }
    .CameraBase--CameraPopup.CameraBase--solarpanels {
      /* IE 9 */
      -webkit-transform: scale(3);
      /* Safari */
      transform: scale(3);
      left: 0.97656rem;
      top: -0.57422rem; }
    .CameraBase--CameraPopup.CameraBase--ice {
      left: -1.65625rem;
      top: 0.27344rem; }
    .CameraBase--CameraPopup.CameraBase--greenhouse {
      /* IE 9 */
      -webkit-transform: scale(3.75);
      /* Safari */
      transform: scale(3.75);
      left: 0.29688rem;
      top: -0.6875rem; }
    .CameraBase--CameraPopup.CameraBase--rocket {
      left: -0.69531rem;
      top: 0.45312rem; }
    .CameraBase--CameraPopup.CameraBase--satellite {
      left: -1.13281rem;
      top: -0.22656rem; }
    .CameraBase--CameraPopup.CameraBase--communication {
      left: -0.78125rem;
      top: -0.39062rem; }
    .CameraBase--CameraPopup.CameraBase--dorms {
      /* IE 9 */
      -webkit-transform: scale(3.7);
      /* Safari */
      transform: scale(3.7);
      left: 0.35156rem;
      top: -0.00781rem; }
    .CameraBase--CameraPopup.CameraBase--crater {
      left: -1.52734rem;
      top: 0.625rem; }
    .CameraBase--CameraPopup.CameraBase--vehicles {
      left: -1.52344rem;
      top: -0.14844rem; }
    .CameraBase--CameraPopup.CameraBase--lab {
      /* IE 9 */
      -webkit-transform: scale(3);
      /* Safari */
      transform: scale(3);
      left: 0.39062rem;
      top: -0.39062rem; }
    .CameraBase--CameraPopup.CameraBase--garage {
      left: -0.48438rem;
      top: -0.09375rem; }
    .CameraBase--CameraPopup.CameraBase--curiosity {
      /* IE 9 */
      -webkit-transform: scale(4.5);
      /* Safari */
      transform: scale(4.5);
      left: -0.35938rem;
      top: -1.08203rem; }
    .CameraBase--CameraPopup.CameraBase--stars {
      /* IE 9 */
      -webkit-transform: scale(3.7);
      /* Safari */
      transform: scale(3.7);
      left: 0.07031rem;
      top: 0.74219rem; }
    .CameraBase--CameraPopup.CameraBase--spacesuit {
      left: -0.10156rem;
      top: -0.79688rem; }
    .CameraBase--CameraPopup.CameraBase--hidden-base {
      left: -1.01562rem;
      top: 0.70312rem; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.CameraPopup {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(1, 27, 28, 0.8);
  padding: 0.03906rem;
  z-index: 10; }
  .CameraPopup--solarpanels, .CameraPopup--hidden-base, .CameraPopup--hidden-base2 {
    padding: 0.03906rem 0.11719rem; }
  .CameraPopup--ice, .CameraPopup--satellite, .CameraPopup--communication, .CameraPopup--crater, .CameraPopup--vehicles, .CameraPopup--lab, .CameraPopup--stars {
    padding: 0.03906rem 0.19531rem; }
  .CameraPopup--satellite.CameraPopup--cam2 {
    padding: 0.03906rem 0.0625rem; }
    .CameraPopup--satellite.CameraPopup--cam2 .CameraPopup-image {
      margin-top: 0.01562rem;
      background-size: contain; }
    .CameraPopup--satellite.CameraPopup--cam2 .CameraPopup-overlay {
      display: none; }

.CameraPopup-frame {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0.00781rem;
  margin-bottom: 0.01953rem;
  background-color: #011B1C;
  border-width: 0.00234rem;
  border-style: solid;
  border-image: url(/static/media/frame-green.44647b50.svg) round;
  border-image-slice: 10 10 10 10;
  border-image-width: 0.00781rem 0.00781rem; }

.CameraPopup-overlay {
  position: absolute;
  top: unset;
  right: 0.00781rem;
  bottom: 0.00781rem;
  left: 0.00781rem;
  height: 0.11719rem;
  background: linear-gradient(rgba(165, 43, 23, 0), rgba(165, 43, 23, 0.62)); }

.CameraPopup-image {
  width: 100%;
  height: 0.42969rem;
  background-repeat: no-repeat;
  background-size: 100%; }

.CameraPopup-info1 {
  position: absolute;
  left: 0.0125rem;
  bottom: 0.0125rem;
  text-align: left;
  font-size: 0.01562rem;
  text-transform: uppercase;
  line-height: 0.01953rem; }
  .CameraPopup-info1 span {
    display: block;
    font-size: 0.0125rem; }

.CameraPopup-info2 {
  position: absolute;
  right: 0.0125rem;
  bottom: 0.0125rem;
  text-align: left;
  text-transform: uppercase;
  font-size: 0.02344rem; }
  .CameraPopup-info2 span {
    display: block;
    text-transform: none;
    font-size: 0.0125rem; }

.CameraPopup-printIcon {
  position: absolute;
  right: 0.01562rem;
  top: 0.00391rem;
  width: 0.03125rem;
  height: 0.03125rem;
  background-image: url(/static/media/icon-print.c4621386.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer; }
  .CameraPopup-printIcon:hover {
    /* IE 9 */
    -webkit-transform: scale(1.05);
    /* Safari */
    transform: scale(1.05); }

/* This should match styles/print.css */
.CameraPopup-printImage {
  width: 90%;
  min-width: 90%;
  height: auto;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0; }

@media print {
  * {
    /* Chrome, Safari */
    color-adjust: exact !important;
    /*Firefox*/ }
  .CameraPopup,
  .CameraPopup-frame {
    background-image: none;
    background-color: white;
    padding: 0;
    border: none; }
  .CameraPopup-printIcon,
  .CameraPopup-info2,
  .CameraPopup-image {
    display: none; }
  .CameraPopup-printImage {
    opacity: 1;
    visibility: visible;
    content: url(/static/media/morse-code-print.934812e3.svg); } }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.Camera {
  position: absolute;
  right: 0.025rem;
  top: 0.01875rem;
  width: 0.17578rem;
  text-align: left; }
  .Camera--settings {
    display: none; }
  .Camera--overview {
    top: -0.00391rem;
    right: 0.21875rem;
    z-index: 1; }
    .Camera--overview .Camera-images {
      background-image: none; }
      .Camera--overview .Camera-images::before {
        display: none; }
    .Camera--overview .Camera-title,
    .Camera--overview .Camera-frame--cam1,
    .Camera--overview .Camera-frame--cam2 {
      display: none; }
    .Camera--overview .Camera-frame--cam3 {
      width: 0.11016rem;
      height: 0.05469rem;
      background-image: linear-gradient(#015051, #002F2C); }
      .Camera--overview .Camera-frame--cam3 .CameraBaseContainer--BaseOverview {
        position: relative;
        height: 0.04453rem; }
        .Camera--overview .Camera-frame--cam3 .CameraBaseContainer--BaseOverview .CameraBase {
          top: -0.25781rem;
          left: -0.44922rem;
          /* IE 9 */
          -webkit-transform: scale(0.1);
          /* Safari */
          transform: scale(0.1); }
      .Camera--overview .Camera-frame--cam3 .Camera-info1 {
        width: 100%; }
      .Camera--overview .Camera-frame--cam3 .Camera-overlay {
        background-image: linear-gradient(rgba(165, 43, 23, 0), rgba(165, 43, 23, 0.4)); }
      .Camera--overview .Camera-frame--cam3 .Camera-info1 span,
      .Camera--overview .Camera-frame--cam3 .Camera-info2 {
        display: none; }

.Camera-title {
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  font-size: 0.01562rem;
  line-height: 0.01875rem;
  margin-bottom: 0.00937rem; }

.Camera-images {
  position: relative;
  background-image: linear-gradient(#015051, #002F2C);
  padding: 0.01953rem 0.01562rem 0 0.01953rem;
  border-bottom: 0.00156rem solid #04FDFD;
  min-height: 0.3125rem; }
  .Camera-images::before {
    content: "";
    position: absolute;
    width: 0.17578rem;
    height: 0.00781rem;
    top: -0.00078rem;
    left: 0;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAfQAAAAJCAYAAADErd75AAAACXBIWXMAABhTAAAYUwG37UILAAAAaElEQVR4nO3b0Q2AIAwFwEoYXDdxFTfROQzRCFMU70bo+3gppEtt7Qlgu0tZfz8FIK0iOgDIz4YOwxURp1kAWSl0AJiAJ3cAmIBCB4AJVCFC5w8dSE2hw7A7WwMy+wr9kCDYzoHEIuIFX0UST8omeVQAAAAASUVORK5CYII=);
    background-size: 0.17578rem auto;
    background-position: left top;
    background-repeat: no-repeat; }

.Camera-frame {
  position: relative;
  padding: 0.00234rem;
  margin-bottom: 0.01953rem;
  overflow: hidden;
  border-width: 0.00234rem;
  border-style: solid;
  border-image: url(/static/media/frame-cyan.6d9c79f0.svg) round;
  border-image-slice: 10 10 10 10;
  border-image-width: 0.00781rem 0.00781rem; }
  .Camera-frame--cam1 {
    height: 0.10938rem; }
  .Camera-frame--cam2 {
    height: 0.12109rem; }
  .Camera-frame--cam3 {
    height: 0.07812rem; }
  .Camera-frame--clickable {
    cursor: pointer; }

.Camera-image {
  position: relative;
  height: 100%;
  background-color: black;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: top center; }

.Camera-overlay {
  position: absolute;
  top: unset;
  right: 0.00234rem;
  bottom: 0.00234rem;
  left: 0.00234rem; }
  .Camera-overlay--red {
    height: 0.03906rem;
    background: linear-gradient(rgba(165, 43, 23, 0), rgba(165, 43, 23, 0.62)); }
  .Camera-overlay--black {
    top: unset;
    height: 0.02891rem;
    background-color: rgba(0, 0, 0, 0.5); }

.Camera-info1 {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 65%;
  text-align: left;
  padding: 0.00469rem;
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-size: 0.00781rem;
  line-height: 0.00937rem;
  text-transform: uppercase; }
  .Camera-info1 span {
    display: block;
    font-size: 0.00625rem;
    height: 0.00625rem; }

.Camera-info2 {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 65%;
  padding: 0.00469rem;
  padding-right: 0.00625rem;
  text-align: right;
  text-transform: uppercase;
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-size: 0.00937rem; }
  .Camera-info2 span {
    display: block;
    text-transform: none;
    font-size: 0.00625rem;
    height: 0.00625rem; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.SessionStatus {
  position: absolute;
  bottom: 0.09375rem;
  left: 0.04688rem;
  color: #21CBCB;
  font-size: 0.01406rem;
  font-weight: 600;
  text-transform: uppercase;
  z-index: 1;
  text-align: center;
  padding: 0.00625rem 0;
  background-color: #0B6564;
  width: 0.13281rem;
  height: 0.03281rem; }
  .SessionStatus .Button {
    width: 100%;
    padding: 0.00625rem 0;
    height: 0.03281rem;
    margin-top: -0.00625rem;
    font-size: 0.01406rem;
    background: linear-gradient(#FEC186, #D36C5C); }
    .SessionStatus .Button:hover, .SessionStatus .Button:focus, .SessionStatus .Button:active {
      background-image: linear-gradient(#feb46d, #ce5a48); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.NavigationPanel {
  position: absolute;
  width: 100%;
  height: 0.03906rem;
  top: 0;
  left: 0; }

.NavigationPanel-navTabBottomLine {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0.00156rem;
  background: #04FDFD;
  z-index: 1; }

.NavigationPanel-navTab {
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  width: 0.17188rem;
  height: 0.03906rem;
  overflow: hidden;
  z-index: 0;
  cursor: pointer; }
  .NavigationPanel-navTab--selected {
    border-bottom: none;
    border-left: 0.00313rem solid #04FDFD;
    z-index: 2; }
    .NavigationPanel-navTab--selected .NavigationPanel-navTabBackground {
      background-color: #015051;
      border-right: none;
      border-bottom: none;
      height: 100%;
      border-top: 0.00313rem solid #04FDFD;
      -webkit-transform-origin: 100% 100%;
      transform-origin: 100% 100%;
      -webkit-transform: skewX(20deg);
      transform: skewX(20deg); }
    .NavigationPanel-navTab--selected .NavigationPanel-navTabBorder {
      display: block; }
    .NavigationPanel-navTab--selected .NavigationPanel-navTabText {
      padding: 0.00781rem 0.0125rem; }
  .NavigationPanel-navTab--selected.NavigationPanel-navTab--1 {
    border-left: none; }
    .NavigationPanel-navTab--selected.NavigationPanel-navTab--1 .NavigationPanel-navTabText {
      padding: 0.00781rem 0.01562rem; }
  .NavigationPanel-navTab--locked {
    display: none;
    cursor: not-allowed;
    opacity: 0.5; }

.NavigationPanel-navTabBackground {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  border-bottom: 0.03906rem solid #015051;
  border-right: 0.01172rem solid transparent; }

.NavigationPanel-navTabBorder {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-right: 0.00313rem solid #04FDFD; }

.NavigationPanel-navTabText {
  z-index: 3;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 0.00781rem 0.01562rem;
  padding-right: 0; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.InstructionsIcon {
  position: absolute;
  top: 0.07812rem;
  right: 0.25391rem;
  width: 0.025rem;
  height: 0.025rem;
  background-image: url(/static/media/icon-instructions.fc38193b.svg);
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer; }
  .InstructionsIcon:hover {
    /* IE 9 */
    -webkit-transform: scale(1.1);
    /* Safari */
    transform: scale(1.1); }
  .InstructionsIcon--story {
    background-image: url(/static/media/icon-instructions-group.662ec730.svg); }
  .InstructionsIcon--puzzle {
    position: absolute;
    top: 0.11094rem;
    right: 0.04922rem;
    width: 0.01875rem;
    height: 0.01875rem;
    background-image: url(/static/media/icon-instructions-light.dcf5e07b.svg);
    z-index: 2; }
  .InstructionsIcon--minigame-3 {
    top: 0.07812rem; }
  .InstructionsIcon--minigame-5 {
    top: 0.07422rem; }
  .InstructionsIcon--minigame-7 {
    top: 0.07266rem; }
  .InstructionsIcon--cyoa {
    width: 0.02266rem;
    height: 0.02266rem;
    top: 0.00781rem;
    right: 0.06641rem; }
  .InstructionsIcon--infoMinigame {
    top: 0.05469rem;
    right: 0.04297rem;
    z-index: 2;
    width: 0.01875rem;
    height: 0.01875rem;
    background-image: url(/static/media/icon-instructions-light.dcf5e07b.svg); }

.Instructions.show .ReactCollapse--collapse,
.Instructions.hide .ReactCollapse--collapse,
.Instructions.hidden .ReactCollapse--collapse {
  /* Firefox */
  /* WebKit */
  /* Opera */
  transition: height 0.4s ease-in-out;
  /* Standard */ }

.InstructionsIconDropdown {
  width: 0.51562rem;
  margin-top: 0.01172rem;
  margin-left: 0.01562rem;
  padding-left: 0.00781rem;
  padding-right: 0.00781rem;
  padding-top: 0.00391rem;
  padding-bottom: 0.00313rem;
  font-family: "Fivo Sans";
  font-weight: 600;
  font-size: 0.01562rem;
  color: #04FDFD;
  background-color: rgba(4, 253, 253, 0.1);
  background-image: url(/static/media/icon-down.714cf81c.svg);
  background-repeat: no-repeat;
  background-position: right 0.01562rem center;
  background-size: 0.01172rem;
  cursor: pointer; }
  .InstructionsIconDropdown--show {
    background-image: url(/static/media/icon-up.e89c8eef.svg); }

.InstructionsDropdown {
  width: 0.51562rem;
  overflow: hidden;
  margin-left: 0.01562rem;
  padding-left: 0.01953rem;
  padding-right: 0.01953rem;
  padding-top: 0rem;
  background-color: rgba(4, 253, 253, 0.1); }
  .InstructionsDropdown .Instructions-body {
    padding-bottom: 0.02344rem; }
    .InstructionsDropdown .Instructions-body a {
      color: white; }

.InstructionsPopup {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(1, 27, 28, 0.5);
  display: flex;
  justify-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-flex-align: center;
  align-items: center;
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" 0 ")";
  /* IE 8 */
  filter: alpha(opacity=0);
  /* IE 5-7 */
  -moz-opacity: 0;
  /* Netscape */
  -khtml-opacity: 0;
  /* Safari 1.x */
  opacity: 0;
  /* Firefox */
  /* WebKit */
  /* Opera */
  transition: opacity 0.7s ease-in-out, visibility 0.7s ease-in-out;
  /* Standard */
  z-index: -1;
  /* instructionsZoomOut contains the left and top position to which the instruction popup should close */
  /* Minigames, teacher + groups */
  /* CYOA game, teacher + groups */
  /* Wave simulator, teacher + groups */
  /* Info Minigame (LIPS, base rooms discovery), teacher + groups */
  /* Investigations, groups */
  /* Investigations, teacher */ }
  .InstructionsPopup--show, .InstructionsPopup--hide {
    z-index: 52;
    visibility: visible;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" 100 ")";
    /* IE 8 */
    filter: alpha(opacity=100);
    /* IE 5-7 */
    -moz-opacity: 1;
    /* Netscape */
    -khtml-opacity: 1;
    /* Safari 1.x */
    opacity: 1; }
  .InstructionsPopup--hidden {
    z-index: 52;
    visibility: hidden;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" 0 ")";
    /* IE 8 */
    filter: alpha(opacity=0);
    /* IE 5-7 */
    -moz-opacity: 0;
    /* Netscape */
    -khtml-opacity: 0;
    /* Safari 1.x */
    opacity: 0; }
    .InstructionsPopup--hidden .Instructions-body {
      -webkit-transform-origin: 0.75391rem -0.00781rem;
              transform-origin: 0.75391rem -0.00781rem;
      /* Fx 5+ */
      /* Opera 12+ */
      -webkit-animation: instructionsZoomOut 0.5s linear 0s 1 forwards;
      animation: instructionsZoomOut 0.5s linear 0s 1 forwards; }
  .InstructionsPopup.InstructionsPopup.InstructionsPopup--minigame-3.InstructionsPopup--hidden .Instructions-body, .InstructionsPopup.InstructionsPopup.InstructionsPopup--minigame-5.InstructionsPopup--hidden .Instructions-body, .InstructionsPopup.InstructionsPopup.InstructionsPopup--minigame-7.InstructionsPopup--hidden .Instructions-body {
    -webkit-transform-origin: 0.75391rem -0.03906rem;
            transform-origin: 0.75391rem -0.03906rem;
    /* Fx 5+ */
    /* Opera 12+ */
    -webkit-animation: instructionsZoomOut 0.5s linear 0s 1 forwards;
    animation: instructionsZoomOut 0.5s linear 0s 1 forwards; }
  .InstructionsPopup.InstructionsPopup--cyoa.InstructionsPopup--hidden .Instructions-body {
    -webkit-transform-origin: 0.72656rem -0.09766rem;
            transform-origin: 0.72656rem -0.09766rem;
    /* Fx 5+ */
    /* Opera 12+ */
    -webkit-animation: instructionsZoomOut 0.5s linear 0s 1 forwards;
    animation: instructionsZoomOut 0.5s linear 0s 1 forwards; }
  .InstructionsPopup.InstructionsPopup--waveSimulator.InstructionsPopup--hidden .Instructions-body {
    -webkit-transform-origin: 0.75781rem -0.03125rem;
            transform-origin: 0.75781rem -0.03125rem;
    /* Fx 5+ */
    /* Opera 12+ */
    -webkit-animation: instructionsZoomOut 0.5s linear 0s 1 forwards;
    animation: instructionsZoomOut 0.5s linear 0s 1 forwards; }
  .InstructionsPopup.InstructionsPopup--infoMinigame.InstructionsPopup--hidden .Instructions-body {
    -webkit-transform-origin: 0.75781rem -0.0625rem;
            transform-origin: 0.75781rem -0.0625rem;
    /* Fx 5+ */
    /* Opera 12+ */
    -webkit-animation: instructionsZoomOut 0.5s linear 0s 1 forwards;
    animation: instructionsZoomOut 0.5s linear 0s 1 forwards; }
  .InstructionsPopup.InstructionsPopup--infoMinigame.InstructionsPopup--infoMinigame-1.InstructionsPopup--hidden .Instructions-body {
    -webkit-transform-origin: 0.75781rem 0rem;
            transform-origin: 0.75781rem 0rem;
    /* Fx 5+ */
    /* Opera 12+ */
    -webkit-animation: instructionsZoomOut 0.5s linear 0s 1 forwards;
    animation: instructionsZoomOut 0.5s linear 0s 1 forwards; }
  .InstructionsPopup.InstructionsPopup--investigation.InstructionsPopup--hidden .Instructions-body, .InstructionsPopup.InstructionsPopup--minigameAndInvestigation.InstructionsPopup--hidden .Instructions-body {
    -webkit-transform-origin: 0.67188rem -0.08984rem;
            transform-origin: 0.67188rem -0.08984rem;
    /* Fx 5+ */
    /* Opera 12+ */
    -webkit-animation: instructionsZoomOut 0.5s linear 0s 1 forwards;
    animation: instructionsZoomOut 0.5s linear 0s 1 forwards; }
  .InstructionsPopup.InstructionsPopup--investigation.InstructionsPopup--hidden.InstructionsPopup--investigation-11 .Instructions-body, .InstructionsPopup.InstructionsPopup--minigameAndInvestigation.InstructionsPopup--hidden.InstructionsPopup--investigation-11 .Instructions-body {
    -webkit-transform-origin: 0.67188rem -0.03906rem;
            transform-origin: 0.67188rem -0.03906rem;
    /* Fx 5+ */
    /* Opera 12+ */
    -webkit-animation: instructionsZoomOut 0.5s linear 0s 1 forwards;
    animation: instructionsZoomOut 0.5s linear 0s 1 forwards; }
  .InstructionsPopup.InstructionsPopup--investigation.InstructionsPopup--teacher.InstructionsPopup--hidden .Instructions-body, .InstructionsPopup.InstructionsPopup--minigameAndInvestigation.InstructionsPopup--teacher.InstructionsPopup--hidden .Instructions-body {
    -webkit-transform-origin: 0.75rem -0.09766rem;
            transform-origin: 0.75rem -0.09766rem;
    /* Fx 5+ */
    /* Opera 12+ */
    -webkit-animation: instructionsZoomOut 0.5s linear 0s 1 forwards;
    animation: instructionsZoomOut 0.5s linear 0s 1 forwards; }
  .InstructionsPopup .Instructions-body {
    position: relative;
    height: 0.3125rem;
    width: 0.625rem;
    background-image: linear-gradient(#015051, #002F2C);
    color: white;
    overflow: hidden;
    border-width: 0.00234rem;
    border-style: solid;
    border-image: url(/static/media/frame-big-cyan.2452e273.svg) round;
    border-image-slice: 20 20 20 20;
    border-image-width: 0.01562rem 0.01562rem;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    /* Konqueror HTML */
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently*/ }
  .InstructionsPopup .Instructions-close {
    position: absolute;
    top: 0.01562rem;
    right: 0.01562rem;
    width: 0.02344rem;
    height: 0.02344rem;
    background-image: url(/static/media/icon-x.be145b6d.svg);
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer; }
    .InstructionsPopup .Instructions-close:hover {
      /* IE 9 */
      -webkit-transform: scale(1.1);
      /* Safari */
      transform: scale(1.1); }
  .InstructionsPopup .Instructions-text {
    position: relative;
    height: 100%;
    width: 100%;
    padding: 0.05078rem;
    overflow-y: auto;
    font-size: 0.0125rem;
    line-height: 1.375; }
    .InstructionsPopup .Instructions-text::-webkit-scrollbar {
      width: 0.00781rem;
      height: 100%; }
    .InstructionsPopup .Instructions-text::-webkit-scrollbar-track {
      background-color: rgba(4, 253, 253, 0.2);
      border-radius: 0.00781rem; }
    .InstructionsPopup .Instructions-text::-webkit-scrollbar-thumb {
      border-radius: 0.00781rem;
      background: #04FDFD;
      width: 100%;
      height: 0.01562rem;
      border: none; }
    .InstructionsPopup .Instructions-text::-webkit-scrollbar-thumb:window-inactive {
      background-color: transparent;
      border: 1px solid #04FDFD; }
  .InstructionsPopup table {
    color: white;
    border: 1px solid white;
    border-collapse: collapse;
    margin-top: 0.00781rem; }
    .InstructionsPopup table th {
      font-weight: 400; }
    .InstructionsPopup table th, .InstructionsPopup table td {
      width: 0.27344rem;
      text-align: center;
      padding: 0.00391rem;
      border: 1px solid white;
      font-weight: 100; }
  .InstructionsPopup--infoMinigame-1 .Instructions-body {
    height: 0.42969rem;
    color: #04FDFD; }
    .InstructionsPopup--infoMinigame-1 .Instructions-body table {
      color: #04FDFD;
      border: 1px solid #04FDFD; }
      .InstructionsPopup--infoMinigame-1 .Instructions-body table th, .InstructionsPopup--infoMinigame-1 .Instructions-body table td {
        border: 1px solid #04FDFD; }
  .InstructionsPopup--investigation-11 .Instructions-body {
    height: 0.42969rem; }

@media print {
  .Instructions {
    display: none; } }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.MissionControlMessage {
  position: relative;
  padding: 0.01562rem 0.03906rem; }
  .MissionControlMessage::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 0.0125rem;
    border-top: 0.00156rem solid #04FDFD;
    border-left: 0.00156rem solid #04FDFD;
    border-right: 0.00156rem solid #04FDFD;
    top: -0.00156rem;
    left: -0.00156rem; }
  .MissionControlMessage::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 0.0125rem;
    border-bottom: 0.00156rem solid #04FDFD;
    border-left: 0.00156rem solid #04FDFD;
    border-right: 0.00156rem solid #04FDFD;
    bottom: -0.00156rem;
    left: -0.00156rem; }

.MissionControlMessage-content {
  font-family: 'Fivo Sans';
  font-size: 0.01172rem;
  padding: 0 0.01172rem;
  color: #04FDFD; }
  .MissionControlMessage-content ul {
    list-style: none;
    -webkit-padding-start: 0;
            padding-inline-start: 0; }
    .MissionControlMessage-content ul li {
      position: relative;
      text-indent: 0.00781rem;
      margin: 0.01172rem 0; }
      .MissionControlMessage-content ul li p {
        display: inline; }
      .MissionControlMessage-content ul li::before {
        content: "/";
        position: absolute;
        left: -0.00781rem;
        color: white;
        font-weight: 700; }

.MissionControlMessage-start,
.MissionControlMessage-end {
  text-transform: uppercase;
  font-weight: 600; }
  .MissionControlMessage-start span,
  .MissionControlMessage-end span {
    display: inline-block;
    color: #04FDFD;
    margin-right: 0.00781rem; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.Audio {
  width: 0.20312rem; }
  .Audio .rhap_container {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    display: block;
    background-color: transparent;
    box-shadow: none; }
    .Audio .rhap_container:focus {
      outline: none; }
    .Audio .rhap_container .rhap_controls-section {
      z-index: 2;
      width: 2em;
      margin-top: 1em;
      margin-right: 1.5em;
      display: inline-block;
      vertical-align: top; }
      .Audio .rhap_container .rhap_controls-section .rhap_additional-controls,
      .Audio .rhap_container .rhap_controls-section .rhap_volume-controls {
        display: none; }
      .Audio .rhap_container .rhap_controls-section .rhap_main-controls .rhap_play-pause-button {
        font-size: 2em;
        width: 1em;
        height: 1em; }
      .Audio .rhap_container .rhap_controls-section .rhap_main-controls .rhap_main-controls-button {
        color: #04FDFD; }
    .Audio .rhap_container .rhap_progress-section {
      display: block;
      width: 11em;
      padding-top: 1.1em; }
      .Audio .rhap_container .rhap_progress-section .rhap_progress-container {
        height: 2em;
        margin: 0; }
        .Audio .rhap_container .rhap_progress-section .rhap_progress-container:focus {
          outline: none; }
        .Audio .rhap_container .rhap_progress-section .rhap_progress-container .rhap_progress-bar {
          width: 11em;
          height: 1em;
          border-radius: 1em;
          box-shadow: 0 0 0.00469rem 0 #04FDFD; }
        .Audio .rhap_container .rhap_progress-section .rhap_progress-container .rhap_progress-bar-show-download {
          background-color: #002F2C;
          border-radius: 1em; }
          .Audio .rhap_container .rhap_progress-section .rhap_progress-container .rhap_progress-bar-show-download:before {
            content: "";
            width: 0.9em;
            height: 1em;
            background-color: #002F2C;
            position: absolute;
            border-top-right-radius: 1em;
            border-bottom-right-radius: 1em;
            right: -0.55em;
            box-shadow: 0.00078rem 0 0.00469rem 0 rgba(4, 253, 253, 0.85); }
          .Audio .rhap_container .rhap_progress-section .rhap_progress-container .rhap_progress-bar-show-download:after {
            content: "";
            width: 1.2em;
            height: 1em;
            background-color: #002F2C;
            position: absolute;
            right: 0em; }
        .Audio .rhap_container .rhap_progress-section .rhap_progress-container .rhap_progress-filled {
          background-color: transparent; }
        .Audio .rhap_container .rhap_progress-section .rhap_progress-container .rhap_download-progress {
          background-color: rgba(0, 47, 44, 0.5);
          border-radius: 1em; }
        .Audio .rhap_container .rhap_progress-section .rhap_progress-container .rhap_progress-indicator {
          width: 1.5em;
          height: 1em;
          margin-left: -0.15em;
          top: -0.05em;
          background-color: transparent;
          box-shadow: none;
          background-image: url(/static/media/icon-circle-cyan-full.b870cdf4.svg);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center; }
      .Audio .rhap_container .rhap_progress-section .rhap_current-time,
      .Audio .rhap_container .rhap_progress-section .rhap_current-left-time,
      .Audio .rhap_container .rhap_progress-section .rhap_total-time {
        display: none; }
      .Audio .rhap_container .rhap_progress-section .rhap_current-left-time,
      .Audio .rhap_container .rhap_progress-section .rhap_total-time {
        text-align: right; }
  .Audio--feature {
    width: 0.20312rem;
    margin-top: -0.00781rem; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.BasicPage {
  padding: 0.02344rem; }
  .BasicPage--page-9-1-1-feature b {
    font-weight: 600;
    color: #04FDFD; }

.BasicPage-title {
  font-size: 0.01562rem;
  font-family: "Fivo Sans";
  margin-bottom: 0.00781rem; }

.BasicPage-text {
  margin-bottom: 0.02344rem; }
  .BasicPage-text img {
    width: 95%; }
  .BasicPage-text a {
    color: white; }

.BasicPage-table {
  width: 100%;
  margin-bottom: 0.03125rem; }
  .BasicPage-table th, .BasicPage-table td {
    font-size: 0.01172rem;
    text-align: left;
    padding: 0.00391rem;
    padding-left: 0.00781rem;
    vertical-align: top; }
    .BasicPage-table th:nth-child(1), .BasicPage-table th:nth-child(2), .BasicPage-table td:nth-child(1), .BasicPage-table td:nth-child(2) {
      border-right: 0.00156rem solid #04FDFD; }
  .BasicPage-table thead th {
    font-size: 0.01172rem;
    border-bottom: 0.00156rem solid #04FDFD; }
  .BasicPage-table tbody td {
    border-bottom: 0.00078rem solid white; }
  .BasicPage-table tbody tr:nth-last-child(1) td {
    border-bottom: none; }

.BasicPage-tables.BasicPage-tables--page-2-2-1-energyConsumption div:nth-child(2) td:nth-child(1) {
  width: 0.14062rem; }

.BasicPage-tables.BasicPage-tables--page-2-2-1-energyConsumption div:nth-child(2) td:nth-child(2) {
  width: 0.15625rem; }

.BasicPage-tables.BasicPage-tables--page-2-2-1-energyConsumption div:nth-child(2) td.equation {
  position: relative;
  margin-left: -0.00781rem; }
  .BasicPage-tables.BasicPage-tables--page-2-2-1-energyConsumption div:nth-child(2) td.equation .paranthesis {
    position: absolute;
    top: 0;
    font-size: 0.02734rem; }
  .BasicPage-tables.BasicPage-tables--page-2-2-1-energyConsumption div:nth-child(2) td.equation .fraction {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    letter-spacing: 0.00078rem;
    text-align: center;
    padding-left: 0.01172rem; }
  .BasicPage-tables.BasicPage-tables--page-2-2-1-energyConsumption div:nth-child(2) td.equation .fraction > span {
    display: block;
    padding: 0.00078rem; }
  .BasicPage-tables.BasicPage-tables--page-2-2-1-energyConsumption div:nth-child(2) td.equation .fraction span.bottom {
    border-top: thin solid white; }
  .BasicPage-tables.BasicPage-tables--page-2-2-1-energyConsumption div:nth-child(2) td.equation .fraction span.symbol {
    display: none; }

.BasicPage-tables.BasicPage-tables--page-2-2-2-task th:nth-child(2), .BasicPage-tables.BasicPage-tables--page-2-2-2-task td:nth-child(2) {
  border-right: none; }

.BasicPage-tables.BasicPage-tables--page-2-2-2-task td:nth-child(1) {
  width: 0.15625rem; }

.BasicPage-tables.BasicPage-tables--page-2-2-2-task td:nth-child(2) {
  width: 0.15625rem; }

.BasicPage-tables.BasicPage-tables--page-2-3-1-rocketFuel th:nth-child(2) {
  white-space: pre-wrap;
  position: relative; }
  .BasicPage-tables.BasicPage-tables--page-2-3-1-rocketFuel th:nth-child(2):after {
    content: "L";
    position: absolute;
    top: 0.00234rem;
    left: 0.08438rem;
    font-size: 0.0125rem;
    font-style: italic; }

.BasicPage-tables.BasicPage-tables--page-2-3-1-rocketFuel th:nth-child(2), .BasicPage-tables.BasicPage-tables--page-2-3-1-rocketFuel td:nth-child(2) {
  border-right: none; }

.BasicPage-tables.BasicPage-tables--page-2-3-1-rocketFuel td {
  width: 0.15625rem; }

.BasicPage-tables.BasicPage-tables--page-2-3-1-supplyNeeds td:nth-child(1) {
  width: 0.14062rem; }

.BasicPage-tables.BasicPage-tables--page-2-3-1-supplyNeeds td:nth-child(2) {
  width: 0.19922rem; }

.BasicPage-tables.BasicPage-tables--page-2-3-1-calculateLoad th:nth-child(2), .BasicPage-tables.BasicPage-tables--page-2-3-1-calculateLoad td:nth-child(2) {
  padding-right: 0.00781rem;
  text-align: right;
  border-right: none; }

.BasicPage-tables.BasicPage-tables--page-2-3-1-calculateLoad td:nth-child(1) {
  width: 0.3125rem; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.MinigameGroupStatuses {
  position: relative;
  margin-top: 0.02344rem;
  padding-right: 0.01562rem;
  overflow: hidden; }

.MinigameGroupStatuses-header {
  font-size: 0.01406rem;
  font-weight: 600;
  padding-bottom: 0.00547rem;
  margin-bottom: 0.01172rem;
  border-bottom: 1px solid white;
  display: flex;
  justify-content: space-between;
  -webkit-box-pack: space-between;
  -ms-flex-pack: space-between;
  -webkit-flex-align: center;
  align-items: center;
  flex-direction: row; }
  .MinigameGroupStatuses-header span:last-of-type {
    padding-right: 0.01562rem; }

.MinigameGroupStatuses-groups {
  margin-top: -0.00391rem; }
  .MinigameGroupStatuses-groups:before {
    content: "";
    position: absolute;
    top: 0.02656rem;
    left: 0.04531rem;
    width: 1px;
    height: 100%;
    background-color: #04FDFD; }

.MinigameGroupStatuses-group {
  margin: 0.00781rem 0;
  padding-bottom: 0.00391rem;
  border-bottom: 1px solid #04FDFD; }
  .MinigameGroupStatuses-group:last-of-type {
    margin-bottom: 0; }

.MinigameGroupStatuses-groupStatus,
.MinigameGroupStatuses-groupPoints,
.MinigameGroupStatuses-part {
  display: inline-block;
  vertical-align: top; }

.MinigameGroupStatuses-groupStatus {
  width: 80%;
  display: inline-flex;
  justify-content: space-around; }

.MinigameGroupStatuses-groupPoints {
  margin-top: 0.00391rem;
  margin-left: 0.00781rem; }
  .MinigameGroupStatuses-groupPoints span {
    padding-left: 0.00781rem; }

.MinigameGroupStatuses-part {
  width: 0.01953rem;
  height: 0.025rem;
  background-image: url(/static/media/icon-circle-light.f26a876a.svg);
  background-size: 0.00859rem 0.00859rem;
  background-position: center center;
  background-repeat: no-repeat; }
  .MinigameGroupStatuses-part--completed {
    background-image: url(/static/media/icon-circle-cyan-full.b870cdf4.svg); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.MinigameIntro {
  padding: 0.02344rem; }
  .MinigameIntro .Button {
    margin-right: 0.01562rem;
    font-size: 0.01406rem; }

.MinigameIntro-title {
  font-size: 0.01562rem;
  font-family: "Fivo Sans";
  margin-bottom: 0.00781rem; }

.MinigameIntro-text {
  margin-bottom: 0.02344rem; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.MinigameScore {
  position: absolute;
  top: 0.02344rem;
  right: 0.08594rem; }

.MinigameScore-slider {
  width: 0.23438rem;
  height: 0.00156rem;
  background-color: white; }

.MinigameScore-counter {
  background-color: #04FDFD;
  height: 0.00332rem;
  width: 0;
  position: absolute;
  top: -0.00078rem;
  /* Firefox */
  /* WebKit */
  /* Opera */
  transition: width 1s ease-out;
  /* Standard */ }

.MinigameScore-value {
  font-family: "Fivo Sans";
  font-weight: 600;
  font-size: 0.01094rem;
  color: white;
  margin-top: 0.00781rem; }
  .MinigameScore-value span {
    color: #04FDFD; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.Puzzle-dragPiece {
  position: fixed;
  pointer-events: none;
  z-index: 10;
  left: 0em;
  top: 0em; }

.Puzzle.Puzzle--23 .Puzzle-dragPiece .Puzzle-piece::before,
.Puzzle.Puzzle--24 .Puzzle-dragPiece .Puzzle-piece::before,
.Puzzle.Puzzle--25 .Puzzle-dragPiece .Puzzle-piece::before,
.Puzzle.Puzzle--26 .Puzzle-dragPiece .Puzzle-piece::before {
  font-family: 'Open Sans';
  font-size: 0.02344rem;
  font-weight: 600;
  height: 0.04453rem;
  width: 0.04453rem; }

.Puzzle.Puzzle--33 .Puzzle-dragPiece .Puzzle-piece {
  width: 0.06016rem;
  height: 0.29844rem;
  padding: 0; }
  .Puzzle.Puzzle--33 .Puzzle-dragPiece .Puzzle-piece::before {
    display: none; }

.Puzzle.Puzzle--34 .Puzzle-dragPiece .Puzzle-piece {
  width: 0.08594rem;
  height: 0.06719rem;
  background: none;
  border: none; }
  .Puzzle.Puzzle--34 .Puzzle-dragPiece .Puzzle-piece::before {
    display: none; }
  .Puzzle.Puzzle--34 .Puzzle-dragPiece .Puzzle-piece .Puzzle-icon {
    width: 0.07812rem;
    height: 0.05937rem;
    margin-left: 0.00391rem;
    margin-top: 0.00391rem; }
  .Puzzle.Puzzle--34 .Puzzle-dragPiece .Puzzle-piece .Puzzle-pieceName {
    display: none; }

.Puzzle.Puzzle--35 .Puzzle-dragPiece .Puzzle-piece,
.Puzzle.Puzzle--36 .Puzzle-dragPiece .Puzzle-piece,
.Puzzle.Puzzle--37 .Puzzle-dragPiece .Puzzle-piece {
  width: 0.07031rem;
  height: 0.03125rem;
  padding: 0; }
  .Puzzle.Puzzle--35 .Puzzle-dragPiece .Puzzle-piece::before,
  .Puzzle.Puzzle--36 .Puzzle-dragPiece .Puzzle-piece::before,
  .Puzzle.Puzzle--37 .Puzzle-dragPiece .Puzzle-piece::before {
    display: none; }

.Puzzle.Puzzle--38 .Puzzle-dragPiece .Puzzle-piece {
  width: 0.09375rem;
  height: 0.03125rem;
  padding: 0; }
  .Puzzle.Puzzle--38 .Puzzle-dragPiece .Puzzle-piece::before {
    display: none; }

.Puzzle.Puzzle--39 .Puzzle-dragPiece .Puzzle-piece {
  background: none;
  border: none; }
  .Puzzle.Puzzle--39 .Puzzle-dragPiece .Puzzle-piece::before {
    display: none; }
  .Puzzle.Puzzle--39 .Puzzle-dragPiece .Puzzle-piece .Puzzle-icon {
    width: 0.0625rem;
    height: 0.03125rem; }
    .Puzzle.Puzzle--39 .Puzzle-dragPiece .Puzzle-piece .Puzzle-icon--infrared-waves {
      height: 0.01953rem; }

@charset "UTF-8";
@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.Puzzle {
  position: relative;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/ }

.Puzzle-title {
  font-family: "Fivo Sans";
  font-size: 0.01875rem;
  margin: 0.01172rem 0;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/ }

.Puzzle-info {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/ }

.Puzzle-infoImage {
  display: inline-block;
  vertical-align: middle;
  width: 0.07812rem;
  height: 0.07812rem;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat; }
  .Puzzle-infoImage--capri-sonne {
    background-image: url(/static/media/capri-sonne.5b68671a.svg);
    background-size: auto 100%; }
  .Puzzle-infoImage--apple {
    background-image: url(/static/media/apple.189b86c9.svg);
    background-size: auto 0.06563rem; }
  .Puzzle-infoImage--marsbar {
    background-image: url(/static/media/marsbar.65354e5c.svg);
    background-size: auto 0.03125rem; }
  .Puzzle-infoImage--pizza {
    background-image: url(/static/media/pizza.3384e9cb.svg);
    background-size: auto 0.05469rem; }

.Puzzle-infoText {
  display: inline-block;
  vertical-align: middle;
  font-family: "Fivo Sans";
  font-size: 0.01875rem; }

.Puzzle-answerBtn {
  position: absolute;
  bottom: 0.02813rem;
  right: 0rem;
  width: 0.09375rem;
  height: 0.05078rem;
  font-family: "Fivo Sans";
  font-size: 0.01562rem;
  line-height: 0.05078rem;
  text-align: center;
  text-transform: uppercase;
  background-image: linear-gradient(#012F2F, #002223, #002F2C);
  border-top: 0.00156rem solid #04FDFD;
  border-bottom: 0.00156rem solid #04FDFD;
  line-height: 1;
  padding-top: 0.01562rem;
  cursor: pointer;
  opacity: 0;
  /* Fx 5+ */
  /* Opera 12+ */
  -webkit-animation: fadeIn 0.5s linear 0s 1 forwards;
  animation: fadeIn 0.5s linear 0s 1 forwards;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/ }
  .Puzzle-answerBtn--teacher {
    right: 0.10938rem; }

.Puzzle-areas {
  position: relative;
  width: 100%;
  height: 0.32422rem;
  background-size: 100% auto;
  background-position: top left;
  background-repeat: no-repeat;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/ }

.Puzzle-area {
  position: absolute;
  width: 0.15234rem;
  height: 0.04297rem;
  background-image: linear-gradient(#012F2F, #002223, #002F2C);
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/ }
  .Puzzle-area span {
    display: block;
    font-size: 0.01406rem;
    font-family: "Fivo Sans";
    font-weight: 600;
    line-height: 0.04375rem;
    text-align: center; }

.Puzzle-areaLabel {
  position: absolute;
  font-size: 0.01562rem;
  font-family: "Fivo Sans";
  font-weight: 600; }

.Puzzle-pieces {
  position: relative;
  width: 0.78125rem;
  margin-top: 0.01562rem;
  display: flex;
  justify-content: flex-start;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  -webkit-flex-align: center;
  align-items: center;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/ }

.Puzzle-piece {
  display: flex;
  justify-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-flex-align: center;
  align-items: center;
  position: relative;
  width: 0.15234rem;
  height: 0.04297rem;
  margin-bottom: 0.01562rem;
  margin-right: 0.01562rem;
  background-image: linear-gradient(#015051, #002F2C);
  border-top: 0.00156rem solid #00CBCB;
  border-bottom: 0.00156rem solid #00CBCB;
  padding-left: 0.05547rem;
  padding-right: 0.02344rem;
  font-family: "Fivo Sans";
  font-size: 0.0125rem;
  font-weight: 0.46875rem;
  line-height: 0.0125rem;
  text-align: center;
  cursor: not-allowed;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/ }
  .Puzzle-piece::before {
    content: "";
    position: absolute;
    top: -0.00078rem;
    left: 0;
    width: 0.04297rem;
    height: 0.04063rem;
    background-image: linear-gradient(#00CBCB, #009898, #00ACAC, #00CBCB);
    border-top: 0.00078rem solid #00CBCB;
    border-bottom: 0.00078rem solid #00CBCB; }
  .Puzzle-piece--draggable {
    cursor: move; }
  .Puzzle-piece--placed {
    background-image: linear-gradient(#00CBCB, #006666);
    border: none; }
    .Puzzle-piece--placed::before {
      top: 0;
      height: 0.04297rem;
      background-image: linear-gradient(#015051, #002F2C);
      border: none; }
  .Puzzle-piece--wrong {
    box-shadow: 0 0 0.00781rem 0.00156rem #E21D59; }
  .Puzzle-piece--correct, .Puzzle-piece--solved {
    box-shadow: 0 0 0.00625rem 0 #04FDFD;
    cursor: not-allowed; }
  .Puzzle-piece--notAvailable {
    opacity: 0.4; }
  .Puzzle-piece--iconOnly, .Puzzle-piece--d1, .Puzzle-piece--d2 {
    width: 0.04453rem;
    height: 0.04453rem;
    background-image: linear-gradient(0.5turn, #00C5C5, #009898, #00ACAC, #00CBCB); }

.Puzzle-arrow {
  position: absolute;
  top: 0rem;
  display: inline-block;
  vertical-align: top;
  width: 0.02344rem;
  height: 0.02344rem;
  background-image: url(/static/media/icon-back.c44df0e3.svg);
  background-size: auto 0.02344rem;
  background-position: center top;
  background-repeat: no-repeat;
  margin-right: 0.00391rem;
  cursor: pointer;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/ }
  .Puzzle-arrow--left {
    left: 0.0625rem; }
  .Puzzle-arrow--right {
    left: 0.5625rem;
    /* IE 9 */
    -webkit-transform: rotate(180deg);
    /* Safari */
    transform: rotate(180deg); }

.Puzzle-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 0.04297rem;
  height: 0.04297rem;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat; }
  .Puzzle-icon--potential-energy {
    background-image: url(/static/media/potential-energy.08d698b4.svg);
    background-size: 0.03125rem auto; }
  .Puzzle-icon--nuclear-energy {
    background-image: url(/static/media/nuclear-energy.67de5d71.svg);
    background-size: 0.02734rem auto; }
  .Puzzle-icon--electric-energy {
    background-image: url(/static/media/electric-energy.10540e81.svg);
    background-size: auto 0.03125rem; }
  .Puzzle-icon--thermal-energy {
    background-image: url(/static/media/thermal-energy.ca9fd8e4.svg);
    background-size: auto 0.03125rem; }
  .Puzzle-icon--chemical-energy {
    background-image: url(/static/media/chemical-energy.aa7c393d.svg);
    background-size: 0.02344rem auto; }
  .Puzzle-icon--radiation-energy {
    background-image: url(/static/media/radiation-energy.6f8e0487.svg);
    background-size: 0.02734rem auto; }
  .Puzzle-icon--kinetic-energy {
    background-image: url(/static/media/kinetic-energy.071fe559.svg);
    background-size: 0.03125rem auto; }
  .Puzzle-icon--arrows-to-right {
    background-image: url(/static/media/arrows-to-right.5f1b32de.svg);
    background-size: 0.02344rem auto; }
  .Puzzle-icon--arrows-downwards {
    background-image: url(/static/media/arrows-downwards.99c7e8f7.svg);
    background-size: 0.04297rem auto; }
  .Puzzle-icon--arrows-without-fluctuations {
    background-image: url(/static/media/arrows-without-fluctuations.62ccd06d.svg);
    background-size: 0.01797rem auto;
    left: 0.00234rem; }
  .Puzzle-icon--arrows-towards-center {
    background-image: url(/static/media/arrows-towards-center.3aaf3b02.svg);
    background-size: 0.02734rem auto; }
  .Puzzle-icon--arrows-to-left {
    background-image: url(/static/media/arrows-to-left.1331ed65.svg);
    background-size: 0.02344rem auto; }
  .Puzzle-icon--arrows-upwards {
    background-image: url(/static/media/arrows-upwards.25e32676.svg);
    background-size: 0.04297rem auto; }
  .Puzzle-icon--arrows-with-fluctuations {
    background-image: url(/static/media/arrows-with-fluctuations.019e6104.svg);
    background-size: 0.01797rem auto;
    left: 0.00156rem; }
  .Puzzle-icon--arrows-from-center {
    background-image: url(/static/media/arrows-from-center.a333c5c4.svg);
    background-size: 0.02734rem auto; }
  .Puzzle-icon--graph-1, .Puzzle-icon--graph-2, .Puzzle-icon--graph-3, .Puzzle-icon--graph-4, .Puzzle-icon--graph-5 {
    width: 100%;
    height: 100%;
    background-size: 0.46016rem 0.11953rem;
    background-position: top 0.00234rem left 0.00469rem;
    background-repeat: no-repeat;
    background-color: #00CBCB; }
  .Puzzle-icon--graph-1 {
    background-image: url(/static/media/graph-1.189d3127.svg); }
  .Puzzle-icon--graph-2 {
    background-image: url(/static/media/graph-2.6d622892.svg); }
  .Puzzle-icon--graph-3 {
    background-image: url(/static/media/graph-3.54ac147b.svg); }
  .Puzzle-icon--graph-4 {
    background-image: url(/static/media/graph-4.2d8ef75b.svg); }
  .Puzzle-icon--graph-5 {
    background-image: url(/static/media/graph-5.8a76dcef.svg); }
  .Puzzle-icon--wave-1 {
    background-image: url(/static/media/wave-1.fefe6dc9.svg); }
  .Puzzle-icon--wave-2 {
    background-image: url(/static/media/wave-2.c2a3d2c1.svg); }
  .Puzzle-icon--wave-3 {
    background-image: url(/static/media/wave-3.2eeb0012.svg); }
  .Puzzle-icon--wave-4 {
    background-image: url(/static/media/wave-4.10dffc1a.svg); }
  .Puzzle-icon--wave-5 {
    background-image: url(/static/media/wave-5.3302a96f.svg); }
  .Puzzle-icon--wave-6 {
    background-image: url(/static/media/wave-6.3cca6bd8.svg); }
  .Puzzle-icon--wave-7 {
    background-image: url(/static/media/wave-7.e0fcfd6a.svg); }
  .Puzzle-icon--wave-8 {
    background-image: url(/static/media/wave-8.888aa962.svg); }
  .Puzzle-icon--wave-9 {
    background-image: url(/static/media/wave-9.01ed49d7.svg); }
  .Puzzle-icon--wave-10 {
    background-image: url(/static/media/wave-10.a8b795a4.svg); }
  .Puzzle-icon--wave-11 {
    background-image: url(/static/media/wave-11.150da4b4.svg); }
  .Puzzle-icon--molecule {
    background-image: url(/static/media/molecule.9496ba37.svg); }
  .Puzzle-icon--dna {
    background-size: auto;
    background-image: url(/static/media/dna.acc9b8f3.svg); }
  .Puzzle-icon--family {
    background-image: url(/static/media/family.9246a21b.svg); }
  .Puzzle-icon--virus {
    background-image: url(/static/media/virus.4377faab.svg); }
  .Puzzle-icon--football-field {
    background-image: url(/static/media/football-field.43726adb.svg); }
  .Puzzle-icon--bacteria {
    background-image: url(/static/media/bacteria.1cf80032.svg); }
  .Puzzle-icon--needle {
    background-image: url(/static/media/needle.4189f677.svg); }
  .Puzzle-icon--x-rays {
    background-image: url(/static/media/x-rays.637206a3.svg); }
  .Puzzle-icon--microwaves {
    background-image: url(/static/media/microwaves.37a37125.svg); }
  .Puzzle-icon--gamma-rays {
    background-image: url(/static/media/gamma-rays.d7e3615c.svg); }
  .Puzzle-icon--infrared-waves {
    background-image: url(/static/media/infrared-waves.f3485b81.svg); }
  .Puzzle-icon--radio-waves-router {
    background-image: url(/static/media/radio-waves-router.d7f2b14b.svg); }
  .Puzzle-icon--ultraviolet {
    background-image: url(/static/media/ultraviolet.75a9865f.svg); }
  .Puzzle-icon--radio-waves-radio {
    background-image: url(/static/media/radio-waves-radio.a5de94e6.svg); }

.Puzzle-pieceLabels {
  width: 100%; }

.Puzzle-pieceLabel {
  display: inline-block;
  font-size: 0.01562rem;
  font-family: "Fivo Sans";
  font-weight: 600;
  background-size: auto 90%;
  background-position: center;
  background-repeat: no-repeat; }
  .Puzzle-pieceLabel--pizza {
    background-image: url(/static/media/pizza.3384e9cb.svg);
    background-size: auto 0.04844rem; }
  .Puzzle-pieceLabel--apple {
    background-image: url(/static/media/apple.189b86c9.svg);
    background-size: auto 0.05rem; }
  .Puzzle-pieceLabel--tuna {
    background-image: url(/static/media/tuna.e2bfcaae.svg);
    background-size: auto 0.04063rem; }
  .Puzzle-pieceLabel--coffee {
    background-image: url(/static/media/coffee.cafc5da9.svg);
    background-size: auto 0.05312rem; }
  .Puzzle-pieceLabel--smarties {
    background-image: url(/static/media/smarties.bb236e57.svg);
    background-size: auto 0.03906rem; }
  .Puzzle-pieceLabel--capri-sonne {
    background-image: url(/static/media/capri-sonne.5b68671a.svg);
    background-size: auto 0.05937rem; }
  .Puzzle-pieceLabel--hazelnuts {
    background-image: url(/static/media/hazelnuts.e7eba793.svg);
    background-size: auto 0.05312rem; }
  .Puzzle-pieceLabel--tortilla {
    background-image: url(/static/media/tortilla.eeba4abd.svg);
    background-size: auto 0.04375rem; }
  .Puzzle-pieceLabel--crackers {
    background-image: url(/static/media/crackers.5ad90a86.svg);
    background-size: auto 0.04375rem; }
  .Puzzle-pieceLabel--potato {
    background-image: url(/static/media/potato.9e9d7bae.svg);
    background-size: auto 0.03516rem; }
  .Puzzle-pieceLabel--water {
    background-image: url(/static/media/water.21988ef6.svg);
    background-size: auto 0.04922rem; }
  .Puzzle-pieceLabel--spaghetti {
    background-image: url(/static/media/spaghetti.e7a39633.svg);
    background-size: auto 0.03516rem; }
  .Puzzle-pieceLabel--marsbar {
    background-image: url(/static/media/marsbar.65354e5c.svg);
    background-size: auto 0.03516rem; }
  .Puzzle-pieceLabel--cheese {
    background-image: url(/static/media/cheese.eb8838e7.svg);
    background-size: auto 0.04063rem; }
  .Puzzle-pieceLabel--salat {
    background-image: url(/static/media/salat.becd5783.svg);
    background-size: auto 0.04688rem; }

.Puzzle-pieceTextLabel {
  color: #00CBCB;
  font-size: 0.01406rem;
  font-weight: 600;
  position: absolute; }

.Puzzle.Puzzle--1 .Puzzle-areas {
  background-image: url(/static/media/puzzle-1-bg.cc014a96.svg); }

.Puzzle.Puzzle--1 .Puzzle-area--1 {
  top: 0.15469rem;
  left: 0.0625rem; }

.Puzzle.Puzzle--1 .Puzzle-area--2 {
  top: 0.24219rem;
  left: 0.27578rem; }

.Puzzle.Puzzle--1 .Puzzle-area--3 {
  top: 0.19844rem;
  left: 0.47969rem; }

.Puzzle.Puzzle--1 .Puzzle-area--4 {
  top: 0.03906rem;
  left: 0.70937rem; }

.Puzzle.Puzzle--2 .Puzzle-areas {
  background-image: url(/static/media/puzzle-2-bg.24a34c58.svg); }

.Puzzle.Puzzle--2 .Puzzle-area--1 {
  top: 0.1875rem;
  left: 0.14531rem; }

.Puzzle.Puzzle--2 .Puzzle-area--2 {
  top: 0.06641rem;
  left: 0.42266rem; }

.Puzzle.Puzzle--2 .Puzzle-area--3 {
  top: 0.26406rem;
  left: 0.36797rem; }

.Puzzle.Puzzle--3 .Puzzle-areas {
  background-image: url(/static/media/puzzle-3-bg.18c28ab2.svg); }

.Puzzle.Puzzle--3 .Puzzle-area--1 {
  top: 0.24063rem;
  left: 0.26406rem; }

.Puzzle.Puzzle--3 .Puzzle-area--2 {
  top: 0.24063rem;
  left: 0.48828rem; }

.Puzzle.Puzzle--4 .Puzzle-areas {
  background-image: url(/static/media/puzzle-4-bg.12668c98.svg); }

.Puzzle.Puzzle--4 .Puzzle-area--1 {
  top: 0.05078rem;
  left: 0.12578rem; }

.Puzzle.Puzzle--4 .Puzzle-area--2 {
  top: 0.04922rem;
  left: 0.49766rem; }

.Puzzle.Puzzle--4 .Puzzle-area--3 {
  top: 0.17969rem;
  left: 0.65781rem; }

.Puzzle.Puzzle--5 .Puzzle-areas {
  background-image: url(/static/media/puzzle-5-bg.a96f98e1.svg); }

.Puzzle.Puzzle--5 .Puzzle-area--1 {
  top: 0.0625rem;
  left: 0.17031rem; }

.Puzzle.Puzzle--5 .Puzzle-area--2 {
  top: 0.25078rem;
  left: 0.35859rem; }

.Puzzle.Puzzle--5 .Puzzle-area--3 {
  top: 0.03438rem;
  left: 0.61016rem; }

.Puzzle.Puzzle--6 .Puzzle-areas {
  background-image: url(/static/media/puzzle-6-bg.cb6fb999.svg); }

.Puzzle.Puzzle--6 .Puzzle-area--1 {
  top: 0.11719rem;
  left: 0.05703rem; }

.Puzzle.Puzzle--6 .Puzzle-area--2 {
  top: 0.23438rem;
  left: 0.3125rem; }

.Puzzle.Puzzle--6 .Puzzle-area--3 {
  top: 0.24219rem;
  left: 0.5625rem; }

.Puzzle.Puzzle--7 .Puzzle-areas {
  background-image: url(/static/media/puzzle-7-bg.b0740d35.svg); }

.Puzzle.Puzzle--7 .Puzzle-area--1 {
  top: 0.24063rem;
  left: 0.04531rem; }

.Puzzle.Puzzle--7 .Puzzle-area--2 {
  top: 0.24063rem;
  left: 0.25703rem; }

.Puzzle.Puzzle--7 .Puzzle-area--3 {
  top: 0.09375rem;
  left: 0.71562rem; }

.Puzzle.Puzzle--8 .Puzzle-areas {
  background-image: url(/static/media/puzzle-8-bg.abc2d898.svg); }

.Puzzle.Puzzle--8 .Puzzle-area {
  width: 0.13281rem;
  height: 0.04297rem; }
  .Puzzle.Puzzle--8 .Puzzle-area--1 {
    top: 0.26406rem;
    left: 0.06641rem; }
  .Puzzle.Puzzle--8 .Puzzle-area--2 {
    top: 0.24531rem;
    left: 0.20703rem; }
  .Puzzle.Puzzle--8 .Puzzle-area--3 {
    top: 0.22656rem;
    left: 0.34766rem; }
  .Puzzle.Puzzle--8 .Puzzle-area--4 {
    top: 0.20781rem;
    left: 0.48828rem; }
  .Puzzle.Puzzle--8 .Puzzle-area--5 {
    top: 0.18906rem;
    left: 0.62891rem; }
  .Puzzle.Puzzle--8 .Puzzle-area--6 {
    top: 0.17031rem;
    left: 0.76953rem; }

.Puzzle.Puzzle--8 .Puzzle-piece.Puzzle-piece--placed {
  width: 0.13281rem;
  height: 0.04297rem;
  padding-left: 0.05078rem; }

.Puzzle.Puzzle--8 .Puzzle-area--2 .Puzzle-piece.Puzzle-piece--placed {
  padding-left: 0.06641rem; }

.Puzzle.Puzzle--9,
.Puzzle.Puzzle--10,
.Puzzle.Puzzle--11,
.Puzzle.Puzzle--15,
.Puzzle.Puzzle--27 {
  margin-top: 0.01562rem;
  background-image: linear-gradient(#015051, #002F2C); }
  .Puzzle.Puzzle--9 .Puzzle-title,
  .Puzzle.Puzzle--10 .Puzzle-title,
  .Puzzle.Puzzle--11 .Puzzle-title,
  .Puzzle.Puzzle--15 .Puzzle-title,
  .Puzzle.Puzzle--27 .Puzzle-title {
    position: absolute;
    z-index: 2;
    left: 0.01562rem; }
  .Puzzle.Puzzle--9 .Puzzle-areas,
  .Puzzle.Puzzle--10 .Puzzle-areas,
  .Puzzle.Puzzle--11 .Puzzle-areas,
  .Puzzle.Puzzle--15 .Puzzle-areas,
  .Puzzle.Puzzle--27 .Puzzle-areas {
    height: 0.30859rem;
    background-image: url(/static/media/puzzle-9-bg.2e5d0f07.svg); }
  .Puzzle.Puzzle--9 .Puzzle-area,
  .Puzzle.Puzzle--10 .Puzzle-area,
  .Puzzle.Puzzle--11 .Puzzle-area,
  .Puzzle.Puzzle--15 .Puzzle-area,
  .Puzzle.Puzzle--27 .Puzzle-area {
    width: 0.11875rem;
    height: 0.04531rem; }
    .Puzzle.Puzzle--9 .Puzzle-area span,
    .Puzzle.Puzzle--10 .Puzzle-area span,
    .Puzzle.Puzzle--11 .Puzzle-area span,
    .Puzzle.Puzzle--15 .Puzzle-area span,
    .Puzzle.Puzzle--27 .Puzzle-area span {
      display: none; }
    .Puzzle.Puzzle--9 .Puzzle-area--1,
    .Puzzle.Puzzle--10 .Puzzle-area--1,
    .Puzzle.Puzzle--11 .Puzzle-area--1,
    .Puzzle.Puzzle--15 .Puzzle-area--1,
    .Puzzle.Puzzle--27 .Puzzle-area--1 {
      top: 0.12812rem;
      left: 0.03125rem; }
    .Puzzle.Puzzle--9 .Puzzle-area--2,
    .Puzzle.Puzzle--10 .Puzzle-area--2,
    .Puzzle.Puzzle--11 .Puzzle-area--2,
    .Puzzle.Puzzle--15 .Puzzle-area--2,
    .Puzzle.Puzzle--27 .Puzzle-area--2 {
      top: 0.12812rem;
      left: 0.21875rem; }
    .Puzzle.Puzzle--9 .Puzzle-area--3,
    .Puzzle.Puzzle--10 .Puzzle-area--3,
    .Puzzle.Puzzle--11 .Puzzle-area--3,
    .Puzzle.Puzzle--15 .Puzzle-area--3,
    .Puzzle.Puzzle--27 .Puzzle-area--3 {
      top: 0.12812rem;
      left: 0.40234rem; }
    .Puzzle.Puzzle--9 .Puzzle-area--4,
    .Puzzle.Puzzle--10 .Puzzle-area--4,
    .Puzzle.Puzzle--11 .Puzzle-area--4,
    .Puzzle.Puzzle--15 .Puzzle-area--4,
    .Puzzle.Puzzle--27 .Puzzle-area--4 {
      top: 0.12812rem;
      left: 0.58594rem; }
    .Puzzle.Puzzle--9 .Puzzle-area--5,
    .Puzzle.Puzzle--10 .Puzzle-area--5,
    .Puzzle.Puzzle--11 .Puzzle-area--5,
    .Puzzle.Puzzle--15 .Puzzle-area--5,
    .Puzzle.Puzzle--27 .Puzzle-area--5 {
      top: 0.12812rem;
      left: 0.76953rem; }
  .Puzzle.Puzzle--9 .Puzzle-areaLabel,
  .Puzzle.Puzzle--10 .Puzzle-areaLabel,
  .Puzzle.Puzzle--11 .Puzzle-areaLabel,
  .Puzzle.Puzzle--15 .Puzzle-areaLabel,
  .Puzzle.Puzzle--27 .Puzzle-areaLabel {
    width: 0.11875rem;
    height: 0.04531rem;
    text-align: center; }
    .Puzzle.Puzzle--9 .Puzzle-areaLabel--1,
    .Puzzle.Puzzle--10 .Puzzle-areaLabel--1,
    .Puzzle.Puzzle--11 .Puzzle-areaLabel--1,
    .Puzzle.Puzzle--15 .Puzzle-areaLabel--1,
    .Puzzle.Puzzle--27 .Puzzle-areaLabel--1 {
      top: 0.20625rem;
      left: 0.03125rem; }
    .Puzzle.Puzzle--9 .Puzzle-areaLabel--2,
    .Puzzle.Puzzle--10 .Puzzle-areaLabel--2,
    .Puzzle.Puzzle--11 .Puzzle-areaLabel--2,
    .Puzzle.Puzzle--15 .Puzzle-areaLabel--2,
    .Puzzle.Puzzle--27 .Puzzle-areaLabel--2 {
      top: 0.20625rem;
      left: 0.21875rem; }
    .Puzzle.Puzzle--9 .Puzzle-areaLabel--3,
    .Puzzle.Puzzle--10 .Puzzle-areaLabel--3,
    .Puzzle.Puzzle--11 .Puzzle-areaLabel--3,
    .Puzzle.Puzzle--15 .Puzzle-areaLabel--3,
    .Puzzle.Puzzle--27 .Puzzle-areaLabel--3 {
      top: 0.20625rem;
      left: 0.40234rem; }
    .Puzzle.Puzzle--9 .Puzzle-areaLabel--4,
    .Puzzle.Puzzle--10 .Puzzle-areaLabel--4,
    .Puzzle.Puzzle--11 .Puzzle-areaLabel--4,
    .Puzzle.Puzzle--15 .Puzzle-areaLabel--4,
    .Puzzle.Puzzle--27 .Puzzle-areaLabel--4 {
      top: 0.20625rem;
      left: 0.58594rem; }
    .Puzzle.Puzzle--9 .Puzzle-areaLabel--5,
    .Puzzle.Puzzle--10 .Puzzle-areaLabel--5,
    .Puzzle.Puzzle--11 .Puzzle-areaLabel--5,
    .Puzzle.Puzzle--15 .Puzzle-areaLabel--5,
    .Puzzle.Puzzle--27 .Puzzle-areaLabel--5 {
      top: 0.20625rem;
      left: 0.76953rem; }
  .Puzzle.Puzzle--9 .Puzzle-pieces,
  .Puzzle.Puzzle--10 .Puzzle-pieces,
  .Puzzle.Puzzle--11 .Puzzle-pieces,
  .Puzzle.Puzzle--15 .Puzzle-pieces,
  .Puzzle.Puzzle--27 .Puzzle-pieces {
    width: 100%;
    padding: 0.00781rem 0.02344rem 0rem 0.02344rem; }
  .Puzzle.Puzzle--9 .Puzzle-piece,
  .Puzzle.Puzzle--10 .Puzzle-piece,
  .Puzzle.Puzzle--11 .Puzzle-piece,
  .Puzzle.Puzzle--15 .Puzzle-piece,
  .Puzzle.Puzzle--27 .Puzzle-piece {
    width: 0.11875rem;
    height: 0.04531rem;
    margin-right: 0.02344rem;
    padding-left: 0.00781rem;
    padding-right: 0.00781rem;
    font-size: 0.01094rem;
    font-weight: 600; }
    .Puzzle.Puzzle--9 .Puzzle-piece::before,
    .Puzzle.Puzzle--10 .Puzzle-piece::before,
    .Puzzle.Puzzle--11 .Puzzle-piece::before,
    .Puzzle.Puzzle--15 .Puzzle-piece::before,
    .Puzzle.Puzzle--27 .Puzzle-piece::before {
      display: none; }
  .Puzzle.Puzzle--9 .Puzzle-pieceLabels,
  .Puzzle.Puzzle--10 .Puzzle-pieceLabels,
  .Puzzle.Puzzle--11 .Puzzle-pieceLabels,
  .Puzzle.Puzzle--15 .Puzzle-pieceLabels,
  .Puzzle.Puzzle--27 .Puzzle-pieceLabels {
    padding: 0 0.02344rem 0.00781rem 0.02344rem; }
  .Puzzle.Puzzle--9 .Puzzle-pieceLabel,
  .Puzzle.Puzzle--10 .Puzzle-pieceLabel,
  .Puzzle.Puzzle--11 .Puzzle-pieceLabel,
  .Puzzle.Puzzle--15 .Puzzle-pieceLabel,
  .Puzzle.Puzzle--27 .Puzzle-pieceLabel {
    width: 0.11875rem;
    height: 0.0625rem;
    margin-right: 0.02344rem; }

.Puzzle.Puzzle--12,
.Puzzle.Puzzle--13,
.Puzzle.Puzzle--14 {
  margin-top: 0.01562rem;
  background-image: linear-gradient(#015051, #002F2C); }
  .Puzzle.Puzzle--12 .Puzzle-title,
  .Puzzle.Puzzle--13 .Puzzle-title,
  .Puzzle.Puzzle--14 .Puzzle-title {
    position: absolute;
    z-index: 2;
    left: 0.01562rem; }
  .Puzzle.Puzzle--12 .Puzzle-info,
  .Puzzle.Puzzle--13 .Puzzle-info,
  .Puzzle.Puzzle--14 .Puzzle-info {
    position: absolute;
    z-index: 2;
    left: 0.01562rem;
    top: 0.03906rem; }
  .Puzzle.Puzzle--12 .Puzzle-areas,
  .Puzzle.Puzzle--13 .Puzzle-areas,
  .Puzzle.Puzzle--14 .Puzzle-areas {
    height: 0.37266rem;
    background-image: url(/static/media/puzzle-12-bg.50907acf.svg); }
  .Puzzle.Puzzle--12 .Puzzle-area,
  .Puzzle.Puzzle--13 .Puzzle-area,
  .Puzzle.Puzzle--14 .Puzzle-area {
    width: 0.11875rem;
    height: 0.04531rem; }
    .Puzzle.Puzzle--12 .Puzzle-area span,
    .Puzzle.Puzzle--13 .Puzzle-area span,
    .Puzzle.Puzzle--14 .Puzzle-area span {
      display: none; }
    .Puzzle.Puzzle--12 .Puzzle-area--1,
    .Puzzle.Puzzle--13 .Puzzle-area--1,
    .Puzzle.Puzzle--14 .Puzzle-area--1 {
      top: 0.16797rem;
      left: 0.08984rem; }
    .Puzzle.Puzzle--12 .Puzzle-area--2,
    .Puzzle.Puzzle--13 .Puzzle-area--2,
    .Puzzle.Puzzle--14 .Puzzle-area--2 {
      top: 0.16797rem;
      left: 0.30078rem; }
    .Puzzle.Puzzle--12 .Puzzle-area--3,
    .Puzzle.Puzzle--13 .Puzzle-area--3,
    .Puzzle.Puzzle--14 .Puzzle-area--3 {
      top: 0.16797rem;
      left: 0.51172rem; }
    .Puzzle.Puzzle--12 .Puzzle-area--4,
    .Puzzle.Puzzle--13 .Puzzle-area--4,
    .Puzzle.Puzzle--14 .Puzzle-area--4 {
      top: 0.16797rem;
      left: 0.71875rem; }
  .Puzzle.Puzzle--12 .Puzzle-pieces,
  .Puzzle.Puzzle--13 .Puzzle-pieces,
  .Puzzle.Puzzle--14 .Puzzle-pieces {
    width: 100%;
    padding: 0.00781rem 0.02344rem 0.01172rem 0.02344rem; }
    .Puzzle.Puzzle--12 .Puzzle-pieces .Puzzle-piece:nth-last-child(1),
    .Puzzle.Puzzle--13 .Puzzle-pieces .Puzzle-piece:nth-last-child(1),
    .Puzzle.Puzzle--14 .Puzzle-pieces .Puzzle-piece:nth-last-child(1) {
      display: none; }
  .Puzzle.Puzzle--12 .Puzzle-piece,
  .Puzzle.Puzzle--13 .Puzzle-piece,
  .Puzzle.Puzzle--14 .Puzzle-piece {
    width: 0.11875rem;
    height: 0.04531rem;
    margin-right: 0.00391rem;
    padding-left: 0.00781rem;
    padding-right: 0.00781rem;
    font-size: 0.01094rem;
    font-weight: 600; }
    .Puzzle.Puzzle--12 .Puzzle-piece::before,
    .Puzzle.Puzzle--13 .Puzzle-piece::before,
    .Puzzle.Puzzle--14 .Puzzle-piece::before {
      display: none; }

.Puzzle.Puzzle--16 .Puzzle-areas {
  background-image: url(/static/media/puzzle-16-bg.41ac387b.svg); }

.Puzzle.Puzzle--16 .Puzzle-area span {
  display: none; }

.Puzzle.Puzzle--16 .Puzzle-area--1 {
  top: 0.05625rem;
  right: 0.24922rem;
  width: 0.04453rem; }

.Puzzle.Puzzle--16 .Puzzle-area--2 {
  top: 0.05625rem;
  right: 0.14062rem;
  width: 0.04453rem; }

.Puzzle.Puzzle--16 .Puzzle-area--3 {
  top: 0.12734rem;
  right: 0.14062rem; }

.Puzzle.Puzzle--16 .Puzzle-area--4 {
  top: 0.17578rem;
  right: 0.14062rem; }

.Puzzle.Puzzle--16 .Puzzle-area--5 {
  top: 0.225rem;
  right: 0.14062rem; }

.Puzzle.Puzzle--16 .Puzzle-area--6 {
  top: 0.27422rem;
  right: 0.14062rem; }

.Puzzle.Puzzle--16 .Puzzle-areaLabel {
  width: 0.11719rem;
  font-size: 0.01406rem;
  line-height: 0.01406rem; }
  .Puzzle.Puzzle--16 .Puzzle-areaLabel--1 {
    top: 0.02109rem;
    right: 0.17734rem; }
  .Puzzle.Puzzle--16 .Puzzle-areaLabel--2 {
    top: 0.02109rem;
    right: 0.06797rem; }

.Puzzle.Puzzle--16 .Puzzle-pieces {
  width: 100%; }

.Puzzle.Puzzle--16 .Puzzle-piece {
  padding-left: 0;
  padding-right: 0;
  font-weight: 600; }
  .Puzzle.Puzzle--16 .Puzzle-piece::before {
    display: none; }
  .Puzzle.Puzzle--16 .Puzzle-piece:nth-child(4) {
    margin-right: 0.05625rem; }
  .Puzzle.Puzzle--16 .Puzzle-piece:nth-child(5), .Puzzle.Puzzle--16 .Puzzle-piece:nth-child(6), .Puzzle.Puzzle--16 .Puzzle-piece:nth-child(7), .Puzzle.Puzzle--16 .Puzzle-piece:nth-child(8), .Puzzle.Puzzle--16 .Puzzle-piece:nth-child(11), .Puzzle.Puzzle--16 .Puzzle-piece:nth-child(12) {
    position: absolute;
    bottom: -0.05859rem; }
  .Puzzle.Puzzle--16 .Puzzle-piece:nth-child(6) {
    left: 0.06094rem; }
  .Puzzle.Puzzle--16 .Puzzle-piece:nth-child(7) {
    left: 0.12109rem; }
  .Puzzle.Puzzle--16 .Puzzle-piece:nth-child(8) {
    left: 0.18125rem; }
  .Puzzle.Puzzle--16 .Puzzle-piece:nth-child(11) {
    left: 0.28125rem; }
  .Puzzle.Puzzle--16 .Puzzle-piece:nth-child(12) {
    left: 0.44922rem; }

.Puzzle.Puzzle--17 .Puzzle-areas {
  background-image: url(/static/media/puzzle-17-bg.481aedf3.svg); }

.Puzzle.Puzzle--17 .Puzzle-area {
  width: 0.47109rem;
  height: 0.12266rem;
  top: 0.10391rem;
  left: 0.08984rem;
  background-color: transparent;
  background-size: 0.46016rem 0.11953rem;
  background-position: top 0.00234rem left 0.00469rem;
  background-repeat: no-repeat;
  background-image: url(/static/media/graph-grid.7d4ae8bb.svg); }
  .Puzzle.Puzzle--17 .Puzzle-area span {
    display: none; }
  .Puzzle.Puzzle--17 .Puzzle-area--placed {
    background-image: none; }

.Puzzle.Puzzle--17 .Puzzle-pieces {
  margin-top: 0.00781rem;
  padding-left: 0.08828rem; }

.Puzzle.Puzzle--17 .Puzzle-piece {
  width: 0.47266rem;
  height: 0.12266rem;
  padding-left: 0;
  padding-right: 0; }
  .Puzzle.Puzzle--17 .Puzzle-piece::before {
    display: none; }
  .Puzzle.Puzzle--17 .Puzzle-piece--placed {
    background-image: none; }
  .Puzzle.Puzzle--17 .Puzzle-piece--placed .Puzzle-icon {
    background-color: transparent; }

.Puzzle.Puzzle--18 .Puzzle-areas {
  height: 0.36094rem;
  background-image: url(/static/media/puzzle-18-bg.aebdb355.svg); }

.Puzzle.Puzzle--18 .Puzzle-area {
  width: 0.09219rem;
  height: 0.03438rem; }
  .Puzzle.Puzzle--18 .Puzzle-area span {
    display: none; }
  .Puzzle.Puzzle--18 .Puzzle-area--1 {
    top: 0.01172rem;
    left: 0.01406rem; }
  .Puzzle.Puzzle--18 .Puzzle-area--2 {
    top: 0.01172rem;
    left: 0.46797rem; }
  .Puzzle.Puzzle--18 .Puzzle-area--3 {
    top: 0.31875rem;
    left: 0.01406rem; }
  .Puzzle.Puzzle--18 .Puzzle-area--4 {
    top: 0.31875rem;
    left: 0.46797rem; }

.Puzzle.Puzzle--18 .Puzzle-piece {
  font-weight: 600;
  width: 0.09219rem;
  height: 0.03438rem;
  padding-left: 0;
  padding-right: 0; }
  .Puzzle.Puzzle--18 .Puzzle-piece::before {
    display: none; }

.Puzzle.Puzzle--19 .Puzzle-areas {
  background-image: url(/static/media/puzzle-19-bg.0a004942.svg); }

.Puzzle.Puzzle--19 .Puzzle-area {
  height: 0.03438rem; }
  .Puzzle.Puzzle--19 .Puzzle-area span {
    display: none; }
  .Puzzle.Puzzle--19 .Puzzle-area--1 {
    top: 0.23438rem;
    left: 0.70703rem;
    width: 0.06563rem; }
  .Puzzle.Puzzle--19 .Puzzle-area--2 {
    top: 0.23438rem;
    left: 0.78125rem;
    width: 0.03828rem; }

.Puzzle.Puzzle--19 .Puzzle-areaLabel {
  font-size: 0.01406rem; }
  .Puzzle.Puzzle--19 .Puzzle-areaLabel--1 {
    top: 0.24063rem;
    left: 0.62734rem; }

.Puzzle.Puzzle--19 .Puzzle-pieces {
  margin-top: 0.04297rem; }

.Puzzle.Puzzle--19 .Puzzle-piece {
  font-weight: 600;
  width: 0.06563rem;
  height: 0.03438rem;
  padding-left: 0;
  padding-right: 0;
  margin-right: 0.00781rem; }
  .Puzzle.Puzzle--19 .Puzzle-piece::before {
    display: none; }
  .Puzzle.Puzzle--19 .Puzzle-piece:nth-child(3) {
    margin-right: 0.05625rem; }
  .Puzzle.Puzzle--19 .Puzzle-piece:nth-child(4), .Puzzle.Puzzle--19 .Puzzle-piece:nth-child(5), .Puzzle.Puzzle--19 .Puzzle-piece:nth-child(9), .Puzzle.Puzzle--19 .Puzzle-piece:nth-child(10) {
    position: absolute;
    bottom: -0.04688rem; }
  .Puzzle.Puzzle--19 .Puzzle-piece:nth-child(5) {
    left: 0.07422rem; }
  .Puzzle.Puzzle--19 .Puzzle-piece:nth-child(9) {
    left: 0.26953rem; }
  .Puzzle.Puzzle--19 .Puzzle-piece:nth-child(10) {
    left: 0.31562rem;
    padding-left: 0.00391rem; }
  .Puzzle.Puzzle--19 .Puzzle-piece--unit, .Puzzle.Puzzle--19 .Puzzle-piece--formulaSymbol {
    width: 0.03828rem; }

.Puzzle.Puzzle--19 .Puzzle-pieceTextLabel {
  bottom: 0.05469rem; }
  .Puzzle.Puzzle--19 .Puzzle-pieceTextLabel--6 {
    left: 0.26875rem; }

.Puzzle.Puzzle--20 .Puzzle-areas {
  background-image: url(/static/media/puzzle-20-bg.790c3a2c.svg); }

.Puzzle.Puzzle--20 .Puzzle-area {
  height: 0.03438rem; }
  .Puzzle.Puzzle--20 .Puzzle-area span {
    display: none; }
  .Puzzle.Puzzle--20 .Puzzle-area--1 {
    bottom: 0.0125rem;
    left: 0.09062rem;
    width: 0.06563rem; }
  .Puzzle.Puzzle--20 .Puzzle-area--2 {
    bottom: 0.0125rem;
    left: 0.17344rem;
    width: 0.03828rem; }
  .Puzzle.Puzzle--20 .Puzzle-area--3 {
    bottom: 0.0125rem;
    left: 0.32734rem;
    width: 0.09375rem; }
  .Puzzle.Puzzle--20 .Puzzle-area--4 {
    bottom: 0.0125rem;
    left: 0.44141rem;
    width: 0.09375rem; }
  .Puzzle.Puzzle--20 .Puzzle-area--5 {
    bottom: 0.0125rem;
    left: 0.55234rem;
    width: 0.09375rem; }
  .Puzzle.Puzzle--20 .Puzzle-area--6 {
    bottom: 0.0125rem;
    left: 0.73906rem;
    width: 0.03828rem; }
  .Puzzle.Puzzle--20 .Puzzle-area--7 {
    bottom: 0.0125rem;
    left: 0.79844rem;
    width: 0.03828rem; }
  .Puzzle.Puzzle--20 .Puzzle-area--8 {
    bottom: 0.0125rem;
    left: 0.85625rem;
    width: 0.03828rem; }

.Puzzle.Puzzle--20 .Puzzle-areaLabel {
  font-size: 0.01406rem; }
  .Puzzle.Puzzle--20 .Puzzle-areaLabel--1 {
    bottom: 0.01406rem;
    left: 0.0125rem;
    width: 0.07187rem;
    line-height: 0.01406rem; }
  .Puzzle.Puzzle--20 .Puzzle-areaLabel--3 {
    bottom: 0.01875rem;
    left: 0.24844rem; }
  .Puzzle.Puzzle--20 .Puzzle-areaLabel--4 {
    bottom: 0.01875rem;
    left: 0.42734rem; }
  .Puzzle.Puzzle--20 .Puzzle-areaLabel--5 {
    bottom: 0.01875rem;
    left: 0.54063rem; }
  .Puzzle.Puzzle--20 .Puzzle-areaLabel--6 {
    bottom: 0.01875rem;
    left: 0.68281rem; }
  .Puzzle.Puzzle--20 .Puzzle-areaLabel--7 {
    bottom: 0.01875rem;
    left: 0.78359rem; }
  .Puzzle.Puzzle--20 .Puzzle-areaLabel--8 {
    bottom: 0.01875rem;
    left: 0.84297rem; }

.Puzzle.Puzzle--20 .Puzzle-pieces {
  margin-top: 0.04297rem; }

.Puzzle.Puzzle--20 .Puzzle-piece {
  font-weight: 600;
  width: 0.06563rem;
  height: 0.03438rem;
  padding-left: 0;
  padding-right: 0;
  margin-right: 0.00781rem; }
  .Puzzle.Puzzle--20 .Puzzle-piece::before {
    display: none; }
  .Puzzle.Puzzle--20 .Puzzle-piece--unit, .Puzzle.Puzzle--20 .Puzzle-piece--formulaSymbol {
    width: 0.03828rem; }
  .Puzzle.Puzzle--20 .Puzzle-piece--formulaWord {
    width: 0.09375rem; }
  .Puzzle.Puzzle--20 .Puzzle-piece:nth-child(3), .Puzzle.Puzzle--20 .Puzzle-piece:nth-child(8), .Puzzle.Puzzle--20 .Puzzle-piece:nth-child(12) {
    margin-right: 0.01719rem; }
  .Puzzle.Puzzle--20 .Puzzle-piece:nth-child(4), .Puzzle.Puzzle--20 .Puzzle-piece:nth-child(5), .Puzzle.Puzzle--20 .Puzzle-piece:nth-child(9), .Puzzle.Puzzle--20 .Puzzle-piece:nth-child(10), .Puzzle.Puzzle--20 .Puzzle-piece:nth-child(13), .Puzzle.Puzzle--20 .Puzzle-piece:nth-child(14), .Puzzle.Puzzle--20 .Puzzle-piece:nth-child(17), .Puzzle.Puzzle--20 .Puzzle-piece:nth-child(18) {
    position: absolute;
    bottom: -0.04688rem; }
  .Puzzle.Puzzle--20 .Puzzle-piece:nth-child(5) {
    left: 0.07344rem; }
  .Puzzle.Puzzle--20 .Puzzle-piece:nth-child(9) {
    left: 0.23047rem; }
  .Puzzle.Puzzle--20 .Puzzle-piece:nth-child(10) {
    left: 0.27656rem; }
  .Puzzle.Puzzle--20 .Puzzle-piece:nth-child(13) {
    left: 0.37734rem; }
  .Puzzle.Puzzle--20 .Puzzle-piece:nth-child(14) {
    left: 0.47891rem; }
  .Puzzle.Puzzle--20 .Puzzle-piece:nth-child(17) {
    left: 0.58906rem; }
  .Puzzle.Puzzle--20 .Puzzle-piece:nth-child(18) {
    left: 0.63594rem; }

.Puzzle.Puzzle--20 .Puzzle-pieceTextLabel {
  bottom: 0.05469rem; }
  .Puzzle.Puzzle--20 .Puzzle-pieceTextLabel--6 {
    left: 0.22969rem; }
  .Puzzle.Puzzle--20 .Puzzle-pieceTextLabel--11 {
    left: 0.37656rem; }
  .Puzzle.Puzzle--20 .Puzzle-pieceTextLabel--15 {
    left: 0.58906rem; }

.Puzzle.Puzzle--21 .Puzzle-areas {
  background-image: url(/static/media/puzzle-21-bg.75dbce45.svg); }

.Puzzle.Puzzle--21 .Puzzle-area {
  width: 0.04453rem;
  height: 0.04453rem;
  top: 0.12422rem; }
  .Puzzle.Puzzle--21 .Puzzle-area span {
    display: none; }
  .Puzzle.Puzzle--21 .Puzzle-area--1 {
    left: 0.05rem; }
  .Puzzle.Puzzle--21 .Puzzle-area--2 {
    left: 0.17188rem; }
  .Puzzle.Puzzle--21 .Puzzle-area--3 {
    left: 0.29375rem; }
  .Puzzle.Puzzle--21 .Puzzle-area--4 {
    left: 0.42969rem; }
  .Puzzle.Puzzle--21 .Puzzle-area--5 {
    left: 0.56875rem; }
  .Puzzle.Puzzle--21 .Puzzle-area--6 {
    left: 0.6875rem; }
  .Puzzle.Puzzle--21 .Puzzle-area--7 {
    left: 0.83984rem; }

.Puzzle.Puzzle--22 .Puzzle-areas {
  background-image: url(/static/media/puzzle-22-bg.207c930c.svg); }

.Puzzle.Puzzle--22 .Puzzle-area {
  width: 0.04453rem;
  height: 0.04453rem;
  top: 0.06094rem; }
  .Puzzle.Puzzle--22 .Puzzle-area span {
    display: none; }
  .Puzzle.Puzzle--22 .Puzzle-area--1 {
    left: 0.02344rem; }
  .Puzzle.Puzzle--22 .Puzzle-area--2 {
    left: 0.13047rem; }
  .Puzzle.Puzzle--22 .Puzzle-area--3 {
    left: 0.43125rem; }
  .Puzzle.Puzzle--22 .Puzzle-area--4 {
    left: 0.51406rem; }
  .Puzzle.Puzzle--22 .Puzzle-area--5 {
    left: 0.60313rem; }
  .Puzzle.Puzzle--22 .Puzzle-area--6 {
    left: 0.68203rem; }
  .Puzzle.Puzzle--22 .Puzzle-area--7 {
    left: 0.78281rem; }

.Puzzle.Puzzle--21 .Puzzle-piece, .Puzzle.Puzzle--22 .Puzzle-piece {
  border: none; }
  .Puzzle.Puzzle--21 .Puzzle-piece::before, .Puzzle.Puzzle--22 .Puzzle-piece::before {
    top: 0; }
  .Puzzle.Puzzle--21 .Puzzle-piece--placed::before, .Puzzle.Puzzle--22 .Puzzle-piece--placed::before {
    width: 0.04453rem;
    height: 0.04453rem; }
  .Puzzle.Puzzle--21 .Puzzle-piece--iconOnly, .Puzzle.Puzzle--22 .Puzzle-piece--iconOnly {
    padding: 0;
    margin-bottom: 0.01562rem;
    margin-right: 0.11719rem; }
  .Puzzle.Puzzle--21 .Puzzle-piece .Puzzle-icon, .Puzzle.Puzzle--22 .Puzzle-piece .Puzzle-icon {
    width: 0.04453rem;
    height: 0.04453rem; }

.Puzzle.Puzzle--21 .Puzzle-pieceTextLabel, .Puzzle.Puzzle--22 .Puzzle-pieceTextLabel {
  position: absolute;
  width: 0.10703rem;
  height: 0.04453rem;
  background-image: linear-gradient(#015051, #002F2C);
  padding-left: 0.01562rem;
  padding-right: 0.01562rem;
  padding-top: 0.00937rem;
  color: white;
  font-family: "Fivo Sans";
  font-size: 0.0125rem;
  font-weight: 0.46875rem;
  line-height: 0.0125rem;
  text-align: center;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/
  bottom: 0.07578rem; }
  .Puzzle.Puzzle--21 .Puzzle-pieceTextLabel--1, .Puzzle.Puzzle--22 .Puzzle-pieceTextLabel--1 {
    left: 0.04375rem; }
  .Puzzle.Puzzle--21 .Puzzle-pieceTextLabel--2, .Puzzle.Puzzle--22 .Puzzle-pieceTextLabel--2 {
    left: 0.20625rem;
    padding-top: 0.01562rem; }
  .Puzzle.Puzzle--21 .Puzzle-pieceTextLabel--3, .Puzzle.Puzzle--22 .Puzzle-pieceTextLabel--3 {
    left: 0.36797rem; }
  .Puzzle.Puzzle--21 .Puzzle-pieceTextLabel--4, .Puzzle.Puzzle--22 .Puzzle-pieceTextLabel--4 {
    left: 0.52969rem; }
  .Puzzle.Puzzle--21 .Puzzle-pieceTextLabel--5, .Puzzle.Puzzle--22 .Puzzle-pieceTextLabel--5 {
    left: 0.04375rem;
    bottom: 0.01562rem; }
  .Puzzle.Puzzle--21 .Puzzle-pieceTextLabel--6, .Puzzle.Puzzle--22 .Puzzle-pieceTextLabel--6 {
    left: 0.20625rem;
    bottom: 0.01562rem;
    padding: 0;
    padding-top: 0.01562rem; }
  .Puzzle.Puzzle--21 .Puzzle-pieceTextLabel--7, .Puzzle.Puzzle--22 .Puzzle-pieceTextLabel--7 {
    left: 0.36797rem;
    bottom: 0.01562rem; }

.Puzzle.Puzzle--21 .Puzzle-answerBtn, .Puzzle.Puzzle--22 .Puzzle-answerBtn {
  bottom: 0.03203rem; }

.Puzzle.Puzzle--23 .Puzzle-title,
.Puzzle.Puzzle--24 .Puzzle-title,
.Puzzle.Puzzle--25 .Puzzle-title,
.Puzzle.Puzzle--26 .Puzzle-title {
  position: absolute;
  top: 0.01172rem;
  left: 0.02344rem;
  z-index: 1;
  font-family: 'Open Sans';
  font-size: 0.0125rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #04FDFD; }

.Puzzle.Puzzle--23 .Puzzle-areas,
.Puzzle.Puzzle--24 .Puzzle-areas,
.Puzzle.Puzzle--25 .Puzzle-areas,
.Puzzle.Puzzle--26 .Puzzle-areas {
  margin-top: 0.01562rem;
  height: 0.23047rem;
  background-size: 95% auto, 100% auto;
  background-position: bottom 0.02344rem center, top left;
  background-repeat: no-repeat; }

.Puzzle.Puzzle--23 .Puzzle-area::before,
.Puzzle.Puzzle--23 .Puzzle-piece::before,
.Puzzle.Puzzle--24 .Puzzle-area::before,
.Puzzle.Puzzle--24 .Puzzle-piece::before,
.Puzzle.Puzzle--25 .Puzzle-area::before,
.Puzzle.Puzzle--25 .Puzzle-piece::before,
.Puzzle.Puzzle--26 .Puzzle-area::before,
.Puzzle.Puzzle--26 .Puzzle-piece::before {
  content: '';
  font-family: 'Open Sans';
  font-size: 0.02344rem;
  font-weight: 600;
  height: 0.04453rem; }

.Puzzle.Puzzle--23 .Puzzle-area,
.Puzzle.Puzzle--24 .Puzzle-area,
.Puzzle.Puzzle--25 .Puzzle-area,
.Puzzle.Puzzle--26 .Puzzle-area {
  width: 0.04453rem;
  height: 0.04453rem;
  top: 0.07031rem;
  padding-top: 0.00391rem;
  padding-left: 0.00703rem; }
  .Puzzle.Puzzle--23 .Puzzle-area span,
  .Puzzle.Puzzle--24 .Puzzle-area span,
  .Puzzle.Puzzle--25 .Puzzle-area span,
  .Puzzle.Puzzle--26 .Puzzle-area span {
    display: none; }
  .Puzzle.Puzzle--23 .Puzzle-area--placed ::before,
  .Puzzle.Puzzle--24 .Puzzle-area--placed ::before,
  .Puzzle.Puzzle--25 .Puzzle-area--placed ::before,
  .Puzzle.Puzzle--26 .Puzzle-area--placed ::before {
    content: ''; }

.Puzzle.Puzzle--23 .Puzzle-pieces,
.Puzzle.Puzzle--24 .Puzzle-pieces,
.Puzzle.Puzzle--25 .Puzzle-pieces,
.Puzzle.Puzzle--26 .Puzzle-pieces {
  width: 100%; }

.Puzzle.Puzzle--23 .Puzzle-piece,
.Puzzle.Puzzle--24 .Puzzle-piece,
.Puzzle.Puzzle--25 .Puzzle-piece,
.Puzzle.Puzzle--26 .Puzzle-piece {
  width: 0.02344rem;
  height: 0.02344rem;
  background-image: none;
  padding: 0; }
  .Puzzle.Puzzle--23 .Puzzle-piece::before,
  .Puzzle.Puzzle--24 .Puzzle-piece::before,
  .Puzzle.Puzzle--25 .Puzzle-piece::before,
  .Puzzle.Puzzle--26 .Puzzle-piece::before {
    font-size: 0.0125rem;
    width: 0.02344rem;
    height: 0.02344rem;
    top: unset;
    border: none;
    display: flex;
    justify-content: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-flex-align: center;
    align-items: center; }
  .Puzzle.Puzzle--23 .Puzzle-piece:nth-of-type(7), .Puzzle.Puzzle--23 .Puzzle-piece:nth-of-type(8),
  .Puzzle.Puzzle--24 .Puzzle-piece:nth-of-type(7),
  .Puzzle.Puzzle--24 .Puzzle-piece:nth-of-type(8),
  .Puzzle.Puzzle--25 .Puzzle-piece:nth-of-type(7),
  .Puzzle.Puzzle--25 .Puzzle-piece:nth-of-type(8),
  .Puzzle.Puzzle--26 .Puzzle-piece:nth-of-type(7),
  .Puzzle.Puzzle--26 .Puzzle-piece:nth-of-type(8) {
    margin-top: 0.01562rem; }
  .Puzzle.Puzzle--23 .Puzzle-piece:nth-of-type(8), .Puzzle.Puzzle--23 .Puzzle-piece:nth-of-type(10),
  .Puzzle.Puzzle--24 .Puzzle-piece:nth-of-type(8),
  .Puzzle.Puzzle--24 .Puzzle-piece:nth-of-type(10),
  .Puzzle.Puzzle--25 .Puzzle-piece:nth-of-type(8),
  .Puzzle.Puzzle--25 .Puzzle-piece:nth-of-type(10),
  .Puzzle.Puzzle--26 .Puzzle-piece:nth-of-type(8),
  .Puzzle.Puzzle--26 .Puzzle-piece:nth-of-type(10) {
    margin-right: 0.54688rem; }
  .Puzzle.Puzzle--23 .Puzzle-piece--placed::before,
  .Puzzle.Puzzle--24 .Puzzle-piece--placed::before,
  .Puzzle.Puzzle--25 .Puzzle-piece--placed::before,
  .Puzzle.Puzzle--26 .Puzzle-piece--placed::before {
    position: absolute;
    background-image: linear-gradient(#00CBCB, #006666);
    width: 0.04453rem;
    height: 0.04531rem;
    font-size: 0.02344rem;
    top: -0.03906rem;
    left: -0.00703rem; }
  .Puzzle.Puzzle--23 .Puzzle-piece--placed.Puzzle-piece--solved, .Puzzle.Puzzle--23 .Puzzle-piece--placed.Puzzle-piece--wrong,
  .Puzzle.Puzzle--24 .Puzzle-piece--placed.Puzzle-piece--solved,
  .Puzzle.Puzzle--24 .Puzzle-piece--placed.Puzzle-piece--wrong,
  .Puzzle.Puzzle--25 .Puzzle-piece--placed.Puzzle-piece--solved,
  .Puzzle.Puzzle--25 .Puzzle-piece--placed.Puzzle-piece--wrong,
  .Puzzle.Puzzle--26 .Puzzle-piece--placed.Puzzle-piece--solved,
  .Puzzle.Puzzle--26 .Puzzle-piece--placed.Puzzle-piece--wrong {
    width: 0.04453rem;
    height: 0.04531rem;
    top: -0.03906rem;
    left: -0.00703rem; }
    .Puzzle.Puzzle--23 .Puzzle-piece--placed.Puzzle-piece--solved::before, .Puzzle.Puzzle--23 .Puzzle-piece--placed.Puzzle-piece--wrong::before,
    .Puzzle.Puzzle--24 .Puzzle-piece--placed.Puzzle-piece--solved::before,
    .Puzzle.Puzzle--24 .Puzzle-piece--placed.Puzzle-piece--wrong::before,
    .Puzzle.Puzzle--25 .Puzzle-piece--placed.Puzzle-piece--solved::before,
    .Puzzle.Puzzle--25 .Puzzle-piece--placed.Puzzle-piece--wrong::before,
    .Puzzle.Puzzle--26 .Puzzle-piece--placed.Puzzle-piece--solved::before,
    .Puzzle.Puzzle--26 .Puzzle-piece--placed.Puzzle-piece--wrong::before {
      top: 0rem;
      left: 0rem; }

.Puzzle.Puzzle--23 .Puzzle-icon,
.Puzzle.Puzzle--24 .Puzzle-icon,
.Puzzle.Puzzle--25 .Puzzle-icon,
.Puzzle.Puzzle--26 .Puzzle-icon {
  width: 0.02344rem;
  height: 0.02344rem; }

.Puzzle.Puzzle--23 .Puzzle-pieceTextLabel,
.Puzzle.Puzzle--24 .Puzzle-pieceTextLabel,
.Puzzle.Puzzle--25 .Puzzle-pieceTextLabel,
.Puzzle.Puzzle--26 .Puzzle-pieceTextLabel {
  position: absolute;
  padding-top: 0.00937rem;
  color: white;
  font-family: "Open Sans";
  font-size: 0.0125rem;
  font-weight: 0.46875rem;
  line-height: 0.01406rem;
  text-align: left;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/ }
  .Puzzle.Puzzle--23 .Puzzle-pieceTextLabel--1,
  .Puzzle.Puzzle--24 .Puzzle-pieceTextLabel--1,
  .Puzzle.Puzzle--25 .Puzzle-pieceTextLabel--1,
  .Puzzle.Puzzle--26 .Puzzle-pieceTextLabel--1 {
    left: 0.03125rem;
    bottom: 0.23047rem; }
  .Puzzle.Puzzle--23 .Puzzle-pieceTextLabel--2,
  .Puzzle.Puzzle--24 .Puzzle-pieceTextLabel--2,
  .Puzzle.Puzzle--25 .Puzzle-pieceTextLabel--2,
  .Puzzle.Puzzle--26 .Puzzle-pieceTextLabel--2 {
    left: 0.44531rem;
    bottom: 0.23047rem; }
  .Puzzle.Puzzle--23 .Puzzle-pieceTextLabel--3,
  .Puzzle.Puzzle--24 .Puzzle-pieceTextLabel--3,
  .Puzzle.Puzzle--25 .Puzzle-pieceTextLabel--3,
  .Puzzle.Puzzle--26 .Puzzle-pieceTextLabel--3 {
    left: 0.03125rem;
    bottom: 0.19141rem; }
  .Puzzle.Puzzle--23 .Puzzle-pieceTextLabel--4,
  .Puzzle.Puzzle--24 .Puzzle-pieceTextLabel--4,
  .Puzzle.Puzzle--25 .Puzzle-pieceTextLabel--4,
  .Puzzle.Puzzle--26 .Puzzle-pieceTextLabel--4 {
    left: 0.44531rem;
    bottom: 0.19141rem; }
  .Puzzle.Puzzle--23 .Puzzle-pieceTextLabel--5,
  .Puzzle.Puzzle--24 .Puzzle-pieceTextLabel--5,
  .Puzzle.Puzzle--25 .Puzzle-pieceTextLabel--5,
  .Puzzle.Puzzle--26 .Puzzle-pieceTextLabel--5 {
    left: 0.03125rem;
    bottom: 0.15234rem; }
  .Puzzle.Puzzle--23 .Puzzle-pieceTextLabel--6,
  .Puzzle.Puzzle--24 .Puzzle-pieceTextLabel--6,
  .Puzzle.Puzzle--25 .Puzzle-pieceTextLabel--6,
  .Puzzle.Puzzle--26 .Puzzle-pieceTextLabel--6 {
    left: 0.44531rem;
    bottom: 0.15234rem; }
  .Puzzle.Puzzle--23 .Puzzle-pieceTextLabel--7,
  .Puzzle.Puzzle--24 .Puzzle-pieceTextLabel--7,
  .Puzzle.Puzzle--25 .Puzzle-pieceTextLabel--7,
  .Puzzle.Puzzle--26 .Puzzle-pieceTextLabel--7 {
    left: 0.03125rem;
    bottom: 0.09766rem; }
  .Puzzle.Puzzle--23 .Puzzle-pieceTextLabel--8,
  .Puzzle.Puzzle--24 .Puzzle-pieceTextLabel--8,
  .Puzzle.Puzzle--25 .Puzzle-pieceTextLabel--8,
  .Puzzle.Puzzle--26 .Puzzle-pieceTextLabel--8 {
    left: 0.26562rem;
    bottom: 0.09766rem; }
  .Puzzle.Puzzle--23 .Puzzle-pieceTextLabel--9,
  .Puzzle.Puzzle--24 .Puzzle-pieceTextLabel--9,
  .Puzzle.Puzzle--25 .Puzzle-pieceTextLabel--9,
  .Puzzle.Puzzle--26 .Puzzle-pieceTextLabel--9 {
    left: 0.03125rem;
    bottom: 0.05937rem; }
  .Puzzle.Puzzle--23 .Puzzle-pieceTextLabel--10,
  .Puzzle.Puzzle--24 .Puzzle-pieceTextLabel--10,
  .Puzzle.Puzzle--25 .Puzzle-pieceTextLabel--10,
  .Puzzle.Puzzle--26 .Puzzle-pieceTextLabel--10 {
    left: 0.26562rem;
    bottom: 0.05937rem; }
  .Puzzle.Puzzle--23 .Puzzle-pieceTextLabel--11,
  .Puzzle.Puzzle--24 .Puzzle-pieceTextLabel--11,
  .Puzzle.Puzzle--25 .Puzzle-pieceTextLabel--11,
  .Puzzle.Puzzle--26 .Puzzle-pieceTextLabel--11 {
    left: 0.03125rem;
    bottom: 0.01953rem; }
  .Puzzle.Puzzle--23 .Puzzle-pieceTextLabel--12,
  .Puzzle.Puzzle--24 .Puzzle-pieceTextLabel--12,
  .Puzzle.Puzzle--25 .Puzzle-pieceTextLabel--12,
  .Puzzle.Puzzle--26 .Puzzle-pieceTextLabel--12 {
    left: 0.26562rem;
    bottom: 0.01953rem; }

.Puzzle.Puzzle--23 .Puzzle-areas {
  background-image: url(/static/media/puzzle-23-bg.14307615.svg), url(/static/media/puzzle-time-bg.png); }

.Puzzle.Puzzle--23 .Puzzle-area--1 {
  left: 0.27344rem; }
  .Puzzle.Puzzle--23 .Puzzle-area--1::before {
    content: 'D1'; }

.Puzzle.Puzzle--23 .Puzzle-area--2 {
  left: 0.45937rem; }
  .Puzzle.Puzzle--23 .Puzzle-area--2::before {
    content: 'D2'; }

.Puzzle.Puzzle--23 .Puzzle-area--3 {
  left: 0.82031rem; }
  .Puzzle.Puzzle--23 .Puzzle-area--3::before {
    content: 'D1'; }

.Puzzle.Puzzle--23 .Puzzle-area--4 {
  left: 0.08594rem;
  top: 0.11875rem; }
  .Puzzle.Puzzle--23 .Puzzle-area--4::before {
    content: 'D2'; }

.Puzzle.Puzzle--23 .Puzzle-pieces {
  width: 100%; }

.Puzzle.Puzzle--23 .Puzzle-piece--d2 {
  margin-right: 0.39062rem; }
  .Puzzle.Puzzle--23 .Puzzle-piece--d2::before {
    content: 'D2'; }

.Puzzle.Puzzle--23 .Puzzle-piece--d1 {
  position: relative;
  margin-right: 0.21094rem; }
  .Puzzle.Puzzle--23 .Puzzle-piece--d1::before {
    content: 'D1'; }

.Puzzle.Puzzle--24 .Puzzle-areas {
  background-image: url(/static/media/puzzle-24-bg.86742fb6.svg), url(/static/media/puzzle-time-bg.png);
  background-position: bottom 0.00781rem left 0.02344rem, top left;
  background-size: 85% auto, 100% auto; }

.Puzzle.Puzzle--24 .Puzzle-area {
  padding-left: 0.00469rem; }
  .Puzzle.Puzzle--24 .Puzzle-area--1 {
    left: 0.29688rem; }
    .Puzzle.Puzzle--24 .Puzzle-area--1::before {
      content: 'M1'; }
  .Puzzle.Puzzle--24 .Puzzle-area--2 {
    left: 0.47266rem; }
    .Puzzle.Puzzle--24 .Puzzle-area--2::before {
      content: 'M2'; }
  .Puzzle.Puzzle--24 .Puzzle-area--3 {
    left: 0.09141rem;
    top: 0.11875rem; }
    .Puzzle.Puzzle--24 .Puzzle-area--3::before {
      content: 'M1'; }
  .Puzzle.Puzzle--24 .Puzzle-area--4 {
    left: 0.22266rem;
    top: 0.11875rem; }
    .Puzzle.Puzzle--24 .Puzzle-area--4::before {
      content: 'M2'; }

.Puzzle.Puzzle--24 .Puzzle-piece--m2 {
  margin-right: 0.39062rem; }
  .Puzzle.Puzzle--24 .Puzzle-piece--m2::before {
    content: 'M2'; }

.Puzzle.Puzzle--24 .Puzzle-piece--m1 {
  position: relative;
  margin-right: 0.21094rem; }
  .Puzzle.Puzzle--24 .Puzzle-piece--m1::before {
    content: 'M1'; }

.Puzzle.Puzzle--24 .Puzzle-piece--placed::before, .Puzzle.Puzzle--24 .Puzzle-piece--placed.Puzzle-piece--solved, .Puzzle.Puzzle--24 .Puzzle-piece--placed.Puzzle-piece--wrong {
  left: -0.00469rem; }

.Puzzle.Puzzle--24 .Puzzle-pieceTextLabel--6 {
  bottom: 0.14375rem; }

.Puzzle.Puzzle--25 .Puzzle-areas {
  background-image: url(/static/media/puzzle-25-bg.4119a5d8.svg), url(/static/media/puzzle-time-bg.png);
  background-position: bottom 0.00625rem left 0.02344rem, top left; }

.Puzzle.Puzzle--25 .Puzzle-area--1 {
  left: 0.24766rem; }
  .Puzzle.Puzzle--25 .Puzzle-area--1::before {
    content: 'å1'; }

.Puzzle.Puzzle--25 .Puzzle-area--2 {
  left: 0.44141rem; }
  .Puzzle.Puzzle--25 .Puzzle-area--2::before {
    content: 'å2'; }

.Puzzle.Puzzle--25 .Puzzle-area--3 {
  left: 0.77734rem; }
  .Puzzle.Puzzle--25 .Puzzle-area--3::before {
    content: 'å1'; }

.Puzzle.Puzzle--25 .Puzzle-area--4 {
  left: 0.02344rem;
  top: 0.11875rem; }
  .Puzzle.Puzzle--25 .Puzzle-area--4::before {
    content: 'å2'; }

.Puzzle.Puzzle--25 .Puzzle-piece {
  margin-top: 0rem; }
  .Puzzle.Puzzle--25 .Puzzle-piece--å2 {
    margin-right: 0.39062rem; }
    .Puzzle.Puzzle--25 .Puzzle-piece--å2::before {
      content: 'å2'; }
  .Puzzle.Puzzle--25 .Puzzle-piece--å1 {
    position: relative;
    margin-right: 0.21094rem; }
    .Puzzle.Puzzle--25 .Puzzle-piece--å1::before {
      content: 'å1'; }
  .Puzzle.Puzzle--25 .Puzzle-piece:nth-of-type(5) {
    margin-right: 0.89062rem; }
  .Puzzle.Puzzle--25 .Puzzle-piece:nth-of-type(6), .Puzzle.Puzzle--25 .Puzzle-piece:nth-of-type(7) {
    margin-top: 0.01562rem; }
  .Puzzle.Puzzle--25 .Puzzle-piece:nth-of-type(8), .Puzzle.Puzzle--25 .Puzzle-piece:nth-of-type(10) {
    margin-right: 0.21094rem; }
  .Puzzle.Puzzle--25 .Puzzle-piece:nth-of-type(7), .Puzzle.Puzzle--25 .Puzzle-piece:nth-of-type(9) {
    margin-right: 0.64844rem; }
  .Puzzle.Puzzle--25 .Puzzle-piece:nth-of-type(8) {
    margin-top: 0rem; }

.Puzzle.Puzzle--25 .Puzzle-pieceTextLabel--1 {
  width: 0.375rem;
  bottom: 0.22187rem; }

.Puzzle.Puzzle--25 .Puzzle-pieceTextLabel--2 {
  width: 0.375rem;
  bottom: 0.22187rem; }

.Puzzle.Puzzle--25 .Puzzle-pieceTextLabel--3 {
  width: 0.375rem;
  bottom: 0.18281rem; }

.Puzzle.Puzzle--25 .Puzzle-pieceTextLabel--4 {
  width: 0.375rem;
  bottom: 0.18281rem; }

.Puzzle.Puzzle--25 .Puzzle-pieceTextLabel--5 {
  width: 0.375rem;
  bottom: 0.14375rem; }

.Puzzle.Puzzle--25 .Puzzle-pieceTextLabel--6 {
  left: 0.26562rem;
  bottom: 0.09609rem; }

.Puzzle.Puzzle--25 .Puzzle-pieceTextLabel--6 {
  left: 0.03125rem;
  bottom: 0.09609rem; }

.Puzzle.Puzzle--25 .Puzzle-pieceTextLabel--7 {
  left: 0.26562rem;
  bottom: 0.09609rem; }

.Puzzle.Puzzle--25 .Puzzle-pieceTextLabel--8 {
  left: 0.03125rem;
  bottom: 0.05781rem; }

.Puzzle.Puzzle--25 .Puzzle-pieceTextLabel--9 {
  left: 0.26562rem;
  bottom: 0.05781rem; }

.Puzzle.Puzzle--25 .Puzzle-pieceTextLabel--10 {
  left: 0.03125rem;
  bottom: 0.01797rem; }

.Puzzle.Puzzle--25 .Puzzle-pieceTextLabel--11 {
  left: 0.26562rem;
  bottom: 0.01797rem; }

.Puzzle.Puzzle--26 .Puzzle-areas {
  background-image: url(/static/media/puzzle-26-bg.92b96a25.svg), url(/static/media/puzzle-time-bg.png);
  background-position: bottom 0.04453rem left 0.02344rem, top left; }

.Puzzle.Puzzle--26 .Puzzle-area--1 {
  left: 0.29688rem; }
  .Puzzle.Puzzle--26 .Puzzle-area--1::before {
    content: 'Å1'; }

.Puzzle.Puzzle--26 .Puzzle-area--2 {
  left: 0.66797rem; }
  .Puzzle.Puzzle--26 .Puzzle-area--2::before {
    content: 'Å2'; }

.Puzzle.Puzzle--26 .Puzzle-area--3 {
  left: 0.20703rem;
  top: 0.11875rem; }
  .Puzzle.Puzzle--26 .Puzzle-area--3::before {
    content: 'Å1'; }

.Puzzle.Puzzle--26 .Puzzle-area--4 {
  left: 0.45703rem;
  top: 0.11875rem; }
  .Puzzle.Puzzle--26 .Puzzle-area--4::before {
    content: 'Å2'; }

.Puzzle.Puzzle--26 .Puzzle-piece--å2 {
  margin-right: 0.39062rem; }
  .Puzzle.Puzzle--26 .Puzzle-piece--å2::before {
    content: 'Å2'; }

.Puzzle.Puzzle--26 .Puzzle-piece--å1 {
  position: relative;
  margin-right: 0.21094rem; }
  .Puzzle.Puzzle--26 .Puzzle-piece--å1::before {
    content: 'Å1'; }

.Puzzle.Puzzle--26 .Puzzle-pieceTextLabel--1 {
  width: 0.375rem;
  bottom: 0.22187rem; }

.Puzzle.Puzzle--26 .Puzzle-pieceTextLabel--2 {
  width: 0.375rem;
  bottom: 0.22187rem; }

.Puzzle.Puzzle--26 .Puzzle-pieceTextLabel--3 {
  width: 0.375rem;
  bottom: 0.18281rem; }

.Puzzle.Puzzle--26 .Puzzle-pieceTextLabel--4 {
  width: 0.375rem;
  bottom: 0.18281rem; }

.Puzzle.Puzzle--26 .Puzzle-pieceTextLabel--5 {
  width: 0.375rem;
  bottom: 0.14375rem; }

.Puzzle.Puzzle--26 .Puzzle-pieceTextLabel--6 {
  width: 0.375rem;
  bottom: 0.15391rem; }

.Puzzle.Puzzle--28 .Puzzle-areas,
.Puzzle.Puzzle--29 .Puzzle-areas,
.Puzzle.Puzzle--30 .Puzzle-areas,
.Puzzle.Puzzle--31 .Puzzle-areas,
.Puzzle.Puzzle--32 .Puzzle-areas,
.Puzzle.Puzzle--33 .Puzzle-areas {
  height: 0.1875rem;
  background-image: url(/static/media/puzzle-28-bg.e6eb7c97.svg); }

.Puzzle.Puzzle--28 .Puzzle-area,
.Puzzle.Puzzle--29 .Puzzle-area,
.Puzzle.Puzzle--30 .Puzzle-area,
.Puzzle.Puzzle--31 .Puzzle-area,
.Puzzle.Puzzle--32 .Puzzle-area,
.Puzzle.Puzzle--33 .Puzzle-area {
  width: 0.22734rem;
  height: 0.07656rem; }
  .Puzzle.Puzzle--28 .Puzzle-area::before,
  .Puzzle.Puzzle--29 .Puzzle-area::before,
  .Puzzle.Puzzle--30 .Puzzle-area::before,
  .Puzzle.Puzzle--31 .Puzzle-area::before,
  .Puzzle.Puzzle--32 .Puzzle-area::before,
  .Puzzle.Puzzle--33 .Puzzle-area::before {
    content: ''; }
  .Puzzle.Puzzle--28 .Puzzle-area span,
  .Puzzle.Puzzle--29 .Puzzle-area span,
  .Puzzle.Puzzle--30 .Puzzle-area span,
  .Puzzle.Puzzle--31 .Puzzle-area span,
  .Puzzle.Puzzle--32 .Puzzle-area span,
  .Puzzle.Puzzle--33 .Puzzle-area span {
    display: none; }
  .Puzzle.Puzzle--28 .Puzzle-area--1,
  .Puzzle.Puzzle--29 .Puzzle-area--1,
  .Puzzle.Puzzle--30 .Puzzle-area--1,
  .Puzzle.Puzzle--31 .Puzzle-area--1,
  .Puzzle.Puzzle--32 .Puzzle-area--1,
  .Puzzle.Puzzle--33 .Puzzle-area--1 {
    right: 0.06094rem;
    top: 0.04609rem; }

.Puzzle.Puzzle--28 .Puzzle-piece,
.Puzzle.Puzzle--29 .Puzzle-piece,
.Puzzle.Puzzle--30 .Puzzle-piece,
.Puzzle.Puzzle--31 .Puzzle-piece,
.Puzzle.Puzzle--32 .Puzzle-piece,
.Puzzle.Puzzle--33 .Puzzle-piece {
  margin-right: 0.02187rem;
  margin-bottom: 0.0125rem;
  width: 0.22734rem;
  height: 0.07656rem;
  background: linear-gradient(#015051, #002F2C); }
  .Puzzle.Puzzle--28 .Puzzle-piece--placed,
  .Puzzle.Puzzle--29 .Puzzle-piece--placed,
  .Puzzle.Puzzle--30 .Puzzle-piece--placed,
  .Puzzle.Puzzle--31 .Puzzle-piece--placed,
  .Puzzle.Puzzle--32 .Puzzle-piece--placed,
  .Puzzle.Puzzle--33 .Puzzle-piece--placed {
    background-image: linear-gradient(#012F2F, #002223, #002F2C); }
  .Puzzle.Puzzle--28 .Puzzle-piece--placed::before, .Puzzle.Puzzle--28 .Puzzle-piece::before,
  .Puzzle.Puzzle--29 .Puzzle-piece--placed::before,
  .Puzzle.Puzzle--29 .Puzzle-piece::before,
  .Puzzle.Puzzle--30 .Puzzle-piece--placed::before,
  .Puzzle.Puzzle--30 .Puzzle-piece::before,
  .Puzzle.Puzzle--31 .Puzzle-piece--placed::before,
  .Puzzle.Puzzle--31 .Puzzle-piece::before,
  .Puzzle.Puzzle--32 .Puzzle-piece--placed::before,
  .Puzzle.Puzzle--32 .Puzzle-piece::before,
  .Puzzle.Puzzle--33 .Puzzle-piece--placed::before,
  .Puzzle.Puzzle--33 .Puzzle-piece::before {
    content: "";
    width: 100%;
    height: 0.00156rem;
    background: #0C7171;
    position: absolute;
    top: 0.03672rem;
    left: 0;
    border: none; }
  .Puzzle.Puzzle--28 .Puzzle-piece .Puzzle-icon,
  .Puzzle.Puzzle--29 .Puzzle-piece .Puzzle-icon,
  .Puzzle.Puzzle--30 .Puzzle-piece .Puzzle-icon,
  .Puzzle.Puzzle--31 .Puzzle-piece .Puzzle-icon,
  .Puzzle.Puzzle--32 .Puzzle-piece .Puzzle-icon,
  .Puzzle.Puzzle--33 .Puzzle-piece .Puzzle-icon {
    width: 100%;
    height: 100%; }
    .Puzzle.Puzzle--28 .Puzzle-piece .Puzzle-icon--wave-6,
    .Puzzle.Puzzle--29 .Puzzle-piece .Puzzle-icon--wave-6,
    .Puzzle.Puzzle--30 .Puzzle-piece .Puzzle-icon--wave-6,
    .Puzzle.Puzzle--31 .Puzzle-piece .Puzzle-icon--wave-6,
    .Puzzle.Puzzle--32 .Puzzle-piece .Puzzle-icon--wave-6,
    .Puzzle.Puzzle--33 .Puzzle-piece .Puzzle-icon--wave-6 {
      background-size: 100% 100%; }

.Puzzle.Puzzle--29 .Puzzle-areas {
  background-image: url(/static/media/puzzle-29-bg.5b19d2c0.svg); }

.Puzzle.Puzzle--30 .Puzzle-areas {
  background-image: url(/static/media/puzzle-30-bg.f197a4e7.svg); }

.Puzzle.Puzzle--31 .Puzzle-areas {
  background-image: url(/static/media/puzzle-31-bg.ef1e6413.svg); }

.Puzzle.Puzzle--32 .Puzzle-areas {
  height: 0.35938rem;
  border: 1px solid #18A4A4;
  background-image: url(/static/media/puzzle-32-bg.af9833bb.svg); }

.Puzzle.Puzzle--32 .Puzzle-area {
  left: 0.34766rem; }
  .Puzzle.Puzzle--32 .Puzzle-area--1 {
    border: 0.00234rem solid #FD0D1B;
    top: 0.09375rem; }
  .Puzzle.Puzzle--32 .Puzzle-area--2 {
    border: 0.00234rem solid #0C609C;
    top: 0.20703rem; }

.Puzzle.Puzzle--32 .Puzzle-areaLabel {
  left: 0.34766rem; }
  .Puzzle.Puzzle--32 .Puzzle-areaLabel--1 {
    top: 0.07031rem; }
  .Puzzle.Puzzle--32 .Puzzle-areaLabel--2 {
    top: 0.18359rem; }

.Puzzle.Puzzle--32 .Puzzle-pieces {
  width: 0.23438rem;
  position: absolute;
  top: 0.11797rem;
  right: 0.0375rem;
  flex-direction: column; }
  .Puzzle.Puzzle--32 .Puzzle-pieces .Puzzle-piece:first-of-type {
    margin-bottom: 0.0375rem; }

.Puzzle.Puzzle--32 .Puzzle-piece.Puzzle-piece--placed {
  width: 0.22266rem;
  height: 0.07187rem; }

.Puzzle.Puzzle--33 .Puzzle-areas {
  height: 0.37734rem;
  border: 1px solid #18A4A4;
  background-image: url(/static/media/puzzle-33-bg.f702f38b.svg); }

.Puzzle.Puzzle--33 .Puzzle-area {
  width: 0.06016rem;
  height: 0.29844rem;
  top: 0.04688rem;
  left: 0.34766rem; }

.Puzzle.Puzzle--33 .Puzzle-pieces {
  width: 0.40625rem;
  position: absolute;
  top: 0.07031rem;
  right: 0.0375rem;
  display: inline-block; }
  .Puzzle.Puzzle--33 .Puzzle-pieces .Puzzle-piece {
    width: 0.06016rem;
    height: 0.29844rem;
    display: inline-block;
    margin-right: 0.03906rem;
    padding: 0;
    border-top: none;
    border-bottom: none;
    border-left: 1px solid #04FDFD;
    border-right: 1px solid #04FDFD;
    background-image: linear-gradient(-90deg, #015051, #002F2C); }
    .Puzzle.Puzzle--33 .Puzzle-pieces .Puzzle-piece::before {
      display: none; }
    .Puzzle.Puzzle--33 .Puzzle-pieces .Puzzle-piece .Puzzle-icon {
      background-size: 100% 100%; }
      .Puzzle.Puzzle--33 .Puzzle-pieces .Puzzle-piece .Puzzle-icon--wave-8 {
        background-size: 101% 100%; }
      .Puzzle.Puzzle--33 .Puzzle-pieces .Puzzle-piece .Puzzle-icon--wave-10 {
        background-size: 101% 100%; }
      .Puzzle.Puzzle--33 .Puzzle-pieces .Puzzle-piece .Puzzle-icon--wave-11 {
        background-size: 103% 100%; }

.Puzzle.Puzzle--33 .Puzzle-piece--placed {
  width: 0.06016rem;
  height: 0.29844rem;
  padding: 0; }
  .Puzzle.Puzzle--33 .Puzzle-piece--placed::before {
    display: none; }

.Puzzle.Puzzle--33 .Puzzle-piece--correct {
  width: 0.06016rem;
  padding: 0;
  margin: 0; }

.Puzzle.Puzzle--34,
.Puzzle.Puzzle--35,
.Puzzle.Puzzle--36,
.Puzzle.Puzzle--37,
.Puzzle.Puzzle--38,
.Puzzle.Puzzle--39 {
  background: linear-gradient(#015051, #002F2C); }
  .Puzzle.Puzzle--34 .Puzzle-areas,
  .Puzzle.Puzzle--35 .Puzzle-areas,
  .Puzzle.Puzzle--36 .Puzzle-areas,
  .Puzzle.Puzzle--37 .Puzzle-areas,
  .Puzzle.Puzzle--38 .Puzzle-areas,
  .Puzzle.Puzzle--39 .Puzzle-areas {
    height: 0.47266rem;
    border-bottom: 0.00156rem solid #04FDFD; }
    .Puzzle.Puzzle--34 .Puzzle-areas .Puzzle-area,
    .Puzzle.Puzzle--35 .Puzzle-areas .Puzzle-area,
    .Puzzle.Puzzle--36 .Puzzle-areas .Puzzle-area,
    .Puzzle.Puzzle--37 .Puzzle-areas .Puzzle-area,
    .Puzzle.Puzzle--38 .Puzzle-areas .Puzzle-area,
    .Puzzle.Puzzle--39 .Puzzle-areas .Puzzle-area {
      width: 0.07031rem;
      height: 0.03125rem; }
      .Puzzle.Puzzle--34 .Puzzle-areas .Puzzle-area span,
      .Puzzle.Puzzle--35 .Puzzle-areas .Puzzle-area span,
      .Puzzle.Puzzle--36 .Puzzle-areas .Puzzle-area span,
      .Puzzle.Puzzle--37 .Puzzle-areas .Puzzle-area span,
      .Puzzle.Puzzle--38 .Puzzle-areas .Puzzle-area span,
      .Puzzle.Puzzle--39 .Puzzle-areas .Puzzle-area span {
        display: none; }
      .Puzzle.Puzzle--34 .Puzzle-areas .Puzzle-area--1,
      .Puzzle.Puzzle--35 .Puzzle-areas .Puzzle-area--1,
      .Puzzle.Puzzle--36 .Puzzle-areas .Puzzle-area--1,
      .Puzzle.Puzzle--37 .Puzzle-areas .Puzzle-area--1,
      .Puzzle.Puzzle--38 .Puzzle-areas .Puzzle-area--1,
      .Puzzle.Puzzle--39 .Puzzle-areas .Puzzle-area--1 {
        left: 0.14531rem; }
      .Puzzle.Puzzle--34 .Puzzle-areas .Puzzle-area--2,
      .Puzzle.Puzzle--35 .Puzzle-areas .Puzzle-area--2,
      .Puzzle.Puzzle--36 .Puzzle-areas .Puzzle-area--2,
      .Puzzle.Puzzle--37 .Puzzle-areas .Puzzle-area--2,
      .Puzzle.Puzzle--38 .Puzzle-areas .Puzzle-area--2,
      .Puzzle.Puzzle--39 .Puzzle-areas .Puzzle-area--2 {
        left: 0.28984rem; }
      .Puzzle.Puzzle--34 .Puzzle-areas .Puzzle-area--3,
      .Puzzle.Puzzle--35 .Puzzle-areas .Puzzle-area--3,
      .Puzzle.Puzzle--36 .Puzzle-areas .Puzzle-area--3,
      .Puzzle.Puzzle--37 .Puzzle-areas .Puzzle-area--3,
      .Puzzle.Puzzle--38 .Puzzle-areas .Puzzle-area--3,
      .Puzzle.Puzzle--39 .Puzzle-areas .Puzzle-area--3 {
        left: 0.44141rem; }
      .Puzzle.Puzzle--34 .Puzzle-areas .Puzzle-area--4,
      .Puzzle.Puzzle--35 .Puzzle-areas .Puzzle-area--4,
      .Puzzle.Puzzle--36 .Puzzle-areas .Puzzle-area--4,
      .Puzzle.Puzzle--37 .Puzzle-areas .Puzzle-area--4,
      .Puzzle.Puzzle--38 .Puzzle-areas .Puzzle-area--4,
      .Puzzle.Puzzle--39 .Puzzle-areas .Puzzle-area--4 {
        left: 0.5875rem; }
      .Puzzle.Puzzle--34 .Puzzle-areas .Puzzle-area--5,
      .Puzzle.Puzzle--35 .Puzzle-areas .Puzzle-area--5,
      .Puzzle.Puzzle--36 .Puzzle-areas .Puzzle-area--5,
      .Puzzle.Puzzle--37 .Puzzle-areas .Puzzle-area--5,
      .Puzzle.Puzzle--38 .Puzzle-areas .Puzzle-area--5,
      .Puzzle.Puzzle--39 .Puzzle-areas .Puzzle-area--5 {
        left: 0.73594rem; }
  .Puzzle.Puzzle--34 .Puzzle-pieces,
  .Puzzle.Puzzle--35 .Puzzle-pieces,
  .Puzzle.Puzzle--36 .Puzzle-pieces,
  .Puzzle.Puzzle--37 .Puzzle-pieces,
  .Puzzle.Puzzle--38 .Puzzle-pieces,
  .Puzzle.Puzzle--39 .Puzzle-pieces {
    position: absolute; }

.Puzzle.Puzzle--34 .Puzzle-areas {
  background-image: url(/static/media/puzzle-34-bg.9e9906cb.svg); }
  .Puzzle.Puzzle--34 .Puzzle-areas .Puzzle-area {
    width: 0.0875rem;
    height: 0.07031rem;
    top: 0.05625rem; }
    .Puzzle.Puzzle--34 .Puzzle-areas .Puzzle-area span {
      display: none; }
    .Puzzle.Puzzle--34 .Puzzle-areas .Puzzle-area--1 {
      left: 0.12734rem; }
    .Puzzle.Puzzle--34 .Puzzle-areas .Puzzle-area--2 {
      left: 0.23125rem; }
    .Puzzle.Puzzle--34 .Puzzle-areas .Puzzle-area--3 {
      left: 0.35156rem; }
    .Puzzle.Puzzle--34 .Puzzle-areas .Puzzle-area--4 {
      left: 0.45rem; }
    .Puzzle.Puzzle--34 .Puzzle-areas .Puzzle-area--5 {
      left: 0.54922rem; }
    .Puzzle.Puzzle--34 .Puzzle-areas .Puzzle-area--6 {
      left: 0.64922rem; }
    .Puzzle.Puzzle--34 .Puzzle-areas .Puzzle-area--7 {
      left: 0.74766rem; }

.Puzzle.Puzzle--34 .Puzzle-pieces {
  top: 0.17344rem;
  left: 0.08281rem; }
  .Puzzle.Puzzle--34 .Puzzle-pieces .Puzzle-piece {
    width: 0.08594rem;
    height: 0.06719rem;
    padding: 0;
    background: none;
    border: none; }
    .Puzzle.Puzzle--34 .Puzzle-pieces .Puzzle-piece:nth-of-type(3) {
      margin-right: 0.01562rem; }
    .Puzzle.Puzzle--34 .Puzzle-pieces .Puzzle-piece:nth-of-type(5), .Puzzle.Puzzle--34 .Puzzle-pieces .Puzzle-piece:nth-of-type(6) {
      margin-right: 0.03516rem; }
    .Puzzle.Puzzle--34 .Puzzle-pieces .Puzzle-piece::before {
      display: none; }
    .Puzzle.Puzzle--34 .Puzzle-pieces .Puzzle-piece .Puzzle-icon {
      width: 100%;
      height: 100%;
      width: 0.08594rem;
      height: 0.06719rem; }
      .Puzzle.Puzzle--34 .Puzzle-pieces .Puzzle-piece .Puzzle-icon--family {
        margin-top: 0.00234rem;
        margin-left: 0.00234rem;
        width: 0.08203rem;
        height: 0.06328rem; }
      .Puzzle.Puzzle--34 .Puzzle-pieces .Puzzle-piece .Puzzle-icon--needle {
        margin-top: 0.00391rem;
        margin-left: 0.00391rem;
        width: 0.08047rem;
        height: 0.06094rem; }
      .Puzzle.Puzzle--34 .Puzzle-pieces .Puzzle-piece .Puzzle-icon--bacteria {
        margin-top: 0.01016rem;
        margin-left: 0.00547rem;
        width: 0.07656rem;
        height: 0.05391rem; }
      .Puzzle.Puzzle--34 .Puzzle-pieces .Puzzle-piece .Puzzle-icon--virus {
        margin-top: 0.00625rem;
        margin-left: 0.00313rem;
        width: 0.08203rem;
        height: 0.0625rem; }
      .Puzzle.Puzzle--34 .Puzzle-pieces .Puzzle-piece .Puzzle-icon--dna {
        margin-top: -0.00625rem;
        margin-left: -0.00625rem;
        width: 0.10156rem;
        height: 0.08281rem;
        background-size: contain; }
      .Puzzle.Puzzle--34 .Puzzle-pieces .Puzzle-piece .Puzzle-icon--molecule {
        margin-top: 0.01016rem;
        margin-left: 0.00781rem;
        width: 0.07031rem;
        height: 0.04922rem; }
    .Puzzle.Puzzle--34 .Puzzle-pieces .Puzzle-piece .Puzzle-pieceName {
      margin-top: 0.07812rem; }

.Puzzle.Puzzle--34 .Puzzle-piece--placed {
  width: 0.0875rem;
  height: 0.07031rem;
  background: none;
  padding: 0.00391rem; }
  .Puzzle.Puzzle--34 .Puzzle-piece--placed::before {
    display: none; }
  .Puzzle.Puzzle--34 .Puzzle-piece--placed .Puzzle-icon {
    width: 0.08594rem;
    height: 0.06719rem; }
    .Puzzle.Puzzle--34 .Puzzle-piece--placed .Puzzle-icon--family {
      margin-top: 0.00234rem;
      margin-left: 0.00234rem;
      width: 0.08203rem;
      height: 0.06328rem; }
    .Puzzle.Puzzle--34 .Puzzle-piece--placed .Puzzle-icon--needle {
      margin-top: 0.00391rem;
      margin-left: 0.00391rem;
      width: 0.08047rem;
      height: 0.06094rem; }
    .Puzzle.Puzzle--34 .Puzzle-piece--placed .Puzzle-icon--bacteria {
      margin-top: 0.01016rem;
      margin-left: 0.00547rem;
      width: 0.07656rem;
      height: 0.05391rem; }
    .Puzzle.Puzzle--34 .Puzzle-piece--placed .Puzzle-icon--virus {
      margin-top: 0.00625rem;
      margin-left: 0.00313rem;
      width: 0.08203rem;
      height: 0.0625rem; }
    .Puzzle.Puzzle--34 .Puzzle-piece--placed .Puzzle-icon--dna {
      margin-top: -0.00625rem;
      margin-left: -0.00625rem;
      width: 0.10156rem;
      height: 0.08281rem;
      background-size: contain; }
    .Puzzle.Puzzle--34 .Puzzle-piece--placed .Puzzle-icon--molecule {
      margin-top: 0.00859rem;
      margin-left: 0.00781rem;
      width: 0.07031rem;
      height: 0.04922rem; }
  .Puzzle.Puzzle--34 .Puzzle-piece--placed .Puzzle-pieceName {
    display: none; }

.Puzzle.Puzzle--35 .Puzzle-areas {
  background-image: url(/static/media/puzzle-35-bg.8a7a724d.svg); }
  .Puzzle.Puzzle--35 .Puzzle-areas .Puzzle-area {
    top: 0.14297rem; }

.Puzzle.Puzzle--35 .Puzzle-pieces {
  top: 0.22656rem;
  left: 0.02344rem;
  width: 0.50781rem; }
  .Puzzle.Puzzle--35 .Puzzle-pieces .Puzzle-piece {
    width: 0.07031rem;
    height: 0.03125rem;
    margin-right: 0.05156rem;
    padding: 0; }
    .Puzzle.Puzzle--35 .Puzzle-pieces .Puzzle-piece::before {
      display: none; }

.Puzzle.Puzzle--35 .Puzzle-piece--placed {
  width: 0.07031rem;
  height: 0.03125rem;
  padding: 0; }
  .Puzzle.Puzzle--35 .Puzzle-piece--placed::before {
    display: none; }

.Puzzle.Puzzle--36 .Puzzle-areas {
  background-image: url(/static/media/puzzle-36-bg.c6751eff.svg); }
  .Puzzle.Puzzle--36 .Puzzle-areas .Puzzle-area {
    top: 0.19922rem; }

.Puzzle.Puzzle--36 .Puzzle-pieces {
  top: 0.28125rem;
  left: 0.02344rem;
  width: 0.60938rem; }
  .Puzzle.Puzzle--36 .Puzzle-pieces .Puzzle-piece {
    width: 0.07031rem;
    height: 0.03125rem;
    margin-right: 0.05156rem;
    padding: 0; }
    .Puzzle.Puzzle--36 .Puzzle-pieces .Puzzle-piece::before {
      display: none; }

.Puzzle.Puzzle--36 .Puzzle-piece--placed {
  width: 0.07031rem;
  height: 0.03125rem;
  padding: 0; }
  .Puzzle.Puzzle--36 .Puzzle-piece--placed::before {
    display: none; }

.Puzzle.Puzzle--37 .Puzzle-areas {
  background-image: url(/static/media/puzzle-37-bg.95322aec.svg); }
  .Puzzle.Puzzle--37 .Puzzle-areas .Puzzle-area {
    top: 0.25625rem; }

.Puzzle.Puzzle--37 .Puzzle-pieces {
  top: 0.33594rem;
  left: 0.02344rem;
  width: 0.60938rem; }
  .Puzzle.Puzzle--37 .Puzzle-pieces .Puzzle-piece {
    width: 0.07031rem;
    height: 0.03125rem;
    margin-right: 0.05156rem;
    padding: 0; }
    .Puzzle.Puzzle--37 .Puzzle-pieces .Puzzle-piece::before {
      display: none; }

.Puzzle.Puzzle--37 .Puzzle-piece--placed {
  width: 0.07031rem;
  height: 0.03125rem;
  padding: 0; }
  .Puzzle.Puzzle--37 .Puzzle-piece--placed::before {
    display: none; }

.Puzzle.Puzzle--38 .Puzzle-areas {
  background-image: url(/static/media/puzzle-38-bg.592cfd3f.svg); }
  .Puzzle.Puzzle--38 .Puzzle-areas .Puzzle-area {
    height: 0.03047rem;
    top: 0.30703rem; }
    .Puzzle.Puzzle--38 .Puzzle-areas .Puzzle-area--1 {
      width: 0.17969rem;
      left: 0.11953rem;
      background-image: url(/static/media/arrow-left.d6b7c89a.svg);
      background-size: contain;
      background-repeat: no-repeat; }
      .Puzzle.Puzzle--38 .Puzzle-areas .Puzzle-area--1 .Puzzle-piece--placed {
        height: 0.03047rem;
        background-image: url(/static/media/arrow-left-cyan.6b13c9c7.svg);
        background-size: cover;
        background-repeat: no-repeat; }
        .Puzzle.Puzzle--38 .Puzzle-areas .Puzzle-area--1 .Puzzle-piece--placed.Puzzle-piece--solved {
          box-shadow: none;
          -webkit-filter: drop-shadow(0 0 0.00547rem rgba(4, 253, 253, 0.63)) drop-shadow(0 0 0 transparent);
          filter: drop-shadow(0 0 0.00547rem rgba(4, 253, 253, 0.63)) drop-shadow(0 0 0 transparent); }
    .Puzzle.Puzzle--38 .Puzzle-areas .Puzzle-area--2 {
      width: 0.14453rem;
      left: 0.30078rem; }
    .Puzzle.Puzzle--38 .Puzzle-areas .Puzzle-area--3 {
      width: 0.10469rem;
      left: 0.44688rem; }
    .Puzzle.Puzzle--38 .Puzzle-areas .Puzzle-area--4 {
      width: 0.02734rem;
      left: 0.55391rem; }
      .Puzzle.Puzzle--38 .Puzzle-areas .Puzzle-area--4.Puzzle-area--placed {
        height: 0.06406rem; }
      .Puzzle.Puzzle--38 .Puzzle-areas .Puzzle-area--4 .Puzzle-piece--placed {
        position: absolute;
        top: 0.01875rem;
        left: -0.01797rem;
        margin: 0;
        width: 0.06406rem;
        height: 0.02734rem;
        font-size: 0.00859rem;
        /* IE 9 */
        -webkit-transform: rotate(90deg);
        /* Safari */
        transform: rotate(90deg); }
    .Puzzle.Puzzle--38 .Puzzle-areas .Puzzle-area--5 {
      width: 0.07109rem;
      left: 0.58359rem; }
    .Puzzle.Puzzle--38 .Puzzle-areas .Puzzle-area--6 {
      width: 0.14531rem;
      left: 0.65781rem; }
    .Puzzle.Puzzle--38 .Puzzle-areas .Puzzle-area--7 {
      width: 0.08828rem;
      left: 0.80547rem;
      background-image: url(/static/media/arrow-right.b884fb7a.svg);
      background-size: contain;
      background-repeat: no-repeat; }
      .Puzzle.Puzzle--38 .Puzzle-areas .Puzzle-area--7 .Puzzle-piece--placed {
        height: 0.03047rem;
        background-image: url(/static/media/arrow-right-cyan.045a38af.svg);
        background-size: cover;
        background-repeat: no-repeat; }
        .Puzzle.Puzzle--38 .Puzzle-areas .Puzzle-area--7 .Puzzle-piece--placed.Puzzle-piece--solved {
          box-shadow: none;
          -webkit-filter: drop-shadow(0 0 0.00547rem rgba(4, 253, 253, 0.63)) drop-shadow(0 0 0 transparent);
          filter: drop-shadow(0 0 0.00547rem rgba(4, 253, 253, 0.63)) drop-shadow(0 0 0 transparent); }

.Puzzle.Puzzle--38 .Puzzle-pieces {
  top: 0.36953rem;
  left: 0.02344rem;
  width: 0.60938rem; }
  .Puzzle.Puzzle--38 .Puzzle-pieces .Puzzle-piece {
    width: 0.09375rem;
    height: 0.03125rem;
    margin-right: 0.05156rem;
    padding: 0;
    font-size: 0.01094rem; }
    .Puzzle.Puzzle--38 .Puzzle-pieces .Puzzle-piece::before {
      display: none; }

.Puzzle.Puzzle--38 .Puzzle-piece--placed {
  width: 100%;
  height: 0.03125rem;
  padding: 0;
  font-size: 0.01016rem; }
  .Puzzle.Puzzle--38 .Puzzle-piece--placed::before {
    display: none; }

.Puzzle.Puzzle--39 .Puzzle-areas {
  background-image: url(/static/media/puzzle-39-bg.c4c62e2c.svg); }
  .Puzzle.Puzzle--39 .Puzzle-areas .Puzzle-area {
    height: 0.03906rem;
    top: 0.34141rem; }
    .Puzzle.Puzzle--39 .Puzzle-areas .Puzzle-area span {
      display: none; }
    .Puzzle.Puzzle--39 .Puzzle-areas .Puzzle-area--1 {
      width: 0.08594rem;
      left: 0.12891rem; }
    .Puzzle.Puzzle--39 .Puzzle-areas .Puzzle-area--2 {
      width: 0.08516rem;
      left: 0.21641rem; }
    .Puzzle.Puzzle--39 .Puzzle-areas .Puzzle-area--3 {
      width: 0.14609rem;
      left: 0.30312rem; }
    .Puzzle.Puzzle--39 .Puzzle-areas .Puzzle-area--4 {
      width: 0.10156rem;
      left: 0.45078rem; }
    .Puzzle.Puzzle--39 .Puzzle-areas .Puzzle-area--5 {
      width: 0.07578rem;
      left: 0.58281rem; }
    .Puzzle.Puzzle--39 .Puzzle-areas .Puzzle-area--6 {
      width: 0.14609rem;
      left: 0.66016rem; }
    .Puzzle.Puzzle--39 .Puzzle-areas .Puzzle-area--7 {
      width: 0.07969rem;
      left: 0.80781rem; }

.Puzzle.Puzzle--39 .Puzzle-pieces {
  top: unset;
  bottom: 0;
  left: 0.03516rem; }
  .Puzzle.Puzzle--39 .Puzzle-pieces .Puzzle-piece {
    border: none;
    background: none;
    margin-right: 0.00781rem; }
    .Puzzle.Puzzle--39 .Puzzle-pieces .Puzzle-piece::before {
      display: none; }
    .Puzzle.Puzzle--39 .Puzzle-pieces .Puzzle-piece .Puzzle-icon {
      width: 0.0625rem;
      height: 0.03125rem; }
      .Puzzle.Puzzle--39 .Puzzle-pieces .Puzzle-piece .Puzzle-icon--infrared-waves {
        margin-top: 0.00625rem;
        height: 0.01953rem; }

.Puzzle.Puzzle--39 .Puzzle-piece--placed {
  width: 100%;
  border: none;
  background: none;
  padding: 0;
  height: 0.03906rem; }
  .Puzzle.Puzzle--39 .Puzzle-piece--placed::before {
    display: none; }
  .Puzzle.Puzzle--39 .Puzzle-piece--placed .Puzzle-icon {
    background-position: center;
    width: 100%;
    height: 0.02969rem;
    margin-top: 0.00391rem; }
    .Puzzle.Puzzle--39 .Puzzle-piece--placed .Puzzle-icon--radio-waves-radio {
      margin-top: 0.00234rem;
      height: 0.03281rem; }
    .Puzzle.Puzzle--39 .Puzzle-piece--placed .Puzzle-icon--infrared-waves {
      margin-top: 0.01172rem;
      height: 0.01562rem; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.Minigame {
  position: absolute;
  left: -0.22656rem;
  right: -0.22656rem;
  top: -0.02344rem;
  bottom: -0.01875rem;
  padding: 0.03125rem 0.03906rem;
  z-index: 10;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/
  background-image: url(/static/media/background.jpg);
  background-size: cover;
  background-position: top left; }
  .Minigame::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(1, 27, 28, 0.8); }

.Minigame-close {
  position: absolute;
  top: 0.02344rem;
  right: 0.03516rem;
  width: 0.02344rem;
  height: 0.02344rem;
  background-image: url(/static/media/icon-x.be145b6d.svg);
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/ }
  .Minigame-close:hover {
    /* IE 9 */
    -webkit-transform: scale(1.1);
    /* Safari */
    transform: scale(1.1); }

.Minigame-back {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 0.01562rem;
  height: 0.01562rem;
  background-image: url(/static/media/icon-back.c44df0e3.svg);
  background-size: auto 0.01406rem;
  background-position: center top;
  background-repeat: no-repeat;
  margin-right: 0.00391rem;
  cursor: pointer;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/ }

.Minigame-part {
  position: relative;
  display: inline-block;
  vertical-align: top;
  font-family: "Fivo Sans";
  font-size: 0.01406rem;
  line-height: 0.01719rem;
  color: #04FDFD;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/ }

.Minigame-nextPartBtn,
.Minigame-exitMinigameBtn {
  position: absolute;
  bottom: 0.03125rem;
  right: 0.02344rem;
  width: 0.10938rem;
  height: 0.05078rem;
  font-family: "Fivo Sans";
  font-size: 0.01562rem;
  line-height: 0.05078rem;
  text-align: left;
  text-transform: uppercase;
  padding-left: 0.02344rem;
  background-image: url(/static/media/next-button.74069835.svg);
  background-size: contain;
  background-position: bottom right;
  background-repeat: no-repeat;
  cursor: pointer;
  opacity: 0;
  /* Fx 5+ */
  /* Opera 12+ */
  -webkit-animation: fadeIn 0.5s linear 0s 1 forwards;
  animation: fadeIn 0.5s linear 0s 1 forwards;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/ }

.Minigame.Minigame--minigame-3 .Minigame-nextPartBtn,
.Minigame.Minigame--minigame-3 .Minigame-exitMinigameBtn {
  bottom: 0.03906rem;
  right: 0.04297rem; }

.Minigame.Minigame--minigame-3 .Puzzle-answerBtn {
  bottom: 0.01094rem;
  right: 0.01953rem; }
  .Minigame.Minigame--minigame-3 .Puzzle-answerBtn--teacher {
    right: 0.11719rem; }

.Minigame.Minigame--minigame-4 .Minigame-nextPartBtn,
.Minigame.Minigame--minigame-4 .Minigame-exitMinigameBtn {
  bottom: 0.025rem;
  right: 0.02734rem; }

.Minigame.Minigame--minigame-4 .Puzzle-answerBtn {
  bottom: -0.03438rem;
  right: 0rem; }
  .Minigame.Minigame--minigame-4 .Puzzle-answerBtn--teacher {
    right: 0.11719rem; }

.Minigame.Minigame--minigame-4 .Puzzle--17 .Puzzle-answerBtn {
  bottom: 0.03594rem; }

.Minigame.Minigame--minigame-4 .Puzzle--18 .Minigame-nextPartBtn,
.Minigame.Minigame--minigame-4 .Puzzle--18 .Minigame-exitMinigameBtn {
  bottom: 0.025rem; }

.Minigame.Minigame--minigame-4 .Puzzle--18 .Puzzle-answerBtn {
  bottom: -0.00781rem; }

.Minigame.Minigame--minigame-4 .Puzzle--19 .Puzzle-answerBtn {
  bottom: -0.01719rem; }

.Minigame.Minigame--minigame-5 .Puzzle-answerBtn {
  bottom: 0.03125rem;
  right: 0rem; }
  .Minigame.Minigame--minigame-5 .Puzzle-answerBtn--teacher {
    right: 0.11719rem; }

.Minigame.Minigame--minigame-6 .Minigame-nextPartBtn,
.Minigame.Minigame--minigame-6 .Minigame-exitMinigameBtn {
  bottom: 0.01562rem; }

.Minigame.Minigame--minigame-6 .Puzzle-answerBtn {
  bottom: -0.06328rem;
  right: 0rem; }
  .Minigame.Minigame--minigame-6 .Puzzle-answerBtn--teacher {
    right: 0.10938rem; }

.Minigame.Minigame--minigame-6 .Puzzle--32 .Puzzle-answerBtn {
  bottom: -0.08516rem; }

.Minigame.Minigame--minigame-7 .Minigame-nextPartBtn,
.Minigame.Minigame--minigame-7 .Minigame-exitMinigameBtn {
  bottom: 0.03906rem;
  right: 0.05469rem; }

.Minigame.Minigame--minigame-7 .Puzzle-answerBtn {
  bottom: 0.01172rem;
  right: 0.02344rem; }
  .Minigame.Minigame--minigame-7 .Puzzle-answerBtn--teacher {
    right: 0.13672rem; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.InvestigationIntro {
  position: relative;
  padding: 0.02344rem; }
  .InvestigationIntro .Button {
    margin-right: 0.01562rem;
    font-size: 0.01406rem; }
  .InvestigationIntro--minigameAndInvestigation {
    padding-top: 0;
    margin-top: -0.00781rem; }

.InvestigationIntro-title {
  font-size: 0.01562rem;
  font-family: "Fivo Sans";
  margin-bottom: 0.00781rem; }

.InvestigationIntro-text {
  margin-bottom: 0.02344rem; }
  .InvestigationIntro-text img {
    width: 95%; }
  .InvestigationIntro-text a {
    color: white; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.WriteSubmission {
  position: relative;
  width: 0.83828rem;
  height: 0.23438rem;
  background-image: linear-gradient(#015051, #002F2C);
  border-top: 0.00156rem solid #04FDFD;
  border-bottom: 0.00156rem solid #04FDFD;
  padding: 0.01562rem 0.03516rem;
  margin: 0.01562rem 0.03125rem 0 0.05156rem; }

.WriteSubmission-header {
  position: relative;
  width: 0.60156rem;
  height: 0.03281rem;
  margin-bottom: 0.01094rem; }

.WriteSubmission-title {
  display: inline-block;
  vertical-align: top;
  width: 0.44141rem;
  height: 0.03281rem;
  line-height: 0.03281rem;
  font-size: 0.01406rem;
  font-weight: 500;
  color: #04FDFD; }

.WriteSubmission-input {
  width: 100%;
  height: 100%;
  font-size: 0.01406rem;
  font-weight: 500;
  color: white;
  line-height: 0.02734rem;
  background: rgba(4, 253, 253, 0.24);
  padding: 0 0.0125rem;
  cursor: text;
  /* Chrome/Opera/Safari */
  /* Firefox 18- */
  /* Firefox 19+ */
  /* IE 10+ */ }
  .WriteSubmission-input::-webkit-input-placeholder {
    color: #04FDFD !important; }
  .WriteSubmission-input:-moz-placeholder {
    color: #04FDFD !important; }
  .WriteSubmission-input::-moz-placeholder {
    color: #04FDFD !important; }
  .WriteSubmission-input:-ms-input-placeholder {
    color: #04FDFD !important; }

.WriteSubmission-text {
  height: 0.16016rem;
  width: 0.60156rem; }

.WriteSubmission-textarea {
  width: 100%;
  height: 100%;
  font-size: 0.0125rem;
  color: white;
  padding: 0.0125rem;
  background: rgba(4, 253, 253, 0.24);
  resize: none;
  cursor: text;
  overflow-y: auto;
  /* Chrome/Opera/Safari */
  /* Firefox 18- */
  /* Firefox 19+ */
  /* IE 10+ */ }
  .WriteSubmission-textarea::-webkit-input-placeholder {
    color: #04FDFD !important; }
  .WriteSubmission-textarea:-moz-placeholder {
    color: #04FDFD !important; }
  .WriteSubmission-textarea::-moz-placeholder {
    color: #04FDFD !important; }
  .WriteSubmission-textarea:-ms-input-placeholder {
    color: #04FDFD !important; }
  .WriteSubmission-textarea::-webkit-scrollbar {
    width: 0.00781rem;
    height: 100%; }
  .WriteSubmission-textarea::-webkit-scrollbar-track {
    background-color: rgba(4, 253, 253, 0.2);
    border-radius: 0.00781rem; }
  .WriteSubmission-textarea::-webkit-scrollbar-thumb {
    border-radius: 0.00781rem;
    background: #04FDFD;
    width: 100%;
    height: 0.01562rem;
    border: none; }
  .WriteSubmission-textarea::-webkit-scrollbar-thumb:window-inactive {
    background-color: transparent;
    border: 1px solid #04FDFD; }

.WriteSubmission-actions {
  position: absolute;
  top: 0.06016rem;
  right: 0.03516rem; }

.WriteSubmission-saveBtn,
.WriteSubmission-submitBtn {
  width: 0.125rem;
  height: 0.04688rem;
  margin-bottom: 0.0125rem; }
  .WriteSubmission-saveBtn .Button,
  .WriteSubmission-submitBtn .Button {
    width: 100%;
    height: 100%;
    padding-top: 0.01562rem; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.GroupSubmissions {
  position: relative;
  height: 0.26953rem;
  padding: 0 0.03125rem 0 0.03125rem; }

.GroupSubmissions-prevBtn,
.GroupSubmissions-nextBtn {
  position: absolute;
  top: 0.12109rem;
  width: 0.03125rem;
  height: 0.03125rem;
  background-size: auto 0.01875rem;
  background-position: center top;
  background-repeat: no-repeat;
  cursor: pointer; }

.GroupSubmissions-prevBtn {
  left: 0.01953rem;
  background-image: url(/static/media/icon-prev.9b73d107.svg); }

.GroupSubmissions-nextBtn {
  right: 0.03203rem;
  background-image: url(/static/media/icon-next.72f86f41.svg); }

.GroupSubmissions-group {
  height: 100%;
  width: 0.83828rem;
  background-image: url(/static/media/corner-triangle.5e00af4b.svg), linear-gradient(#015051, #002F2C);
  background-size: 0.0625rem, 100%;
  background-repeat: no-repeat;
  background-position: top right, center center;
  border-top: 0.00156rem solid #04FDFD;
  border-bottom: 0.00156rem solid #04FDFD;
  padding: 0.02344rem 0.03125rem;
  margin-left: 0.02031rem; }

.GroupSubmissions-groupNumber {
  position: absolute;
  bottom: 0.00781rem;
  right: 0.07344rem;
  font-family: "Fivo Sans";
  font-size: 0.0125rem;
  font-weight: 600;
  color: #04FDFD; }

.GroupSubmissions-groupImage {
  text-align: left;
  height: 100%; }
  .GroupSubmissions-groupImage img {
    height: 100%; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.Clues {
  position: relative;
  width: 0.90078rem;
  height: 0.26953rem;
  padding: 0 0.03125rem 0 0.03125rem;
  margin-left: 0.02031rem; }

.Clues-prevBtn,
.Clues-nextBtn {
  position: absolute;
  top: 0.11719rem;
  width: 0.03125rem;
  height: 0.04688rem;
  background-size: 0.01172rem 0.01875rem;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #014647;
  border-top: 0.00156rem solid #04FDFD;
  border-bottom: 0.00156rem solid #04FDFD;
  cursor: pointer; }

.Clues-prevBtn {
  left: 0;
  background-image: url(/static/media/icon-prev.9b73d107.svg); }

.Clues-nextBtn {
  right: 0;
  background-image: url(/static/media/icon-next.72f86f41.svg); }

.Clues-clue {
  height: 100%;
  background-image: url(/static/media/corner-triangle.5e00af4b.svg), linear-gradient(#015051, #002F2C);
  background-size: 0.0625rem, 100%;
  background-repeat: no-repeat;
  background-position: top right, center center;
  border-top: 0.00156rem solid #04FDFD;
  border-bottom: 0.00156rem solid #04FDFD;
  padding: 0.02344rem 0.03125rem; }

.Clues-clueNumber {
  position: absolute;
  bottom: 0.00781rem;
  right: 0.04141rem;
  font-family: "Fivo Sans";
  font-size: 0.0125rem;
  font-weight: 600;
  color: #04FDFD; }

.Clues-clueImage {
  text-align: left;
  height: 100%; }
  .Clues-clueImage img {
    height: 100%; }

.CluePopup {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(1, 27, 28, 0.8);
  padding: 0.03906rem;
  z-index: 11;
  display: flex;
  justify-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-flex-align: center;
  align-items: center; }

.CluePopup-clueImage img {
  max-height: 100%;
  max-width: 100%; }

.Clues-clueTitle {
  font-size: 0.01562rem;
  font-family: 'Open Sans';
  font-weight: 600;
  margin-top: -0.01953rem;
  text-transform: uppercase; }

.Clues-clueTextWrapper {
  position: relative;
  width: 0.74687rem;
  height: 0.23984rem;
  background-image: url(/static/media/striped-background.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  margin-left: -0.00781rem;
  margin-top: -0.00937rem;
  padding: 0.00156rem; }
  .Clues-clueTextWrapper table {
    color: #04FDFD;
    border: 1px solid #04FDFD;
    border-collapse: collapse;
    margin-top: 0.00781rem; }
    .Clues-clueTextWrapper table th, .Clues-clueTextWrapper table td {
      width: 0.17188rem;
      text-align: left;
      padding: 0.00391rem;
      padding-left: 0.01172rem;
      border: 1px solid #04FDFD; }
    .Clues-clueTextWrapper table td {
      font-weight: 400; }

.Clues-clueText {
  position: relative;
  width: 99.9%;
  height: 99%;
  font-size: 0.0125rem;
  font-family: 'Open Sans';
  font-weight: 600;
  padding: 0.0125rem 0.01719rem;
  overflow: auto; }
  .Clues-clueText::-webkit-scrollbar {
    width: 0.0125rem;
    height: 100%; }
  .Clues-clueText::-webkit-scrollbar-track {
    background-color: #0D5151;
    border-radius: 0; }
  .Clues-clueText::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: #00CBCB;
    width: 0.0125rem;
    height: 0.10859rem;
    border: 1px solid #04FDFD; }
  .Clues-clueText::-webkit-scrollbar-thumb:window-inactive {
    background-color: transparent;
    border: 1px solid #00CBCB; }
  .Clues-clueText p {
    margin: 0; }
  .Clues-clueText p:first-of-type {
    font-family: 'Fivo Sans';
    color: #04FDFD;
    text-transform: uppercase;
    margin-bottom: 0.00547rem; }

.Clues-clueSound:first-child {
  margin-top: 0.02344rem; }

.Clues-clueSound .Clues-clueSoundTitle {
  width: 0.23438rem;
  position: relative;
  text-align: right;
  font-size: 0.01953rem;
  font-family: 'Fivo Sans';
  font-weight: 600;
  color: #04FDFD; }

.Clues-clueSound .Clues-clueSoundTitle, .Clues-clueSound .Audio {
  display: inline-block; }

.InvestigationTutorial--investigation-2 .Clues-clue--2 .Clues-clueImage,
.InvestigationTutorial--investigation-2 .Clues-clue--3 .Clues-clueImage,
.Investigation--investigation-2 .Clues-clue--2 .Clues-clueImage,
.Investigation--investigation-2 .Clues-clue--3 .Clues-clueImage {
  height: 0.23125rem;
  width: 0.74687rem; }
  .InvestigationTutorial--investigation-2 .Clues-clue--2 .Clues-clueImage img,
  .InvestigationTutorial--investigation-2 .Clues-clue--3 .Clues-clueImage img,
  .Investigation--investigation-2 .Clues-clue--2 .Clues-clueImage img,
  .Investigation--investigation-2 .Clues-clue--3 .Clues-clueImage img {
    width: 100%; }

.InvestigationTutorial--investigation-2 .Clues-clue--5 .Clues-clueImage,
.Investigation--investigation-2 .Clues-clue--5 .Clues-clueImage {
  height: 0.25156rem;
  margin-top: -0.01562rem; }

.InvestigationTutorial--investigation-2 .Clues-clue--7 .Clues-clueImage,
.Investigation--investigation-2 .Clues-clue--7 .Clues-clueImage {
  width: 0.74687rem; }
  .InvestigationTutorial--investigation-2 .Clues-clue--7 .Clues-clueImage img,
  .Investigation--investigation-2 .Clues-clue--7 .Clues-clueImage img {
    width: 100%; }

.InvestigationTutorial--investigation-2 .Clues-clueTitle::after,
.Investigation--investigation-2 .Clues-clueTitle::after {
  content: " [RECOVERED]";
  font-size: 0.01562rem;
  font-family: 'Open Sans';
  font-weight: 600;
  color: #04FDFD; }

.InvestigationTutorial--investigation-3 .Clues-clue--1,
.Investigation--investigation-3 .Clues-clue--1 {
  border: none;
  background: linear-gradient(#015051, #002F2C);
  background-size: 100%;
  padding: 0;
  position: relative;
  overflow: hidden; }
  .InvestigationTutorial--investigation-3 .Clues-clue--1 .Clues-clueImage,
  .Investigation--investigation-3 .Clues-clue--1 .Clues-clueImage {
    margin-left: -0.00859rem; }
    .InvestigationTutorial--investigation-3 .Clues-clue--1 .Clues-clueImage img,
    .Investigation--investigation-3 .Clues-clue--1 .Clues-clueImage img {
      width: 0.85rem;
      height: 0.27344rem; }
  .InvestigationTutorial--investigation-3 .Clues-clue--1:after,
  .Investigation--investigation-3 .Clues-clue--1:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0.0625rem;
    height: 0.0625rem;
    background-image: url(/static/media/corner-triangle.5e00af4b.svg);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top right; }

.InvestigationTutorial--investigation-4 .Clues-clue--1 .Clues-clueTitle,
.Investigation--investigation-4 .Clues-clue--1 .Clues-clueTitle {
  text-transform: none;
  font-weight: 600;
  margin-top: 0.00391rem; }

.InvestigationTutorial--investigation-4 .Clues-clue--1 .Clues-clueText,
.Investigation--investigation-4 .Clues-clue--1 .Clues-clueText {
  padding-left: 0; }

.InvestigationTutorial--investigation-4 .Clues-clue--1 .Clues-clueNumber,
.Investigation--investigation-4 .Clues-clue--1 .Clues-clueNumber {
  display: none; }

.InvestigationTutorial--investigation-5 .Clues-clue--1,
.Investigation--investigation-5 .Clues-clue--1 {
  position: relative;
  padding-top: 0.03906rem; }
  .InvestigationTutorial--investigation-5 .Clues-clue--1::before,
  .Investigation--investigation-5 .Clues-clue--1::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 203, 203, 0.2); }
  .InvestigationTutorial--investigation-5 .Clues-clue--1 .Clues-clueTitle::after,
  .Investigation--investigation-5 .Clues-clue--1 .Clues-clueTitle::after {
    content: " [RECOVERED]";
    font-size: 0.01562rem;
    font-family: 'Open Sans';
    font-weight: 600;
    color: #04FDFD; }
  .InvestigationTutorial--investigation-5 .Clues-clue--1 .Clues-clueNumber,
  .Investigation--investigation-5 .Clues-clue--1 .Clues-clueNumber {
    right: 0.01016rem;
    bottom: 0.00625rem; }

.InvestigationTutorial--investigation-5 .Clues-clue--2,
.Investigation--investigation-5 .Clues-clue--2 {
  background: linear-gradient(#015051, #002F2C);
  background-size: 100%;
  padding: 0;
  position: relative;
  overflow: hidden; }
  .InvestigationTutorial--investigation-5 .Clues-clue--2 .Clues-clueImage,
  .Investigation--investigation-5 .Clues-clue--2 .Clues-clueImage {
    margin-left: -0.00859rem;
    margin-top: -0.00156rem; }
    .InvestigationTutorial--investigation-5 .Clues-clue--2 .Clues-clueImage img,
    .Investigation--investigation-5 .Clues-clue--2 .Clues-clueImage img {
      width: 0.85rem;
      height: 0.27344rem; }
  .InvestigationTutorial--investigation-5 .Clues-clue--2:after,
  .Investigation--investigation-5 .Clues-clue--2:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0.0625rem;
    height: 0.0625rem;
    background-image: url(/static/media/corner-triangle.5e00af4b.svg);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top right; }
  .InvestigationTutorial--investigation-5 .Clues-clue--2 .Clues-clueNumber,
  .Investigation--investigation-5 .Clues-clue--2 .Clues-clueNumber {
    right: 0.01016rem;
    bottom: 0.00625rem; }

.InvestigationTutorial--investigation-5 .Clues-clue--3,
.Investigation--investigation-5 .Clues-clue--3 {
  color: #04FDFD; }

.InvestigationTutorial--investigation-6 .Clues-clue--1:before,
.Investigation--investigation-6 .Clues-clue--1:before {
  content: "";
  position: absolute;
  width: 0.74375rem;
  height: 0.23672rem;
  background-image: url(/static/media/striped-background.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  margin-left: -0.00781rem;
  margin-top: -0.00937rem;
  padding: 0.00156rem; }

.InvestigationTutorial--investigation-6 .Clues-clue--1 .Clues-clueImage,
.Investigation--investigation-6 .Clues-clue--1 .Clues-clueImage {
  width: 0.73672rem;
  height: 0.23438rem;
  position: relative;
  margin-top: -0.00781rem;
  padding-top: 0.01562rem;
  padding-bottom: 0.01953rem;
  padding-left: 0.01172rem;
  overflow: auto; }
  .InvestigationTutorial--investigation-6 .Clues-clue--1 .Clues-clueImage::-webkit-scrollbar,
  .Investigation--investigation-6 .Clues-clue--1 .Clues-clueImage::-webkit-scrollbar {
    width: 0.0125rem;
    height: 100%; }
  .InvestigationTutorial--investigation-6 .Clues-clue--1 .Clues-clueImage::-webkit-scrollbar-track,
  .Investigation--investigation-6 .Clues-clue--1 .Clues-clueImage::-webkit-scrollbar-track {
    background-color: #0D5151;
    border-radius: 0; }
  .InvestigationTutorial--investigation-6 .Clues-clue--1 .Clues-clueImage::-webkit-scrollbar-thumb,
  .Investigation--investigation-6 .Clues-clue--1 .Clues-clueImage::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: #00CBCB;
    width: 0.0125rem;
    height: 0.10859rem;
    border: 1px solid #04FDFD; }
  .InvestigationTutorial--investigation-6 .Clues-clue--1 .Clues-clueImage::-webkit-scrollbar-thumb:window-inactive,
  .Investigation--investigation-6 .Clues-clue--1 .Clues-clueImage::-webkit-scrollbar-thumb:window-inactive {
    background-color: transparent;
    border: 1px solid #00CBCB; }
  .InvestigationTutorial--investigation-6 .Clues-clue--1 .Clues-clueImage img,
  .Investigation--investigation-6 .Clues-clue--1 .Clues-clueImage img {
    position: relative;
    width: 0.51406rem;
    height: auto; }

.InvestigationTutorial--investigation-6 .Clues-clue--2 table,
.Investigation--investigation-6 .Clues-clue--2 table {
  margin-left: 0.01172rem;
  margin-top: 0.01172rem; }
  .InvestigationTutorial--investigation-6 .Clues-clue--2 table th:nth-of-type(2), .InvestigationTutorial--investigation-6 .Clues-clue--2 table td:nth-of-type(2),
  .Investigation--investigation-6 .Clues-clue--2 table th:nth-of-type(2),
  .Investigation--investigation-6 .Clues-clue--2 table td:nth-of-type(2) {
    margin-left: 0;
    width: 0.32812rem;
    text-align: center; }

.InvestigationTutorial--investigation-6 .Clues-clue--3 .Clues-clueText div,
.Investigation--investigation-6 .Clues-clue--3 .Clues-clueText div {
  display: inline-block;
  width: 0.26562rem; }

.InvestigationTutorial--investigation-6 .Clues-clue--3 table,
.Investigation--investigation-6 .Clues-clue--3 table {
  margin-left: 0.01172rem;
  margin-top: 0.01172rem; }
  .InvestigationTutorial--investigation-6 .Clues-clue--3 table th, .InvestigationTutorial--investigation-6 .Clues-clue--3 table td,
  .Investigation--investigation-6 .Clues-clue--3 table th,
  .Investigation--investigation-6 .Clues-clue--3 table td {
    width: 0.22656rem; }
  .InvestigationTutorial--investigation-6 .Clues-clue--3 table td,
  .Investigation--investigation-6 .Clues-clue--3 table td {
    border: none;
    padding-top: 0;
    padding-bottom: 0;
    margin: 0; }
  .InvestigationTutorial--investigation-6 .Clues-clue--3 table tr:nth-of-type(2), .InvestigationTutorial--investigation-6 .Clues-clue--3 table tr:nth-of-type(7),
  .Investigation--investigation-6 .Clues-clue--3 table tr:nth-of-type(2),
  .Investigation--investigation-6 .Clues-clue--3 table tr:nth-of-type(7) {
    height: 0.04141rem; }

.InvestigationTutorial--investigation-6 .CluePopup .CluePopup-clueImage,
.Investigation--investigation-6 .CluePopup .CluePopup-clueImage {
  background-color: #0D5151;
  padding: 0.01016rem; }
  .InvestigationTutorial--investigation-6 .CluePopup .CluePopup-clueImage img,
  .Investigation--investigation-6 .CluePopup .CluePopup-clueImage img {
    width: 0.49219rem; }

.InvestigationTutorial--investigation-7 .Clues-clue--1:before,
.Investigation--investigation-7 .Clues-clue--1:before {
  content: "";
  position: absolute;
  width: 0.74375rem;
  height: 0.23672rem;
  background-image: url(/static/media/striped-background.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  margin-left: -0.00781rem;
  margin-top: -0.00937rem;
  padding: 0.00156rem; }

.InvestigationTutorial--investigation-7 .Clues-clue--1 .Clues-clueImage,
.Investigation--investigation-7 .Clues-clue--1 .Clues-clueImage {
  width: 0.73672rem;
  height: 0.23438rem;
  position: relative;
  margin-top: -0.00781rem;
  padding-top: 0.00781rem;
  padding-bottom: 0.01172rem;
  padding-left: 0.00391rem;
  overflow: auto; }
  .InvestigationTutorial--investigation-7 .Clues-clue--1 .Clues-clueImage::-webkit-scrollbar,
  .Investigation--investigation-7 .Clues-clue--1 .Clues-clueImage::-webkit-scrollbar {
    width: 0.0125rem;
    height: 100%; }
  .InvestigationTutorial--investigation-7 .Clues-clue--1 .Clues-clueImage::-webkit-scrollbar-track,
  .Investigation--investigation-7 .Clues-clue--1 .Clues-clueImage::-webkit-scrollbar-track {
    background-color: #0D5151;
    border-radius: 0; }
  .InvestigationTutorial--investigation-7 .Clues-clue--1 .Clues-clueImage::-webkit-scrollbar-thumb,
  .Investigation--investigation-7 .Clues-clue--1 .Clues-clueImage::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: #00CBCB;
    width: 0.0125rem;
    height: 0.10859rem;
    border: 1px solid #04FDFD; }
  .InvestigationTutorial--investigation-7 .Clues-clue--1 .Clues-clueImage::-webkit-scrollbar-thumb:window-inactive,
  .Investigation--investigation-7 .Clues-clue--1 .Clues-clueImage::-webkit-scrollbar-thumb:window-inactive {
    background-color: transparent;
    border: 1px solid #00CBCB; }
  .InvestigationTutorial--investigation-7 .Clues-clue--1 .Clues-clueImage img,
  .Investigation--investigation-7 .Clues-clue--1 .Clues-clueImage img {
    margin-top: 0.00781rem;
    position: relative;
    width: 0.51406rem;
    height: auto; }

.InvestigationTutorial--investigation-7 .CluePopup .CluePopup-clueImage,
.Investigation--investigation-7 .CluePopup .CluePopup-clueImage {
  background-color: #0D5151;
  padding: 0.01016rem; }
  .InvestigationTutorial--investigation-7 .CluePopup .CluePopup-clueImage img,
  .Investigation--investigation-7 .CluePopup .CluePopup-clueImage img {
    width: 0.69531rem; }

.InvestigationTutorial--investigation-8 .Clues-clue--1:before,
.InvestigationTutorial--investigation-8 .Clues-clue--2:before,
.InvestigationTutorial--investigation-8 .Clues-clue--3:before,
.InvestigationTutorial--investigation-8 .Clues-clue--4:before,
.Investigation--investigation-8 .Clues-clue--1:before,
.Investigation--investigation-8 .Clues-clue--2:before,
.Investigation--investigation-8 .Clues-clue--3:before,
.Investigation--investigation-8 .Clues-clue--4:before {
  content: "";
  position: absolute;
  width: 0.74375rem;
  height: 0.23672rem;
  background-image: url(/static/media/striped-background.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  margin-left: -0.00781rem;
  margin-top: -0.00937rem;
  padding: 0.00156rem; }

.InvestigationTutorial--investigation-8 .Clues-clue--1 .Clues-clueImage,
.InvestigationTutorial--investigation-8 .Clues-clue--2 .Clues-clueImage,
.InvestigationTutorial--investigation-8 .Clues-clue--3 .Clues-clueImage,
.InvestigationTutorial--investigation-8 .Clues-clue--4 .Clues-clueImage,
.Investigation--investigation-8 .Clues-clue--1 .Clues-clueImage,
.Investigation--investigation-8 .Clues-clue--2 .Clues-clueImage,
.Investigation--investigation-8 .Clues-clue--3 .Clues-clueImage,
.Investigation--investigation-8 .Clues-clue--4 .Clues-clueImage {
  width: 0.73672rem;
  height: 0.23438rem;
  position: relative;
  margin-top: -0.00781rem;
  padding-top: 0.00781rem;
  padding-bottom: 0.01172rem;
  padding-left: 0.00391rem;
  padding-right: 0.00391rem;
  overflow: auto; }
  .InvestigationTutorial--investigation-8 .Clues-clue--1 .Clues-clueImage::-webkit-scrollbar,
  .InvestigationTutorial--investigation-8 .Clues-clue--2 .Clues-clueImage::-webkit-scrollbar,
  .InvestigationTutorial--investigation-8 .Clues-clue--3 .Clues-clueImage::-webkit-scrollbar,
  .InvestigationTutorial--investigation-8 .Clues-clue--4 .Clues-clueImage::-webkit-scrollbar,
  .Investigation--investigation-8 .Clues-clue--1 .Clues-clueImage::-webkit-scrollbar,
  .Investigation--investigation-8 .Clues-clue--2 .Clues-clueImage::-webkit-scrollbar,
  .Investigation--investigation-8 .Clues-clue--3 .Clues-clueImage::-webkit-scrollbar,
  .Investigation--investigation-8 .Clues-clue--4 .Clues-clueImage::-webkit-scrollbar {
    width: 0.0125rem;
    height: 100%; }
  .InvestigationTutorial--investigation-8 .Clues-clue--1 .Clues-clueImage::-webkit-scrollbar-track,
  .InvestigationTutorial--investigation-8 .Clues-clue--2 .Clues-clueImage::-webkit-scrollbar-track,
  .InvestigationTutorial--investigation-8 .Clues-clue--3 .Clues-clueImage::-webkit-scrollbar-track,
  .InvestigationTutorial--investigation-8 .Clues-clue--4 .Clues-clueImage::-webkit-scrollbar-track,
  .Investigation--investigation-8 .Clues-clue--1 .Clues-clueImage::-webkit-scrollbar-track,
  .Investigation--investigation-8 .Clues-clue--2 .Clues-clueImage::-webkit-scrollbar-track,
  .Investigation--investigation-8 .Clues-clue--3 .Clues-clueImage::-webkit-scrollbar-track,
  .Investigation--investigation-8 .Clues-clue--4 .Clues-clueImage::-webkit-scrollbar-track {
    background-color: #0D5151;
    border-radius: 0; }
  .InvestigationTutorial--investigation-8 .Clues-clue--1 .Clues-clueImage::-webkit-scrollbar-thumb,
  .InvestigationTutorial--investigation-8 .Clues-clue--2 .Clues-clueImage::-webkit-scrollbar-thumb,
  .InvestigationTutorial--investigation-8 .Clues-clue--3 .Clues-clueImage::-webkit-scrollbar-thumb,
  .InvestigationTutorial--investigation-8 .Clues-clue--4 .Clues-clueImage::-webkit-scrollbar-thumb,
  .Investigation--investigation-8 .Clues-clue--1 .Clues-clueImage::-webkit-scrollbar-thumb,
  .Investigation--investigation-8 .Clues-clue--2 .Clues-clueImage::-webkit-scrollbar-thumb,
  .Investigation--investigation-8 .Clues-clue--3 .Clues-clueImage::-webkit-scrollbar-thumb,
  .Investigation--investigation-8 .Clues-clue--4 .Clues-clueImage::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: #00CBCB;
    width: 0.0125rem;
    height: 0.10859rem;
    border: 1px solid #04FDFD; }
  .InvestigationTutorial--investigation-8 .Clues-clue--1 .Clues-clueImage::-webkit-scrollbar-thumb:window-inactive,
  .InvestigationTutorial--investigation-8 .Clues-clue--2 .Clues-clueImage::-webkit-scrollbar-thumb:window-inactive,
  .InvestigationTutorial--investigation-8 .Clues-clue--3 .Clues-clueImage::-webkit-scrollbar-thumb:window-inactive,
  .InvestigationTutorial--investigation-8 .Clues-clue--4 .Clues-clueImage::-webkit-scrollbar-thumb:window-inactive,
  .Investigation--investigation-8 .Clues-clue--1 .Clues-clueImage::-webkit-scrollbar-thumb:window-inactive,
  .Investigation--investigation-8 .Clues-clue--2 .Clues-clueImage::-webkit-scrollbar-thumb:window-inactive,
  .Investigation--investigation-8 .Clues-clue--3 .Clues-clueImage::-webkit-scrollbar-thumb:window-inactive,
  .Investigation--investigation-8 .Clues-clue--4 .Clues-clueImage::-webkit-scrollbar-thumb:window-inactive {
    background-color: transparent;
    border: 1px solid #00CBCB; }
  .InvestigationTutorial--investigation-8 .Clues-clue--1 .Clues-clueImage img,
  .InvestigationTutorial--investigation-8 .Clues-clue--2 .Clues-clueImage img,
  .InvestigationTutorial--investigation-8 .Clues-clue--3 .Clues-clueImage img,
  .InvestigationTutorial--investigation-8 .Clues-clue--4 .Clues-clueImage img,
  .Investigation--investigation-8 .Clues-clue--1 .Clues-clueImage img,
  .Investigation--investigation-8 .Clues-clue--2 .Clues-clueImage img,
  .Investigation--investigation-8 .Clues-clue--3 .Clues-clueImage img,
  .Investigation--investigation-8 .Clues-clue--4 .Clues-clueImage img {
    position: relative;
    width: 0.51406rem;
    height: auto; }

.InvestigationTutorial--investigation-8 .Clues-clue--1 .Clues-clueImage img,
.Investigation--investigation-8 .Clues-clue--1 .Clues-clueImage img {
  width: 0.58594rem; }

.InvestigationTutorial--investigation-8 .Clues-clue--2 .Clues-clueImage img,
.Investigation--investigation-8 .Clues-clue--2 .Clues-clueImage img {
  margin-top: -0.03125rem;
  margin-left: -0.01562rem; }

.InvestigationTutorial--investigation-8 .Clues-clue--3 .Clues-clueImage img,
.InvestigationTutorial--investigation-8 .Clues-clue--4 .Clues-clueImage img,
.Investigation--investigation-8 .Clues-clue--3 .Clues-clueImage img,
.Investigation--investigation-8 .Clues-clue--4 .Clues-clueImage img {
  margin-top: 0.00781rem;
  width: 0.70312rem; }

.InvestigationTutorial--investigation-8 .CluePopup .CluePopup-clueImage,
.Investigation--investigation-8 .CluePopup .CluePopup-clueImage {
  background-color: #0D5151;
  padding: 0.01016rem; }
  .InvestigationTutorial--investigation-8 .CluePopup .CluePopup-clueImage img,
  .Investigation--investigation-8 .CluePopup .CluePopup-clueImage img {
    width: 0.625rem; }

.InvestigationTutorial--investigation-8 .CluePopup--1 .CluePopup-clueImage img,
.Investigation--investigation-8 .CluePopup--1 .CluePopup-clueImage img {
  width: 0.69531rem; }

.InvestigationTutorial--investigation-8 .CluePopup--2 .CluePopup-clueImage img,
.InvestigationTutorial--investigation-8 .CluePopup--3 .CluePopup-clueImage img,
.Investigation--investigation-8 .CluePopup--2 .CluePopup-clueImage img,
.Investigation--investigation-8 .CluePopup--3 .CluePopup-clueImage img {
  width: 0.53125rem; }

.InvestigationTutorial--investigation-8 .CluePopup--4 .CluePopup-clueImage img,
.Investigation--investigation-8 .CluePopup--4 .CluePopup-clueImage img {
  width: 0.78125rem; }

.InvestigationTutorial--investigation-9 .Clues-clue--1,
.Investigation--investigation-9 .Clues-clue--1 {
  padding-top: 0.03906rem; }
  .InvestigationTutorial--investigation-9 .Clues-clue--1 .Clues-clueTitle,
  .Investigation--investigation-9 .Clues-clue--1 .Clues-clueTitle {
    margin-left: -0.01953rem; }
  .InvestigationTutorial--investigation-9 .Clues-clue--1 .Clues-clueImage img,
  .Investigation--investigation-9 .Clues-clue--1 .Clues-clueImage img {
    width: 0.4875rem; }
  .InvestigationTutorial--investigation-9 .Clues-clue--1 .Clues-clueImage div,
  .Investigation--investigation-9 .Clues-clue--1 .Clues-clueImage div {
    position: absolute;
    top: 0.02109rem;
    left: 0.19141rem;
    font-size: 0.01562rem;
    font-weight: 600;
    color: #04FDFD; }

.InvestigationTutorial--investigation-9 .Clues-clue--2:before,
.InvestigationTutorial--investigation-9 .Clues-clue--3:before,
.Investigation--investigation-9 .Clues-clue--2:before,
.Investigation--investigation-9 .Clues-clue--3:before {
  content: "";
  position: absolute;
  width: 0.74375rem;
  height: 0.23672rem;
  background-image: url(/static/media/striped-background.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  margin-left: -0.00781rem;
  margin-top: -0.00937rem;
  padding: 0.00156rem; }

.InvestigationTutorial--investigation-9 .Clues-clue--2 .Clues-clueImage,
.InvestigationTutorial--investigation-9 .Clues-clue--3 .Clues-clueImage,
.Investigation--investigation-9 .Clues-clue--2 .Clues-clueImage,
.Investigation--investigation-9 .Clues-clue--3 .Clues-clueImage {
  width: 0.73672rem;
  height: 0.23438rem;
  position: relative;
  margin-top: -0.00781rem;
  padding-top: 0.00781rem;
  padding-bottom: 0.01172rem;
  padding-left: 0.01172rem;
  padding-right: 0.00391rem;
  overflow: auto; }
  .InvestigationTutorial--investigation-9 .Clues-clue--2 .Clues-clueImage::-webkit-scrollbar,
  .InvestigationTutorial--investigation-9 .Clues-clue--3 .Clues-clueImage::-webkit-scrollbar,
  .Investigation--investigation-9 .Clues-clue--2 .Clues-clueImage::-webkit-scrollbar,
  .Investigation--investigation-9 .Clues-clue--3 .Clues-clueImage::-webkit-scrollbar {
    width: 0.0125rem;
    height: 100%; }
  .InvestigationTutorial--investigation-9 .Clues-clue--2 .Clues-clueImage::-webkit-scrollbar-track,
  .InvestigationTutorial--investigation-9 .Clues-clue--3 .Clues-clueImage::-webkit-scrollbar-track,
  .Investigation--investigation-9 .Clues-clue--2 .Clues-clueImage::-webkit-scrollbar-track,
  .Investigation--investigation-9 .Clues-clue--3 .Clues-clueImage::-webkit-scrollbar-track {
    background-color: #0D5151;
    border-radius: 0; }
  .InvestigationTutorial--investigation-9 .Clues-clue--2 .Clues-clueImage::-webkit-scrollbar-thumb,
  .InvestigationTutorial--investigation-9 .Clues-clue--3 .Clues-clueImage::-webkit-scrollbar-thumb,
  .Investigation--investigation-9 .Clues-clue--2 .Clues-clueImage::-webkit-scrollbar-thumb,
  .Investigation--investigation-9 .Clues-clue--3 .Clues-clueImage::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: #00CBCB;
    width: 0.0125rem;
    height: 0.10859rem;
    border: 1px solid #04FDFD; }
  .InvestigationTutorial--investigation-9 .Clues-clue--2 .Clues-clueImage::-webkit-scrollbar-thumb:window-inactive,
  .InvestigationTutorial--investigation-9 .Clues-clue--3 .Clues-clueImage::-webkit-scrollbar-thumb:window-inactive,
  .Investigation--investigation-9 .Clues-clue--2 .Clues-clueImage::-webkit-scrollbar-thumb:window-inactive,
  .Investigation--investigation-9 .Clues-clue--3 .Clues-clueImage::-webkit-scrollbar-thumb:window-inactive {
    background-color: transparent;
    border: 1px solid #00CBCB; }
  .InvestigationTutorial--investigation-9 .Clues-clue--2 .Clues-clueImage img,
  .InvestigationTutorial--investigation-9 .Clues-clue--3 .Clues-clueImage img,
  .Investigation--investigation-9 .Clues-clue--2 .Clues-clueImage img,
  .Investigation--investigation-9 .Clues-clue--3 .Clues-clueImage img {
    position: relative;
    width: 0.66328rem;
    height: auto; }
  .InvestigationTutorial--investigation-9 .Clues-clue--2 .Clues-clueImage div,
  .InvestigationTutorial--investigation-9 .Clues-clue--3 .Clues-clueImage div,
  .Investigation--investigation-9 .Clues-clue--2 .Clues-clueImage div,
  .Investigation--investigation-9 .Clues-clue--3 .Clues-clueImage div {
    padding-left: 0.01562rem;
    padding-right: 0.03125rem;
    margin-top: -0.01172rem;
    margin-bottom: 0.01562rem;
    white-space: pre-line; }

.InvestigationTutorial--investigation-9 .Clues-clue--3 .Clues-clueImage,
.Investigation--investigation-9 .Clues-clue--3 .Clues-clueImage {
  padding-top: 0.01172rem;
  padding-left: 0.00781rem; }
  .InvestigationTutorial--investigation-9 .Clues-clue--3 .Clues-clueImage img,
  .Investigation--investigation-9 .Clues-clue--3 .Clues-clueImage img {
    width: 0.44531rem; }

.InvestigationTutorial--investigation-9 .CluePopup--0 .CluePopup-clueImage img,
.Investigation--investigation-9 .CluePopup--0 .CluePopup-clueImage img {
  width: 0.70312rem; }

.InvestigationTutorial--investigation-9 .CluePopup--1 .CluePopup-clueImage,
.Investigation--investigation-9 .CluePopup--1 .CluePopup-clueImage {
  background-color: #0D5151;
  padding: 0.01016rem; }
  .InvestigationTutorial--investigation-9 .CluePopup--1 .CluePopup-clueImage img,
  .Investigation--investigation-9 .CluePopup--1 .CluePopup-clueImage img {
    width: 0.38281rem; }

.InvestigationTutorial--investigation-9 .CluePopup--2 .CluePopup-clueImage,
.Investigation--investigation-9 .CluePopup--2 .CluePopup-clueImage {
  background-color: #0D5151;
  padding: 0.01016rem; }
  .InvestigationTutorial--investigation-9 .CluePopup--2 .CluePopup-clueImage img,
  .Investigation--investigation-9 .CluePopup--2 .CluePopup-clueImage img {
    width: 0.50781rem; }

.InvestigationTutorial--investigation-10 .Clues-clue::before,
.Investigation--investigation-10 .Clues-clue::before {
  content: '';
  position: absolute;
  width: 0.83906rem;
  height: 100%;
  top: 0;
  left: 0.03047rem;
  background-color: rgba(0, 203, 203, 0.27); }

.InvestigationTutorial--investigation-10 .Clues-clue--1 .Clues-clueTitle,
.Investigation--investigation-10 .Clues-clue--1 .Clues-clueTitle {
  margin-left: -0.01562rem;
  margin-top: 0rem;
  margin-bottom: 0.05078rem;
  position: relative; }
  .InvestigationTutorial--investigation-10 .Clues-clue--1 .Clues-clueTitle::after,
  .Investigation--investigation-10 .Clues-clue--1 .Clues-clueTitle::after {
    content: " [RECOVERED]";
    font-size: 0.01562rem;
    font-family: 'Open Sans';
    font-weight: 600;
    color: #04FDFD; }

.InvestigationTutorial--investigation-10 .Clues-clue--2 .Clues-clueImage,
.InvestigationTutorial--investigation-10 .Clues-clue--4 .Clues-clueImage,
.Investigation--investigation-10 .Clues-clue--2 .Clues-clueImage,
.Investigation--investigation-10 .Clues-clue--4 .Clues-clueImage {
  padding-top: 0rem;
  padding-left: 0.00781rem;
  width: 0.78125rem;
  height: 0.25781rem;
  margin-top: -0.01953rem;
  position: relative; }
  .InvestigationTutorial--investigation-10 .Clues-clue--2 .Clues-clueImage img,
  .InvestigationTutorial--investigation-10 .Clues-clue--4 .Clues-clueImage img,
  .Investigation--investigation-10 .Clues-clue--2 .Clues-clueImage img,
  .Investigation--investigation-10 .Clues-clue--4 .Clues-clueImage img {
    width: 100%; }

.InvestigationTutorial--investigation-10 .Clues-clue--4 .Clues-clueImage,
.Investigation--investigation-10 .Clues-clue--4 .Clues-clueImage {
  padding-left: 0rem;
  margin-left: -0.01406rem;
  margin-top: -0.02344rem; }

.InvestigationTutorial--investigation-10 .Clues-clue--3::before,
.Investigation--investigation-10 .Clues-clue--3::before {
  content: none; }

.InvestigationTutorial--investigation-10 .Clues-clue--3 .Clues-clueText,
.Investigation--investigation-10 .Clues-clue--3 .Clues-clueText {
  margin: 0.07031rem 0.02344rem; }
  .InvestigationTutorial--investigation-10 .Clues-clue--3 .Clues-clueText p,
  .Investigation--investigation-10 .Clues-clue--3 .Clues-clueText p {
    margin-bottom: -0.00391rem;
    font-size: 0.01562rem;
    font-weight: 400;
    text-transform: none; }

.InvestigationTutorial--investigation-10 .CluePopup--1 .CluePopup-clueImage,
.Investigation--investigation-10 .CluePopup--1 .CluePopup-clueImage {
  background-color: #0D5151;
  padding: 0.01562rem; }
  .InvestigationTutorial--investigation-10 .CluePopup--1 .CluePopup-clueImage img,
  .Investigation--investigation-10 .CluePopup--1 .CluePopup-clueImage img {
    width: 0.85938rem; }

.InvestigationTutorial--investigation-11 .Clues .Clues-clue,
.Investigation--investigation-11 .Clues .Clues-clue {
  white-space: pre-line; }
  .InvestigationTutorial--investigation-11 .Clues .Clues-clue .Clues-clueText,
  .Investigation--investigation-11 .Clues .Clues-clue .Clues-clueText {
    padding-left: 0.03125rem;
    padding-right: 0.03125rem;
    font-weight: 400; }

.InvestigationTutorial--investigation-12 .Clues-clue,
.Investigation--investigation-12 .Clues-clue {
  position: relative; }
  .InvestigationTutorial--investigation-12 .Clues-clue:before,
  .Investigation--investigation-12 .Clues-clue:before {
    content: "";
    position: absolute;
    width: 0.74375rem;
    height: 0.23672rem;
    background-image: url(/static/media/striped-background.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    margin-left: -0.00781rem;
    margin-top: -0.00937rem;
    padding: 0.00156rem; }
  .InvestigationTutorial--investigation-12 .Clues-clue .Clues-clueImage,
  .Investigation--investigation-12 .Clues-clue .Clues-clueImage {
    width: 0.73672rem;
    height: 0.23438rem;
    position: relative;
    margin-top: -0.00781rem;
    padding-top: 0.00781rem;
    padding-bottom: 0.01172rem;
    padding-left: 0.02344rem;
    overflow: auto; }
    .InvestigationTutorial--investigation-12 .Clues-clue .Clues-clueImage::-webkit-scrollbar,
    .Investigation--investigation-12 .Clues-clue .Clues-clueImage::-webkit-scrollbar {
      width: 0.0125rem;
      height: 100%; }
    .InvestigationTutorial--investigation-12 .Clues-clue .Clues-clueImage::-webkit-scrollbar-track,
    .Investigation--investigation-12 .Clues-clue .Clues-clueImage::-webkit-scrollbar-track {
      background-color: #0D5151;
      border-radius: 0; }
    .InvestigationTutorial--investigation-12 .Clues-clue .Clues-clueImage::-webkit-scrollbar-thumb,
    .Investigation--investigation-12 .Clues-clue .Clues-clueImage::-webkit-scrollbar-thumb {
      border-radius: 0;
      background: #00CBCB;
      width: 0.0125rem;
      height: 0.10859rem;
      border: 1px solid #04FDFD; }
    .InvestigationTutorial--investigation-12 .Clues-clue .Clues-clueImage::-webkit-scrollbar-thumb:window-inactive,
    .Investigation--investigation-12 .Clues-clue .Clues-clueImage::-webkit-scrollbar-thumb:window-inactive {
      background-color: transparent;
      border: 1px solid #00CBCB; }
    .InvestigationTutorial--investigation-12 .Clues-clue .Clues-clueImage img,
    .Investigation--investigation-12 .Clues-clue .Clues-clueImage img {
      margin-top: 0.00781rem;
      position: relative;
      width: 0.41406rem;
      height: auto;
      cursor: pointer; }
    .InvestigationTutorial--investigation-12 .Clues-clue .Clues-clueImage div,
    .Investigation--investigation-12 .Clues-clue .Clues-clueImage div {
      width: 0.25781rem;
      display: inline-block;
      vertical-align: top;
      padding-top: 0.00234rem;
      padding-left: 0.00781rem;
      margin-right: 0.01172rem; }
      .InvestigationTutorial--investigation-12 .Clues-clue .Clues-clueImage div h2,
      .Investigation--investigation-12 .Clues-clue .Clues-clueImage div h2 {
        font-size: 0.01562rem;
        margin-bottom: 0.00391rem; }

.InvestigationTutorial--investigation-12 .CluePopup .CluePopup-clueImage,
.Investigation--investigation-12 .CluePopup .CluePopup-clueImage {
  background-color: #0D5151;
  padding: 0.01562rem; }
  .InvestigationTutorial--investigation-12 .CluePopup .CluePopup-clueImage img,
  .Investigation--investigation-12 .CluePopup .CluePopup-clueImage img {
    width: 0.57812rem;
    cursor: pointer; }

.InvestigationTutorial--investigation-13 .Clues-clue,
.Investigation--investigation-13 .Clues-clue {
  position: relative; }
  .InvestigationTutorial--investigation-13 .Clues-clue:before,
  .Investigation--investigation-13 .Clues-clue:before {
    content: "";
    position: absolute;
    width: 0.74375rem;
    height: 0.23672rem;
    background-image: url(/static/media/striped-background.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    margin-left: -0.00781rem;
    margin-top: -0.00937rem;
    padding: 0.00156rem; }
  .InvestigationTutorial--investigation-13 .Clues-clue .Clues-clueImage,
  .Investigation--investigation-13 .Clues-clue .Clues-clueImage {
    width: 0.73672rem;
    height: 0.23438rem;
    position: relative;
    margin-top: -0.00781rem;
    padding-top: 0.00781rem;
    padding-bottom: 0.01172rem;
    padding-left: 0.00391rem;
    overflow: auto;
    border-top: 0.00078rem solid #04FDFD; }
    .InvestigationTutorial--investigation-13 .Clues-clue .Clues-clueImage::-webkit-scrollbar,
    .Investigation--investigation-13 .Clues-clue .Clues-clueImage::-webkit-scrollbar {
      width: 0.0125rem;
      height: 100%; }
    .InvestigationTutorial--investigation-13 .Clues-clue .Clues-clueImage::-webkit-scrollbar-track,
    .Investigation--investigation-13 .Clues-clue .Clues-clueImage::-webkit-scrollbar-track {
      background-color: #0D5151;
      border-radius: 0; }
    .InvestigationTutorial--investigation-13 .Clues-clue .Clues-clueImage::-webkit-scrollbar-thumb,
    .Investigation--investigation-13 .Clues-clue .Clues-clueImage::-webkit-scrollbar-thumb {
      border-radius: 0;
      background: #00CBCB;
      width: 0.0125rem;
      height: 0.10859rem;
      border: 1px solid #04FDFD; }
    .InvestigationTutorial--investigation-13 .Clues-clue .Clues-clueImage::-webkit-scrollbar-thumb:window-inactive,
    .Investigation--investigation-13 .Clues-clue .Clues-clueImage::-webkit-scrollbar-thumb:window-inactive {
      background-color: transparent;
      border: 1px solid #00CBCB; }
    .InvestigationTutorial--investigation-13 .Clues-clue .Clues-clueImage img,
    .Investigation--investigation-13 .Clues-clue .Clues-clueImage img {
      margin-top: 0.00781rem;
      position: relative;
      width: 0.41406rem;
      height: auto;
      cursor: pointer;
      border: 0.00078rem solid #04FDFD; }
    .InvestigationTutorial--investigation-13 .Clues-clue .Clues-clueImage div,
    .Investigation--investigation-13 .Clues-clue .Clues-clueImage div {
      width: 0.27344rem;
      display: inline-block;
      vertical-align: top;
      padding-top: 0.00234rem;
      padding-left: 0.00781rem;
      margin-right: 0.02344rem; }

.InvestigationTutorial--investigation-13 .CluePopup .CluePopup-clueImage,
.Investigation--investigation-13 .CluePopup .CluePopup-clueImage {
  background-color: #0D5151;
  padding: 0.01562rem; }
  .InvestigationTutorial--investigation-13 .CluePopup .CluePopup-clueImage img,
  .Investigation--investigation-13 .CluePopup .CluePopup-clueImage img {
    width: 0.57812rem;
    cursor: pointer; }

.InvestigationTutorial--investigation-14 .Clues-clue,
.Investigation--investigation-14 .Clues-clue {
  padding-left: 0.07812rem; }

.InvestigationTutorial--investigation-14 .CluePopup .CluePopup-clueImage,
.Investigation--investigation-14 .CluePopup .CluePopup-clueImage {
  background-color: #0D5151;
  padding: 0.01562rem; }
  .InvestigationTutorial--investigation-14 .CluePopup .CluePopup-clueImage img,
  .Investigation--investigation-14 .CluePopup .CluePopup-clueImage img {
    width: 0.625rem;
    cursor: pointer; }

.InvestigationTutorial--investigation-14 .CluePopup--1 .CluePopup-clueImage img,
.Investigation--investigation-14 .CluePopup--1 .CluePopup-clueImage img {
  width: 0.42188rem; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.NextStep {
  position: absolute;
  bottom: 0.01875rem;
  right: 0.0125rem;
  width: 0.1875rem;
  height: 0.05469rem;
  background-image: url(/static/media/next-step-button.25ee9365.svg);
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat;
  font-family: "Fivo Sans";
  font-size: 0.01562rem;
  font-weight: bold;
  line-height: 1;
  padding-top: 0.01953rem;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/ }
  .NextStep--investigation {
    width: 0.12109rem;
    height: 0.04688rem;
    bottom: 0.01953rem;
    color: #04FDFD;
    padding-top: 0.01562rem;
    background-image: url(/static/media/next-step-button.fea7d660.svg); }
  .NextStep--next-session {
    padding-top: 0.02109rem;
    padding-left: 0.00625rem;
    text-align: left;
    font-size: 0.01328rem; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.ManageSubmissions {
  position: absolute;
  top: 0.07031rem;
  left: 0.05078rem;
  right: 0.03125rem;
  bottom: 0.03125rem;
  background-image: linear-gradient(#015051, #002F2C);
  padding: 0.01953rem;
  width: 0.94453rem; }

.ManageSubmissions-noSubmissions {
  width: 0.76172rem;
  height: 100%;
  padding-top: 0.00781rem; }

.ManageSubmissions-groups {
  position: relative;
  width: 0.76172rem;
  height: 100%;
  padding-right: 0.01562rem;
  overflow: auto; }
  .ManageSubmissions-groups::-webkit-scrollbar {
    width: 0.00781rem;
    height: 100%; }
  .ManageSubmissions-groups::-webkit-scrollbar-track {
    background-color: rgba(4, 253, 253, 0.2);
    border-radius: 0.00781rem; }
  .ManageSubmissions-groups::-webkit-scrollbar-thumb {
    border-radius: 0.00781rem;
    background: #04FDFD;
    width: 100%;
    height: 0.01562rem;
    border: none; }
  .ManageSubmissions-groups::-webkit-scrollbar-thumb:window-inactive {
    background-color: transparent;
    border: 1px solid #04FDFD; }

.ManageSubmissions-group {
  width: 100%;
  height: 0.05078rem;
  background-color: rgba(4, 253, 253, 0.24);
  margin-bottom: 0.01172rem;
  /* Firefox */
  /* WebKit */
  /* Opera */
  transition: height 0.5s ease-in-out;
  /* Standard */
  overflow: hidden; }
  .ManageSubmissions-group:nth-last-child(1) {
    margin-bottom: 0; }
  .ManageSubmissions-group.expanded {
    height: 0.19141rem; }
    .ManageSubmissions-group.expanded .ManageSubmissions-groupText.submissionOpen {
      overflow-y: auto;
      word-break: break-word; }
      .ManageSubmissions-group.expanded .ManageSubmissions-groupText.submissionOpen p {
        margin: 0; }

.ManageSubmissions-groupHeader {
  position: relative;
  width: 100%;
  height: 0.05078rem;
  padding: 0.01094rem 0.01562rem;
  border-top: 0.00117rem solid #04FDFD;
  border-bottom: 0.00117rem solid #04FDFD; }

.ManageSubmissions-submissionTitle {
  display: inline-block;
  vertical-align: top;
  width: 0.42969rem;
  height: 0.02734rem;
  line-height: 0.02734rem;
  margin-left: 0.01953rem;
  font-size: 0.01406rem;
  font-weight: 500;
  color: #04FDFD; }

.ManageSubmissions-input {
  width: 100%;
  height: 100%;
  font-size: 0.01406rem;
  font-weight: 500;
  color: #04FDFD;
  line-height: 0.02734rem;
  background: rgba(255, 255, 255, 0.1);
  padding: 0;
  cursor: text;
  /* Chrome/Opera/Safari */
  /* Firefox 18- */
  /* Firefox 19+ */
  /* IE 10+ */ }
  .ManageSubmissions-input::-webkit-input-placeholder {
    color: #04FDFD !important; }
  .ManageSubmissions-input:-moz-placeholder {
    color: #04FDFD !important; }
  .ManageSubmissions-input::-moz-placeholder {
    color: #04FDFD !important; }
  .ManageSubmissions-input:-ms-input-placeholder {
    color: #04FDFD !important; }

.ManageSubmissions-teacherActions {
  position: absolute;
  right: 0;
  top: 0.01094rem;
  width: 0.19531rem;
  height: 0.02734rem;
  text-align: right; }

.ManageSubmissions-saveGroupBtn {
  position: absolute;
  left: -0.04688rem;
  display: none; }
  .ManageSubmissions-saveGroupBtn.show {
    display: block; }

.ManageSubmissions-expandSubmissionBtn,
.ManageSubmissions-toggleGroupBtn,
.ManageSubmissions-editGroupBtn,
.ManageSubmissions-removeGroupBtn {
  width: 0.03906rem;
  height: 0.02734rem;
  display: inline-block;
  background-size: auto 0.02344rem;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer; }

.ManageSubmissions-expandSubmissionBtn {
  width: 0.02344rem;
  margin-right: 0.01953rem;
  background-size: contain;
  background-image: url(/static/media/icon-arrow-up.01a0018b.svg);
  /* IE 9 */
  -webkit-transform: rotate(180deg);
  /* Safari */
  transform: rotate(180deg); }
  .ManageSubmissions-expandSubmissionBtn.expanded {
    /* IE 9 */
    -webkit-transform: rotate(0deg);
    /* Safari */
    transform: rotate(0deg); }

.ManageSubmissions-toggleGroupBtn {
  margin-right: 0.00547rem;
  background-image: url(/static/media/icon-checkbox.5a667937.svg); }
  .ManageSubmissions-toggleGroupBtn.selected {
    background-image: url(/static/media/icon-checkbox.5a667937.svg), url(/static/media/icon-checkmark.ee1a3e3f.svg);
    background-size: auto 0.02344rem, auto 0.01562rem; }

.ManageSubmissions-editGroupBtn {
  background-image: url(/static/media/icon-edit.f0d23460.svg); }

.ManageSubmissions-removeGroupBtn {
  background-image: url(/static/media/icon-delete.f2649a96.svg); }

.ManageSubmissions-groupText {
  padding: 0.01172rem 0.01562rem;
  height: 0.14062rem;
  font-size: 0.01406rem;
  /* Firefox */
  /* WebKit */
  /* Opera */
  transition: height 0.5s ease-in-out;
  /* Standard */ }
  .ManageSubmissions-groupText::-webkit-scrollbar {
    width: 0.00781rem;
    height: 100%; }
  .ManageSubmissions-groupText::-webkit-scrollbar-track {
    background-color: rgba(4, 253, 253, 0.2);
    border-radius: 0.00781rem; }
  .ManageSubmissions-groupText::-webkit-scrollbar-thumb {
    border-radius: 0.00781rem;
    background: #04FDFD;
    width: 100%;
    height: 0.01562rem;
    border: none; }
  .ManageSubmissions-groupText::-webkit-scrollbar-thumb:window-inactive {
    background-color: transparent;
    border: 1px solid #04FDFD; }

.ManageSubmissions-textarea {
  width: 100%;
  height: 100%;
  font-size: 0.01406rem;
  color: white;
  padding: 0;
  background: rgba(255, 255, 255, 0.1);
  resize: none;
  cursor: text;
  /* Chrome/Opera/Safari */
  /* Firefox 18- */
  /* Firefox 19+ */
  /* IE 10+ */ }
  .ManageSubmissions-textarea::-webkit-input-placeholder {
    color: #04FDFD !important; }
  .ManageSubmissions-textarea:-moz-placeholder {
    color: #04FDFD !important; }
  .ManageSubmissions-textarea::-moz-placeholder {
    color: #04FDFD !important; }
  .ManageSubmissions-textarea:-ms-input-placeholder {
    color: #04FDFD !important; }
  .ManageSubmissions-textarea::-webkit-scrollbar {
    width: 0.00781rem;
    height: 100%; }
  .ManageSubmissions-textarea::-webkit-scrollbar-track {
    background-color: rgba(4, 253, 253, 0.2);
    border-radius: 0.00781rem; }
  .ManageSubmissions-textarea::-webkit-scrollbar-thumb {
    border-radius: 0.00781rem;
    background: #04FDFD;
    width: 100%;
    height: 0.01562rem;
    border: none; }
  .ManageSubmissions-textarea::-webkit-scrollbar-thumb:window-inactive {
    background-color: transparent;
    border: 1px solid #04FDFD; }

.ManageSubmissions .Button {
  position: absolute;
  top: 0.02031rem;
  right: 0.01562rem;
  width: 0.125rem;
  height: 0.03516rem;
  padding-top: 0.00937rem; }

.ManageSubmissions .ManageSubmissions-saveGroupBtn .Button {
  top: unset;
  right: unset;
  height: 0.02891rem;
  width: 0.0625rem;
  padding-top: 0.00625rem; }

.ManageSubmissions-closeSubmissions {
  position: absolute;
  top: 0.0625rem;
  right: 0.01562rem;
  width: 0.125rem;
  height: 0.03516rem;
  background-image: linear-gradient(#015051, #002F2C);
  border: 0.00156rem solid #0D5151;
  font-family: "Fivo Sans";
  font-size: 0.01172rem;
  text-transform: uppercase;
  text-align: center;
  padding-top: 0.00937rem;
  color: #04FDFD;
  cursor: pointer; }

.ManageSubmissions-groupsSelected {
  position: absolute;
  top: 0.11328rem;
  right: 0.01562rem;
  width: 0.125rem;
  font-family: "Fivo Sans";
  font-size: 0.01172rem;
  text-transform: uppercase;
  text-align: center;
  line-height: 0.03516rem;
  color: #04FDFD; }

.ManageSubmissions-backBtn {
  position: absolute;
  top: -0.04453rem;
  left: 0.01719rem;
  width: 0.02344rem;
  height: 0.02344rem;
  background-size: 0.01016rem auto;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/static/media/icon-prev.9b73d107.svg); }
  .ManageSubmissions-backBtn:hover {
    cursor: pointer; }

.ManageSubmissions-stepNumber {
  position: absolute;
  top: -0.04297rem;
  left: 0.04688rem;
  font-family: "Fivo Sans";
  font-size: 0.01406rem;
  color: #04FDFD; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.InvestigationTutorial {
  position: absolute;
  left: -0.25rem;
  right: -0.25rem;
  top: -0.02344rem;
  bottom: -0.01875rem;
  padding: 0.01562rem 0.07812rem 0.01562rem 0.01562rem;
  z-index: 10;
  background-image: url(/static/media/background.jpg);
  background-size: cover;
  background-position: top left; }
  .InvestigationTutorial::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(1, 27, 28, 0.8); }
  .InvestigationTutorial .Clues {
    margin-left: 0.0625rem; }
  .InvestigationTutorial .WriteSubmission {
    margin-left: 0.09375rem; }
  .InvestigationTutorial .GroupSubmissions {
    margin-left: 0.04297rem; }

.InvestigationTutorial-instructionsBtn {
  position: absolute;
  top: 0.01172rem;
  right: 0.06641rem;
  cursor: pointer;
  width: 0.02344rem;
  height: 0.02344rem;
  background-image: url(/static/media/icon-instructions.fc38193b.svg);
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 12; }
  .InvestigationTutorial-instructionsBtn.show {
    top: 0.01562rem;
    right: 0.07812rem;
    width: 0.01562rem;
    height: 0.01562rem;
    background-image: url(/static/media/icon-x.be145b6d.svg); }

.InvestigationTutorial-instructions {
  position: absolute;
  top: 0.21094rem;
  right: 0.04688rem;
  width: 0.31875rem;
  background-image: linear-gradient(rgba(1, 80, 81, 0.9), rgba(0, 47, 44, 0.9));
  color: white;
  padding: 0.00781rem 0.01875rem 0.04688rem 0.01562rem;
  font-size: 0.01094rem;
  font-family: 'Fivo Sans';
  line-height: 1.4;
  font-weight: 600;
  z-index: 11;
  overflow: hidden;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/
  border-width: 0.00234rem;
  border-style: solid;
  border-image: url(/static/media/frame-cyan.6d9c79f0.svg) round;
  border-image-slice: 10 10 10 10;
  border-image-width: 0.00781rem 0.00781rem; }

.InvestigationTutorial-dragBtn {
  position: absolute;
  top: -0.01797rem;
  right: -0.01172rem;
  cursor: grab;
  cursor: -webkit-grab;
  width: 0.02734rem;
  height: 0.05078rem;
  background-image: url(/static/media/icon-drag-handle.40389cd5.svg);
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 12; }
  .InvestigationTutorial-dragBtn.dragging {
    cursor: grabbing;
    cursor: -webkit-grabbing; }

.InvestigationTutorial-instructionsTitle {
  color: #04FDFD;
  font-size: 0.01406rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: -0.00781rem; }

.InvestigationTutorial-instructionsButtons {
  position: relative;
  width: 100%; }

.InvestigationTutorial-instructionsPrevBtn,
.InvestigationTutorial-instructionsNextBtn {
  position: absolute;
  width: 0.09375rem;
  bottom: -0.03516rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.01406rem;
  background-image: linear-gradient(#00CBCB, #006666);
  padding: 0.00625rem 0.01562rem;
  padding-bottom: 0.00391rem;
  cursor: pointer; }
  .InvestigationTutorial-instructionsPrevBtn:hover,
  .InvestigationTutorial-instructionsNextBtn:hover {
    background-image: linear-gradient(#00b2b2, #004d4d); }

.InvestigationTutorial-instructionsPrevBtn {
  left: 0; }

.InvestigationTutorial-instructionsNextBtn {
  right: -0.00781rem; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.Investigation {
  position: absolute;
  left: -0.25rem;
  right: -0.25rem;
  top: -0.02344rem;
  bottom: -0.01875rem;
  padding: 0.01562rem 0.07812rem 0.01562rem 0.01562rem;
  z-index: 10;
  background-image: url(/static/media/background.jpg);
  background-size: cover;
  background-position: top left; }
  .Investigation::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(1, 27, 28, 0.8); }
  .Investigation .InstructionsIcon {
    z-index: 11;
    width: 0.01875rem;
    height: 0.01875rem;
    top: 0.02578rem;
    right: 0.14844rem;
    background-image: url(/static/media/icon-instructions-light.dcf5e07b.svg); }
  .Investigation--teacher {
    padding: 0.03125rem 0.04688rem; }
    .Investigation--teacher .InstructionsIcon {
      width: 0.02422rem;
      height: 0.02422rem;
      top: 0.01172rem;
      right: 0.07031rem;
      background-image: url(/static/media/icon-instructions.fc38193b.svg); }

.Investigation-close {
  position: absolute;
  top: 0.01172rem;
  right: 0.03516rem;
  width: 0.025rem;
  height: 0.025rem;
  background-image: url(/static/media/icon-x.be145b6d.svg);
  background-size: 100%;
  background-repeat: no-repeat;
  cursor: pointer; }
  .Investigation-close:hover {
    /* IE 9 */
    -webkit-transform: scale(1.1);
    /* Safari */
    transform: scale(1.1); }

.Investigation-title {
  font-family: "Fivo Sans";
  font-size: 0.01875rem;
  margin: 0.01172rem 0; }

.Investigation-info {
  position: absolute;
  top: 0.03906rem;
  right: 0.01172rem;
  width: 0.01875rem;
  height: 0.01875rem;
  background-image: url(/static/media/icon-instructions-light.dcf5e07b.svg);
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  z-index: 2; }
  .Investigation-info:hover {
    /* IE 9 */
    -webkit-transform: scale(1.1);
    /* Safari */
    transform: scale(1.1); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.CyoaIntro {
  position: relative;
  padding: 0.02344rem; }
  .CyoaIntro .Button {
    margin-right: 0.01562rem;
    font-size: 0.01406rem; }

.CyoaIntro-title {
  font-size: 0.01562rem;
  font-family: "Fivo Sans";
  margin-bottom: 0.00781rem; }

.CyoaIntro-text {
  margin-bottom: 0.02344rem; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.Graph {
  width: 0.35156rem;
  height: 0.16641rem;
  background-color: white;
  position: absolute;
  right: 0.01953rem;
  overflow: hidden; }
  .Graph canvas {
    width: 100% !important;
    height: 100% !important; }
  .Graph.oxygen {
    top: 0.03516rem; }
  .Graph.energy {
    top: 0.20703rem; }
  .Graph.distance {
    top: 0.37891rem;
    height: 0.17969rem; }
  .Graph.atomReactorEnergy, .Graph.atomReactor {
    position: relative;
    width: 0.23438rem;
    height: 0.11719rem;
    display: inline-block;
    margin-right: 0.00781rem;
    margin-top: 0.00781rem;
    margin-bottom: 0.00781rem; }
  .Graph.atomReactorEnergy {
    position: absolute;
    right: 0.01172rem; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.Rover {
  position: absolute;
  left: -0.22656rem;
  right: -0.22656rem;
  top: -0.02734rem;
  bottom: -0.02031rem;
  padding: 0.03125rem 0.03906rem;
  z-index: 10;
  color: white;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/
  background-image: url(/static/media/background.jpg);
  background-size: cover;
  background-position: top left; }
  .Rover::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(1, 27, 28, 0.8); }
  .Rover--showBlur {
    filter: blur(0.00234rem);
    -webkit-filter: blur(0.00234rem);
    -moz-filter: blur(0.00234rem);
    -o-filter: blur(0.00234rem);
    -ms-filter: blur(0.00234rem); }

.Rover-stats {
  position: absolute;
  top: 0.03906rem; }

.Rover-staticData {
  position: absolute;
  top: 0.03906rem;
  left: 0.49219rem;
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer; }
  .Rover-staticData:hover {
    font-size: 0.01328rem; }

.Rover-entry {
  position: relative;
  margin-top: 0.09375rem;
  width: 0.54688rem;
  height: 0.39062rem;
  overflow: auto; }
  .Rover-entry::-webkit-scrollbar {
    width: 0.00781rem;
    height: 100%; }
  .Rover-entry::-webkit-scrollbar-track {
    background-color: rgba(4, 253, 253, 0.2);
    border-radius: 0.00781rem; }
  .Rover-entry::-webkit-scrollbar-thumb {
    border-radius: 0.00781rem;
    background: #04FDFD;
    width: 100%;
    height: 0.01562rem;
    border: none; }
  .Rover-entry::-webkit-scrollbar-thumb:window-inactive {
    background-color: transparent;
    border: 1px solid #04FDFD; }
  .Rover-entry pre,
  .Rover-entry code {
    font-family: 'Fivo Sans';
    white-space: pre-line; }

.Rover-choices .Rover-choice {
  display: inline-block;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  padding: 0.00391rem 0.01172rem;
  background-color: lightseagreen;
  color: white;
  border-radius: 0.125em;
  outline: none;
  touch-action: manipulation;
  pointer-events: auto;
  margin-right: 0.00781rem;
  margin-bottom: 0.00781rem;
  cursor: pointer;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" 100 ")";
  /* IE 8 */
  filter: alpha(opacity=100);
  /* IE 5-7 */
  -moz-opacity: 1;
  /* Netscape */
  -khtml-opacity: 1;
  /* Safari 1.x */
  opacity: 1;
  /* Firefox */
  /* WebKit */
  /* Opera */
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  /* Standard */ }
  .Rover-choices .Rover-choice:hover, .Rover-choices .Rover-choice:focus, .Rover-choices .Rover-choice:active {
    background-color: #1c9c95; }

.Rover-close {
  position: absolute;
  top: 0.00781rem;
  right: 0.03516rem;
  width: 0.02344rem;
  height: 0.02344rem;
  background-image: url(/static/media/icon-x.be145b6d.svg);
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/ }
  .Rover-close:hover {
    /* IE 9 */
    -webkit-transform: scale(1.1);
    /* Safari */
    transform: scale(1.1); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.AtomReactor {
  position: absolute;
  left: -0.22656rem;
  right: -0.22656rem;
  top: -0.02734rem;
  bottom: -0.02031rem;
  padding: 0.03125rem 0.03906rem;
  z-index: 10;
  color: white;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/
  background-image: url(/static/media/background.jpg);
  background-size: cover;
  background-position: top left;
  overflow: hidden; }
  .AtomReactor::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(1, 27, 28, 0.8); }
  .AtomReactor::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(1, 27, 28, 0.8);
    visibility: hidden;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" 0 ")";
    /* IE 8 */
    filter: alpha(opacity=0);
    /* IE 5-7 */
    -moz-opacity: 0;
    /* Netscape */
    -khtml-opacity: 0;
    /* Safari 1.x */
    opacity: 0;
    /* Firefox */
    /* WebKit */
    /* Opera */
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    /* Standard */ }
  .AtomReactor--showOverlay::after {
    visibility: visible;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" 100 ")";
    /* IE 8 */
    filter: alpha(opacity=100);
    /* IE 5-7 */
    -moz-opacity: 1;
    /* Netscape */
    -khtml-opacity: 1;
    /* Safari 1.x */
    opacity: 1; }
  .AtomReactor .AtomReactor-content {
    position: relative; }
  .AtomReactor .AtomReactor-diagram {
    position: relative;
    display: flex;
    justify-content: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-flex-align: center;
    align-items: center;
    flex-direction: column; }
  .AtomReactor .AtomReactor-btn {
    margin: 0.00391rem 0;
    color: #04FDFD;
    cursor: pointer;
    font-weight: 600; }
    .AtomReactor .AtomReactor-btn:hover {
      text-decoration: underline; }
    .AtomReactor .AtomReactor-btn:first-of-type {
      margin-top: 0.01172rem; }
    .AtomReactor .AtomReactor-btn.inline {
      display: inline-block;
      margin-top: 0.00781rem; }
    .AtomReactor .AtomReactor-btn.margin-left {
      margin-left: 0.00391rem; }
  .AtomReactor .AtomReactor-gif {
    width: 0.3125rem;
    height: 0.23438rem;
    background: url(/static/media/reactor-diagram.gif);
    background-repeat: no-repeat;
    background-size: contain; }
  .AtomReactor table th, .AtomReactor table td {
    width: 0.05469rem;
    text-align: center; }
  .AtomReactor table th {
    font-weight: normal; }
  .AtomReactor table td:first-of-type {
    text-align: left; }
  .AtomReactor .CoolantLevels {
    margin-top: 0.02344rem; }
    .AtomReactor .CoolantLevels table td:first-of-type {
      width: 0.13281rem; }
  .AtomReactor .Column {
    position: relative;
    width: 30%;
    display: inline-block;
    vertical-align: top;
    margin-right: 3%; }
    .AtomReactor .Column:nth-of-type(2), .AtomReactor .Column:nth-of-type(3) {
      padding-top: 0.02344rem; }
    .AtomReactor .Column:nth-of-type(3) {
      margin-right: 0;
      width: 33%; }
  .AtomReactor p {
    margin-bottom: 0;
    vertical-align: top; }
  .AtomReactor input[type=range] {
    -webkit-appearance: none;
    margin: 0.00781rem;
    margin-left: 0;
    margin-bottom: -0.00391rem;
    width: 40%; }
  .AtomReactor input[type=range]:focus {
    outline: none; }
  .AtomReactor input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.00781rem;
    cursor: pointer;
    border: 0.00078rem solid #444;
    background: #333; }
  .AtomReactor input[type=range]::-webkit-slider-thumb {
    box-shadow: 0.00078rem 0.00078rem 0.00078rem #000000, 0rem 0rem 0.00078rem #0d0d0d;
    border: 0.00078rem solid #69fefe;
    height: 0.01406rem;
    width: 0.02344rem;
    background: #04FDFD;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -0.00391rem; }
  .AtomReactor input[type=range]:focus::-webkit-slider-runnable-track {
    background: #333; }
  .AtomReactor input[type=range]::-moz-range-track {
    width: 100%;
    height: 0.01406rem;
    cursor: pointer;
    box-shadow: 0.00078rem 0.00078rem 0.00078rem #000000, 0px 0px 0.00078rem #0d0d0d;
    background: #333;
    border: 0.00078rem solid #444; }
  .AtomReactor input[type=range]::-moz-range-thumb {
    box-shadow: 0.00078rem 0.00078rem 0.00078rem #000000, 0px 0px 0.00078rem #0d0d0d;
    border: 0.00078rem solid #69fefe;
    height: 0.01406rem;
    width: 0.02344rem;
    background: #04FDFD;
    cursor: pointer;
    border-radius: 0;
    margin-top: -0.00781rem; }
  .AtomReactor input[type=range]::-ms-track {
    width: 100%;
    height: 0.00781rem;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 0.0125rem 0;
    color: transparent; }
  .AtomReactor input[type=range]::-ms-fill-lower {
    background: #333;
    border: 0.00078rem solid #444;
    box-shadow: 0.00078rem 0.00078rem 0.00078rem #000000, 0px 0px 0.00078rem #0d0d0d; }
  .AtomReactor input[type=range]::-ms-fill-upper {
    background: #333;
    border: 0.00078rem solid #444;
    border-radius: 2.6px;
    box-shadow: 0.00078rem 0.00078rem 0.00078rem #000000, 0px 0px 0.00078rem #0d0d0d; }
  .AtomReactor input[type=range]::-ms-thumb {
    box-shadow: 0.00078rem 0.00078rem 0.00078rem #000000, 0px 0px 0.00078rem #0d0d0d;
    border: 0.00078rem solid #69fefe;
    height: 0.01406rem;
    width: 0.02813rem;
    background: #04FDFD;
    cursor: pointer; }
  .AtomReactor input[type=range]:focus::-ms-fill-lower {
    background: #333; }
  .AtomReactor input[type=range]:focus::-ms-fill-upper {
    background: #333; }

.AtomReactor-iframeWrapper {
  position: relative;
  width: 0.9375rem;
  height: 0.50781rem;
  overflow-x: visible; }

.AtomReactor-close {
  position: absolute;
  top: 0.00781rem;
  right: 0.03516rem;
  width: 0.02344rem;
  height: 0.02344rem;
  background-image: url(/static/media/icon-x.be145b6d.svg);
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/ }
  .AtomReactor-close:hover {
    /* IE 9 */
    -webkit-transform: scale(1.1);
    /* Safari */
    transform: scale(1.1); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.InfoMinigame {
  position: relative;
  height: 100%;
  padding: 0.02344rem; }
  .InfoMinigame .Button {
    margin-right: 0.01562rem;
    font-size: 0.01406rem; }

.InfoMinigame-title {
  font-size: 0.01562rem;
  font-family: "Fivo Sans";
  margin-bottom: 0.00781rem; }

.InfoMinigame-text {
  margin-bottom: 0.02344rem; }
  .InfoMinigame-text a {
    color: white; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.Lips {
  position: absolute;
  left: -0.22656rem;
  right: -0.22656rem;
  top: -0.02734rem;
  bottom: -0.02031rem;
  padding: 0.03125rem 0.03906rem;
  z-index: 10;
  color: white;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/
  background-image: url(/static/media/background.jpg);
  background-size: cover;
  background-position: top left;
  font-family: "Fivo Sans"; }
  .Lips::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(1, 27, 28, 0.8); }
  .Lips .Button.Button--backBtn {
    width: 0.11719rem;
    height: 0.05078rem;
    margin-top: 0.02344rem;
    font-size: 0.01562rem;
    font-weight: 600;
    padding-top: 0.01328rem;
    background-image: url(/static/media/prev-step-button.00ab0a8b.svg);
    background-size: contain;
    background-repeat: no-repeat; }
  .Lips .Lips-selectedGridCoordinates {
    width: 0.13281rem;
    height: 0.05234rem;
    background-color: #002F2C;
    margin-top: 0.03906rem;
    margin-right: 0.01562rem;
    font-size: 0.01562rem;
    display: inline-flex;
    justify-content: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-flex-align: center;
    align-items: center;
    flex-direction: row; }

.Lips-close {
  position: absolute;
  top: 0.00781rem;
  right: 0.03516rem;
  width: 0.02344rem;
  height: 0.02344rem;
  background-image: url(/static/media/icon-x.be145b6d.svg);
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/ }
  .Lips-close:hover {
    /* IE 9 */
    -webkit-transform: scale(1.1);
    /* Safari */
    transform: scale(1.1); }

.Lips-content {
  position: relative;
  width: 0.92734rem;
  height: 0.49219rem;
  background-image: linear-gradient(#015051, #002F2C);
  border-bottom: 0.00156rem solid #04FDFD;
  padding: 0.01562rem 0.03516rem;
  margin-top: 0.01562rem; }
  .Lips-content::after {
    content: "";
    width: 0.0625rem;
    height: 0.0625rem;
    position: absolute;
    top: 0;
    right: 0;
    background-image: url(/static/media/corner-triangle.5e00af4b.svg);
    background-size: 100%;
    background-position: center; }

.Lips-rockChoices {
  display: flex;
  justify-content: space-between;
  -webkit-box-pack: space-between;
  -ms-flex-pack: space-between;
  -webkit-flex-align: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding: 0.00781rem 0.05469rem; }
  .Lips-rockChoices .Lips-rockChoice {
    display: flex;
    justify-content: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-flex-align: center;
    align-items: center;
    flex-direction: column; }
    .Lips-rockChoices .Lips-rockChoice .Lips-rockChoiceImg {
      width: 0.19531rem;
      height: 0.18438rem;
      border: 0.00234rem solid #04FDFD; }
    .Lips-rockChoices .Lips-rockChoice .Button {
      margin-top: 0.02734rem;
      width: 0.10156rem; }

.Lips-squareChoices .Lips-squareSelection {
  position: absolute;
  top: 0.03906rem;
  left: 0.49219rem;
  font-size: 0.01875rem;
  font-weight: 600;
  width: 0.3125rem;
  height: 0.41406rem; }

.Lips-squareChoices .Button.deleteBtn {
  width: 0.13281rem;
  height: 0.04922rem;
  padding-top: 0.01562rem;
  position: absolute;
  top: 0.06875rem;
  right: 0.02734rem; }

.Lips-squareChoices .Lips-gridCoordinates {
  position: absolute;
  top: 0.14062rem; }
  .Lips-squareChoices .Lips-gridCoordinates .Button.coordBtn {
    width: 0.05703rem;
    height: 0.03906rem;
    display: inline-block;
    margin-top: 0.01562rem;
    margin-right: 0.01875rem; }
    .Lips-squareChoices .Lips-gridCoordinates .Button.coordBtn.selected {
      background-image: linear-gradient(#01696a, #004944);
      color: white; }

.Lips-squareChoices .Button.analyseBtn {
  width: 0.13281rem;
  height: 0.05078rem;
  padding-top: 0.01797rem;
  position: absolute;
  bottom: 0.01562rem;
  right: 0.02734rem; }

.Lips-squareChoices .Lips-rockGrid {
  position: relative; }
  .Lips-squareChoices .Lips-rockGrid .Lips-chosenRock {
    margin-top: 0.02344rem;
    margin-left: 0.02344rem;
    width: 0.34531rem;
    height: 0.32578rem; }
  .Lips-squareChoices .Lips-rockGrid table {
    position: absolute;
    border-collapse: collapse;
    top: 0;
    left: 0; }
    .Lips-squareChoices .Lips-rockGrid table td, .Lips-squareChoices .Lips-rockGrid table th {
      border: 0.00156rem solid #04FDFD;
      text-align: center;
      width: 0.06641rem;
      height: 0.06641rem;
      transition: all 0.3s ease-in-out; }
    .Lips-squareChoices .Lips-rockGrid table thead th {
      border-top: none;
      vertical-align: top;
      height: 0.04688rem; }
      .Lips-squareChoices .Lips-rockGrid table thead th:first-of-type {
        border-left: none; }
    .Lips-squareChoices .Lips-rockGrid table tbody tr td:first-of-type {
      width: 0.06797rem;
      border-left: none;
      text-align: left; }
    .Lips-squareChoices .Lips-rockGrid table tbody tr td:last-of-type {
      border-right: none;
      width: 0.03516rem; }
    .Lips-squareChoices .Lips-rockGrid table tbody tr:last-of-type td {
      border-bottom: none;
      height: 0.03672rem; }
    .Lips-squareChoices .Lips-rockGrid table td.selected {
      border: 0.00234rem solid white;
      box-shadow: inset 0px 0px 0px 0.00156rem white; }
    .Lips-squareChoices .Lips-rockGrid table td.prevSelected {
      position: relative; }
      .Lips-squareChoices .Lips-rockGrid table td.prevSelected::after {
        content: "";
        width: 0.06641rem;
        height: 0.06641rem;
        background-color: rgba(255, 255, 255, 0.45);
        position: absolute;
        top: 0;
        left: 0; }

.Lips-showGraph .Lips-graphImg {
  position: absolute;
  top: -0.03906rem;
  left: -0.04688rem;
  width: 0.78125rem;
  height: 0.53906rem; }

.Lips-showGraph .Lips-selections {
  position: absolute;
  top: 0.03906rem;
  right: 0.07812rem;
  font-size: 0.01875rem;
  font-weight: 600; }

.Lips-showGraph .Button.Button--backBtn {
  position: absolute;
  bottom: 0.01562rem;
  right: 0.00391rem; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.HiddenBase {
  position: absolute;
  left: -0.22656rem;
  right: -0.22656rem;
  top: -0.02734rem;
  bottom: -0.02031rem;
  padding: 0.03125rem 0.03906rem;
  z-index: 10;
  color: white;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/
  background-image: url(/static/media/background.jpg);
  background-size: cover;
  background-position: top left;
  font-family: "Fivo Sans"; }
  .HiddenBase::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(1, 27, 28, 0.8); }

.HiddenBase-close {
  position: absolute;
  top: 0.00781rem;
  right: 0.03516rem;
  width: 0.02344rem;
  height: 0.02344rem;
  background-image: url(/static/media/icon-x.be145b6d.svg);
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/ }
  .HiddenBase-close:hover {
    /* IE 9 */
    -webkit-transform: scale(1.1);
    /* Safari */
    transform: scale(1.1); }

.HiddenBase-content {
  position: relative;
  width: 0.92734rem;
  height: 0.49219rem;
  background-image: linear-gradient(#015051, #002F2C);
  border-bottom: 0.00156rem solid #04FDFD;
  padding: 0.01562rem;
  padding-right: 0;
  margin-top: 0.01562rem; }
  .HiddenBase-content::before {
    content: "";
    width: 0.92734rem;
    height: 0.3875rem;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(#00CBCB, #006666); }
  .HiddenBase-content::after {
    content: "";
    width: 0.0625rem;
    height: 0.0625rem;
    position: absolute;
    top: 0;
    right: 0;
    background-image: url(/static/media/corner-triangle.5e00af4b.svg);
    background-size: 100%;
    background-position: center; }
  .HiddenBase-content .HiddenBase-rooms {
    width: 0.46094rem;
    height: 0.34375rem;
    display: inline-block;
    background-image: url(/static/media/rooms.png);
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    margin-top: 0.03906rem; }
    .HiddenBase-content .HiddenBase-rooms .HiddenBase-room {
      position: absolute;
      top: 0.01328rem;
      left: 0;
      width: 0.09219rem;
      height: 0.06172rem;
      text-align: center;
      display: flex;
      justify-content: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-flex-align: center;
      align-items: center;
      cursor: pointer; }
      .HiddenBase-content .HiddenBase-rooms .HiddenBase-room div {
        z-index: 2; }
      .HiddenBase-content .HiddenBase-rooms .HiddenBase-room span {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(#015051, #002F2C);
        outline: 0.00156rem solid transparent;
        transition: all 0.3s ease-in-out;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" 0 ")";
        /* IE 8 */
        filter: alpha(opacity=0);
        /* IE 5-7 */
        -moz-opacity: 0;
        /* Netscape */
        -khtml-opacity: 0;
        /* Safari 1.x */
        opacity: 0; }
      .HiddenBase-content .HiddenBase-rooms .HiddenBase-room:hover span,
      .HiddenBase-content .HiddenBase-rooms .HiddenBase-room--selected span {
        outline: 0.00156rem solid #04FDFD;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" 100 ")";
        /* IE 8 */
        filter: alpha(opacity=100);
        /* IE 5-7 */
        -moz-opacity: 1;
        /* Netscape */
        -khtml-opacity: 1;
        /* Safari 1.x */
        opacity: 1; }
      .HiddenBase-content .HiddenBase-rooms .HiddenBase-room--office {
        left: 0.02422rem; }
      .HiddenBase-content .HiddenBase-rooms .HiddenBase-room--workshop-lab {
        width: 0.16094rem;
        height: 0.08438rem;
        left: 0.13281rem;
        padding: 0 0.01562rem; }
      .HiddenBase-content .HiddenBase-rooms .HiddenBase-room--kitchen {
        width: 0.125rem;
        height: 0.08438rem;
        left: 0.31016rem; }
      .HiddenBase-content .HiddenBase-rooms .HiddenBase-room--living {
        width: 0.125rem;
        height: 0.16719rem;
        top: 0.12031rem;
        left: 0.31016rem; }
      .HiddenBase-content .HiddenBase-rooms .HiddenBase-room--technic {
        /* IE 9 */
        -webkit-transform: rotate(90deg);
        /* Safari */
        transform: rotate(90deg);
        width: 0.11172rem;
        height: 0.04453rem;
        top: 0.15391rem;
        left: 0.21563rem; }
      .HiddenBase-content .HiddenBase-rooms .HiddenBase-room--reactor {
        width: 0.11172rem;
        height: 0.11094rem;
        border-radius: 50%;
        top: 0.11797rem;
        left: 0.125rem; }
        .HiddenBase-content .HiddenBase-rooms .HiddenBase-room--reactor span {
          border-radius: 50%;
          outline: none;
          border: 0.00156rem solid #04FDFD; }
        .HiddenBase-content .HiddenBase-rooms .HiddenBase-room--reactor:hover span,
        .HiddenBase-content .HiddenBase-rooms .HiddenBase-room--reactor--selected span {
          outline: none; }
      .HiddenBase-content .HiddenBase-rooms .HiddenBase-room--control {
        width: 0.05781rem;
        height: 0.06094rem;
        top: 0.15937rem;
        left: 0.05937rem; }
      .HiddenBase-content .HiddenBase-rooms .HiddenBase-room--storage {
        width: 0.07031rem;
        height: 0.06094rem;
        top: 0.08672rem;
        left: 0.02422rem; }
  .HiddenBase-content .HiddenBase-infoWrapper {
    display: inline-block;
    position: relative;
    vertical-align: top;
    margin-top: 0.01953rem;
    width: 0.42578rem;
    height: 0.30781rem;
    font-size: 0.01562rem;
    font-weight: 600; }
    .HiddenBase-content .HiddenBase-infoWrapper::before, .HiddenBase-content .HiddenBase-infoWrapper::after {
      content: "";
      width: 0.42969rem;
      height: 0.01562rem;
      position: absolute;
      left: -0.00234rem;
      top: 0.03047rem;
      z-index: 2;
      background-image: url(/static/media/border-bottom.25f177ff.svg);
      background-repeat: no-repeat;
      background-size: 100%;
      background-position-y: bottom;
      /* IE 9 */
      -webkit-transform: rotate(180deg);
      /* Safari */
      transform: rotate(180deg); }
    .HiddenBase-content .HiddenBase-infoWrapper::after {
      /* IE 9 */
      -webkit-transform: rotate(0deg);
      /* Safari */
      transform: rotate(0deg);
      top: unset;
      bottom: 0rem;
      background-position-y: bottom; }
    .HiddenBase-content .HiddenBase-infoWrapper .HiddenBase-info {
      height: 0.27656rem;
      padding-top: 0.01562rem;
      padding-left: 0.01562rem;
      padding-right: 0.00391rem;
      margin-top: 0.00781rem;
      overflow: auto;
      position: relative;
      background-image: linear-gradient(#015051, #002F2C); }
      .HiddenBase-content .HiddenBase-infoWrapper .HiddenBase-info::-webkit-scrollbar {
        width: 0.0125rem;
        height: 100%; }
      .HiddenBase-content .HiddenBase-infoWrapper .HiddenBase-info::-webkit-scrollbar-track {
        background-color: #0D5151;
        border-radius: 0; }
      .HiddenBase-content .HiddenBase-infoWrapper .HiddenBase-info::-webkit-scrollbar-thumb {
        border-radius: 0;
        background: #00CBCB;
        width: 0.0125rem;
        height: 0.10859rem;
        border: 1px solid #04FDFD; }
      .HiddenBase-content .HiddenBase-infoWrapper .HiddenBase-info::-webkit-scrollbar-thumb:window-inactive {
        background-color: transparent;
        border: 1px solid #00CBCB; }
      .HiddenBase-content .HiddenBase-infoWrapper .HiddenBase-info img {
        width: 0.40469rem;
        margin-top: 0.00391rem;
        margin-left: -0.01562rem; }
      .HiddenBase-content .HiddenBase-infoWrapper .HiddenBase-info div {
        font-size: 0.0125rem;
        font-weight: 500;
        white-space: pre-line; }
        .HiddenBase-content .HiddenBase-infoWrapper .HiddenBase-info div strong {
          font-weight: 600; }
        .HiddenBase-content .HiddenBase-infoWrapper .HiddenBase-info div:first-of-type {
          margin-right: 0.00781rem; }
      .HiddenBase-content .HiddenBase-infoWrapper .HiddenBase-info--construction {
        display: flex;
        justify-content: space-between;
        -webkit-box-pack: space-between;
        -ms-flex-pack: space-between;
        -webkit-flex-align: flex-start;
        align-items: flex-start; }
        .HiddenBase-content .HiddenBase-infoWrapper .HiddenBase-info--construction div {
          width: 50%;
          padding-bottom: 0.01562rem; }
  .HiddenBase-content .HiddenBase-constructionMethods {
    position: absolute;
    font-size: 0.01562rem;
    font-weight: 600;
    bottom: 0.01562rem; }
    .HiddenBase-content .HiddenBase-constructionMethods p {
      margin-bottom: 0.00781rem; }
    .HiddenBase-content .HiddenBase-constructionMethods .Button {
      margin-right: 0.01562rem;
      margin-bottom: 0.00391rem; }
      .HiddenBase-content .HiddenBase-constructionMethods .Button.selected {
        background-image: linear-gradient(#01696a, #004944);
        color: white; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.EvaluationDndDragLayer {
  position: fixed;
  pointer-events: none;
  z-index: 10;
  left: 0em;
  top: 0em; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.Evaluation {
  position: relative;
  height: 0.41406rem;
  padding: 0.02344rem; }

.Evaluation-container {
  position: relative;
  width: 100%;
  height: 100%;
  margin-left: 0.00781rem;
  margin-top: 0.01562rem; }

.Evaluation-title {
  font-size: 0.01562rem;
  font-family: "Fivo Sans";
  margin-bottom: 0.00781rem; }

.Evaluation-axis {
  position: absolute; }
  .Evaluation-axis--x {
    height: 0.04688rem;
    width: 0.3125rem;
    left: 0.05859rem;
    top: 0.3125rem; }
    .Evaluation-axis--x .Evaluation-axisTitle {
      padding-left: 0.01953rem; }
    .Evaluation-axis--x .Evaluation-axisTic {
      display: inline-block;
      width: 0.0625rem;
      padding-top: 0.00391rem;
      text-align: center; }
  .Evaluation-axis--y {
    height: 0.3125rem;
    width: 0.05469rem;
    top: 0rem;
    left: 0.00391rem; }
    .Evaluation-axis--y .Evaluation-axisTitle {
      /* IE 9 */
      -webkit-transform: rotate(-90deg);
      /* Safari */
      transform: rotate(-90deg);
      -webkit-transform-origin: 0% 0%;
      transform-origin: 0% 0%;
      width: 0.30469rem;
      bottom: -0.02734rem;
      padding-left: 0.01953rem; }
      .Evaluation-axis--y .Evaluation-axisTitle input {
        cursor: vertical-text; }
    .Evaluation-axis--y .Evaluation-axisTic {
      height: 0.0625rem;
      text-align: right;
      padding-right: 0.01172rem;
      padding-top: 0.02344rem; }

.Evaluation-axisTitle {
  position: absolute;
  text-align: left;
  bottom: 0;
  left: 0;
  width: 100%;
  background-image: url(/static/media/icon-pencil.336c2eca.svg);
  background-repeat: no-repeat;
  background-size: 0.01562rem;
  background-position: 0 0.00547rem; }
  .Evaluation-axisTitle input {
    min-width: 0.19531rem;
    font-size: 0.01172rem;
    margin-right: 0.00781rem;
    padding: 0.00391rem 0.00391rem 0.00391rem 0.00781rem;
    background: transparent;
    color: white;
    height: 0.02344rem;
    cursor: text; }
    .Evaluation-axisTitle input:focus {
      background: rgba(16, 160, 160, 0.8); }
    .Evaluation-axisTitle input:-webkit-autofill {
      background: transparent !important;
      color: white !important; }
  .Evaluation-axisTitle .Button {
    position: absolute;
    top: 0;
    right: 0;
    width: 0.06016rem;
    height: 0.02266rem;
    padding-top: 0.00234rem; }

.Evaluation-axisTic {
  font-weight: 600;
  font-size: 0.01016rem;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/ }

.EvaluationGrid {
  position: absolute;
  left: 0.05859rem;
  width: 0.3125rem;
  height: 0.3125rem;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/ }
  .EvaluationGrid .GroupBadge {
    width: 100%;
    height: 100%; }

.EvaluationGrid-square {
  display: inline-block;
  vertical-align: top;
  width: 0.0625rem;
  height: 0.0625rem;
  border: 1px solid #10A0A0;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/ }
  .EvaluationGrid-square--gridSize1 .EvaluationGrid-group {
    width: 0.04688rem;
    height: 0.04688rem;
    margin: 0.00625rem;
    margin-top: 0.00859rem; }
  .EvaluationGrid-square--gridSize4 .EvaluationGrid-group {
    width: 0.02813rem;
    height: 0.02813rem;
    margin-top: 0.00234rem;
    margin-left: 0.00156rem; }
  .EvaluationGrid-square--gridSize9 .EvaluationGrid-group {
    width: 0.01641rem;
    height: 0.01641rem;
    margin-left: 0.00313rem;
    margin-top: 0.00156rem; }
  .EvaluationGrid-square--gridSize12 .EvaluationGrid-group {
    width: 0.01406rem;
    height: 0.01562rem;
    margin-top: 0.00156rem; }

.EvaluationGrid-group {
  display: inline-block;
  width: 0.03125rem;
  height: 0.03125rem;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/ }
  .EvaluationGrid-group--draggable, .EvaluationGrid-group--dragging {
    cursor: move; }
  .EvaluationGrid-group--hover .GroupBadge-icon {
    -webkit-filter: drop-shadow(0 0 0.00547rem rgba(4, 253, 253, 0.63)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00547rem rgba(4, 253, 253, 0.63)) drop-shadow(0 0 0 transparent); }

.EvaluationGroups {
  position: absolute;
  top: 0rem;
  left: 0.38281rem;
  width: 0.11719rem; }

.EvaluationGroups-group {
  display: inline-block;
  margin: 0.00469rem 0.00625rem;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/ }
  .EvaluationGroups-group--draggable {
    cursor: move; }
  .EvaluationGroups-group--hover .GroupBadge-icon {
    -webkit-filter: drop-shadow(0 0 0.00547rem rgba(4, 253, 253, 0.63)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00547rem rgba(4, 253, 253, 0.63)) drop-shadow(0 0 0 transparent); }
  .EvaluationGroups-group--isPlaced .GroupBadge-icon {
    -webkit-filter: none;
    filter: none; }
  .EvaluationGroups-group .GroupBadge {
    width: 0.04609rem;
    background-size: contain; }
    .EvaluationGroups-group .GroupBadge .GroupBadge-name span {
      font-size: 0.00781rem; }

.Evaluation-saveBtn {
  position: absolute;
  left: 0.33594rem;
  top: -0.04688rem; }
  .Evaluation-saveBtn .Button {
    width: 0.16406rem;
    height: 0.03281rem;
    padding: 0.00781rem 0.00078rem; }
    .Evaluation-saveBtn .Button--loading {
      background-size: auto 60%, cover; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.Presentation {
  position: relative;
  height: 100%;
  padding: 0.02344rem; }

.Presentation-title {
  font-size: 0.01562rem;
  font-weight: 600;
  font-family: "Fivo Sans";
  margin-bottom: 0.00781rem; }

.Presentation-text {
  margin-bottom: 0.02344rem; }

.Presentation-table {
  width: 100%;
  margin-bottom: 0.03906rem; }
  .Presentation-table th, .Presentation-table td {
    font-size: 0.00937rem;
    text-align: left;
    padding: 0.00391rem;
    vertical-align: top; }
    .Presentation-table th:nth-child(1), .Presentation-table td:nth-child(1) {
      border-right: 0.00156rem solid #04FDFD; }
  .Presentation-table thead th {
    font-size: 0.01094rem;
    border-bottom: 0.00156rem solid #04FDFD; }
  .Presentation-table tbody td {
    border-bottom: 0.00078rem solid white; }
  .Presentation-table tbody tr:nth-last-child(1) td {
    border-bottom: none; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.GroupLoginStatuses {
  position: relative;
  padding: 0.02344rem; }

.GroupLoginStatuses-title {
  font-family: "Fivo Sans";
  font-size: 0.01875rem;
  margin: 0.01172rem 0; }

.GroupLoginStatuses-info {
  font-size: 0.01562rem;
  padding: 0.00781rem; }
  .GroupLoginStatuses-info p {
    margin: 0; }
  .GroupLoginStatuses-info span {
    float: left;
    width: 0.05078rem;
    font-weight: bold; }

.GroupLoginStatuses-container {
  margin-top: 0.01562rem;
  padding: 0.00781rem; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.Result {
  position: relative;
  padding: 0.02344rem; }
  .Result .Button {
    position: absolute; }
    .Result .Button--activateTagging {
      top: 0.01484rem;
      right: 0.01562rem;
      width: 0.14375rem;
      height: 0.03281rem;
      padding-top: 0.00781rem; }
    .Result .Button--goToNextSession {
      bottom: 0.00781rem;
      right: 0.01562rem; }

.Result-title {
  font-size: 0.01562rem;
  font-family: "Fivo Sans";
  margin-bottom: 0.01562rem; }

.Result-text {
  margin-bottom: 0.02344rem; }

.Result-ranking {
  position: relative;
  overflow: hidden; }

.Result-headerRow {
  width: 95%;
  padding-bottom: 0.00391rem;
  border-bottom: 0.00078rem solid white;
  display: flex;
  justify-content: flex-start;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  -webkit-flex-align: center;
  align-items: center; }
  .Result-headerRow div {
    width: 35%;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    font-size: 0.01406rem; }
    .Result-headerRow div:nth-of-type(1) {
      padding-left: 0.01562rem; }
    .Result-headerRow div:nth-of-type(2) {
      padding-left: 0.05469rem; }
    .Result-headerRow div:nth-of-type(3) {
      padding-left: 0.03906rem; }
  .Result-headerRow--3 div:nth-of-type(1) {
    padding-left: 0.04688rem; }
  .Result-headerRow--3 div:nth-of-type(2) {
    padding-left: 0.05469rem; }
  .Result-headerRow--3 div:nth-of-type(3) {
    padding-left: 0.05469rem; }
  .Result-headerRow--3 div:nth-of-type(4) {
    padding-left: 0.03125rem; }

.Result-groupRow {
  width: 95%;
  display: flex;
  justify-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-flex-align: center;
  align-items: center;
  border-bottom: 0.00078rem solid #10A0A0; }

.Result-groupPoints {
  width: 100%;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  padding-top: 0.00781rem;
  padding-bottom: 0.00781rem;
  border-left: 0.00078rem solid #10A0A0; }
  .Result-groupPoints--total {
    position: relative;
    border-left: 0.00234rem solid #04FDFD;
    width: 50%; }
    .Result-groupPoints--total::before {
      content: "";
      background-color: #04FDFD;
      width: 0.00234rem;
      height: 100%;
      position: absolute;
      left: -0.00234rem;
      top: 0.00234rem; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.BaseTag {
  position: absolute;
  height: 0.06641rem;
  width: 0.05078rem;
  margin-top: -0.01562rem;
  margin-left: -0.01172rem;
  background-image: url(/static/media/tag-empty-glow.40245f2f.svg);
  background-size: auto 0.06641rem;
  background-position: top center;
  background-repeat: no-repeat;
  z-index: 20;
  display: none; }
  .BaseTag--dome {
    top: 0.28516rem;
    left: 0.41797rem; }
  .BaseTag--solarpanels {
    top: 0.36719rem;
    left: 0.07812rem; }
  .BaseTag--ice {
    top: 0.13281rem;
    right: 0.08594rem; }
  .BaseTag--greenhouse {
    top: 0.37109rem;
    left: 0.39062rem; }
  .BaseTag--rocket {
    top: 0.02734rem;
    left: 0.66875rem; }
  .BaseTag--satellite {
    top: 0.22266rem;
    left: 0.74609rem; }
  .BaseTag--entrance {
    top: 0.36719rem;
    left: 0.55859rem; }
  .BaseTag--communication {
    top: 0.30859rem;
    left: 0.67969rem; }
  .BaseTag--dorms {
    top: 0.19531rem;
    left: 0.39062rem; }
  .BaseTag--crater {
    top: 0.02734rem;
    left: 0.86484rem; }
  .BaseTag--vehicles {
    top: 0.22266rem;
    left: 0.88281rem; }
  .BaseTag--lab {
    top: 0.33594rem;
    left: 0.27344rem; }
  .BaseTag--garage {
    top: 0.21875rem;
    left: 0.60156rem; }
  .BaseTag--curiosity {
    top: 0.46094rem;
    left: 0.57422rem; }
  .BaseTag--stars {
    top: 0.01172rem;
    left: 0.39062rem; }
  .BaseTag--spacesuit {
    top: 0.44531rem;
    left: 0.49766rem; }
  .BaseTag--hidden-base {
    top: 0rem;
    left: 0.71328rem; }
  .BaseTag--3dbase {
    top: 0.13281rem;
    left: 0.10938rem; }
  .BaseTag--tagged {
    display: inline-block;
    background-image: none;
    margin-top: 0;
    margin-left: -0.00781rem;
    cursor: pointer; }
  .BaseTag--currentMission {
    display: inline-block;
    /* Fx 5+ */
    /* Opera 12+ */
    -webkit-animation: bounceGlow 2s infinite ease-in-out;
    animation: bounceGlow 2s infinite ease-in-out; }
  .BaseTag--open {
    z-index: 21; }

.BaseTag-icon {
  position: absolute;
  width: 0.04297rem;
  height: 0.05391rem;
  top: -0.00781rem;
  left: 0;
  background-image: url(/static/media/tag-bg-full.2063ad67.svg);
  background-size: 0.04219rem auto;
  background-repeat: no-repeat;
  background-position: top 0.00039rem center;
  text-align: center;
  padding: 0.00234rem;
  padding-top: 0.00937rem;
  z-index: 2; }
  .BaseTag-icon img {
    width: 0.02344rem; }
  .BaseTag-icon--pulsating {
    background-size: 0.04219rem auto;
    background-repeat: no-repeat;
    background-position: top 0.00039rem center;
    background-image: url(/static/media/tag-bg-full-pulsating.829f8588.svg); }
  .BaseTag-icon::after {
    content: "";
    display: block;
    width: 0.01328rem;
    height: 0.02187rem;
    background-image: url(/static/media/tag-cone-full.dde9202e.svg);
    background-size: 100%;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 0;
    left: 0.01406rem; }

.BaseTag-name {
  position: absolute;
  top: -0.00859rem;
  left: 0.00625rem;
  width: 0;
  height: 0.03906rem;
  overflow: hidden;
  background-image: url(/static/media/tag-name-bg.59bfcc82.svg);
  background-size: 0.125rem 0.04297rem;
  background-repeat: no-repeat;
  background-position: left center;
  color: white;
  /* Firefox */
  /* WebKit */
  /* Opera */
  transition: width 0.25s ease-in-out;
  /* Standard */
  font-family: 'Fivo Sans';
  font-size: 0.01172rem;
  line-height: 0.02344rem;
  text-transform: uppercase;
  z-index: 1; }
  .BaseTag-name--open {
    width: 0.125rem; }
  .BaseTag-name input, .BaseTag-name span {
    position: absolute;
    top: 0.00781rem;
    left: 0.02734rem;
    width: 100%;
    color: white;
    padding: 0.00234rem 0.00391rem;
    line-height: 0.02031rem;
    background-color: transparent;
    font-family: 'Fivo Sans';
    font-size: 0.01094rem;
    text-transform: uppercase; }
  .BaseTag-name input.input--teacher {
    width: 78%;
    padding-right: 0.02344rem;
    background-image: url(/static/media/icon-pencil.336c2eca.svg);
    background-size: 0.01328rem;
    background-repeat: no-repeat;
    background-position: right 0.00781rem top 0.00391rem; }

.BaseTag-saveBtn {
  position: absolute;
  top: 0.03516rem;
  left: 0.03906rem; }
  .BaseTag-saveBtn .Button {
    padding-left: 0.01562rem;
    padding-right: 0.01562rem; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.Base-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  visibility: hidden;
  display: block;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" 0 ")";
  /* IE 8 */
  filter: alpha(opacity=0);
  /* IE 5-7 */
  -moz-opacity: 0;
  /* Netscape */
  -khtml-opacity: 0;
  /* Safari 1.x */
  opacity: 0;
  /* Firefox */
  /* WebKit */
  /* Opera */
  transition: opacity 0.8s ease-in-out, visibility 0.8s ease-in-out;
  /* Standard */ }
  .Base-container--closed {
    -webkit-transform-origin: 0.89609rem 0.37891rem;
            transform-origin: 0.89609rem 0.37891rem;
    /* Fx 5+ */
    /* Opera 12+ */
    -webkit-animation: baseZoomOut 0.5s linear 0s 1 forwards;
    animation: baseZoomOut 0.5s linear 0s 1 forwards; }
    .Base-container--closed.Base-container--closeToCenter {
      -webkit-transform-origin: 0.5rem 0.28125rem;
              transform-origin: 0.5rem 0.28125rem;
      /* Fx 5+ */
      /* Opera 12+ */
      -webkit-animation: baseZoomOut2 0.5s linear 0s 1 forwards;
      animation: baseZoomOut2 0.5s linear 0s 1 forwards; }
  .Base-container--open {
    visibility: visible;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1);
    -webkit-transform-origin: 0.5rem 0.28125rem;
            transform-origin: 0.5rem 0.28125rem;
    /* Fx 5+ */
    /* Opera 12+ */
    -webkit-animation: baseZoomIn 0.3s linear 0s 1 forwards;
    animation: baseZoomIn 0.3s linear 0s 1 forwards; }
  .Base-container--fadeIn {
    transition-delay: 0.2s, 0.2s;
    visibility: visible;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" 100 ")";
    /* IE 8 */
    filter: alpha(opacity=100);
    /* IE 5-7 */
    -moz-opacity: 1;
    /* Netscape */
    -khtml-opacity: 1;
    /* Safari 1.x */
    opacity: 1; }

.Base {
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #F1986B;
  background-image: url(/static/media/background.5ea30e69.svg);
  background-size: 100% 100%;
  background-position: top left;
  background-repeat: no-repeat;
  z-index: 1000; }
  .Base--repaired {
    background-image: url(/static/media/background.21a3f5e7.svg); }

.Base-close {
  position: absolute;
  top: 0.01562rem;
  right: 0.01562rem;
  width: 0.02344rem;
  height: 0.02344rem;
  background-image: url(/static/media/icon-x.be145b6d.svg);
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1001;
  cursor: pointer; }
  .Base-close:hover {
    /* IE 9 */
    -webkit-transform: scale(1.1);
    /* Safari */
    transform: scale(1.1); }

.BasePopup {
  position: relative;
  top: 0.03125rem;
  left: 0.32344rem;
  width: 0.33125rem;
  border-top: 0.00156rem solid #04FDFD;
  padding: 0.01875rem 0.02109rem 0.05469rem 0.02109rem;
  background-image: linear-gradient(#004F50, rgba(0, 47, 44, 0));
  font-size: 0.01406rem;
  -webkit-transform-origin: 0.64062rem 0.52344rem;
          transform-origin: 0.64062rem 0.52344rem;
  /* IE 9 */
  -webkit-transform: scale(0);
  /* Safari */
  transform: scale(0);
  /* Firefox */
  /* WebKit */
  /* Opera */
  transition: all 0.5s ease-in-out;
  /* Standard */
  transition-delay: 1.5s, 0s; }
  .BasePopup--open {
    transition-delay: 0s, 0s;
    /* IE 9 */
    -webkit-transform: scale(1);
    /* Safari */
    transform: scale(1); }
  .BasePopup--noAnimation {
    /* IE 9 */
    -webkit-transform: scale(1);
    /* Safari */
    transform: scale(1);
    /* Firefox */
    /* WebKit */
    /* Opera */
    transition: none 0s ease-in-out;
    /* Standard */ }
  .BasePopup--lower {
    top: 0.07812rem; }
  .BasePopup span {
    color: #04FDFD;
    font-weight: 600; }
  .BasePopup .BasePopup-missionTitle {
    font-size: 0.01562rem;
    margin-bottom: 0.01016rem; }
  .BasePopup .BasePopup-playBtn {
    position: absolute;
    width: 0.03594rem;
    height: 0.03594rem;
    right: 0.01562rem;
    bottom: 0.01562rem;
    background-image: url(/static/media/icon-play.f7bcca49.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer; }

.Base-showPopupBtn {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0.01875rem 0.08984rem 0.01875rem 0.02109rem;
  border-top: 0.00156rem solid #04FDFD;
  background-image: linear-gradient(#004F50, rgba(0, 47, 44, 0));
  cursor: pointer;
  opacity: 1;
  /* Firefox */
  /* WebKit */
  /* Opera */
  transition: opacity 0.3s ease-in-out;
  /* Standard */
  transition-delay: 1s, 0s; }
  .Base-showPopupBtn--hide {
    transition-delay: 0s, 0s;
    opacity: 0; }
  .Base-showPopupBtn--noAnimation {
    /* Firefox */
    /* WebKit */
    /* Opera */
    transition: none 0s ease-in-out;
    /* Standard */
    opacity: 0; }
  .Base-showPopupBtn span {
    color: #04FDFD;
    font-weight: 600; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.MissionPanel {
  position: absolute;
  left: 0.22656rem;
  right: 0.22656rem;
  top: 0.025rem;
  bottom: 0.01875rem; }

.MissionPanel-content {
  overflow: auto;
  height: 0.47891rem;
  margin-top: 0.03906rem;
  border-bottom: 0.00156rem solid #04FDFD;
  background-image: linear-gradient(#015051, #002F2C); }
  .MissionPanel-content::-webkit-scrollbar {
    width: 0.00781rem;
    height: 100%; }
  .MissionPanel-content::-webkit-scrollbar-track {
    background-color: rgba(4, 253, 253, 0.2);
    border-radius: 0.00781rem; }
  .MissionPanel-content::-webkit-scrollbar-thumb {
    border-radius: 0.00781rem;
    background: #04FDFD;
    width: 100%;
    height: 0.01562rem;
    border: none; }
  .MissionPanel-content::-webkit-scrollbar-thumb:window-inactive {
    background-color: transparent;
    border: 1px solid #04FDFD; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.WaveSimulator {
  position: absolute;
  left: 0rem;
  right: 0rem;
  top: 0rem;
  bottom: 0rem;
  padding: 0.01562rem 0.03516rem;
  z-index: 10;
  color: white;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/
  background-image: url(/static/media/background.jpg);
  background-size: cover;
  background-position: top left; }
  .WaveSimulator--interference {
    display: flex;
    justify-content: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-flex-align: center;
    align-items: center; }
    .WaveSimulator--interference iframe {
      background-color: black; }
  .WaveSimulator::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: rgba(1, 27, 28, 0.8); }
  .WaveSimulator .WaveSimulator-heading {
    position: relative;
    font-size: 0.03281rem; }
  .WaveSimulator .WaveSimulator-variables {
    font-weight: 600;
    font-size: 0.01562rem; }
  .WaveSimulator .WaveSimulator-variables-container {
    position: relative;
    left: 0.02344rem;
    top: 0.03906rem; }
  .WaveSimulator .WaveSimulator-variables-name,
  .WaveSimulator .WaveSimulator-variables-number,
  .WaveSimulator .WaveSimulator-variables-unit {
    display: inline-block;
    padding-top: 0.00781rem;
    margin-bottom: 0.01562rem; }
  .WaveSimulator .WaveSimulator-variables-name {
    width: 0.10156rem;
    text-align: right;
    margin-right: 0.00781rem; }
  .WaveSimulator .WaveSimulator-variables-number {
    position: relative;
    width: 0.09844rem;
    height: 0.04531rem;
    background-image: linear-gradient(#015051, #002F2C);
    border-top: 0.00156rem solid #04FDFD;
    border-bottom: 0.00156rem solid #04FDFD;
    text-align: center;
    margin-right: 0.00391rem; }
    .WaveSimulator .WaveSimulator-variables-number div {
      padding-right: 0.00391rem; }
    .WaveSimulator .WaveSimulator-variables-number sup {
      position: absolute;
      top: 0rem;
      right: 0.02734rem;
      font-size: 80%; }
  .WaveSimulator .WaveSimulator-controlpanel {
    position: absolute;
    bottom: 0.01406rem;
    right: 0.03516rem;
    height: 0.15078rem;
    width: 0.92969rem;
    background-image: linear-gradient(#015051, #002F2C);
    border-top: 0.00156rem solid #04FDFD;
    border-bottom: 0.00156rem solid #04FDFD; }
    .WaveSimulator .WaveSimulator-controlpanel .Button {
      position: absolute;
      left: 0.04219rem;
      top: 0.05859rem;
      font-size: 0.01875rem;
      width: 0.10547rem;
      border: none; }
  .WaveSimulator .WaveSimulator-controlpanel-type {
    position: absolute;
    top: 0.03125rem;
    left: 0.20078rem;
    text-align: center; }
    .WaveSimulator .WaveSimulator-controlpanel-type .light {
      left: 0.00391rem; }
    .WaveSimulator .WaveSimulator-controlpanel-type .sound {
      left: 0.0625rem; }
    .WaveSimulator .WaveSimulator-controlpanel-type .WaveSimulator-controlpanel-rotary {
      cursor: pointer;
      top: 0.01562rem;
      left: 0.02344rem; }
      .WaveSimulator .WaveSimulator-controlpanel-type .WaveSimulator-controlpanel-rotary.sound {
        /* IE 9 */
        -webkit-transform: rotate(70deg);
        /* Safari */
        transform: rotate(70deg); }
    .WaveSimulator .WaveSimulator-controlpanel-type .WaveSimulator-controlpanel-label {
      position: absolute;
      top: 0.07031rem;
      left: 0.01172rem;
      font-size: 0.01562rem;
      font-weight: 600;
      color: #10A0A0;
      -webkit-touch-callout: none;
      /* iOS Safari */
      -webkit-user-select: none;
      /* Safari */
      /* Konqueror HTML */
      /* Firefox */
      -ms-user-select: none;
      /* Internet Explorer/Edge */
      user-select: none;
      /* Non-prefixed version, currently*/ }
  .WaveSimulator .WaveSimulator-controlpanel-environment {
    position: absolute;
    top: 0.04219rem;
    left: 0.35156rem; }
    .WaveSimulator .WaveSimulator-controlpanel-environment .earth {
      top: -0.01094rem;
      left: 0rem; }
    .WaveSimulator .WaveSimulator-controlpanel-environment .mars {
      position: absolute;
      top: -0.02734rem;
      left: 0.05234rem; }
    .WaveSimulator .WaveSimulator-controlpanel-environment .space {
      position: absolute;
      top: -0.01094rem;
      left: 0.09766rem; }
    .WaveSimulator .WaveSimulator-controlpanel-environment .WaveSimulator-controlpanel-rotary {
      top: 0.00625rem;
      left: 0.04688rem;
      cursor: pointer; }
      .WaveSimulator .WaveSimulator-controlpanel-environment .WaveSimulator-controlpanel-rotary.earth {
        /* IE 9 */
        -webkit-transform: rotate(-7deg);
        /* Safari */
        transform: rotate(-7deg); }
      .WaveSimulator .WaveSimulator-controlpanel-environment .WaveSimulator-controlpanel-rotary.mars {
        /* IE 9 */
        -webkit-transform: rotate(29deg);
        /* Safari */
        transform: rotate(29deg); }
      .WaveSimulator .WaveSimulator-controlpanel-environment .WaveSimulator-controlpanel-rotary.space {
        /* IE 9 */
        -webkit-transform: rotate(73deg);
        /* Safari */
        transform: rotate(73deg); }
    .WaveSimulator .WaveSimulator-controlpanel-environment .WaveSimulator-controlpanel-label {
      position: absolute;
      top: 0.06094rem;
      left: 0.03281rem;
      font-size: 0.01562rem;
      font-weight: 600;
      color: #10A0A0;
      -webkit-touch-callout: none;
      /* iOS Safari */
      -webkit-user-select: none;
      /* Safari */
      /* Konqueror HTML */
      /* Firefox */
      -ms-user-select: none;
      /* Internet Explorer/Edge */
      user-select: none;
      /* Non-prefixed version, currently*/ }
  .WaveSimulator .WaveSimulator-controlpanel-param {
    position: absolute;
    padding: 0 0.00391rem;
    border-radius: 0.00547rem;
    color: white;
    font-size: 0.0125rem;
    font-weight: 600;
    cursor: pointer;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    /* Konqueror HTML */
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently*/ }
  .WaveSimulator .WaveSimulator-controlpanel-rotary {
    position: absolute;
    width: 0.05156rem;
    height: 0.0625rem;
    background-image: url(/static/media/rotary-button.79667cfc.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    -webkit-transform-origin: center;
            transform-origin: center;
    /* Firefox */
    /* WebKit */
    /* Opera */
    transition: -webkit-transform 0.2s linear;
    transition: transform 0.2s linear;
    transition: transform 0.2s linear, -webkit-transform 0.2s linear;
    /* Standard */ }
  .WaveSimulator .WaveSimulator-controlpanel-sliders {
    position: relative;
    top: 0.00781rem;
    left: 0.57031rem; }
  .WaveSimulator .WaveSimulator-controlpanel-lock {
    position: absolute;
    top: 0.01875rem;
    left: -0.03125rem;
    width: 0.01172rem;
    height: 0.01797rem;
    background-image: url(/static/media/icon-lock.d0ba3a2e.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    cursor: pointer; }
    .WaveSimulator .WaveSimulator-controlpanel-lock:hover {
      /* IE 9 */
      -webkit-transform: scale(1.1);
      /* Safari */
      transform: scale(1.1); }
    .WaveSimulator .WaveSimulator-controlpanel-lock.unlocked {
      width: 0.01406rem;
      height: 0.02031rem;
      left: -0.03203rem;
      background-image: url(/static/media/icon-lock-unlocked.61f5b440.svg); }
  .WaveSimulator .WaveSimulator-controlpanel-wavelength,
  .WaveSimulator .WaveSimulator-controlpanel-frequency,
  .WaveSimulator .WaveSimulator-controlpanel-temperature {
    font-size: 0.01328rem;
    font-weight: 600;
    margin-left: 0.01172rem;
    position: relative; }
  .WaveSimulator .WaveSimulator-controlpanel-range {
    -webkit-appearance: none;
    appearance: none;
    width: 0.31719rem;
    height: 0.01562rem;
    border-radius: 0.03906rem;
    background-color: #002F2C;
    margin-bottom: 0.00781rem;
    outline: none;
    box-shadow: 0 0 0.00469rem 0 #04FDFD; }
  .WaveSimulator .WaveSimulator-controlpanel-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background-color: #04FDFD;
    width: 0.04609rem;
    height: 0.01406rem;
    border-radius: 0.03906rem; }
  .WaveSimulator .WaveSimulator-controlpanel-range::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    background-color: #04FDFD;
    width: 0.04609rem;
    height: 0.01406rem;
    border-radius: 0.03906rem; }
  .WaveSimulator .WaveSimulator-view {
    position: absolute;
    right: 0.03516rem;
    top: 0.07031rem;
    width: 0.54688rem;
    height: 0.3125rem;
    background-image: linear-gradient(#015051, #002F2C);
    border-bottom: 0.00156rem solid #0D5151;
    overflow: hidden; }
    .WaveSimulator .WaveSimulator-view:before {
      content: "";
      width: 100%;
      height: 0.00156rem;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #04FDFD; }
    .WaveSimulator .WaveSimulator-view:after {
      content: "";
      position: absolute;
      width: 0.0625rem;
      height: 0.0625rem;
      top: -0.00156rem;
      right: -0.00156rem;
      background-image: url(/static/media/corner-triangle.5e00af4b.svg);
      background-size: contain;
      background-repeat: no-repeat; }
  .WaveSimulator #WaveSimulator-canvas {
    width: 0.54688rem;
    height: 0.3125rem; }
  .WaveSimulator .WaveSimulator-close {
    position: absolute;
    top: 0.02344rem;
    right: 0.03516rem;
    width: 0.02344rem;
    height: 0.02344rem;
    background-image: url(/static/media/icon-x.be145b6d.svg);
    background-size: 99%;
    background-repeat: no-repeat;
    cursor: pointer;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    /* Konqueror HTML */
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently*/ }
    .WaveSimulator .WaveSimulator-close:hover {
      /* IE 9 */
      -webkit-transform: scale(1.1);
      /* Safari */
      transform: scale(1.1); }
  .WaveSimulator .InstructionsIcon {
    top: 0.07812rem;
    right: 0.04297rem;
    width: 0.01875rem;
    height: 0.01875rem;
    background-image: url(/static/media/icon-instructions-light.dcf5e07b.svg);
    z-index: 2; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.BackupAdmin {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  color: black;
  padding: 0.05469rem 0.01562rem;
  overflow-y: auto; }
  .BackupAdmin input {
    display: inline-block;
    vertical-align: middle;
    width: 0.25rem;
    font-size: 0.0125rem; }
  .BackupAdmin textarea {
    width: 0.25rem;
    height: 0.1875rem;
    font-size: 0.0125rem; }

.BackupAdmin-title {
  font-weight: bold;
  font-size: 0.01406rem;
  margin: 0.01406rem 0; }

.BackupAdmin-backBtn {
  position: absolute;
  top: 0.0125rem;
  left: 0.0125rem;
  display: inline-block;
  background-color: lightgrey;
  padding: 0.5em 0.9em;
  border-radius: 0.3em;
  font-weight: bold;
  margin: 0;
  cursor: pointer; }
  .BackupAdmin-backBtn:hover {
    background-color: #c6c6c6; }

.BackupAdmin-action,
.BackupAdmin-uploadFile {
  width: 40em;
  display: block;
  margin-top: 1em;
  background-color: lightgrey;
  padding: 0.5em 0.3em;
  border-radius: 0.25em;
  font-weight: bold;
  text-align: center;
  cursor: pointer; }
  .BackupAdmin-action:hover,
  .BackupAdmin-uploadFile:hover {
    background-color: #c6c6c6; }
  .BackupAdmin-action.loading,
  .BackupAdmin-uploadFile.loading {
    background-image: url(/static/media/icon-loading.bf682090.svg);
    background-size: 2em auto;
    background-position: right 0.00391rem center;
    background-repeat: no-repeat; }

.BackupAdmin-uploadFile {
  width: 20em;
  padding: 0.5em 0.3em; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.StoriesAdmin {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  color: black;
  padding: 0.03906rem 0.01562rem;
  overflow-y: scroll; }

.StoriesAdmin-backBtn {
  position: absolute;
  top: 0.00781rem;
  left: 0.01562rem;
  display: inline-block;
  background-color: lightgrey;
  padding: 0.00625rem 0.00937rem;
  border-radius: 0.00391rem;
  font-weight: bold;
  margin: 0;
  cursor: pointer; }
  .StoriesAdmin-backBtn:hover {
    background-color: #c6c6c6; }

.StoriesAdmin-saveBtn {
  width: 0.07812rem;
  display: block;
  margin-top: 0.02344rem;
  background-color: lightgrey;
  padding: 0.00625rem 0.00937rem;
  border-radius: 0.00391rem;
  font-weight: bold;
  text-align: center;
  cursor: pointer; }
  .StoriesAdmin-saveBtn:hover {
    background-color: #c6c6c6; }
  .StoriesAdmin-saveBtn.disabled {
    opacity: 0.3;
    cursor: not-allowed; }
    .StoriesAdmin-saveBtn.disabled:hover {
      background-color: lightgrey; }
  .StoriesAdmin-saveBtn.saving {
    background-image: url(/static/media/icon-loading.bf682090.svg);
    background-size: 0.02344rem auto;
    background-position: right 0.00391rem center;
    background-repeat: no-repeat; }

.StoriesAdmin-title {
  font-weight: bold;
  font-size: 0.01406rem;
  margin: 0.01562rem 0 0.02344rem 0; }

.StoriesAdmin-navForm {
  display: inline-block;
  vertical-align: top;
  margin-right: 0.01562rem;
  width: 0.23438rem; }

.StoriesAdmin-form {
  display: block;
  margin-bottom: 0.03906rem; }

.StoriesAdmin-inputRow {
  margin: 0.00781rem 0; }

span.StoriesAdmin-label {
  display: inline-block;
  vertical-align: middle;
  width: 0.10156rem;
  font-weight: bold; }

p.StoriesAdmin-label {
  font-weight: bold;
  margin-bottom: 0.00391rem; }

.StoriesAdmin-input {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.0125rem;
  width: 0.27344rem;
  border: 1px solid rgba(0, 0, 0, 0.25); }
  .StoriesAdmin-input.long {
    width: 0.35156rem; }
  .StoriesAdmin-input.short {
    width: 0.03906rem; }

.StoriesAdmin-textarea {
  width: 0.39062rem;
  height: 0.23438rem;
  font-size: 0.0125rem;
  vertical-align: top;
  border: 1px solid rgba(0, 0, 0, 0.25); }
  .StoriesAdmin-textarea.small {
    height: 0.07812rem; }

.StoriesAdmin-radioButtonWrap {
  display: inline-block;
  margin-right: 0.01562rem; }

.StoriesAdmin-radio {
  -webkit-appearance: radio;
  appearance: radio; }

.StoriesAdmin-checkboxWrap {
  display: inline-block;
  margin-right: 0.01562rem; }

.StoriesAdmin-checkbox {
  -webkit-appearance: checkbox;
  appearance: checkbox; }

.StoriesAdmin-addBtn {
  width: 0.11719rem;
  display: block;
  margin-top: 0.02344rem;
  background-color: white;
  padding: 0.00625rem 0.00937rem;
  border-radius: 0.00391rem;
  border: 0.00078rem solid black;
  font-weight: bold;
  text-align: center;
  cursor: pointer; }
  .StoriesAdmin-addBtn:hover {
    background-color: rgba(211, 211, 211, 0.5); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.Tips {
  position: absolute;
  top: 0.01562rem;
  right: 0.01562rem;
  height: 0.025rem;
  overflow: hidden;
  z-index: 1;
  cursor: pointer; }
  .Tips.show {
    height: auto; }
  .Tips table {
    width: 0.3125rem; }
  .Tips td {
    font-size: 0.01562rem;
    width: 0.15625rem;
    vertical-align: middle; }
  .Tips td.left {
    text-align: left; }
  .Tips td.right {
    text-align: right; }
  .Tips pre {
    margin: 0;
    font-size: 0.01562rem; }
  .Tips ul, .Tips ol {
    margin: 0;
    padding-left: 0.01953rem; }
  .Tips h1, .Tips h2, .Tips h3 {
    margin: 0; }
  .Tips--minigame {
    top: 0.16406rem;
    right: -0.01562rem; }
    .Tips--minigame table {
      width: 0.1875rem; }
    .Tips--minigame td, .Tips--minigame pre {
      font-size: 0.0125rem; }
    .Tips--minigame td {
      width: 0.09375rem; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.PagesAdmin {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  color: black;
  padding: 0.03906rem 0.01562rem;
  overflow-y: scroll; }

.PagesAdmin-backBtn {
  position: absolute;
  top: 0.00781rem;
  left: 0.01562rem;
  display: inline-block;
  background-color: lightgrey;
  padding: 0.00625rem 0.00937rem;
  border-radius: 0.00391rem;
  font-weight: bold;
  margin: 0;
  cursor: pointer; }
  .PagesAdmin-backBtn:hover {
    background-color: #c6c6c6; }

.PagesAdmin-saveBtn {
  width: 0.07812rem;
  display: block;
  margin-top: 0.02344rem;
  background-color: lightgrey;
  padding: 0.00625rem 0.00937rem;
  border-radius: 0.00391rem;
  font-weight: bold;
  text-align: center;
  cursor: pointer; }
  .PagesAdmin-saveBtn:hover {
    background-color: #c6c6c6; }
  .PagesAdmin-saveBtn.disabled {
    opacity: 0.3;
    cursor: not-allowed; }
    .PagesAdmin-saveBtn.disabled:hover {
      background-color: lightgrey; }
  .PagesAdmin-saveBtn.saving {
    background-image: url(/static/media/icon-loading.bf682090.svg);
    background-size: 0.02344rem auto;
    background-position: right 0.00391rem center;
    background-repeat: no-repeat; }

.PagesAdmin-title {
  font-weight: bold;
  font-size: 0.01406rem;
  margin: 0.01562rem 0 0.02344rem 0; }

.PagesAdmin-navForm {
  display: inline-block;
  vertical-align: top;
  margin-right: 0.01562rem;
  width: 0.23438rem; }

.PagesAdmin-form {
  display: block;
  margin-bottom: 0.03906rem; }

.PagesAdmin-inputRow {
  margin: 0.00781rem 0; }

span.PagesAdmin-label {
  display: inline-block;
  vertical-align: middle;
  width: 0.07812rem;
  font-weight: bold; }

p.PagesAdmin-label {
  font-weight: bold;
  margin-bottom: 0.00391rem; }

.PagesAdmin-input {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.0125rem;
  width: 0.27344rem;
  border: 1px solid rgba(0, 0, 0, 0.25); }

.PagesAdmin-textarea {
  width: 0.39062rem;
  height: 0.23438rem;
  font-size: 0.0125rem;
  vertical-align: top;
  border: 1px solid rgba(0, 0, 0, 0.25); }
  .PagesAdmin-textarea.small {
    height: 0.07812rem; }

.PagesAdmin-radioButtonWrap {
  display: inline-block;
  margin-right: 0.01562rem; }

.PagesAdmin-radio {
  -webkit-appearance: radio;
  appearance: radio; }

.PagesAdmin-preview {
  display: inline-block;
  vertical-align: bottom;
  border: 1px solid black;
  width: 0.39062rem;
  height: 0.23438rem;
  padding: 0 0.00781rem;
  margin-left: 0.03906rem;
  overflow: auto; }
  .PagesAdmin-preview.small {
    height: 0.07812rem; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.MinigamesAdmin {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  color: black;
  padding: 0.03906rem 0.01562rem;
  overflow-y: scroll; }

.MinigamesAdmin-backBtn {
  position: absolute;
  top: 0.00781rem;
  left: 0.01562rem;
  display: inline-block;
  background-color: lightgrey;
  padding: 0.00625rem 0.00937rem;
  border-radius: 0.00391rem;
  font-weight: bold;
  margin: 0;
  cursor: pointer; }
  .MinigamesAdmin-backBtn:hover {
    background-color: #c6c6c6; }

.MinigamesAdmin-saveBtn {
  width: 0.07812rem;
  display: block;
  margin-top: 0.02344rem;
  background-color: lightgrey;
  padding: 0.00625rem 0.00937rem;
  border-radius: 0.00391rem;
  font-weight: bold;
  text-align: center;
  cursor: pointer; }
  .MinigamesAdmin-saveBtn:hover {
    background-color: #c6c6c6; }
  .MinigamesAdmin-saveBtn.disabled {
    opacity: 0.3;
    cursor: not-allowed; }
    .MinigamesAdmin-saveBtn.disabled:hover {
      background-color: lightgrey; }
  .MinigamesAdmin-saveBtn.saving {
    background-image: url(/static/media/icon-loading.bf682090.svg);
    background-size: 0.02344rem auto;
    background-position: right 0.00391rem center;
    background-repeat: no-repeat; }

.MinigamesAdmin-title {
  font-weight: bold;
  font-size: 0.01406rem;
  margin: 0.01562rem 0 0.02344rem 0; }

.MinigamesAdmin-navForm {
  display: inline-block;
  vertical-align: top;
  margin-right: 0.01562rem;
  width: 0.23438rem; }

.MinigamesAdmin-form {
  display: block;
  margin-bottom: 0.03906rem; }

.MinigamesAdmin-inputRow {
  margin: 0.00781rem 0; }

span.MinigamesAdmin-label {
  display: inline-block;
  vertical-align: middle;
  width: 0.07812rem;
  font-weight: bold; }

p.MinigamesAdmin-label {
  font-weight: bold;
  margin-bottom: 0.00391rem; }

.MinigamesAdmin-input {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.0125rem;
  width: 0.27344rem;
  border: 1px solid rgba(0, 0, 0, 0.25); }

.MinigamesAdmin-textarea {
  width: 0.39062rem;
  height: 0.23438rem;
  font-size: 0.0125rem;
  vertical-align: top;
  border: 1px solid rgba(0, 0, 0, 0.25); }
  .MinigamesAdmin-textarea.small {
    height: 0.07812rem; }

.MinigamesAdmin-radioButtonWrap {
  display: inline-block;
  margin-right: 0.01562rem; }

.MinigamesAdmin-radio {
  -webkit-appearance: radio;
  appearance: radio; }

.MinigamesAdmin-preview {
  display: inline-block;
  vertical-align: bottom;
  border: 1px solid black;
  width: 0.39062rem;
  height: 0.23438rem;
  padding: 0 0.00781rem;
  margin-left: 0.03906rem;
  overflow: auto; }
  .MinigamesAdmin-preview.small {
    height: 0.07812rem; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.InvestigationsAdmin {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  color: black;
  padding: 0.03906rem 0.01562rem;
  overflow-y: scroll; }

.InvestigationsAdmin-backBtn {
  position: absolute;
  top: 0.00781rem;
  left: 0.01562rem;
  display: inline-block;
  background-color: lightgrey;
  padding: 0.00625rem 0.00937rem;
  border-radius: 0.00391rem;
  font-weight: bold;
  margin: 0;
  cursor: pointer; }
  .InvestigationsAdmin-backBtn:hover {
    background-color: #c6c6c6; }

.InvestigationsAdmin-saveBtn {
  width: 0.07812rem;
  display: block;
  margin-top: 0.02344rem;
  background-color: lightgrey;
  padding: 0.00625rem 0.00937rem;
  border-radius: 0.00391rem;
  font-weight: bold;
  text-align: center;
  cursor: pointer; }
  .InvestigationsAdmin-saveBtn:hover {
    background-color: #c6c6c6; }
  .InvestigationsAdmin-saveBtn.disabled {
    opacity: 0.3;
    cursor: not-allowed; }
    .InvestigationsAdmin-saveBtn.disabled:hover {
      background-color: lightgrey; }
  .InvestigationsAdmin-saveBtn.saving {
    background-image: url(/static/media/icon-loading.bf682090.svg);
    background-size: 0.02344rem auto;
    background-position: right 0.00391rem center;
    background-repeat: no-repeat; }

.InvestigationsAdmin-title {
  font-weight: bold;
  font-size: 0.01406rem;
  margin: 0.01562rem 0 0.02344rem 0; }

.InvestigationsAdmin-navForm {
  display: inline-block;
  vertical-align: top;
  margin-right: 0.01562rem;
  width: 0.23438rem; }

.InvestigationsAdmin-form {
  display: block;
  margin-bottom: 0.03906rem; }

.InvestigationsAdmin-inputRow {
  margin: 0.00781rem 0; }

span.InvestigationsAdmin-label {
  display: inline-block;
  vertical-align: middle;
  width: 0.07812rem;
  font-weight: bold; }

p.InvestigationsAdmin-label {
  font-weight: bold;
  margin-bottom: 0.00391rem; }

.InvestigationsAdmin-input {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.0125rem;
  width: 0.27344rem;
  border: 1px solid rgba(0, 0, 0, 0.25); }

.InvestigationsAdmin-textarea {
  width: 0.39062rem;
  height: 0.23438rem;
  font-size: 0.0125rem;
  vertical-align: top;
  border: 1px solid rgba(0, 0, 0, 0.25); }
  .InvestigationsAdmin-textarea.small {
    height: 0.07812rem; }

.InvestigationsAdmin-radioButtonWrap {
  display: inline-block;
  margin-right: 0.01562rem; }

.InvestigationsAdmin-radio {
  -webkit-appearance: radio;
  appearance: radio; }

.InvestigationsAdmin-preview {
  display: inline-block;
  vertical-align: bottom;
  border: 1px solid black;
  width: 0.39062rem;
  height: 0.23438rem;
  padding: 0 0.00781rem;
  margin-left: 0.03906rem;
  overflow: auto; }
  .InvestigationsAdmin-preview.small {
    height: 0.07812rem; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.CyoaGamesAdmin {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  color: black;
  padding: 0.03906rem 0.01562rem;
  overflow-y: scroll; }

.CyoaGamesAdmin-backBtn {
  position: absolute;
  top: 0.00781rem;
  left: 0.01562rem;
  display: inline-block;
  background-color: lightgrey;
  padding: 0.00625rem 0.00937rem;
  border-radius: 0.00391rem;
  font-weight: bold;
  margin: 0;
  cursor: pointer; }
  .CyoaGamesAdmin-backBtn:hover {
    background-color: #c6c6c6; }

.CyoaGamesAdmin-saveBtn {
  width: 0.07812rem;
  display: block;
  margin-top: 0.02344rem;
  background-color: lightgrey;
  padding: 0.00625rem 0.00937rem;
  border-radius: 0.00391rem;
  font-weight: bold;
  text-align: center;
  cursor: pointer; }
  .CyoaGamesAdmin-saveBtn:hover {
    background-color: #c6c6c6; }
  .CyoaGamesAdmin-saveBtn.disabled {
    opacity: 0.3;
    cursor: not-allowed; }
    .CyoaGamesAdmin-saveBtn.disabled:hover {
      background-color: lightgrey; }
  .CyoaGamesAdmin-saveBtn.saving {
    background-image: url(/static/media/icon-loading.bf682090.svg);
    background-size: 0.02344rem auto;
    background-position: right 0.00391rem center;
    background-repeat: no-repeat; }

.CyoaGamesAdmin-title {
  font-weight: bold;
  font-size: 0.01406rem;
  margin: 0.01562rem 0 0.02344rem 0; }

.CyoaGamesAdmin-navForm {
  display: inline-block;
  vertical-align: top;
  margin-right: 0.01562rem;
  width: 0.23438rem; }

.CyoaGamesAdmin-form {
  display: block;
  margin-bottom: 0.03906rem; }

.CyoaGamesAdmin-inputRow {
  margin: 0.00781rem 0; }

span.CyoaGamesAdmin-label {
  display: inline-block;
  vertical-align: middle;
  width: 0.07812rem;
  font-weight: bold; }

p.CyoaGamesAdmin-label {
  font-weight: bold;
  margin-bottom: 0.00391rem; }

.CyoaGamesAdmin-input {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.0125rem;
  width: 0.27344rem;
  border: 1px solid rgba(0, 0, 0, 0.25); }

.CyoaGamesAdmin-textarea {
  width: 0.39062rem;
  height: 0.23438rem;
  font-size: 0.0125rem;
  vertical-align: top;
  border: 1px solid rgba(0, 0, 0, 0.25); }
  .CyoaGamesAdmin-textarea.small {
    height: 0.07812rem; }

.CyoaGamesAdmin-radioButtonWrap {
  display: inline-block;
  margin-right: 0.01562rem; }

.CyoaGamesAdmin-radio {
  -webkit-appearance: radio;
  appearance: radio; }

.CyoaGamesAdmin-preview {
  display: inline-block;
  vertical-align: bottom;
  border: 1px solid black;
  width: 0.39062rem;
  height: 0.23438rem;
  padding: 0 0.00781rem;
  margin-left: 0.03906rem;
  overflow: auto; }
  .CyoaGamesAdmin-preview.small {
    height: 0.07812rem; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.WaveSimulatorsAdmin {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  color: black;
  padding: 0.03906rem 0.01562rem;
  overflow-y: scroll; }

.WaveSimulatorsAdmin-backBtn {
  position: absolute;
  top: 0.00781rem;
  left: 0.01562rem;
  display: inline-block;
  background-color: lightgrey;
  padding: 0.00625rem 0.00937rem;
  border-radius: 0.00391rem;
  font-weight: bold;
  margin: 0;
  cursor: pointer; }
  .WaveSimulatorsAdmin-backBtn:hover {
    background-color: #c6c6c6; }

.WaveSimulatorsAdmin-saveBtn {
  width: 0.07812rem;
  display: block;
  margin-top: 0.02344rem;
  background-color: lightgrey;
  padding: 0.00625rem 0.00937rem;
  border-radius: 0.00391rem;
  font-weight: bold;
  text-align: center;
  cursor: pointer; }
  .WaveSimulatorsAdmin-saveBtn:hover {
    background-color: #c6c6c6; }
  .WaveSimulatorsAdmin-saveBtn.disabled {
    opacity: 0.3;
    cursor: not-allowed; }
    .WaveSimulatorsAdmin-saveBtn.disabled:hover {
      background-color: lightgrey; }
  .WaveSimulatorsAdmin-saveBtn.saving {
    background-image: url(/static/media/icon-loading.bf682090.svg);
    background-size: 0.02344rem auto;
    background-position: right 0.00391rem center;
    background-repeat: no-repeat; }

.WaveSimulatorsAdmin-title {
  font-weight: bold;
  font-size: 0.01406rem;
  margin: 0.01562rem 0 0.02344rem 0; }

.WaveSimulatorsAdmin-navForm {
  display: inline-block;
  vertical-align: top;
  margin-right: 0.01562rem;
  width: 0.23438rem; }

.WaveSimulatorsAdmin-form {
  display: block;
  margin-bottom: 0.03906rem; }

.WaveSimulatorsAdmin-inputRow {
  margin: 0.00781rem 0; }

span.WaveSimulatorsAdmin-label {
  display: inline-block;
  vertical-align: middle;
  width: 0.07812rem;
  font-weight: bold; }

p.WaveSimulatorsAdmin-label {
  font-weight: bold;
  margin-bottom: 0.00391rem; }

.WaveSimulatorsAdmin-input {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.0125rem;
  width: 0.27344rem;
  border: 1px solid rgba(0, 0, 0, 0.25); }

.WaveSimulatorsAdmin-textarea {
  width: 0.39062rem;
  height: 0.23438rem;
  font-size: 0.0125rem;
  vertical-align: top;
  border: 1px solid rgba(0, 0, 0, 0.25); }
  .WaveSimulatorsAdmin-textarea.small {
    height: 0.07812rem; }

.WaveSimulatorsAdmin-radioButtonWrap {
  display: inline-block;
  margin-right: 0.01562rem; }

.WaveSimulatorsAdmin-radio {
  -webkit-appearance: radio;
  appearance: radio; }

.WaveSimulatorsAdmin-preview {
  display: inline-block;
  vertical-align: bottom;
  border: 1px solid black;
  width: 0.39062rem;
  height: 0.23438rem;
  padding: 0 0.00781rem;
  margin-left: 0.03906rem;
  overflow: auto; }
  .WaveSimulatorsAdmin-preview.small {
    height: 0.07812rem; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.StatsAdmin {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  color: black;
  padding: 0.03906rem 0.01562rem;
  overflow-y: scroll; }
  .StatsAdmin-title {
    font-weight: bold; }

.StatsAdmin-loading span {
  -webkit-animation-name: loading-dots;
          animation-name: loading-dots;
  -webkit-animation-duration: 1.4s;
          animation-duration: 1.4s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.StatsAdmin-loading span:nth-child(2) {
  -webkit-animation-delay: .2s;
          animation-delay: .2s; }

.StatsAdmin-loading span:nth-child(3) {
  -webkit-animation-delay: .4s;
          animation-delay: .4s; }

.StatsAdmin-backBtn {
  position: absolute;
  top: 0.00781rem;
  left: 0.01562rem;
  display: inline-block;
  background-color: lightgrey;
  padding: 0.00625rem 0.00937rem;
  border-radius: 0.00391rem;
  font-weight: bold;
  margin: 0;
  cursor: pointer; }
  .StatsAdmin-backBtn:hover {
    background-color: #c6c6c6; }

.StatsAdmin-overview {
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-content: space-evenly;
  align-items: center; }
  .StatsAdmin-overview-title {
    font-weight: bold; }
  .StatsAdmin-overview .StatsAdmin-overviewContent {
    display: flex;
    width: 80%;
    justify-content: space-evenly;
    align-items: center; }

.StatsAdmin-users table {
  border-style: solid; }
  .StatsAdmin-users table thead {
    font-weight: bold; }
  .StatsAdmin-users table td:last-child {
    text-align: center; }

@-webkit-keyframes loading-dots {
  0% {
    opacity: .2; }
  20% {
    opacity: 1; }
  100% {
    opacity: .2; } }

@keyframes loading-dots {
  0% {
    opacity: .2; }
  20% {
    opacity: 1; }
  100% {
    opacity: .2; } }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.Admin {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  color: black;
  padding: 0.01562rem;
  overflow-y: auto; }
  .Admin .Admin-link {
    width: 0.27344rem;
    background-color: lightgrey;
    padding: 0.00391rem 0.00937rem;
    border-radius: 0.00391rem;
    font-weight: bold;
    cursor: pointer; }
    .Admin .Admin-link:hover {
      background-color: #c6c6c6; }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

.App {
  height: 100%;
  min-height: 0.5625rem;
  min-width: 1rem;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 0.0125rem;
  background-color: black;
  display: flex;
  justify-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-flex-align: center;
  align-items: center; }

.App-letterBoxWrap {
  width: 1rem;
  height: 0.5625rem;
  background-color: #011B1C;
  position: relative;
  overflow: hidden; }

@media print {
  .App {
    display: block;
    background-color: white;
    width: 100vw;
    height: 100vh; }
  .App-letterBoxWrap {
    width: 100%;
    height: 100%;
    background-color: white; } }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/fivosans-regular.5131c9e5.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/fivosans-medium.617a047a.otf); }

@font-face {
  font-family: 'Fivo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/fivosans-light.4fd8c45d.otf); }

/* Colors */
/* Images */
/* Appearance & touch action & no select */
/* Flex */
/* Transforms */
/* Opacity */
/* Input & Textarea */
/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
/* Box shadow */
/* Box shadow for svgs, pngs, etc. */
/* Corner box */
/* Corner box big */
/* Transitions */
/* Animations */
/* Fade in */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Base zoom in */
@-webkit-keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

@keyframes baseZoomIn {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0.9, 0.9, 1);
    /* Safari */
    transform: scale3d(0.9, 0.9, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); } }

/* Base zoom out */
@-webkit-keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes baseZoomOut2 {
  0% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

/* Base zoom out */
/* Instructions zoom out */
@-webkit-keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@keyframes instructionsZoomOut {
  0% {
    /* IE 9 */
    -webkit-transform: scale3d(1, 1, 1);
    /* Safari */
    transform: scale3d(1, 1, 1); }
  100% {
    /* IE 9 */
    -webkit-transform: scale3d(0, 0, 1);
    /* Safari */
    transform: scale3d(0, 0, 1); } }

@-webkit-keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

@keyframes bounceGlow {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%, 75% {
    -webkit-transform: translateY(0.00547rem);
    transform: translateY(0.00547rem); }
  50% {
    -webkit-filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent);
    filter: drop-shadow(0 0 0.00781rem rgba(4, 253, 253, 0.7)) drop-shadow(0 0 0 transparent); } }

html {
  font-size: 100vw;
  width: 100%;
  height: 100%;
  background-color: black;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  touch-action: manipulation;
  -ms-touch-action: manipulation; }

body {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  line-height: 1.5;
  background-color: black;
  touch-action: manipulation;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent; }

h1 {
  font-family: 'Fivo Sans', sans-serif;
  font-size: 0.025rem;
  text-transform: uppercase;
  margin: 0.01172rem 0; }

h2 {
  font-family: 'Fivo Sans', sans-serif;
  font-size: 0.01406rem;
  font-weight: bold;
  margin: 0.00781rem 0; }

ul, ol {
  -webkit-padding-start: 0.02344rem;
          padding-inline-start: 0.02344rem;
  margin: 0; }

input,
textarea {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 0.0125rem;
  line-height: 1.5em;
  border: none;
  outline: none;
  -webkit-appearance: none;
  appearance: none; }

button {
  background-image: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/ }
  button:focus {
    outline: 0; }
  button::-moz-focus-inner {
    border: 0; }

* {
  box-sizing: border-box; }

@media (min-aspect-ratio: 16 / 9) {
  html {
    font-size: calc(100vh * 1.77777778); } }

