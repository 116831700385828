$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.InvestigationTutorial {
	position: absolute;
	left: vw-calc(-320);
	right: vw-calc(-320);
	top: vw-calc(-30);
	bottom: vw-calc(-24);
	padding: vw-calc(20) vw-calc(100) vw-calc(20) vw-calc(20);
	z-index: 10;
	background-image: url('../../assets/images/background.jpg');
	background-size: cover;
	background-position: top left;
	&::before {
		content:"";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($bg-dark, .8);
	}
	.Clues {margin-left: vw-calc(80);}
	.WriteSubmission {margin-left: vw-calc(120);}
	.GroupSubmissions {margin-left: vw-calc(55);}
}

.InvestigationTutorial-instructionsBtn {
	position: absolute;
	top: vw-calc(15);
	right: vw-calc(85);
	cursor: pointer;
	width: vw-calc(30);
	height: vw-calc(30);
	background-image: url('../../assets/images/icon-instructions.svg');
	background-size: contain;
	background-repeat: no-repeat;
	z-index: 12;
	&.show {
		top: vw-calc(20);
		right: vw-calc(100);
		width: vw-calc(20);
		height: vw-calc(20);
		background-image: url('../../assets/images/icon-x.svg');
	}
}

.InvestigationTutorial-instructions {
	position: absolute;
	top: vw-calc(270);
	right: vw-calc(60);
	width: vw-calc(408);
	background-image: linear-gradient(rgba(#015051, 0.9), rgba(#002F2C, 0.9));
	color: white;
	padding: vw-calc(10) vw-calc(24) vw-calc(60) vw-calc(20);
	font-size: vw-calc(14);
	font-family: 'Fivo Sans';
	line-height: 1.4;
	font-weight: 600;
	z-index: 11;
	overflow: hidden;
	@include no-select();
	@include corner-box(cyan);
}

.InvestigationTutorial-dragBtn {
	position: absolute;
	top: vw-calc(-23);
	right: vw-calc(-15);
	cursor: grab;
	cursor: -moz-grab;
	cursor: -webkit-grab;
	width: vw-calc(35);
	height: vw-calc(65);
	background-image: url('../../assets/images/icon-drag-handle.svg');
	background-size: contain;
	background-repeat: no-repeat;
	z-index: 12;
	&.dragging {
		cursor: grabbing;
		cursor: -moz-grabbing;
		cursor: -webkit-grabbing;
	}
}

.InvestigationTutorial-instructionsTitle {
	color: $cyan;
	font-size: vw-calc(18);
	font-weight: 600;
	text-transform: uppercase;
	margin-bottom: vw-calc(-10);
}

.InvestigationTutorial-instructionsButtons {
	position: relative;
	width: 100%;
}

.InvestigationTutorial-instructionsPrevBtn,
.InvestigationTutorial-instructionsNextBtn {
	position: absolute;
	width: vw-calc(120);
	bottom: vw-calc(-45);
	text-align: center;
	text-transform: uppercase;
	font-weight: 600;
	font-size: vw-calc(18);
	background-image: linear-gradient($btn-gradient-light, $btn-gradient-dark);
	padding: vw-calc(8) vw-calc(20);
	padding-bottom: vw-calc(5);
	cursor: pointer;
	&:hover {
		background-image: linear-gradient(darken($btn-gradient-light, 5%), darken($btn-gradient-dark, 5%));
	}
}

.InvestigationTutorial-instructionsPrevBtn {left: 0;}
.InvestigationTutorial-instructionsNextBtn {right: vw-calc(-10);}