$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
.StatsAdmin {
	position: relative;
	width: 100%;
	height: 100%;
	background-color: white;
	color: black;
	padding: vw-calc(50) vw-calc(20);
	overflow-y: scroll;

	&-title {
		font-weight: bold;
	}
}
.StatsAdmin-loading {
	span {
		animation-name: loading-dots;
		animation-duration: 1.4s;
		animation-iteration-count: infinite;
		animation-fill-mode: both;
	}
	span:nth-child(2) {animation-delay: .2s;}
	span:nth-child(3) {animation-delay: .4s;}
}
.StatsAdmin-backBtn {
	position: absolute;
	top: vw-calc(10);
	left: vw-calc(20);
	display: inline-block;
	background-color: lightgrey;
	padding: vw-calc(8) vw-calc(12);
	border-radius: vw-calc(5);
	font-weight: bold;
	margin: 0;
	cursor: pointer;
	&:hover {background-color: darken(lightgrey, 5%);}
}

.StatsAdmin-overview {
	display: flex;
	flex-direction: column;
	width: 80%;
	justify-content: space-evenly;
	align-items: center;
	&-title {
		font-weight: bold;
	}
	.StatsAdmin-overviewContent {
		display: flex;
		width: 80%;
		justify-content: space-evenly;
		align-items: center;
	}
}
.StatsAdmin-users {
	& table {
		border-style: solid;
		& thead{	
			font-weight: bold;			
		}
		& td:last-child{
		text-align: center;
		}
	}
	
}

@-webkit-keyframes loading-dots {
	0% {opacity: .2;}
	20% {opacity: 1;}
	100% {opacity: .2;}
}
@-moz-keyframes loading-dots {
	0% {opacity: .2;}
	20% {opacity: 1;}
	100% {opacity: .2;}
}
@-o-keyframes loading-dots {
	0% {opacity: .2;}
	20% {opacity: 1;}
	100% {opacity: .2;}
}
@keyframes loading-dots {
	0% {opacity: .2;}
	20% {opacity: 1;}
	100% {opacity: .2;}
}

