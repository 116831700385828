$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.CookieConsent {
	position: absolute;
	width: 100%;
	left: 0;
	bottom: vw-calc(-200);
	color: #21CBCB;
	background-color: rgba(#0B6564, 0.85);
	height: vw-calc(180);
	-webkit-animation: slideIn 0.5s linear 1s 1 forwards;
	animation: slideIn 0.5s linear 1s 1 forwards;
	z-index: 100;
	margin-bottom: 3em;
}

.CookieConsent-text {
	width: 100%;
	padding-left: vw-calc(310);
	padding-right: vw-calc(300);
	padding-top: vw-calc(15);
	span {
		font-size: vw-calc(14);
	}
	a {
		font-size: vw-calc(14);
		color: #21CBCB;
	}
}

.CookieConsent-button {
	padding-left: vw-calc(310);
	padding-top: vw-calc(15);

	.Button {
		padding: vw-calc(5) vw-calc(25);
		height: vw-calc(36);
		font-size: vw-calc(16);
		background: linear-gradient(#FEC186, #D36C5C);
		&:hover,
		&:focus,
		&:active {
			background-image: linear-gradient(darken(#FEC186, 5%), darken(#D36C5C, 5%));
		}
	}
}


@-webkit-keyframes slideIn {
	100% {bottom: 0}
}
@keyframes slideIn {
	100% {bottom: 0}
}