$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.CyoaIntro {
	position: relative;
	padding: vw-calc(30);
	.Button {
		margin-right: vw-calc(20);
		font-size: vw-calc(18);
	}
}

.CyoaIntro-title {
	font-size: vw-calc(20);
	font-family: "Fivo Sans";
	margin-bottom: vw-calc(10);
}


.CyoaIntro-text {
	margin-bottom: vw-calc(30);
}
