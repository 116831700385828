$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.Camera {
	position: absolute;
	right: vw-calc(32);
	top: vw-calc(24);
	width: vw-calc(225);
	text-align: left;
	&--settings {
		display: none;
	}
	&--overview {
		top: vw-calc(-5);
		right: vw-calc(280);
		z-index: 1;
		.Camera-images {
			background-image: none;
			&::before {
				display: none;
			}
		}
		.Camera-title,
		.Camera-frame--cam1,
		.Camera-frame--cam2 {
			display: none;
		}
		.Camera-frame--cam3 {
			width: vw-calc(141);
			height: vw-calc(70);
			background-image: linear-gradient($bg-gradient-light, $bg-gradient-dark);
			.CameraBaseContainer--BaseOverview {
				position: relative;
				height: vw-calc(57);
				.CameraBase {
					top: vw-calc(-330);
					left: vw-calc(-575);
					@include scale(0.1);
				}
			}
			.Camera-info1 {
				width: 100%;
			}
			.Camera-overlay {
				background-image: linear-gradient(rgba(165, 43, 23, 0), rgba(165, 43, 23, 0.4))
			}
			.Camera-info1 span,
			.Camera-info2 {
				display: none;
			}
		}
	}
}

.Camera-title {
	position: relative;
	display: inline-block;
	text-transform: uppercase;
	font-size: vw-calc(20);
	line-height: vw-calc(24);
	margin-bottom: vw-calc(12);
}


.Camera-images {
	position: relative;
	background-image: linear-gradient($bg-gradient-light, $bg-gradient-dark);
	padding: vw-calc(25) vw-calc(20) 0 vw-calc(25);
	border-bottom: vw-calc(2) solid $cyan;
	min-height: vw-calc(400);
	&::before {
		content: "";
		position: absolute;
		width: vw-calc(225);
		height: vw-calc(10);
		top: vw-calc(-1);
		left: 0;
		background-image: url('../../assets/images/camera-border.png');
		background-size: vw-calc(225) auto;
		background-position: left top;
		background-repeat: no-repeat;
	}
}

.Camera-frame {
	position: relative;
	padding: vw-calc(3);
	margin-bottom: vw-calc(25);
	overflow: hidden;
	@include corner-box(cyan);

	&--cam1 {
		height: vw-calc(140);
	}
	&--cam2 {
		height: vw-calc(155);
	}
	&--cam3 {
		height: vw-calc(100);
	}
	&--clickable {
		cursor: pointer;
	}
}


.Camera-image {
	position: relative;
	height: 100%;
	background-color: black;
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: top center;
}

.Camera-overlay {
	position: absolute;
	top: unset;
	right: vw-calc(3);
	bottom: vw-calc(3);
	left: vw-calc(3);
	&--red {
		height: vw-calc(50);
		background: linear-gradient(rgba(#A52B17, 0), rgba(#A52B17, 0.62));
	}
	&--black {
		top: unset;
		height: vw-calc(37);
		background-color: rgba(black, 0.5);
	}
}

.Camera-info1 {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 65%;
	text-align: left;
	padding: vw-calc(6);
	font-family: 'Fivo Sans';
	font-weight: 600;
	font-size: vw-calc(10);
	line-height: vw-calc(12);
	text-transform: uppercase;
	span {
		display: block;
		font-size: vw-calc(8);
		height: vw-calc(8);
	}
}


.Camera-info2 {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 65%;
	padding: vw-calc(6);
	padding-right: vw-calc(8);
	text-align: right;
	text-transform: uppercase;
	font-family: 'Fivo Sans';
	font-weight: 600;
	font-size: vw-calc(12);
	span {
		display: block;
		text-transform: none;
		font-size: vw-calc(8);
		height: vw-calc(8);
	}
}