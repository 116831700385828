$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.BaseElement {
	position: absolute;
	background-size: contain;
	background-position: top left;
	background-repeat: no-repeat;
	&--ice {
		top: vw-calc(220);
		right: vw-calc(55);
		width: vw-calc(100);
		height:	vw-calc(110);
		background-image: url('../../assets/images/base/elements/ice.svg');
		background-position: top right;
		&--repaired {
			background-image: url('../../assets/images/base/elements-repaired/ice.svg');
		}
	}
	&--rocket {
		left: vw-calc(835);
		top: vw-calc(103);
		width: vw-calc(85);
		height: vw-calc(147);
		background-image: url('../../assets/images/base/elements-repaired/rocket.svg');
	}
	&--dome {
		left: vw-calc(516);
		top: vw-calc(366);
		width: vw-calc(195);
		height: vw-calc(185);
		background-image: url('../../assets/images/base/elements/dome.svg');
		&--repaired {
			background-image: url('../../assets/images/base/elements-repaired/dome.svg');
		}
	}
	&--dorms {
		left: vw-calc(391);
		top: vw-calc(277);
		width: vw-calc(240);
		height: vw-calc(200);
		background-image: url('../../assets/images/base/elements/dorms.svg');
		&--repaired {
			background-image: url('../../assets/images/base/elements-repaired/dorms.svg');
		}
	}
	&--entrance {
		left: vw-calc(641);
		top: vw-calc(466);
		width: vw-calc(204);
		height: vw-calc(200);
		background-image: url('../../assets/images/base/elements/entrance.svg');
		&--repaired {
			left: vw-calc(642);
			top: vw-calc(467);
			width: vw-calc(153);
			height: vw-calc(200);
			background-image: url('../../assets/images/base/elements-repaired/entrance.svg');
		}
	}
	&--satellite {
		left: vw-calc(918);
		top: vw-calc(402);
		width: vw-calc(155);
		height: vw-calc(200);
		background-image: url('../../assets/images/base/elements/satellite.svg');
		&--repaired {
			width: vw-calc(100);
			left: vw-calc(925);
			top: vw-calc(343);
			background-image: url('../../assets/images/base/elements-repaired/satellite.svg');
		}
	}
	&--garage {
		left: vw-calc(685);
		top: vw-calc(310);
		width: vw-calc(255);
		height: vw-calc(200);
		background-image: url('../../assets/images/base/elements/garage.svg');
		&--repaired {
			left: vw-calc(686);
			top: vw-calc(310);
			width: vw-calc(225);
			background-image: url('../../assets/images/base/elements-repaired/garage.svg');
		}
	}
	&--lab {
		left: vw-calc(294);
		top: vw-calc(449);
		width: vw-calc(264);
		height: vw-calc(200);
		background-image: url('../../assets/images/base/elements/lab.svg');
		&--repaired {
			background-image: url('../../assets/images/base/elements-repaired/lab.svg');
		}
	}
	&--greenhouse {
		left: vw-calc(420);
		top: vw-calc(484);
		width: vw-calc(205);
		height: vw-calc(200);
		background-image: url('../../assets/images/base/elements/greenhouse.svg');
		&--repaired {
			z-index: 1;
			background-image: url('../../assets/images/base/elements-repaired/greenhouse.svg');
		}
	}
	&--communication {
		left: vw-calc(770);
		top: vw-calc(438);
		width: vw-calc(142);
		height: vw-calc(200);
		background-image: url('../../assets/images/base/elements/communication.svg');
		&--repaired {
			left: vw-calc(771);
			background-image: url('../../assets/images/base/elements-repaired/communication.svg');
		}
	}
	&--solarpanels {
		left: vw-calc(30);
		bottom: vw-calc(53);
		width: vw-calc(252);
		height: vw-calc(145);
		background-image: url('../../assets/images/base/elements/solar-cells.svg');
		background-position: bottom left;
		background-size: 98%;
		&--repaired {
			left: vw-calc(35);
			bottom: vw-calc(55);
			width: vw-calc(245);
			background-size: contain;
			background-image: url('../../assets/images/base/elements-repaired/solar-cells.svg');
		}
	}
	&--3dprinter {
		left: vw-calc(40);
		top: vw-calc(335);
		width: vw-calc(129);
		height: vw-calc(74);
		// background-image: url('../../assets/images/base/elements/3d-printer.svg');
		background-image: url('../../assets/images/base/elements-repaired/3d-printer.svg');
		&--repaired {
			background-image: url('../../assets/images/base/elements-repaired/3d-printer.svg');
		}
	}
	&--3dbase {
		left: vw-calc(74);
		top: vw-calc(215);
		width: vw-calc(200);
		height: vw-calc(110);
		// background-image: url('../../assets/images/base/elements/3d-base.svg');
		background-image: url('../../assets/images/base/elements-repaired/3d-base.svg');
		&--repaired {
			background-image: url('../../assets/images/base/elements-repaired/3d-base.svg');
		}
	}
	&--curiosity {
		left: vw-calc(685);
		bottom: vw-calc(10);
		width: vw-calc(114);
		height: vw-calc(74);
		background-image: url('../../assets/images/base/elements/rover-curiosity.svg');
		background-position: bottom left;
		&--repaired {
			background-image: url('../../assets/images/base/elements-repaired/rover-curiosity.svg');
		}
	}
	&--bigvehicle1 {
		left: vw-calc(1070);
		top: vw-calc(390);
		width: vw-calc(102);
		height: vw-calc(75);
		background-image: url('../../assets/images/base/elements/vehicle-big.svg');
		&--repaired {
			background-image: url('../../assets/images/base/elements-repaired/vehicle-big.svg');
		}
	}
	&--bigvehicle2 {
		left: vw-calc(770);
		top: vw-calc(205);
		width: vw-calc(40);
		height: vw-calc(30);
		@include scaleX(-1);
		background-image: url('../../assets/images/base/elements/vehicle-big.svg');
		&--repaired {
			background-image: url('../../assets/images/base/elements-repaired/vehicle-big.svg');
		}
	}
	&--smallvehicle1 {
		left: vw-calc(1125);
		top: vw-calc(357);
		width: vw-calc(63);
		height: vw-calc(46);
		z-index: 2;
		-webkit-transform: rotate(15deg);
		transform: rotate(15deg);
		background-image: url('../../assets/images/base/elements/vehicle-small.svg');
		&--repaired {
			left: vw-calc(1120);
			top: vw-calc(350);
			-webkit-transform: none;
			transform: none;
			background-image: url('../../assets/images/base/elements-repaired/vehicle-small.svg');
		}
	}
	&--smallvehicle2 {
		left: vw-calc(1083);
		top: vw-calc(332);
		width: vw-calc(63);
		height: vw-calc(46);
		z-index: 1;
		background-image: url('../../assets/images/base/elements/vehicle-small.svg');
		&--repaired {
			background-image: url('../../assets/images/base/elements-repaired/vehicle-small.svg');
		}
	}
	&--smallvehicle3 {
		left: vw-calc(1090);
		top: vw-calc(215);
		width: vw-calc(22);
		height: vw-calc(16);
		@include scaleX(-1);
		background-image: url('../../assets/images/base/elements/vehicle-small.svg');
		&--repaired {
			background-image: url('../../assets/images/base/elements-repaired/vehicle-small.svg');
		}
	}
	&--smallvehicle4 {
		left: vw-calc(440);
		top: vw-calc(258);
		width: vw-calc(60);
		height: vw-calc(42);
		background-image: url('../../assets/images/base/elements/vehicle-small.svg');
		&--repaired {
			background-image: url('../../assets/images/base/elements-repaired/vehicle-small.svg');
		}
	}
	&--drone1 {
		left: vw-calc(230);
		top: vw-calc(390);
		width: vw-calc(45);
		height: vw-calc(22);
		background-image: url('../../assets/images/base/elements/drone.svg');
		&--repaired {
			left: vw-calc(250);
			top: vw-calc(400);
			background-image: url('../../assets/images/base/elements-repaired/drone.svg');
		}
	}
	&--drone2 {
		left: vw-calc(990);
		top: vw-calc(285);
		width: vw-calc(45);
		height: vw-calc(22);
		background-image: url('../../assets/images/base/elements/drone.svg');
		@include scaleX(-1);
		&--repaired {
			background-image: url('../../assets/images/base/elements-repaired/drone.svg');
		}
	}
	&--stars {
		top: vw-calc(20);
		left: vw-calc(450);
		width: vw-calc(390);
		height: vw-calc(75);
		background-image: url('../../assets/images/base/elements/stars.svg');
	}
	&--crater {
		top: vw-calc(111);
		left: vw-calc(1094);
		width: vw-calc(150);
		height: vw-calc(28);
		background-image: none;
		&--repaired {
			background-image: url('../../assets/images/base/elements-repaired/crater.svg');
		}
	}
	&--spacesuit {
		left: vw-calc(642);
		top: vw-calc(640);
		width: vw-calc(300);
		height: vw-calc(45);
		background-image: url('../../assets/images/base/elements-repaired/spacesuit.svg');
	}
	&--hidden-base {
		top: vw-calc(65);
		left: vw-calc(907);
		width: vw-calc(150);
		height: vw-calc(57);
		background-image: none;
		&--repaired {
			background-image: url('../../assets/images/base/elements-repaired/hidden-base.svg');
		}
	}
	&--foreground {
		bottom: 0;
		left: 0;
		width: vw-calc(390);
		height: vw-calc(75);
		background-image: url('../../assets/images/base/foreground.svg');
		background-position: bottom left;
	}
}