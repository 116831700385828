// 300: light, 400: regular, 600: semiBold, 700: bold


// font-family: 'Open Sans', sans-serif;
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');


// font-family: 'Fivo Sans', sans-serif;
@font-face {
	font-family: 'Fivo Sans';
	font-weight: 400;
	font-style: normal;
  src: url('../assets/fonts/fivosans/fivosans-regular.otf');
}

@font-face {
	font-family: 'Fivo Sans';
	font-weight: 600;
	font-style: normal;
  src: url('../assets/fonts/fivosans/fivosans-medium.otf');
}

@font-face {
	font-family: 'Fivo Sans';
	font-weight: 300;
	font-style: normal;
  src: url('../assets/fonts/fivosans/fivosans-light.otf');
}