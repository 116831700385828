$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
.HiddenBase {
	position: absolute;
	left: vw-calc(-290);
	right: vw-calc(-290);
	top: vw-calc(-35);
	bottom: vw-calc(-26);
	padding: vw-calc(40) vw-calc(50);
	z-index: 10;
	color: white;
	@include no-select();
	background-image: url('../../../assets/images/background.jpg');
	background-size: cover;
	background-position: top left;
	font-family: "Fivo Sans";
	&::before {
		content:"";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($bg-dark, .8);
	}
}

.HiddenBase-close {
	position: absolute;
	top: vw-calc(10);
	right: vw-calc(45);
	width: vw-calc(30);
	height: vw-calc(30);
	background-image: url('../../../assets/images/icon-x.svg');
	background-size: contain;
	background-repeat: no-repeat;
	cursor: pointer;
	@include no-select();
	&:hover {@include scale(1.1);}
}

.HiddenBase-content {
	position: relative;
	width: vw-calc(1187);
	height: vw-calc(630);
	background-image: linear-gradient($bg-gradient-light, $bg-gradient-dark);
	border-bottom: vw-calc(2) solid $cyan;
	padding: vw-calc(20);
	padding-right: 0;
	margin-top: vw-calc(20);
	&::before {
		content:"";
		width: vw-calc(1187);
		height: vw-calc(496);
		position: absolute;
		top: 0;
		left: 0;
		background-image: linear-gradient($btn-gradient-light, $btn-gradient-dark);
	}
	&::after {
		content:"";
		width: vw-calc(80);
		height: vw-calc(80);
		position: absolute;
		top: 0;
		right: 0;
		background-image: url('../../../assets/images/corner-triangle.svg');
		background-size: 100%;
		background-position: center;
	}

	.HiddenBase-rooms {
		width: vw-calc(590);
		height: vw-calc(440);
		display: inline-block;
		background-image: url('../../../assets/images/infoMinigames/infoMinigame-2/rooms.png');
		background-size: contain;
		background-repeat: no-repeat;
		position: relative;
		margin-top: vw-calc(50);

		.HiddenBase-room {
			position: absolute;
			top: vw-calc(17);
			left: 0;
			width: vw-calc(118);
			height: vw-calc(79);
			text-align: center;
			@include flex('center', 'center');
			cursor: pointer;
			div {z-index: 2;}
			span {
				z-index: 1;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-image: linear-gradient(#015051, #002F2C);
				outline: vw-calc(2) solid transparent;
				transition: all 0.3s ease-in-out;
				@include opacity(0);
			}
			&:hover span,
			&--selected span {
				outline: vw-calc(2) solid $cyan;
				@include opacity(1);
			}
			&--office {
				left: vw-calc(31);
			}
			&--workshop-lab {
				width: vw-calc(206);
				height: vw-calc(108);
				left: vw-calc(170);
				padding: 0 vw-calc(20);
			}
			&--kitchen {
				width: vw-calc(160);
				height: vw-calc(108);
				left: vw-calc(397);
			}
			&--living {
				width: vw-calc(160);
				height: vw-calc(214);
				top: vw-calc(154);
				left: vw-calc(397);
			}
			&--technic {
				@include rotate(90deg);
				width: vw-calc(143);
				height: vw-calc(57);
				top: vw-calc(197);
				left: vw-calc(276);
			}
			&--reactor {
				width: vw-calc(143);
				height: vw-calc(142);
				border-radius: 50%;
				top: vw-calc(151);
				left: vw-calc(160);
				span {
					border-radius: 50%;
					outline: none;
					border: vw-calc(2) solid $cyan;
				}
				&:hover span,
				&--selected span {
					outline: none;
				}
			}
			&--control {
				width: vw-calc(74);
				height: vw-calc(78);
				top: vw-calc(204);
				left: vw-calc(76);
			}
			&--storage {
				width: vw-calc(90);
				height: vw-calc(78);
				top: vw-calc(111);
				left: vw-calc(31);
			}
		}
	}

	.HiddenBase-infoWrapper {
		display: inline-block;
		position: relative;
		vertical-align: top;
		margin-top: vw-calc(25);
		width: vw-calc(545);
		height: vw-calc(394);
		font-size: vw-calc(20);
		font-weight: 600;
		&::before,
		&::after {
			content: "";
			width: vw-calc(550);
			height: vw-calc(20);
			position: absolute;
			left: vw-calc(-3);
			top: vw-calc(39);
			z-index: 2;
			background-image: url('../../../assets/images/border-bottom.svg');
			background-repeat: no-repeat;
			background-size: 100%;
			background-position-y: bottom;
			@include rotate(180deg);
		}

		&::after {
			@include rotate(0deg);
			top: unset;
			bottom: vw-calc(0);
			background-position-y: bottom;
		}
		.HiddenBase-info {
			height: vw-calc(354);
			padding-top: vw-calc(20);
			padding-left: vw-calc(20);
			padding-right: vw-calc(5);
			margin-top: vw-calc(10);
			overflow: auto;
			position: relative;
			background-image: linear-gradient(#015051, #002F2C);
			@include fancy-scrollbar(vw-calc(16), 100%, #0D5151, 0, vw-calc(16), vw-calc(139), #00CBCB, 1px solid $cyan);
			img {
				width: vw-calc(518);
				margin-top: vw-calc(5);
				margin-left: vw-calc(-20);
			}
			div {
				font-size: vw-calc(16);
				font-weight: 500;
				white-space: pre-line;
				strong {
					font-weight: 600;
				}
				&:first-of-type {
					margin-right: vw-calc(10);
				}
			}
			&--construction {
				@include flex('space-between', 'flex-start');
				div {
					width: 50%;
					padding-bottom: vw-calc(20);
				}
			}
		}
	}

	.HiddenBase-constructionMethods {
		position: absolute;
		font-size: vw-calc(20);
		font-weight: 600;
		bottom: vw-calc(20);
		p {
			margin-bottom: vw-calc(10);
		}
		.Button {
			margin-right: vw-calc(20);
			margin-bottom: vw-calc(5);
			&.selected {
				background-image: linear-gradient(lighten($bg-gradient-light, 5%), lighten($bg-gradient-dark, 5%));
				color: white;
			}
		}
	}
}