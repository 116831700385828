$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.MinigameScore {
	position: absolute;
	top: vw-calc(30);
	right: vw-calc(110);
}

.MinigameScore-slider {
	width: vw-calc(300);
	height: vw-calc(2);
	background-color: white;
}
.MinigameScore-counter {
	background-color: $cyan;
	height: vw-calc(4.25);
	width: 0;
	position: absolute;
	top: vw-calc(-1);
	@include transition('width', 1s, 'ease-out');
}

.MinigameScore-value {
	font-family: "Fivo Sans";
	font-weight: 600;
	font-size: vw-calc(14);
	color: white;
	margin-top: vw-calc(10);
	span {
		color: $cyan;
	}
}