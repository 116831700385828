$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.Tips {
	position: absolute;
	top: vw-calc(20);
	right: vw-calc(20);
	height: vw-calc(32);
	overflow: hidden;
	z-index: 1;
	cursor: pointer;
	&.show {height: auto;}
	table {width: vw-calc(400);}
	td {
		font-size: vw-calc(20);
		width: vw-calc(200);
		vertical-align: middle;
	}
	td.left {text-align: left;}
	td.right {text-align: right;}
	pre {
		margin: 0;
		font-size: vw-calc(20);
	}
	ul, ol {
		margin: 0;
		padding-left: vw-calc(25);
	}
	h1, h2, h3 {
		margin: 0;
	}

	&--minigame {
		top: vw-calc(210);
		right: vw-calc(-20);
		table {
			width: vw-calc(240);
		}
		td, pre {
			font-size: vw-calc(16);
		}
		td {
			width: vw-calc(120);
		}
	}
}
