$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.Minigame {
	position: absolute;
	left: vw-calc(-290);
	right: vw-calc(-290);
	top: vw-calc(-30);
	bottom: vw-calc(-24);
	padding: vw-calc(40) vw-calc(50);
	z-index: 10;
	@include no-select();
	background-image: url('../../assets/images/background.jpg');
	background-size: cover;
	background-position: top left;
	&::before {
		content:"";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($bg-dark, .8);
	}
}

.Minigame-close {
	position: absolute;
	top: vw-calc(30);
	right: vw-calc(45);
	width: vw-calc(30);
	height: vw-calc(30);
	background-image: url('../../assets/images/icon-x.svg');
	background-size: contain;
	background-repeat: no-repeat;
	cursor: pointer;
	@include no-select();
	&:hover {@include scale(1.1);}
}

.Minigame-back {
	position: relative;
	display: inline-block;
	vertical-align: top;
	width: vw-calc(20);
	height: vw-calc(20);
	background-image: url('../../assets/images/icon-back.svg');
	background-size: auto vw-calc(18);
	background-position: center top;
	background-repeat: no-repeat;
	margin-right: vw-calc(5);
	cursor: pointer;
	@include no-select();
}

.Minigame-part {
	position: relative;
	display: inline-block;
	vertical-align: top;
	font-family: "Fivo Sans";
	font-size: vw-calc(18);
	line-height: vw-calc(22);
	color: $cyan;
	@include no-select();
}

.Minigame-nextPartBtn,
.Minigame-exitMinigameBtn {
	position: absolute;
	bottom: vw-calc(40);
	right: vw-calc(30);
	width: vw-calc(140);
	height: vw-calc(65);
	font-family: "Fivo Sans";
	font-size: vw-calc(20);
	line-height: vw-calc(65);
	text-align: left;	
	text-transform: uppercase;
	padding-left: vw-calc(30);
	background-image: url('../../assets/images/minigames/next-button.svg');
	background-size: contain;
	background-position: bottom right;
	background-repeat: no-repeat;
	cursor: pointer;
	opacity: 0;
	@include fadeIn(0.5);
	@include no-select();
}



.Minigame.Minigame--minigame-3 {
	.Minigame-nextPartBtn,
	.Minigame-exitMinigameBtn {
		bottom: vw-calc(50);
		right: vw-calc(55);
	}
	.Puzzle-answerBtn {
		bottom: vw-calc(14);
		right: vw-calc(25);
		&--teacher {
			right: vw-calc(150);
		}
	}
}

.Minigame.Minigame--minigame-4 {
	.Minigame-nextPartBtn,
	.Minigame-exitMinigameBtn {
		bottom: vw-calc(32);
		right: vw-calc(35);
	}
	.Puzzle-answerBtn {
		bottom: vw-calc(-44);
		right: vw-calc(0);
		&--teacher {
			right: vw-calc(150);
		}
	}
	.Puzzle--17 {
		.Puzzle-answerBtn {
			bottom: vw-calc(46);
		}
	}
	.Puzzle--18 {
		.Minigame-nextPartBtn,
		.Minigame-exitMinigameBtn {
			bottom: vw-calc(32);
		}
		.Puzzle-answerBtn {
			bottom: vw-calc(-10);
		}
	}
	.Puzzle--19 {
		.Puzzle-answerBtn {
			bottom: vw-calc(-22);
		}
	}
}

.Minigame.Minigame--minigame-5 {
	.Puzzle-answerBtn {
		bottom: vw-calc(40);
		right: vw-calc(0);
		&--teacher {
			right: vw-calc(150);
		}
	}
}

.Minigame.Minigame--minigame-6 {
	.Minigame-nextPartBtn,
	.Minigame-exitMinigameBtn {
		bottom: vw-calc(20);
	}
	.Puzzle-answerBtn {
		bottom: vw-calc(-81);
		right: vw-calc(0);
		&--teacher {
			right: vw-calc(140);
		}
	}
	.Puzzle--32 {
		.Puzzle-answerBtn {
			bottom: vw-calc(-109);
		}
	}
}

.Minigame.Minigame--minigame-7 {
	.Minigame-nextPartBtn,
	.Minigame-exitMinigameBtn {
		bottom: vw-calc(50);
		right: vw-calc(70);
	}
	.Puzzle-answerBtn {
		bottom: vw-calc(15);
		right: vw-calc(30);
		&--teacher {
			right: vw-calc(175);
		}
	}
}