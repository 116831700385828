$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../styles/fonts';
@import '../styles/variables';
@import '../styles/mixins';
.App {
	height: 100%;
	min-height: vw-calc(720);
	min-width: vw-calc(1280);
	font-family: "Open Sans", Arial, sans-serif;
	font-size: vw-calc(16);
	background-color: black;
	@include flex($justify:"center", $align:"center", $direction:"");
}

.App-letterBoxWrap {
	width: vw-calc(1280);
	height: vw-calc(720);
	background-color: $bg-dark;
	position: relative;
	overflow: hidden;
}

@media print {
	.App {
		display: block;
		background-color: white;
		width: 100vw;
		height: 100vh;
	}
	.App-letterBoxWrap {
		width: 100%;
		height: 100%;
		background-color: white;
	}
}
