$border-radius: 0.25em;

/* Colors */
$bg-dark: #011B1C;
$green-darkest: #002222;
$green-dark: #013030;
$green: #166563;
$cyan: #04FDFD;
$red: #E21D59;
$brown: #A0390B;

$btn-gradient-light: #00CBCB;
$btn-gradient-dark: #006666;
$bg-tab-title: #015051;
$bg-gradient-light: $bg-tab-title;
$bg-gradient-dark: #002F2C;

$bg-input: #015051;

$text-dark: #10A0A0;
/* Images */