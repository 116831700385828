$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import 'fonts';
@import 'variables';
@import 'mixins';
html {
	font-size: 100vw;
	width: 100%;
	height: 100%;
	background-color: black;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	@include touchaction(manipulation);
}

body {
	position: relative;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	line-height: 1.5;
	background-color: black;
	touch-action: manipulation; // disables double-tap gesture -> no click delay by the browser
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-tap-highlight-color: transparent;
}

h1 {
	font-family: 'Fivo Sans', sans-serif;
	font-size: vw-calc(32);
	text-transform: uppercase;
	margin: vw-calc(15) 0;
}
h2 {
	font-family: 'Fivo Sans', sans-serif;
  font-size: vw-calc(18);
  font-weight: bold;
	margin: vw-calc(10) 0;
}

ul, ol {
	padding-inline-start: vw-calc(30);
	margin: 0;
}

input,
textarea {
	font-family: "Open Sans", Arial, sans-serif;
	font-size: vw-calc(16);
	line-height: 1.5em;
  border: none;
  outline: none;
	@include appearance(none);
}

button {
  background-image: none;
  -webkit-tap-highlight-color: transparent;
  @include no-select();
  &:focus {outline: 0;}
  &::-moz-focus-inner {border: 0;}
}

* {box-sizing: border-box;}

@media (min-aspect-ratio: 16/9) {
	html {
		font-size: calc(100vh * 1.77777778);
	}
}