$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.OverviewStory {
	position: relative;
	width: vw-calc(1220);
	height: vw-calc(350);
	margin: vw-calc(15) vw-calc(30) 0 vw-calc(30);
	&--group {
		.OverviewMission.OverviewMission--ready {
			.OverviewMission-info {
				background: linear-gradient(#009D9D, #015555);
			}
		}
		.OverviewSession.OverviewSession--ready {
			opacity: 0.5;
			cursor: not-allowed;
			.OverviewSession-playBtn {
				opacity: 0.25;
				cursor: not-allowed;
			}
		}
	}
}

.OverviewStory-info {
	position: absolute;
	width: vw-calc(300);
}

.OverviewStory-title {
	width: 100%;
	height: vw-calc(50);
	position: relative;
	z-index: 1;
	overflow: hidden;

}

.OverviewStory-titleBackground {
	background-color: $bg-gradient-light;
	height: 100%;
	width: 100%;
	border-top: vw-calc(4) solid $cyan;
	@include origin(100%, 100%);
	@include skewX(20);
	z-index: 2;
}

.OverviewStory-titleBorder {
	position: absolute;
	width: 100%;
	height: 100%;
	border-right: vw-calc(4) solid $cyan;
	z-index: 2;
}

.OverviewStory-titleText {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	font-family: 'Fivo Sans';
	font-weight: 600;
	font-size: vw-calc(18);
	line-height: 1;
	padding-left: vw-calc(15);
	padding-top: vw-calc(20);
	text-transform: uppercase;
	z-index: 3;
}

.OverviewStory-text {
	position: absolute;
	top: vw-calc(45);
	left: 0;
	height: vw-calc(318);
	width: vw-calc(300);
	padding: vw-calc(25);
	font-size: vw-calc(14);
	background-image: linear-gradient($bg-gradient-light, $bg-gradient-dark);
	border-bottom: vw-calc(2) solid $cyan;
	z-index: 2;
	
}
.OverviewStory-introText {
	margin-bottom: vw-calc(16);
}
.OverviewStory-objectivesText {
	p  {
		margin: 0;
		span {color: $cyan;}
	}
}

.OverviewMissions {
	position: absolute;
	top: vw-calc(45);
	left: vw-calc(294);
	right: 0;
	border-top: vw-calc(2) solid $cyan;
	border-left: vw-calc(4) solid $cyan;
	border-bottom: vw-calc(2) solid $cyan;
	background-image: linear-gradient($bg-gradient-light, $bg-gradient-dark);
	height: vw-calc(318);
	z-index: 3;
	&--3 {
		.OverviewMission {width: 33.3333%;}
		.OverviewSession {width: 100%;}
	}
	&--4 {
		.OverviewMission {width: 25%;}
		.OverviewSession {width: 100%;}
	}
	&--2,
	&--3,
	&--4 {
		.OverviewMission {
			&:nth-last-child(1) {
				.OverviewMission-info {border-right: none;}
				.OverviewSessions {border-right: none;}
			}
		}
	}
}

.OverviewMission {
	display: inline-block;
	vertical-align: top;
	width: 50%;
	height: 100%;
}

.OverviewMission-info {
	height: vw-calc(85);
	padding: vw-calc(10) vw-calc(20);
	background: linear-gradient($btn-gradient-light, $btn-gradient-dark);
	border-right: vw-calc(2) solid $bg-gradient-light;
}
.OverviewMission-title {
	font-family: 'Fivo Sans';
	font-size: vw-calc(16);
	font-weight: 600;
	text-transform: uppercase;
	color: white;
	margin-bottom: vw-calc(5);

}
.OverviewMission-text {
	font-size: vw-calc(12);
}

.OverviewSessions {
	border-right: vw-calc(2) solid $cyan;
	height: vw-calc(230);

	&--3 {
		display: flex;
		flex-wrap: wrap;

		.OverviewSession {
			width: 50%;
			&:nth-child(3) {
				position: absolute;
				bottom: 0;
				left: 25%;
				width: 25%;
				&:before {
					content: "";
					position: absolute;
					top: -98.7%;
					left: 0;
					height: 199%;
					background-color: $cyan;
					width: 1px;
				
				}
				&:after {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					height: 1px;
					width: 100%;
					background-image: linear-gradient(to right, $cyan 0%, $cyan 25%, rgba(255, 255,255,0) 25%, rgba(255, 255,255,0) 75%, $cyan 75%, $cyan 100%);
					background-position: center left;
					background-size: 100% 100%;
					background-repeat: repeat-x;
				
				}
			}
		}
	}

	&--story-7,
	&--story-8 {
		display: block;
		.OverviewSession {
			&:nth-child(3) {
				position: relative;
				left: 50%;
				bottom: 100%;
				width: 50%;
				&:before {
					top: 0;
				}
				&:after {
					top: unset;
					bottom: 0;
				}
			}
		}
	}
}

.OverviewSession {
	position: relative;
	height: vw-calc(115);
	padding: vw-calc(10);
	z-index: 1;
	cursor: pointer;
	&:nth-child(1)::after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		height: 1px;
		width: 100%;
		background-image: linear-gradient(to right, $cyan 0%, $cyan 25%, rgba(255, 255,255,0) 25%, rgba(255, 255,255,0) 75%, $cyan 75%, $cyan 100%);
		background-position: center left;
		background-size: 100% 100%;
		background-repeat: repeat-x;
	
	}
	&--experiment {
		background-image: url('../../assets/images/icon-experiment.svg');
		background-size: auto vw-calc(20);
		background-position: top vw-calc(13) right vw-calc(15);
		background-repeat: no-repeat;
	}
	&--active {
		.OverviewSession-title::before {
			background-image: url('../../assets/images/icon-circle-light.svg');
		}
	}
	&--closed {
		.OverviewSession-title::before {
			background-image: url('../../assets/images/icon-circle-light-full.svg');
		}

	}

}

.OverviewSession-title {
	position: relative;
	font-size: vw-calc(13);
	padding-left: vw-calc(12);
	font-weight: 700;
	text-transform: uppercase;
	margin-bottom: vw-calc(5);
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: vw-calc(-2);
		width: vw-calc(10);
		height: 100%;
		background-image: url('../../assets/images/icon-circle-dark.svg');
		background-size: vw-calc(6) vw-calc(6);
		background-position: center center;
		background-repeat: no-repeat;
	}
}

.OverviewSession-text {
	font-size: vw-calc(12);
	padding-left: vw-calc(12);
}

.OverviewSession-subjectsText {
	color: $cyan;
	margin-top: vw-calc(-5);
	margin-bottom: vw-calc(10);
	p {
		color: white;
		margin-bottom: vw-calc(-5);
		font-weight: 600;
	}
}

.OverviewSession-durationText {
	background-image: url('../../assets/images/icon-clock.svg');
	background-size: auto 90%;
	background-position: left center;
	background-repeat: no-repeat;
	padding-left: vw-calc(20);
}


.OverviewSession-playBtn {
	position: absolute;
	width: vw-calc(60);
	height: 50%;
	right: 0;
	bottom: 0;
	background-image: url('../../assets/images/icon-play.svg');
	background-size: vw-calc(25) auto;
	background-position: right vw-calc(14) bottom vw-calc(20);
	background-repeat: no-repeat;
	cursor: pointer;
}