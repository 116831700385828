$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.ManageSubmissions {
	position: absolute;
	top: vw-calc(90);
	left: vw-calc(65);
	right: vw-calc(40);
	bottom: vw-calc(40);
	background-image: linear-gradient($bg-gradient-light, $bg-gradient-dark);
	padding: vw-calc(25);
	width: vw-calc(1209);
}

.ManageSubmissions-noSubmissions {
	width: vw-calc(975);
	height: 100%;
	padding-top: vw-calc(10);
}

.ManageSubmissions-groups {
	position: relative;
	width: vw-calc(975);
	height: 100%;
	padding-right: vw-calc(20);
	overflow: auto;
	// fancy-scrollbar($width,$height,$backgroundColor,$borderRadius,$thumbWidth, $thumbHeight, $thumbColor) { 
	@include fancy-scrollbar(vw-calc(10), 100%, rgba($cyan, 0.2), vw-calc(10), 100%, vw-calc(20), $cyan);
}

.ManageSubmissions-group {
	width: 100%;
	height: vw-calc(65); // height same as ManageSubmissions-groupHeader height
	background-color: rgba($cyan, 0.24);
	margin-bottom: vw-calc(15);
	@include transition('height', 0.5s, 'ease-in-out');
	overflow: hidden;
	&:nth-last-child(1) {margin-bottom: 0;}
	&.expanded {
		height: vw-calc(245);
		.ManageSubmissions-groupText.submissionOpen{
			overflow-y: auto;
			word-break: break-word;
			p {margin: 0;}
		}
	}
}

.ManageSubmissions-groupHeader {
	position: relative;
	width: 100%;
	height: vw-calc(65);
	padding: vw-calc(14) vw-calc(20);
	border-top: vw-calc(1.5) solid $cyan;
	border-bottom: vw-calc(1.5) solid $cyan;
}

.ManageSubmissions-submissionTitle {
	display: inline-block;
	vertical-align: top;
	width: vw-calc(550);
	height: vw-calc(35);
	line-height: vw-calc(35);
	margin-left: vw-calc(25);
	font-size: vw-calc(18);
	font-weight: 500;
	color: $cyan;
	
}

.ManageSubmissions-input {
	width: 100%;
	height: 100%;
	font-size: vw-calc(18);
	font-weight: 500;
	color: $cyan;
	line-height: vw-calc(35);
	background: rgba(white, 0.1);
	padding: 0;
	cursor: text;
	@include input-placeholder($cyan);
}

.ManageSubmissions-teacherActions {
	position: absolute;
	right: 0;
	top: vw-calc(14);
	width: vw-calc(250);
	height: vw-calc(35);
	text-align: right;
}
.ManageSubmissions-saveGroupBtn {
	position: absolute;
	left: vw-calc(-60);
	display: none;
	&.show {
		display: block;
	}
}
.ManageSubmissions-expandSubmissionBtn,
.ManageSubmissions-toggleGroupBtn,
.ManageSubmissions-editGroupBtn,
.ManageSubmissions-removeGroupBtn {
	width: vw-calc(50);
	height: vw-calc(35);
	display: inline-block;
	background-size: auto vw-calc(30);
	background-repeat: no-repeat;
	background-position: center center;
	cursor: pointer;
}

.ManageSubmissions-expandSubmissionBtn {
	width: vw-calc(30);
	margin-right: vw-calc(25);
	background-size: contain;
	background-image: url('../../assets/images/investigations/icon-arrow-up.svg');
	@include rotate(180deg);
	&.expanded {
		@include rotate(0deg);
	}
}

.ManageSubmissions-toggleGroupBtn {
	margin-right: vw-calc(7);
	background-image: url('../../assets/images/investigations/icon-checkbox.svg');
	&.selected {
		background-image: url('../../assets/images/investigations/icon-checkbox.svg'), url('../../assets/images/investigations/icon-checkmark.svg');
		background-size: auto vw-calc(30), auto vw-calc(20);
	}
}
.ManageSubmissions-editGroupBtn {
	background-image: url('../../assets/images/investigations/icon-edit.svg');
}
.ManageSubmissions-removeGroupBtn {
	background-image: url('../../assets/images/investigations/icon-delete.svg');
}

.ManageSubmissions-groupText {
	padding: vw-calc(15) vw-calc(20);
	height: vw-calc(180);
	font-size: vw-calc(18);
	@include transition('height', 0.5s, 'ease-in-out');
	@include fancy-scrollbar(vw-calc(10), 100%, rgba($cyan, 0.2), vw-calc(10), 100%, vw-calc(20), $cyan);
}

.ManageSubmissions-textarea {
	width: 100%;
	height: 100%;
	font-size: vw-calc(18);
	color: white;
	padding: 0;
	background: rgba(white, 0.1);
	resize: none; 
	cursor: text;
	@include textarea-placeholder($cyan);
	@include fancy-scrollbar(vw-calc(10), 100%, rgba($cyan, 0.2), vw-calc(10), 100%, vw-calc(20), $cyan);
}

.ManageSubmissions {
	.Button {
		position: absolute;
		top: vw-calc(26);
		right: vw-calc(20);
		width: vw-calc(160);
		height: vw-calc(45);
		padding-top: vw-calc(12);
	}
	.ManageSubmissions-saveGroupBtn {
		.Button {
			top: unset;
			right: unset;
			height: vw-calc(37);
			width: vw-calc(80);
			padding-top: vw-calc(8);
		}
	}
}


.ManageSubmissions-closeSubmissions {
	position: absolute;
	top: vw-calc(80);
	right: vw-calc(20);
	width: vw-calc(160);
	height: vw-calc(45);
	background-image: linear-gradient($bg-gradient-light, $bg-gradient-dark);
	border: vw-calc(2) solid #0D5151;
	font-family: "Fivo Sans";
	font-size: vw-calc(15);
	text-transform: uppercase;
	text-align: center;
	padding-top: vw-calc(12);
	color: $cyan;
	cursor: pointer;
}

.ManageSubmissions-groupsSelected {
	position: absolute;
	top: vw-calc(145);
	right: vw-calc(20);
	width: vw-calc(160);
	font-family: "Fivo Sans";
	font-size: vw-calc(15);
	text-transform: uppercase;
	text-align: center;
	line-height: vw-calc(45);
	color: $cyan;
}

.ManageSubmissions-backBtn {
	position: absolute;
	top: vw-calc(-57);
	left: vw-calc(22);
	width: vw-calc(30);
	height: vw-calc(30);
	background-size: vw-calc(13) auto;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url('../../assets/images/investigations/icon-prev.svg');
	&:hover {cursor: pointer;}
}

.ManageSubmissions-stepNumber {
	position: absolute;
	top: vw-calc(-55);
	left: vw-calc(60);
	font-family: "Fivo Sans";
	font-size: vw-calc(18);
	color: $cyan;
}