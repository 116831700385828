$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
.Rover {
	position: absolute;
	left: vw-calc(-290);
	right: vw-calc(-290);
	top: vw-calc(-35);
	bottom: vw-calc(-26);
	padding: vw-calc(40) vw-calc(50);
	z-index: 10;
	color: white;
	@include no-select();
	background-image: url('../../../assets/images/background.jpg');
	background-size: cover;
	background-position: top left;
	&::before {
		content:"";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($bg-dark, .8);
	}

	&--showBlur {
		filter: blur(vw-calc(3));
		-webkit-filter: blur(vw-calc(3));
		-moz-filter: blur(vw-calc(3));
		-o-filter: blur(vw-calc(3));
		-ms-filter: blur(vw-calc(3));
	}
}

.Rover-stats {
	position: absolute;
	top: vw-calc(50);
}

.Rover-staticData {
	position: absolute;
	top: vw-calc(50);
	left: vw-calc(630);
	text-decoration: underline;
	font-weight: 600;
	cursor: pointer;
	&:hover {
		font-size: vw-calc(17);
	}
}

.Rover-entry {
	position: relative;
	margin-top: vw-calc(120);
	width: vw-calc(700);
	height: vw-calc(500);
	overflow: auto;
	@include fancy-scrollbar(vw-calc(10), 100%, rgba($cyan, 0.2), vw-calc(10), 100%, vw-calc(20), $cyan);
	pre,
	code {
		font-family: 'Fivo Sans';
		white-space: pre-line;
	}
}

.Rover-choices {
	.Rover-choice {
		display: inline-block;
		font-weight: bold;
		text-transform: uppercase;
		text-decoration: none;
		text-align: center;
		padding: vw-calc(5) vw-calc(15);
		background-color: lightseagreen;
		color: white;
		border-radius: 0.125em;
		outline: none;
		touch-action: manipulation;
		pointer-events: auto;
		margin-right: vw-calc(10);
		margin-bottom: vw-calc(10);
		cursor: pointer;
		@include no-select();
		@include opacity(1);
		@include doubleTransition('opacity', 0.3s, 'ease-in-out', 'visibility', 0.3s, 'ease-in-out');
		&:hover,
		&:focus,
		&:active {
			background-color: darken(lightseagreen, 5%);
		}
	}
}

.Rover-close {
	position: absolute;
	top: vw-calc(10);
	right: vw-calc(45);
	width: vw-calc(30);
	height: vw-calc(30);
	background-image: url('../../../assets/images/icon-x.svg');
	background-size: contain;
	background-repeat: no-repeat;
	cursor: pointer;
	@include no-select();
	&:hover {@include scale(1.1);}
}