$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.MinigameGroupStatuses {
	position: relative;
	margin-top: vw-calc(30);
	padding-right: vw-calc(20);
	overflow: hidden;
}

.MinigameGroupStatuses-header {
	font-size: vw-calc(18);
	font-weight: 600;
	padding-bottom: vw-calc(7);
	margin-bottom: vw-calc(15);
	border-bottom: 1px solid white;
	@include flex($justify:"space-between", $align:"center", $direction:"row");
	span:last-of-type {
		padding-right: vw-calc(20);
	}
}

.MinigameGroupStatuses-groups {
	margin-top: vw-calc(-5);
	&:before {
		content: "";
		position: absolute;
		top: vw-calc(34);
		left: vw-calc(58);
		width: 1px;
		height: 100%;
		background-color: $cyan;
	}
}

.MinigameGroupStatuses-group {
	margin: vw-calc(10) 0;
	padding-bottom: vw-calc(5);
	border-bottom: 1px solid $cyan;
	&:last-of-type {
		margin-bottom: 0;
	}
}

.MinigameGroupStatuses-groupStatus,
.MinigameGroupStatuses-groupPoints,
.MinigameGroupStatuses-part {
	display: inline-block;
	vertical-align: top;
}

.MinigameGroupStatuses-groupStatus {
	width: 80%;
	display: inline-flex;
	justify-content: space-around;
}

.MinigameGroupStatuses-groupPoints {
	margin-top: vw-calc(5);
	margin-left: vw-calc(10);
	span {
		padding-left: vw-calc(10);
	}
}

.MinigameGroupStatuses-part {
	width: vw-calc(25);
	height: vw-calc(32);
	background-image: url('../../assets/images/icon-circle-light.svg');
	background-size: vw-calc(11) vw-calc(11);
	background-position: center center;
	background-repeat: no-repeat;
	&--completed {
		background-image: url('../../assets/images/icon-circle-cyan-full.svg');
	}
}