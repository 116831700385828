$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.Overview {
	position: relative;
	width: 100%;
	height: 100%;
	padding: vw-calc(100) 0;
}

.Overview-text {
	width: fit-content;
	text-transform: uppercase;
	font-size: vw-calc(18);
	font-weight: 600;
	padding: 0 vw-calc(15) 0 vw-calc(30);
	margin-bottom: 1em;
	background-image: url('../../assets/images/icon-next.svg');
	background-size: vw-calc(9);
	background-position: right vw-calc(7);
	background-repeat: no-repeat;
	cursor: pointer;
	&:hover {
		letter-spacing: 0.005em;
	}
}