$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.Puzzle-dragPiece {
	position: fixed;
	pointer-events: none;
	z-index: 10;
	left: 0em;
	top: 0em;
}
.Puzzle.Puzzle--23,
.Puzzle.Puzzle--24,
.Puzzle.Puzzle--25,
.Puzzle.Puzzle--26 {
	.Puzzle-dragPiece {
		.Puzzle-piece::before {
			font-family: 'Open Sans';
			font-size: vw-calc(30);
			font-weight: 600;
			height: vw-calc(57);
			width: vw-calc(57);
		}
	}
}
.Puzzle.Puzzle--33 {
	.Puzzle-dragPiece {
		.Puzzle-piece {
			width: vw-calc(77);
			height: vw-calc(382);
			padding: 0;
			&::before { display: none; }
		}
	}
}
.Puzzle.Puzzle--34 {
	.Puzzle-dragPiece {
		.Puzzle-piece {
			width: vw-calc(110);
			height: vw-calc(86);
			background: none;
			border: none;
			&::before { display: none; }
			.Puzzle-icon {
				width: vw-calc(100);
				height: vw-calc(76);
				margin-left: vw-calc(5);
				margin-top: vw-calc(5);
			}
			.Puzzle-pieceName {
				display: none;
			}
		}
	}
}
.Puzzle.Puzzle--35,
.Puzzle.Puzzle--36,
.Puzzle.Puzzle--37 {
	.Puzzle-dragPiece {
		.Puzzle-piece {
			width: vw-calc(90);
			height: vw-calc(40);
			padding: 0;
			&::before { display: none; }
		}
	}
}

.Puzzle.Puzzle--38 {
	.Puzzle-dragPiece {
		.Puzzle-piece {
			width: vw-calc(120);
			height: vw-calc(40);
			padding: 0;
			&::before { display: none; }
		}
	}
}

.Puzzle.Puzzle--39 {
	.Puzzle-dragPiece {
		.Puzzle-piece {
			background: none;
			border: none;
			&::before { display: none; }
			.Puzzle-icon {
				width: vw-calc(80);
				height: vw-calc(40);
				&--infrared-waves {
					height: vw-calc(25);
				}
			}
		}
	}
}