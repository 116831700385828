$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
.BackupAdmin {
	position: relative;
	width: 100%;
	height: 100%;
	background-color: white;
	color: black;
	padding: vw-calc(70) vw-calc(20);
	overflow-y: auto;
	input {
		display: inline-block;
		vertical-align: middle;
		width: vw-calc(320);
		font-size: vw-calc(16);
	}
	textarea {
		width: vw-calc(320);
		height: vw-calc(240);
		font-size: vw-calc(16);
	}
}

.BackupAdmin-title {
	font-weight: bold;
	font-size: vw-calc(18);
	margin: vw-calc(18) 0;
}

.BackupAdmin-backBtn {
	position: absolute;
	top: vw-calc(16);
	left: vw-calc(16);
	display: inline-block;
	background-color: lightgrey;
	padding: 0.5em 0.9em;
	border-radius: 0.3em;
	font-weight: bold;
	margin: 0;
	cursor: pointer;
	&:hover {background-color: darken(lightgrey, 5%);}
}

.BackupAdmin-action,
.BackupAdmin-uploadFile {
	width: 40em;
	display: block;
	margin-top: 1em;
	background-color: lightgrey;
	padding: 0.5em 0.3em;
	border-radius: $border-radius;
	font-weight: bold;
	text-align: center;
	cursor: pointer;
	&:hover {background-color: darken(lightgrey, 5%);}
	&.loading {
		background-image: url('../../../assets/images/icon-loading.svg');
		background-size: 2em auto;
		background-position: right vw-calc(5) center;
		background-repeat: no-repeat;
	}
}

.BackupAdmin-uploadFile {
	width: 20em;
	padding: 0.5em 0.3em;
}