$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.Audio {
	width: vw-calc(260);
	.rhap_container {
		width: 100%;
		min-width: 100%;
		max-width: 100%;
		display: block;
		background-color: transparent;
		box-shadow: none;
		&:focus {outline: none;}

		.rhap_controls-section {
			z-index: 2;
			width: 2em;
			margin-top: 1em;
			margin-right: 1.5em;
			display: inline-block;
			vertical-align: top;
			.rhap_additional-controls,
			.rhap_volume-controls {
				display: none;
			}
			.rhap_main-controls {
				.rhap_play-pause-button {
					font-size: 2em;
					width: 1em;
					height: 1em;
				}
				.rhap_main-controls-button {
					color: $cyan;
				}
			}
		}

		.rhap_progress-section {
			display: block;
			width: 11em;
			padding-top: 1.1em;
			.rhap_progress-container {
				height: 2em;
				margin: 0;
				&:focus {outline: none;}
				.rhap_progress-bar {
					width: 11em;
					height: 1em;
					border-radius: 1em;
					@include box-shadow(0, 0, vw-calc(6), 0, $cyan);
				}
				.rhap_progress-bar-show-download {
					background-color: #002F2C;
					border-radius: 1em;
					&:before {
						content: "";
						width: 0.9em;
						height: 1em;
						background-color: #002F2C;
						position: absolute;
						border-top-right-radius: 1em;
						border-bottom-right-radius: 1em;
						right: -0.55em;
						@include box-shadow(vw-calc(1), 0, vw-calc(6), 0, rgba($cyan, 0.85));
					}
					&:after {
						content: "";
						width: 1.2em;
						height: 1em;
						background-color: #002F2C;
						position: absolute;
						right: 0em;
					}
				}
				.rhap_progress-filled {
					background-color: transparent;
					// border-radius: 1em;
				}
				.rhap_download-progress {
					background-color: rgba(#002F2C, 0.5);
					border-radius: 1em;
				}
				.rhap_progress-indicator {
					width: 1.5em;
					height: 1em;
					margin-left: -0.15em;
					top: -0.05em;
					background-color: transparent;
					box-shadow: none;
					background-image: url('../../assets/images/icon-circle-cyan-full.svg');
					background-size: contain;
					background-repeat: no-repeat;
					background-position: center center;
				}
			}
			.rhap_current-time, 
			.rhap_current-left-time,
			.rhap_total-time {
				display: none;
			}
			.rhap_current-left-time,
			.rhap_total-time {
				text-align: right;
			}
		}
	}
	&--feature {
		width: vw-calc(260);
		margin-top: vw-calc(-10);
	}
}