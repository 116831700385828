$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.NavigationPanel {
	position: absolute;
	width: 100%;
	height: vw-calc(50);
	top: 0;
	left: 0;	
}

.NavigationPanel-navTabBottomLine {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: vw-calc(2);
	background: $cyan;
	z-index: 1;
}

.NavigationPanel-navTab {
	position: relative;
	display: inline-block;
	vertical-align: bottom;
	width: vw-calc(220);
	height: vw-calc(50);
	overflow: hidden;	
	z-index: 0;
	cursor: pointer;
	&--selected { 
		border-bottom: none;
		border-left: vw-calc(4) solid $cyan;
		z-index: 2;
		.NavigationPanel-navTabBackground {
			background-color: $bg-tab-title;
			border-right: none;
			border-bottom: none;
			height: 100%;
			border-top: vw-calc(4) solid $cyan;
			@include origin(100%, 100%);
			@include skewX(20);
		}
		.NavigationPanel-navTabBorder {
			display: block;
		}
		.NavigationPanel-navTabText {
			padding: vw-calc(10) vw-calc(16);
		}
	}
	&--selected.NavigationPanel-navTab--1 {
		border-left: none;
		.NavigationPanel-navTabText {padding: vw-calc(10) vw-calc(20);}
	}
	&--locked {
		display: none;
		cursor: not-allowed;
		opacity: 0.5;
	}
}



.NavigationPanel-navTabBackground {
	z-index: 2;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 0;
	border-bottom: vw-calc(50) solid $bg-tab-title;
	border-right: vw-calc(15) solid transparent;	
}

.NavigationPanel-navTabBorder {
	display: none;
	position: absolute;
	width: 100%;
	height: 100%;
	border-right: vw-calc(4) solid $cyan;
}

.NavigationPanel-navTabText {
	z-index: 3;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	padding: vw-calc(10) vw-calc(20);
	padding-right: 0;
}