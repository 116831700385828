$reference-width: 1280;
@function vw-calc($size) {
  $vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
.BaseTag {
	position: absolute;
	height: vw-calc(85);
	width: vw-calc(65);
	margin-top: vw-calc(-20);
	margin-left: vw-calc(-15);
	background-image: url('../../assets/images/base/tag-empty-glow.svg');
	background-size: auto vw-calc(85);
	background-position: top center;
	background-repeat: no-repeat;
	z-index: 20;
	display: none;
	&--dome {				top: vw-calc(365);	left: vw-calc(535);}
	&--solarpanels {		top: vw-calc(470);	left: vw-calc(100);}
	&--ice {						top: vw-calc(170);	right: vw-calc(110);}
	&--greenhouse {			top: vw-calc(475);	left: vw-calc(500);}
	&--rocket {					top: vw-calc(35);		left: vw-calc(856);}
	&--satellite {			top: vw-calc(285);	left: vw-calc(955);}
	&--entrance {				top: vw-calc(470);	left: vw-calc(715);}
	&--communication {	top: vw-calc(395);	left: vw-calc(870);}
	&--dorms {	top: vw-calc(250);	left: vw-calc(500);}
	&--crater {				top: vw-calc(35);		left: vw-calc(1107);}
	&--vehicles {				top: vw-calc(285);	left: vw-calc(1130);}
	&--lab {			top: vw-calc(430);	left: vw-calc(350);}
	&--garage {					top: vw-calc(280);	left: vw-calc(770);}
	&--curiosity {			top: vw-calc(590);	left: vw-calc(735);}
	&--stars {					top: vw-calc(15);	left: vw-calc(500);}
	&--spacesuit {				top: vw-calc(570);		left: vw-calc(637);}
	&--hidden-base {				top: vw-calc(0);		left: vw-calc(913);}
	&--3dbase {			top: vw-calc(170);	left: vw-calc(140);}
	&--tagged {
		display: inline-block;
		background-image: none;
		margin-top: 0;
		margin-left: vw-calc(-10);
		cursor: pointer;
	}
	&--currentMission {
		display: inline-block;
		@include bounceGlow(2);
	}
	&--open {
		z-index: 21;
	}
}

.BaseTag-icon {
	position: absolute;
	width: vw-calc(55);
	height: vw-calc(69);
	top: vw-calc(-10);
	left: 0;
	background-image: url('../../assets/images/base/tag-bg-full.svg');
	background-size: vw-calc(54) auto;
	background-repeat: no-repeat;
	background-position: top vw-calc(0.5) center;
	text-align: center;
	padding: vw-calc(3);
	padding-top: vw-calc(12);
	z-index: 2;
	img {
		width: vw-calc(30);
	}
	&--pulsating {
		background-size: vw-calc(54) auto;
		background-repeat: no-repeat;
		background-position: top vw-calc(0.5) center;
		background-image: url('../../assets/images/base/tag-bg-full-pulsating.svg');
	}
	&::after {
		content: "";
		display: block;
		width: vw-calc(17);
		height: vw-calc(28);
		background-image: url('../../assets/images/base/tag-cone-full.svg');
		background-size: 100%;
		background-repeat: no-repeat;
		position: absolute;
		bottom: 0;
		left: vw-calc(18);
	}
}


.BaseTag-name {
	position: absolute;
	top: vw-calc(-11);
	left: vw-calc(8);
	width: 0;
	height: vw-calc(50);
	overflow: hidden;
	background-image: url('../../assets/images/base/tag-name-bg.svg');
	background-size: vw-calc(160) vw-calc(55);
	background-repeat: no-repeat;
	background-position: left center;
	color: white;
	@include transition('width', 0.25s, 'ease-in-out');
	font-family: 'Fivo Sans';
	font-size: vw-calc(15);
	line-height: vw-calc(30);
	text-transform: uppercase;
	z-index: 1;
	&--open {
		width: vw-calc(160);
	}
	input, span {
		position: absolute;
		top: vw-calc(10);
		left: vw-calc(35);
		width: 100%;
		color: white;
		padding: vw-calc(3) vw-calc(5);
		line-height: vw-calc(26);
		background-color: transparent;
		font-family: 'Fivo Sans';
		font-size: vw-calc(14);
		text-transform: uppercase;
	}
	input.input--teacher {
		width: 78%;
		padding-right: vw-calc(30);
		background-image: url('../../assets/images/icon-pencil.svg');
		background-size: vw-calc(17);
		background-repeat: no-repeat;
		background-position: right vw-calc(10) top vw-calc(5);
	}
}

.BaseTag-saveBtn {
	position: absolute;
	top: vw-calc(45);
	left: vw-calc(50);
	.Button {
		padding-left: vw-calc(20);
		padding-right: vw-calc(20);
	}
}